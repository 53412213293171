import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { viewSingleCompanyDetails } from "helpers/services/companies/company";
import { imageURL } from "common/constants/commonURLS";
import {
  Avatar,
  Box,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import {
  BorderCard,
  GreyTypography,
  PrimaryButton,
  PrimaryChip,
  PrimaryTypography,
  SecondaryFilledBox,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { perks } from "common/constants/perks";
import TextDivider from "utils/textDividerPrimary";
import CompanyViewTabs from "components/companyView.js/tabs";
import CompanyOverViewIndex from "components/companyView.js/overviewIndex";
import CompanyJobListForProfile from "components/companyView.js/companyJobs";
import SearchParamHook from "hooks/searchParams";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch, useSelector } from "react-redux";
import AssignAccountManagers from "./AssignAccountManager";

export default function CompanyView() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);

  const user = useSelector(state => state.Login);
  const history = useHistory();
  const searchParams = SearchParamHook();
  useEffect(() => {
    if (searchParams?.get("tabs")) {
      setSelectedNav(searchParams?.get("tabs"));
    }
  }, []);
  const { id } = useParams();
  const [selectedNav, setSelectedNav] = useState("0");
  useEffect(() => {
    searchParams.set("tabs", selectedNav);
    history.replace({ search: searchParams?.toString() });
  }, [selectedNav]);
  useEffect(() => {
    initialFetch(id);
  }, [id]);
  const [company, setCompany] = useState({});
  const [industries, setIndustries] = useState([]);
  async function initialFetch(id) {
    dispatch({ type: "START_LOADING" });
    if (id !== undefined) {
      const data = await viewSingleCompanyDetails(id);
      setIndustries(data?.user_industry_details);
      setCompany(data?.company);
      dispatch({ type: "STOP_LOADING" });

      // setCurrentAcm(
      //   data?.company?.is_job_post_verifier_details?.map(item => ({
      //     label: item?.name,
      //     value: item?.id,
      //   }))
      // );
    }
  }
  const [accountPopUp, setAccountPopUp] = useState(false);
  function handleAccountPopUP() {
    setAccountPopUp(!accountPopUp);
  }
  function FindPage() {
    switch (selectedNav) {
      case "0":
        return <CompanyOverViewIndex company={company} />;
      case "1":
        return <CompanyJobListForProfile />;
      default:
        return <ps>oops Nothing found</ps>;
    }
  }
  if (loading) {
    return (
      <div
        style={{
          display: "grid",
          height: "100vh",
          width: "80vw",
          placeItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Stack spacing={2} sx={{ padding: "0px 20px", position: "relative" }}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Avatar
          variant="circular"
          sx={{
            bgcolor: grey["A200"],
            height: 94,
            color: "black",
            width: 94,
          }}
          alt="Company Logo"
          src={`${imageURL}${company?.company_image}`}
        >
          {company?.company_name?.charAt(0)?.toUpperCase()}
        </Avatar>
        <Stack spacing={2}>
          <PrimaryTypography variant="h4">
            {company?.company_name}
          </PrimaryTypography>
          <Stack direction="column">
            <Stack direction="row" spacing={1}>
              {industries?.map(industry => (
                <PrimaryChip label={industry?.name} />
              ))}
            </Stack>
            {user?.usertype === "ADMIN" ? (
              <SecondaryFilledButton
                onClick={handleAccountPopUP}
                sx={{ width: "260px", marginTop: "10px" }}
                startIcon={<FeatherIcon icon="user-plus" size="16" />}
              >
                {company?.is_job_post_verifier_assigned === true
                  ? "Change Account Manager"
                  : "Assign Account Manager"}
              </SecondaryFilledButton>
            ) : (
              ""
            )}
          </Stack>
        </Stack>

        {/* <PrimaryButton
          onClick={() => {
            window.open(`//${company?.website}`);
          }}
          sx={{ width: "160px", position: "absolute", right: "10px" }}
          startIcon={<FeatherIcon icon="external-link" size="16" />}
        >
          visit website
        </PrimaryButton> */}
      </Stack>

      <CompanyViewTabs
        selectedNav={selectedNav}
        setSelectedNav={setSelectedNav}
      />
      <FindPage/>
      <AssignAccountManagers
        isOpen={accountPopUp}
        currentAcm={{
          label: company?.job_post_verifier_details?.email,
          value: company?.job_post_verifier_details?.id,
        }}
        toggle={handleAccountPopUP}
        company={company}
        extras={company?.is_job_post_verifier_details}
      />
    </Stack>
  );
}
