const INIT_STATE = {
  candidate: {},
};

const Candidates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_CANDIDATES":
      return {
        ...state,
        candidate: action.payload,
      };

    default:
      return state;
  }
};

export default Candidates;
