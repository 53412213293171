import { Popper } from "@mui/material";
import { MenuProps, PrimaryPopUp } from "assets/Mui/globalTheme";

export default function TablePopper({ children, ...rest }) {
  return (
    <Popper
      sx={{
        // width: "200px",
        // height: "500px",
        "& .MuiPaper-root": {
          padding: "10px",
          width: "100%",
          borderRadius: "8px",
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        },
      }}
      PaperProps={MenuProps}
      {...rest}
    >
      {children}
    </Popper>
  );
}
