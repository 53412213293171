import { PrimaryTypography } from "assets/Mui/globalTheme";

export default function HeaderTextForCompany({ children }) {
  return (
    <>
      <PrimaryTypography
        sx={{ textTransform: "capitalize" }}
        component="h4"
        variant="h4"
      >
        {children}
      </PrimaryTypography>
    </>
  );
}
