import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Card, CardBody, Col, Input, Label, Row, Container } from "reactstrap";
import "../../../src/index.css";
class CreateInvoice extends React.Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="page-content" style={{ borderRadius: "12px" }}>
              <h3>Invoice</h3>
              <Card className="p-4">
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={12} md={4} xl={4} lg={4}>
                      <div className="mb-3">
                        <Label>Title</Label>
                        <Input
                          type="text"
                          placeholder="Title"
                          className="formLable"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={4} xl={4} lg={4}>
                      <div className="mb-3">
                        <Label>Slug</Label>
                        <Input
                          type="text"
                          placeholder="Slug"
                          className="formLable"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={4} xl={4} lg={4}>
                      <div className="mb-3">
                        <Label>Categories</Label>
                        <Input
                          type="text"
                          placeholder="Categories"
                          className="formLable"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={12} xl={12} lg={12}>
                      <div className="mb-3">
                        <Label>Content Short</Label>
                        <TextArea className="formLable" />
                      </div>
                    </Col>
                    <Col sm={12} md={12} xl={12} lg={12}>
                      <div className="mb-3">
                        <Label>Content</Label>
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Place Your Content Here..."
                        />{" "}
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary px-4">Submit</button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CreateInvoice;
