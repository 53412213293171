const {
  Container,
  Row,
  Col,
  Label,
  Input,
  CardBody,
  Card,
} = require("reactstrap");
import React from "react";
class AttachementForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="padding_x_20px">
                <Col xs={12}>
                  <h5>Enable Question Attachement</h5>
                  <div
                    className="btn-group me-2"
                    role="group"
                    aria-label="Second group"
                  >
                    <button className="btn btn-outline-secondary">YES</button>
                    <button className="btn btn-outline-secondary">NO</button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h5>Attachment Type</h5>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      // transform: "scale(1.4)",
                    }}
                  >
                    <Input type="radio" />
                    <Label> Comprehension Passage</Label>
                    <Input type="radio" />
                    <Label> Audio</Label>
                    <Input type="radio" />
                    <Label> Video</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <button className="theme_btn">Update</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default AttachementForm;
