import callApi from "helpers/callApi";

async function getID() {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
}

export const getAllScreenedApplicantsForModerator = async () => {
  return await callApi(
    `/common/get_individual_stage_applicants?stage=MODERATOR&user_id=${await getID()}`
  );
};
