import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";

export default function AdditionalInformation({ candidateDetails }) {
  const [candidate, setCandidate] = useState({});
  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);

  return (
    <Stack spacing={2} className="mt-3">
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <PrimaryTypography component="h4" variant="h4">
          Additional Information
        </PrimaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            <InputButtonField
              text_field={"expected salary"}
              input_value={candidate?.expected_salary}
              type="input"
              input_type="number"
              update_type={"expected_salary"}
            />
            <InputButtonField
              text_field={"current salary"}
              input_value={candidate?.current_salary}
              input_type="number"
              type="input"
              update_type={"current_salary"}
            />
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}
