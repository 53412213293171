import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  InputBase,
  Stack,
} from "@mui/material";
import {
  BorderCard,
  BorderedCardActionArea,
  PrimaryButton,
  PrimaryIconButton,
  GreyTypography,
  PrimaryAvatar,
  BlackTypography,
  GrayDivider,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  addAttachements,
  getAllAttachements,
} from "helpers/services/kanban/attachement";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
import SearchParamHook from "hooks/searchParams";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import moment from "moment";
export default function UserJobPostAttachment() {
  const [attachments, setAttachments] = useState([]);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const searchParams = SearchParamHook();
  useEffect(() => {
    const userJobPostId = searchParams.get("user_job_post_id");

    if (userJobPostId) {
      fetchAllAttachments(userJobPostId);
      setUserJobPostId(userJobPostId);
    }
  }, [searchParams]);
  async function fetchAllAttachments(userJobPostId) {
    const { data, status } = await getAllAttachements(userJobPostId);
    if (status === 200) {
      if (data) setAttachments(data?.data);
    }
  }

  const [selectedFiles, setSelectedFiles] = React.useState([]);
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function handleAcceptedFiles(files) {
    if (files.length === 1) {
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        crop: false,
      })
    );

    setSelectedFiles(files);
    files?.map(async file => {
      const url = new Promise((resolve, reject) => {
        const uploadedData = getPreSignedURL([file]);
        if (uploadedData) resolve(uploadedData);
        else reject("error");
      });
      // const url = await getPreSignedURL(file);
      url.then(urlData => {
        addAttachements({
          file_name: urlData,
          user_job_post_id: userJobPostId,
        });
        fetchAllAttachments(userJobPostId);
      });
    });
  }
  return (
    <>
      <BorderCard>
        <CardContent>
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            direction="row"
          >
            <PrimaryTypography className="mb-3" component="h3" variant="h3">
              Upload Attachments
            </PrimaryTypography>
            <PrimaryIconButton>
              <FeatherIcon icon="plus" size="14" />
            </PrimaryIconButton>
          </Stack>

          <Dropzone
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                <InputBase {...getInputProps()} />
                <BorderedCardActionArea
                  {...getRootProps()}
                  sx={{ width: "100%" }}
                >
                  <Stack spacing={2} alignItems={"center"}>
                    <FeatherIcon icon="upload-cloud" size="40" />
                    <BlackTypography variant="h6" component="h6">
                      Drop files here or
                    </BlackTypography>
                    <PrimaryButton>Select files to upload</PrimaryButton>
                    <GreyTypography variant="h6" component="h6">
                      Supported File types (20) MB
                    </GreyTypography>
                  </Stack>
                </BorderedCardActionArea>
              </>
            )}
          </Dropzone>
        </CardContent>
      </BorderCard>
      {attachments?.map((f, i) => {
        return (
          <div
            key={i + "-file"}
            sx={{ padding: "10px 30px", marginRight: "10px" }}
          >
            <Stack direction="row" justifyContent={"space-between"}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <PrimaryAvatar
                  alt={f.attachment_created_by?.first_name}
                  src={`${imageURL}${f.file_name}`}
                >
                  {f.attachment_created_by?.first_name?.charAt("0")}
                </PrimaryAvatar>

                <Stack alignItems="flex-start">
                  <a
                    rel="noopener noreferrer"
                    href={`${imageURL}${f.file_name}`}
                    className="text-muted font-weight-bold"
                  >
                    {f.file_name}
                  </a>
                  <p className="mb-0">
                    <strong>{f.formattedSize}</strong>
                  </p>
                </Stack>
              </Stack>
              <Stack spacing={1} direction="row" alignItems="center">
                <PrimaryTypography variant="h4">
                  {f.attachment_created_by?.first_name}
                </PrimaryTypography>{" "}
                <GreyTypography variant="h6" component="h6">
                  {moment(f?.updatedAt)?.format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                </GreyTypography>
              </Stack>
            </Stack>
            <GrayDivider sx={{ marginTop: "10px" }} />
          </div>
        );
      })}
    </>
  );
}
