import { getAllCompanies } from "helpers/services/companies/company";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CompanyDefaulImage from "../../../assets/images/zepul/company_default_img.svg";

function JobsBasedOnCompanies({ history }) {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    async function dataFetcher() {
      const data = await getAllCompanies();
      setCompanies(data);
    }
    dataFetcher();
  }, []);
  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }
  return (
    <>
      <Row>
        {companies.map(item => (
          <Col xs={6} md={6} lg={4} className="mb-2" key={item.id}>
            <div
              className="job_card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/company-job-list/${item.id}`);
              }}
            >
              <div className="image-box">
                {item.company_image_details !== "" ? (
                  <div className="company_img">
                    <img
                      onError={e => {
                        e.target.src = CompanyDefaulImage;
                      }}
                      src={item.company_image_details}
                      alt="logo"
                    />
                  </div>
                ) : (
                  <div
                    className="profile_alternative"
                    style={{
                      background: randcolor(),
                      color: "white",
                    }}
                  >
                    {item.company_name.charAt(0)}
                  </div>
                )}
                <aside>
                  <Link
                    className="h5 mt-2 mb-2"
                    to={`../joblist/jobs/${item.id}`}
                  >
                    {item?.company_name}
                  </Link>
                  <div className="location mt-1">
                    {item.location === null ? "Gachibowli" : item.location}
                  </div>
                </aside>
              </div>
              <div className="job_details">
                <aside style={{ alignSelf: "flex-start" }}>
                  <span className="job_details_header header_3">
                    {item.is_job_post_verifier_assigned
                      ? "assigend"
                      : "not assigned"}
                  </span>
                </aside>{" "}
              </div>
              <div className="my-2 d-flex align-items-center gap-2 flex-wrap job_contents">
                <div className="job_details">
                  <span className="job_details_header header_5">
                    {" "}
                    {item.company_user_details.email}
                  </span>
                </div>
                <div className="job_details">
                  <span className="job_details_header header_2">
                    {" "}
                    {item.company_user_details.phone_number}
                  </span>
                </div>
                <div className="job_details">
                  <span className="job_details_header header_1">
                    {" "}
                    {item.size_of_company === null ? 1 : item.size_of_company}
                  </span>
                </div>
              </div>
              <p className="font-sm job_description mt-10">{item.tagline}</p>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}
export default withRouter(JobsBasedOnCompanies);
