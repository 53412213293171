import { useDispatch, useSelector } from "react-redux";
import ApproveModal from "./ApproveModel";
import JobRoleCustomModel from "./jobRoleModel";

export default function ModalIndex() {
  const dispatch = useDispatch();
  const { open, values } = useSelector(state => state.Modal);
  function handleClose() {
    dispatch({ type: "CLOSE_MODAL" });
  }
  return (
    <>
      <ApproveModal open={open === "APPROVE_MODAL"} handleClose={handleClose} />
      <JobRoleCustomModel
        values={values}
        open={open === "ADD_JOB_ROLE_MODAL"}
        handleClose={handleClose}
      />
    </>
  );
}
