import { IconButton, Input, Rating, Stack,Tooltip,TextField, Modal,Box } from "@mui/material";
import {
  BlackTypography,
  GreyTypography,
  PrimaryButton,
  PrimaryNormalInputs,
  PrimaryTypography,
  SuccessTextButton,
} from "assets/Mui/globalTheme";
import { makeStyles } from '@mui/styles';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ActionModal from "./commentQuestionModal";
import { MODERATOR, SP,TE } from "common/constants/userModules";

const useStyles = makeStyles({
  label:{
      "& label":{
        marginBottom:"0px !important"
      },
}
})

const feedbacks=[
   "Poor",
   "Not Good",
   "Below Average",
   "Average",
   "Good",
   "Excellent"
]

const  popperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [1,-10], // Adjust the values to reduce the distance
      },
    },
  ],
};

export  function  SkillEvaluation({readOnly,skill,updateSkillHandler,deleteSkillHandler }) {
  // console.log(skill,"ttttt")
  const {
    register,
    setValue,
    watch,
    setError,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues:{
      readComment:skill?.comment || "",
      commentReadOnly: skill?.comment || ''
    }
  });
  const [comments, setcomments] = useState(false);
  const [rating,setRating] = useState({
    value:skill?.rating || 0,
    hover:0
  })
  const classes = useStyles()
  const onSubmit = data => {
    const {readComment} = data
    setValue('commentReadOnly',readComment)
    if (Object.values(data) === null || Object.values(data) === "") return;
    updateSkillHandler(skill?.skillInfo?.id,readComment,rating.value,skill.evaluateId)
    setcomments(false);
  };
  function handleStars(_,val){
    const comment = getValues("commentReadOnly") || skill?.comment
    if(!Boolean(comment)){
      setError('commentReadOnly')
      return;
    }
    setRating((pre)=>({
      ...pre,
      value:val
    }))
    updateSkillHandler(skill?.skillInfo?.id,comment,val,skill.evaluateId)
  }

  function handleTrash(){
    deleteSkillHandler(skill?.evaluateId)
  }
  // console.log(getValues(['readComment','commentReadOnly']),'veda');
  return (
    <>
      <Stack spacing={1}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <BlackTypography
            noWrap
            sx={{ textOverflow: "ellipsis", maxWidth: "250px !important" }}
            variant="h3"
          >
            {skill?.skillInfo?.name}
          </BlackTypography>
          <IconButton disabled={readOnly} onClick={handleTrash}>
            <FeatherIcon icon="trash" size="14" />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!comments ? (
              <PrimaryNormalInputs
                sx={{ width: "100%" }}
                {...register("commentReadOnly", { required: true })}
                type="text"
                inputProps={{
                  style: {
                    padding: "8px",
                  },
                }}
                value={getValues("readComment") || skill?.comment}
                // defaultValue={skill?.comment || ""}
                error={errors.commentReadOnly}
                placeholder="Enter your comments here"
                onClick={() => {
                  if (!readOnly) {
                    setcomments(true);
                    setValue(
                      "readComment",
                      getValues("commentReadOnly") || skill?.comment
                    );
                  }
                }}
                readOnly={readOnly}
              />
            ) : (
              <Stack direction="row" spacing={"1"}>
                <Input
                  fullWidth
                  placeholder={"Enter Comment"}
                  {...register("readComment", { required: true })}
                  id="login-first-name-outlined"
                  autoFocus
                  error={errors.readComment}
                  variant="outlined"
                  // value={getValues("readComment") || skill?.comment}
                  defaultValue={skill?.comment || ""}
                />
                <IconButton
                  onClick={() => {
                    clearErrors("readComment");
                    // setValue()
                    setcomments(false);
                  }}
                  color="error"
                >
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            )}
          </form>
          <Tooltip
            PopperProps={popperProps}
            title={feedbacks[rating.hover] || ""}
          >
            <Rating
              className={classes.label}
              onChange={handleStars}
              onChangeActive={(_, hover) =>
                setRating(pre => ({
                  ...pre,
                  hover,
                }))
              }
              getLabelText={value => {
                return `${value} Star${value !== 1 ? "s" : ""}, ${
                  feedbacks[value]
                }`;
              }}
              // precision={0.5}
              value={rating.value}
              size="medium"
              readOnly={readOnly}
            />
          </Tooltip>
        </Stack>
      </Stack>
    </>
  );
}

export function QuestionEvaluation({
  question,
  updateQuestionHandler,
  deleteQuestionHandler,
  readOnly,
}) {
  const [viewActions,setViewActions] = useState(false)
  function handleTrash() {
    deleteQuestionHandler(question?.evaluateId);
  }
  function handleClose(){
    setViewActions(false)
  }
  function handleOpen(e){
    e.stopPropagation()
    setViewActions(true)
  }
  // console.log(getValues(['readComment','commentReadOnly']),'veda');
  return (
    <>
      {question?.view && (
        <Stack spacing={1}>
          <BlackTypography
            // noWrap
            // sx={{ maxWidth: "250px !important" }}
            variant="h3"
            gutterBottom
          >
            {question?.questionName}
          </BlackTypography>
          <TextField
            sx={{ width: "100%" }}
            label="Answer"
            value={question?.answer || ""}
            placeholder="update"
            InputProps={{
              readOnly: true,
            }}
            multiline
            rows={2.5}
          />
          <div style={{ marginLeft: "auto" }}>
            <Tooltip PopperProps={popperProps} title="comment">
              <IconButton disabled={readOnly} onClick={handleOpen}>
                <FeatherIcon
                  fill={question?.comment ? "currentcolor" : "transparent"}
                  icon="message-square"
                  size="14"
                />
              </IconButton>
            </Tooltip>
            <Tooltip PopperProps={popperProps} title="ratings">
              <IconButton disabled={readOnly} onClick={handleOpen}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill={question?.rating ? "#faaf00" : "none"}
                  stroke={!question?.rating ? "currentcolor" : "#faaf00"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-star"
                >
                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                </svg>
              </IconButton>
            </Tooltip>
            <Tooltip PopperProps={popperProps} title="delete">
              <IconButton disabled={readOnly} onClick={handleTrash}>
                <FeatherIcon icon="trash" size="14" />
              </IconButton>
            </Tooltip>
          </div>
          {viewActions && (
            <ActionModal
              question={question}
              readOnly={readOnly}
              open={viewActions}
              updateQuestionHandler={updateQuestionHandler}
              handleClose={handleClose}
            />
          )}
        </Stack>
      )}
    </>
  );
}


