import React from "react";
import "../../assets/css/email.css";

import { Button, Card, Col, Row } from "reactstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/css/settings.css";

class PipeLineInsights extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <div className="candidate_header email_brand_div">
              <div className="">
                <h4>PipeLine Insights</h4>
                <p>
                  Pipeline insights give you an overall overview of all
                  activities in the pipeline, allowing you to streamline your
                  hiring process. .
                </p>
              </div>
              <Button color="primary">Help</Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Card>
              <div className="justify-content-center row">
                <div className="col-sm-4">
                  <div className="text-center">
                    <h5 className="mb-0">2536</h5>
                    <p className="text-muted text-truncate">Activated</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="text-center">
                    <h5 className="mb-0">69421</h5>
                    <p className="text-muted text-truncate">Pending</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="text-center">
                    <h5 className="mb-0">89854</h5>
                    <p className="text-muted text-truncate">Deactivated</p>
                  </div>
                </div>
              </div>
              <PieChart />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <PieChart />
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <PieChart />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card>
              <PieChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <PieChart />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PipeLineInsights;
