import { FormControl, MenuItem, Select, Stack } from "@mui/material";
import {
  PrimaryAvatar,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import GoogleLocation from "components/Common/Forms/location";
import UploadModel from "components/Common/models/uploadModel";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
const company_name = localStorage.getItem("company_name");
export default function FirstCompanyFollowUps() {
  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    initialFetch();
  }, []);
  async function initialFetch() {
    const data = await getAllIndustries();
    setIndustries(data);
  }
  const [modelOpen, setModelOpen] = useState(false);
  const {
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const sizes = [
    { name: "1-50", value: "1-50" },
    { name: "50-100", value: "50-100" },
    { name: "100-200", value: "100-200" },
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      },
    },
  };
  function handleUpload() {
    setModelOpen(!modelOpen);
  }
  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setValue("company_image", `${url}`);
    handleUpload();
  };
  function handleIndustryChange(e) {
    setValue(
      "industry",
      typeof e.target.value === "string" ? value?.split(",") : value
    );
  }
  return (
    <>
      <UploadModel
        isOpen={modelOpen}
        toggle={handleUpload}
        handleImage={handleImageUpload}
      />
      <Stack spacing={2}>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <PrimaryAvatar
            src={`${imageURL}${watch("company_image")}`}
            sx={{
              ...(errors?.company_image && {
                border: "1px solid red",
              }),
              width: "58px !important",
              height: "58px !important",
            }}
          >
            {company_name?.charAt("0")}
          </PrimaryAvatar>
          <Stack>
            <PrimaryTypography variant="h5">{company_name}</PrimaryTypography>
            <SecondaryFilledButton onClick={handleUpload}>
              Upload New Logo
            </SecondaryFilledButton>
          </Stack>
        </Stack>
        <input
          type="text"
          value={watch("company_image")}
          style={{ display: "none" }}
          {...register("company_image", { required: true })}
        />
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="login-tagline-outlined"
            error={errors.tagline}
          >
            tagline
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            placeholder="Enter your tagline"
            {...register("tagline", { required: true })}
            id="login-tagline-outlined"
            autoFocus
            label="tagline address"
            type={"text"}
            error={errors.tagline}
            variant="outlined"
          />
          <PrimaryNormalFormHelperText error={errors.tagline}>
            {errors?.tagline
              ? "tagline is a required field"
              : "Enter words Max Upto 500 Only."}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <Stack direction="row" spacing={4} justifyContent={"space-between"}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-company-size-outlined"
              error={errors.size_of_company}
            >
              Company Size
            </PrimaryNormalInputLabel>
            <Select
              labelId="folloups-company-size-outlined"
              id="folloups-company-size-outlined"
              value={getValues("size_of_company")}
              {...register("size_of_company", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="folloups-company-size-outlined"
                  error={errors?.size_of_company}
                  {...register("size_of_company", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {sizes?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.value} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.size_of_company}>
              {errors?.size_of_company
                ? "company size is a required field"
                : "Select your company capacity."}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <Stack spacing={2}>
              <PrimaryNormalInputLabel
                shrink
                htmlFor="folloups-location-outlined"
                error={errors.location}
              >
                Location
              </PrimaryNormalInputLabel>
              <GoogleLocation
                updatingContent={"location"}
                setAdditionals={true}
              />
            </Stack>
            <PrimaryNormalFormHelperText error={errors.location}>
              {errors?.location
                ? "location is a required field"
                : "Select your company headQuarters."}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-industry-outlined"
            >
              industry
            </PrimaryNormalInputLabel>
            <Select
              multiple
              labelId="folloups-industry-outlined"
              id="folloups-industry-outlined"
              value={watch("industry")}
              onChange={handleIndustryChange}
              {...register("industry", { required: true })}
              input={
                <PrimaryNormalInputs
                  label="folloups-industry-outlined"
                  error={errors?.industry}
                  {...register("industry", { required: true })}
                />
              }
              MenuProps={MenuProps}
            >
              {industries?.map(item => {
                return (
                  <MenuItem
                    key={item?.value}
                    value={item?.id} // style={getStyles(name, personName, theme)}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <PrimaryNormalFormHelperText error={errors.inustry}>
              {errors?.inustry
                ? "industry is a required field"
                : "Select your Industry."}
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Stack>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <PrimaryNormalInputLabel
            shrink
            htmlFor="folloups-about-outlined"
            error={errors.about}
          >
            About
          </PrimaryNormalInputLabel>
          <PrimaryNormalInputs
            fullWidth
            multiline
            placeholder="Enter about your company"
            {...register("about", { required: true })}
            id="login-about-outlined"
            autoFocus
            label="about address"
            type={"text"}
            error={errors.about}
            variant="outlined"
            rows={4}
          />
          <PrimaryNormalFormHelperText error={errors.about}>
            {errors?.about
              ? "about is a required field"
              : "describe about your company so that we can find better profiles for you."}
          </PrimaryNormalFormHelperText>
        </FormControl>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={4}
        >
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-websiteUrl-outlined"
              error={errors.website}
            >
              websiteUrl
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter websiteUrl your company"
              {...register("website", { required: false })}
              id="login-websiteUrl-outlined"
              autoFocus
              label="websiteUrl address"
              type={"text"}
              error={errors.website}
              variant="outlined"
              rows={4}
            />
            <PrimaryNormalFormHelperText error={errors.website}>
              websiteUrl is an optional field
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-linkedinUrl-outlined"
              error={errors.linkedin_url}
            >
              linkedinUrl
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter linkedinUrl your company"
              {...register("linkedin_url", { required: false })}
              id="login-linkedinUrl-outlined"
              autoFocus
              label="linkedinUrl address"
              type={"text"}
              error={errors.linkedin_url}
              variant="outlined"
              rows={4}
            />
            <PrimaryNormalFormHelperText error={errors.linkedin_url}>
              linkedinUrl is an optional field
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="folloups-facebookUrl-outlined"
              error={errors.facebook_url}
            >
              facebookUrl
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              fullWidth
              placeholder="Enter facebookUrl your company"
              {...register("facebook_url", { required: false })}
              id="login-facebookUrl-outlined"
              autoFocus
              label="facebookUrl address"
              type={"text"}
              error={errors.facebook_url}
              variant="outlined"
              rows={4}
            />
            <PrimaryNormalFormHelperText error={errors.facebook_url}>
              about is an optional field
            </PrimaryNormalFormHelperText>
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}
