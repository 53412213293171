import { connect, useSelector } from "react-redux";
function roleSelector(role) {
  switch (role) {
    case "ZA":
      return "Zepul Admin";
    case "CA":
      return "Company Admin";
    case "CS":
      return "Company Staff";
    case "ZM":
      return "Zepul Moderator";
    case "ACM":
      return "Zepul Account Manager";
    case "TE":
      return "Zepul Technical Evaluator";
    case "BVC":
      return "Zepul BVC";
    case "PL":
      return "Zepul Partner";
    case "RC":
      return "Recrutement Company";
    case "SP":
      return "Zepul Screening Partner";
    case "BVS":
      return "Zepul Background Verification staff";
  }
}
export default function titleCaller(data, route = "") {
  const role =
    route === "" ? JSON.parse(localStorage.getItem("authUser"))?.role : route;
  const titleData = role ? `${roleSelector(role)} - ` : "";
  document.title = `${titleData} ${data}`;
}
