const INIT_STATE = {
  jobLevels: [],
};

const JobAttributes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_JOB_LEVEL":
      return {
        ...state,
        jobLevels: action.payload,
      };

    default:
      return state;
  }
};

export default JobAttributes;
