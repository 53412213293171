const {
  UPDATE_JOB_TITLE,
  UPDATE_JOB_TYPE,
  UPDATE_JOB_LOCATION,
  UPDATE_WORK_MODE,
  UPDATE_JOB_DESCRIPTION,
  UPDATE_FUNCTIONAL_AREA,
  UPDATE_JOB_ROLE,
  UPDATE_JOB,
  DELETE_JOB,
} = require("./actionTypes");

const INIT_STATE = {
  job_title: "",
  job_type: "Full_Time",
  location: "",
  work_mode: "Fully_remote",
  job_desc: "",
  functional_areas: [""],
  job_roles: [""],
  no_of_vacancies: "",
  currency: "USD",
  application_deadline: "",
  time_zone: [],
  min_sal: "0",
  max_sal: "1",
  min_exp: "",
  max_exp: "",
  commission_rate_type: "Percentage",
  commission_rate: 12,
  educational_qualifications: [],
  attachment: "",
  screening_questions: [""],
  hours_per_week: "less_than_30",
  project_duration: "less_than_month",
};

export const Jobs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_JOB_TITLE:
      return { ...state, job_title: action.payload };
    case UPDATE_JOB_TYPE:
      return { ...state, job_type: action.payload };
    case UPDATE_JOB_LOCATION:
      return { ...state, location: action.payload };
    case UPDATE_WORK_MODE:
      return { ...state, work_mode: action.payload };
    case UPDATE_JOB_DESCRIPTION:
      return { ...state, job_desc: action.payload };
    case UPDATE_FUNCTIONAL_AREA:
      return { ...state, functional_area: action.payload };
    case UPDATE_JOB_ROLE:
      return { ...state, job_roles: [...state.job_role, action.payload] };
    case UPDATE_JOB:
      return { ...state, ...action.payload };
    case DELETE_JOB:
      return { ...INIT_STATE, educational_qualifications: [] };
    default:
      return state;
  }
};
