import {
  PrimaryButton,
  PrimaryJobButton,
  PrimaryOutlinedButton,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import SearchParamHook from "hooks/searchParams";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function ViewMoreButton({ id }) {
  const searchPramas = SearchParamHook();
  const history = useHistory();
  function handleViewMore(id) {
    searchPramas.set("id", id);
    history.replace({ search: searchPramas.toString() });
  }
  return (
    <PrimaryJobButton
      endIcon={<FeatherIcon icon="chevron-right" size="14" />}
      sx={{ width: "130px" }}
      onClick={() => handleViewMore(id)}
    >
      view More{" "}
    </PrimaryJobButton>
  );
}
