import * as React from "react";
import {Box,Modal, TextField,Rating,Tooltip} from "@mui/material";
import {
  BlackTypography,
  PrimaryButton,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import {
  Grid,
  Stack,
} from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    label:{
        "& label":{
          marginBottom:"0px !important"
        },
        "&.css-iljtu2-MuiRating-root":{
            border:(props)=>props.rateError ? '1px solid #ff5630':'none'
        }
  }
  })

  const feedbacks=[
    "Poor",
    "Not Good",
    "Below Average",
    "Average",
    "Good",
    "Excellent"
 ]
 
 const  popperProps = {
   modifiers: [
     {
       name: 'offset',
       options: {
         offset: [1,-10], // Adjust the values to reduce the distance
       },
     },
   ],
 };

export default function AddAQuestion({
  updateHandler,
  open,
  toggleModel,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues:{
        question:'',
        answer:'',
        comment:''
    }
  });
  const [rating, setRating] = React.useState({
    value: 0,
    hover: 0,
  });

  const classes = useStyles()
  function onSubimission(data){
    const {question,answer,comment} = data
    const reqObj={
      answer,
      questionName:question,
      comment,
      rating:rating.value,
    }
    updateHandler(reqObj,toggleModel)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            Add a Question{" "}
          </BlackTypography>
          <form onSubmit={handleSubmit(onSubimission)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Question"
                  multiline
                  {...register("question",{required:true})}
                  placeholder="Enter a Question"
                  error={errors.question}
                  sx={{marginBottom:'16px'}}
                  rows={2.5}
                />
                <TextField
                  label="Answer"
                  multiline
                  {...register("answer")}
                  placeholder="Enter an Answer"
                  error={errors.answer}
                  sx={{marginBottom:'16px'}}
                  rows={2.5}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="comment"
                  multiline
                  {...register("comment")}
                  placeholder="Enter a comment"
                  error={errors.comment}
                  sx={{marginBottom:'16px'}}
                  rows={2.5}
                />
                <Tooltip
                  PopperProps={popperProps}
                  title={feedbacks[rating.hover] || ""}
                >
                  <Rating
                    className={classes.label}
                    // style={{border: errors.rating? '1px solid #ff5630':'none'}}
                    onChange={(_,val)=>setRating((pre)=>({
                        ...pre,
                        value:val
                    }))}
                    onChangeActive={(_, hover) =>
                      setRating(pre => ({
                        ...pre,
                        hover,
                      }))
                    }
                    // {...register('rating',{required:true})}
                    getLabelText={value => {
                      return `${value} Star${value !== 1 ? "s" : ""}, ${
                        feedbacks[value]
                      }`;
                    }}
                    // precision={0.5}
                    value={rating.value}
                    size="medium"
                    //   readOnly={readOnly}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggleModel();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
