import React, { useEffect, useState } from "react";

import { Col, Row, Container, Input, Label } from "reactstrap";

import "antd/dist/antd.css";
import { EuroOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { updateJob } from "store/jobs/actions";
import ReactSelect from "react-select";

var cc = require("currency-codes");
const getSymbolFromCurrency = require("currency-symbol-map");
import { toast } from "react-hot-toast";

const SalaryBudget = ({
  currency,
  updateJob,
  min_sal,
  max_sal,
  error,
  type,
}) => {
  const options = cc.codes().map(item => {
    return { value: item, label: item };
  });
  const [prefix, setPrefix] = useState("$");
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setSelected([{ value: currency, label: currency }]);
    setPrefix(getSymbolFromCurrency(currency));
  }, [currency]);

  return (
    <Row className=" mt-4">
      <Label>Annual Salary Budget ({type})</Label>
      <Col xs={4}>
        <Label className={`${error.currency === true ? "red" : ""}`}>
          Currency <span className="red p-2">*</span>
        </Label>

        <ReactSelect
          style={{ zIndex: "999" }}
          value={selected}
          isMulti={false}
          onChange={e => {
            setSelected(e);
            updateJob({ currency: e.value });
          }}
          options={options}
          classNamePrefix="select2-selection"
        />
      </Col>

      <Col xs={2}>
        <Label className={`${error.min_sal === false ? "" : "red"}`}>
          Minimum Salary <span className="red p-2">*</span>
        </Label>

        <span className="d-flex align-items-center gap-2 currency_inpt">
          <p className="h_or_p">{prefix}</p>
          <Input
            invalid={error.min_sal === false ? false : true}
            size="large"
            min={"1"}
            type="number"
            value={Number(min_sal) > 0 ? min_sal : ""}
            className="no_border_inpt"
            onChange={e => {
              if (e.target.value <= "0") {
                toast.error("minimum salary must be greater than 0");
                return;
              }
              updateJob({ min_sal: e.target.value });
            }}
            placeholder="Enter Minimum Salary"
          />
        </span>
      </Col>

      <Col xs={2}>
        <Label className={`${error.max_sal === false ? "" : "red"}`}>
          Maximum Salary <span className="red p-2">*</span>
        </Label>
        <span className="d-flex align-items-center gap-2 currency_inpt">
          <p className="h_or_p">{prefix}</p>

          <Input
            size="large"
            min={Number(min_sal)}
            className={"no_border_inpt"}
            invalid={error.max_sal === false ? false : true}
            value={Number(max_sal) > 0 ? max_sal : ""}
            onBlur={e => {
              if (Number(Number(e.target.value) < Number(min_sal))) {
                updateJob({ max_sal: Number(min_sal) + 1 });
                return;
              }
            }}
            onChange={e => {
              if (e.target.value <= "0") {
                toast.error("maximum salary must be greater than 0");

                return;
              }
              updateJob({ max_sal: e.target.value });
            }}
            type="number"
            placeholder="Enter Maximum Salary"
            prefix={prefix}
          />
        </span>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ Jobs }) => ({
  currency: Jobs.currency,
  min_sal: Jobs.min_sal,
  max_sal: Jobs.max_sal,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalaryBudget);
