import KeySkillSelect from "../header/keySkillSelect";

export const KeySkillOperator = [
  {
    label: "includes",
    value: "includes",
    getApplyFilterFn: filterItem => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return params => {
        return filterItem?.value?.includes(params?.value);
      };
    },
    InputComponent: KeySkillSelect,
    getValueAsString: value => `${value}`,
  },
];
