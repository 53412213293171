import {
  FormControl,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { BlackTypography, PrimaryOpacitedButton } from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import GoogleMaterialInputLocation from "components/Common/Forms/InputLocation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};
export default function EditInputText({
  text_field,
  input_value,
  type,
  update_type,
  sx,
  setUpdate,
  multiline,
  apiUrl = "update",
  children,
}) {
  const [input, setInput] = useState(false);
  const [data, setData] = useState(input_value);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // setValue("previous", input_value);
    setValue(update_type, input_value);
  }, [update_type, input_value]);
  function checkInputs() {
    if (data === "" || data === null) {
      return true;
    }
    return false;
  }
  function toggleInputs() {
    setInput(!input);
  }
  function onSubmit(data) {
    setData(watch(update_type));
    setUpdate(update_type, watch(update_type));
    toggleInputs();
  }
  function findSelect() {
    switch (update_type) {
      case "gender":
        return [
          { name: "Male", value: "Male" },
          { name: "Female", value: "Female" },
          { name: "prefer Not To say", value: "prefer_not_to_say" },
        ];
      default:
        [{ name: "", value: "" }];
    }
  }
  function findUpdateInput(item) {
    switch (item) {
      case "input":
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <Stack direction="row" alignItems={"center"} spacing={"1"}>
                <Input
                  fullWidth
                  multiline={multiline}
                  placeholder={`${text_field}`}
                  {...register(update_type, { required: true })}
                  id="login-first-name-outlined"
                  autoFocus
                  label={`${update_type}`}
                  type={"text"}
                  error={errors[`${update_type}`]}
                  variant="outlined"
                />
                <IconButton onClick={toggleInputs} color="error">
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </FormControl>
          </form>
        );
      case "multiline":
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <Stack direction="row" spacing={"1"}>
                <Input
                  fullWidth
                  multiline
                  tabs={3}
                  placeholder={`${text_field}`}
                  {...register(update_type, { required: true })}
                  id="login-first-name-outlined"
                  autoFocus
                  label={`${update_type}`}
                  type={"text"}
                  error={errors[`${update_type}`]}
                  variant="outlined"
                />
                <IconButton onClick={toggleInputs} color="error">
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </FormControl>
          </form>
        );
      case "date":
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <Stack direction="row" spacing={"1"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      disableFuture={true}
                      label="Date"
                      value={watch(update_type)}
                      onChange={newValue => {
                        setValue(update_type, newValue);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          error={errors[`${update_type}`]}
                          {...register(update_type, { required: true })}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                <IconButton color="error" onClick={toggleInputs}>
                  <FeatherIcon icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </FormControl>
          </form>
        );
      case "select":
        return (
          <FormControl sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={"1"}>
                <Select
                  labelId={update_type}
                  id={update_type}
                  {...register(update_type, { required: true })}
                  input={
                    <Input
                      label="signup-user-type"
                      error={errors[`${update_type}`]}
                      {...register(update_type, { required: true })}
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {findSelect(type)?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value} // style={getStyles(name, personName, theme)}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton color="error">
                  <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                </IconButton>
                <IconButton type="submit" color="success">
                  <FeatherIcon icon="check" size="14" />
                </IconButton>
              </Stack>
            </form>
          </FormControl>
        );
      case "location":
        return (
          <FormControl sx={{ width: "100%", ...sx }}>
            <FormProvider
              register={register}
              watch={watch}
              errors={errors}
              formSetValue={setValue}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" spacing={"1"}>
                  <GoogleMaterialInputLocation updatingContent={update_type} />
                  <IconButton color="error">
                    <FeatherIcon onClick={toggleInputs} icon="x" size="14" />
                  </IconButton>
                  <IconButton type="submit" color="success">
                    <FeatherIcon icon="check" size="14" />
                  </IconButton>
                </Stack>
              </form>
            </FormProvider>
          </FormControl>
        );
    }
  }
  return (
    <>
      <>
        {input ? (
          <>{findUpdateInput(type)}</>
        ) : (
          <>
            {checkInputs() ? (
              <>
                <PrimaryOpacitedButton
                  sx={{ width: "100px" }}
                  onClick={toggleInputs}
                  startIcon={<FeatherIcon icon="plus" size="14" />}
                >
                  Add
                </PrimaryOpacitedButton>
              </>
            ) : (
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                {children}
                <IconButton onClick={toggleInputs} color="success">
                  <FeatherIcon icon="edit" size="14" />
                </IconButton>
              </Stack>
            )}
          </>
        )}
      </>
    </>
  );
}
