import React from "react";
import { Alert, Card, Col, Input, Row } from "reactstrap";

class NewCandidates extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Col xs={12}>
          <Row>
            <Card
              style={{
                padding: "20px",
              }}
            >
              <div className="flex_btns">
                <div className="alert_small " style={{ width: "300px" }}>
                  <Alert color="info" className="alert_small_comp">
                    <p className="bold_success">New Candidates</p>
                  </Alert>
                </div>{" "}
                <Input
                  type="text"
                  placeholder="search candidates by anything or use keywords"
                />
              </div>
            </Card>
          </Row>
          <Row>
            <div
              className="email_brand_div new_calender_grid"
              style={{ minHeight: "65vh" }}
            >
              <div className="calender_contents">
                <i
                  className="dripicons-align-justify
"
                />
                <h5>No New Candidates</h5>
                <p>
                  New candidates will appear here until their profiles are
                  viewed.
                </p>
                {/* <Button color="primary">Sync Calendar</Button> */}
              </div>
            </div>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

export default NewCandidates;
