import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function CreateJobPost(data) {
  return await callApi("company/job_post", data, POST);
}

export async function getJobsBasedOnCompany(id) {
  return await callApi("user_job_post_update/company_job_posts/" + id);
}
export async function getAllJobs() {
  return await callApi("company/job_post");
}
export async function getAllJobsBasedOnCompany(id) {
  const data = await callApi(`company/job_post?company_id=${id}`);
  const items = data?.data;
  return items;
}
export async function getSingleJob(id) {
  const data = await callApi(`company/job_post/${id}`);
  const items = data?.data;
  return items;
}
export async function getSingleJobDetails(id) {
  return await callApi(`company/job_post/${id}`);
}

export async function deleteSingleJob(id) {
  const data = await callApi(`company/job_post/${id}`, {}, DELETE);
  return data;
}
export async function deleteJobPost() {
  const items = await getAllJobs();
  const ids = items.map(async item => {
    const data = await callApi(`company/job_post/${item.id}`, {}, DELETE);
    // return data.data;
  });
}
export async function updateJobPost(id, data) {
  return await callApi(`company/job_post/${id}`, data, PUT);
}
export const applyForJobPost = async (data, id) => {
  return await callApi(
    `referral_partner_job_posts/refer_now_from_admin_panel`,
    {
      ...data,
      job_post_id: id,
      job_apply_type: "refer",
      about_this_job: "no_preview",
      address: "no_preivew",
    },
    POST
  );
};
