import React, { useEffect } from "react";
import {
  Col,
  Label,
  Row,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import YouTube from "react-youtube";
import { ContentState, convertFromHTML, EditorState } from "draft-js";

import "../SettingCss/EditProfile.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import ReactSelect from "react-select";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import UploadModel from "components/Common/models/uploadModel";
import { Editor } from "react-draft-wysiwyg";
import { location } from "common/constants/options";
import MultipleUpload from "components/Common/models/multipleUpload";
import { imageURL } from "common/constants/commonURLS";
import { PlacesAutocomplete } from "helpers/locationProvider/locationTest";
import { updateCompanyModel } from "helpers/models/companyModel";
import { updateCompanies } from "helpers/services/companies/company";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import findParticularUserByRole from "helpers/services/CommonUser/findUserbasedOnType";
import { getPreSignedURLForMultipleUpload } from "helpers/services/pre_signed_urls/multipleUpload";
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CompanyProfileEditModel = props => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const [perks, setPerks] = useState([
    {
      name: "Meals",
      selected: false,
      image: "https://img.icons8.com/fluency/48/000000/buffet-breakfast.png",
    },
    {
      name: "Health Care",
      selected: false,
      image: "https://img.icons8.com/doodle/48/000000/nature-care.png",
    },
    {
      name: "Retirement",
      selected: false,
      image:
        "https://img.icons8.com/external-smashingstocks-thin-outline-color-smashing-stocks/67/000000/external-benefits-finance-smashingstocks-thin-outline-color-smashing-stocks.png",
    },
    {
      name: "Parental Leave",
      selected: false,
      image:
        "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-parental-parenthood-flaticons-flat-flat-icons.png",
    },
    {
      name: "Equity Benefits",
      selected: false,
      image:
        "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-equity-accounting-flaticons-flat-flat-icons.png",
    },
    {
      name: "Remote",
      selected: false,
      image: "https://img.icons8.com/doodle/48/000000/home-office.png",
    },
    {
      name: "Vaccation",
      selected: false,
      image:
        "https://img.icons8.com/external-gradient-design-circle/66/000000/external-vacation-hotel-and-services-gradient-design-circle.png",
    },
    {
      name: "Wellness",
      selected: false,
      image:
        "https://img.icons8.com/external-bearicons-detailed-outline-bearicons/64/000000/external-Wellness-human-mind-bearicons-detailed-outline-bearicons.png",
    },
    {
      name: "Pet Freindly",
      selected: false,
      image: "https://img.icons8.com/officel/80/000000/dog-park.png",
    },
    {
      name: "Others",
      selected: false,
      image: "https://img.icons8.com/ios/50/000000/gift--v1.png",
    },
  ]);
  const onPauseVideo = e => {
    e.target.play();
  };
  const onPlayVideo = e => {
    e.target.pauseVideo();
  };
  const onReadyVideo = e => {
    e.target.pauseVideo();
  };
  const [video, setVideo] = useState("");

  useEffect(() => {
    if (
      props?.userInfo?.industry !== null &&
      props?.userInfo?.industry?.length > 0
    )
      setCompany({
        ...props?.userInfo,
        industry: {
          value: props?.userInfo?.industry[0],
          label: "label is not getting from backend",
        },
      });
    else {
      setCompany({
        ...props?.userInfo,
      });
    }
    setVideo(
      props?.userInfo?.culture_media_video_url?.includes("youtube")
        ? props?.userInfo?.culture_media_video_url.split("=")[1]
        : props?.userInfo?.culture_media_video_url
    );
  }, [props?.userInfo]);
  useEffect(() => {
    props?.userInfo?.perks?.map(item => {
      const idx = perks.findIndex(
        ({ name }) => name.toLowerCase() === item.toLowerCase()
      );
      const newState = [...perks];
      newState[idx].selected = true;
      setPerks(newState);
    });
  }, [props?.userInfo]);

  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    getIndustry();
  }, []);
  async function getIndustry() {
    const industries = await getAllIndustries();
    const indus = industries.map(item => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setIndustries(indus);
  }
  const handleYoutubeVideo = () => {
    setVideo(
      company?.culture_media_video_url.includes("youtube")
        ? company?.culture_media_video_url.split("=")[1]
        : company?.culture_media_video_url
    );
  };
  const [company, setCompany] = useState({
    first_name: "",
    last_name: "",
    profile_image: "",
    phone_number: "",
    email: "",
    job_role_details: "",
    fn_area_details: "",
    linkedin_url: "",
    industry: "",
    twitter_url: "",
    facebook_url: "",
    glassdoors_url: "",
    ambition_box_url: "",
    comparably_url: "",
    careerbliss_url: "",
    payscale_url: "",
    about: "",
    google_reviews: "",
    company_name: "",
    culture_media_images: [],
    describe_your_culture: "",
    perks: [],
    culture_media_video_url: "",
    location: "",
    tagline: "",
    size_of_company: "",
    website: "",
  });
  const [phone, setPhone] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [cultureModalOpen, setCultureModalOpen] = useState(false);

  const toggleModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);

    setCompany({
      ...company,
      profile_image: url,
    });
    toggleModel();
  };
  function handleSelect(name, indexValue) {
    const idx = company.perks.findIndex(item => {
      return item.toLowerCase() === name.toLowerCase();
    });
    if (idx === -1) {
      setCompany({
        ...company,
        perks: [...company.perks, name],
      });
    } else {
      const perkArray = company.perks;
      perkArray.splice(idx, 1);
      setCompany({ ...company, perks: perkArray });
    }
    const companyPerks = perks;
    companyPerks[indexValue].selected = !companyPerks[indexValue].selected;
  }

  function toggleCultureMediaModel() {
    setCultureModalOpen(!cultureModalOpen);
  }

  async function handleImage(data) {
    const urls = [];
    data.map(async item => {
      const image = await getPreSignedURLForMultipleUpload(item);
      urls.push(image);
    });
    setCompany({
      ...company,
      culture_media_images: [...company.culture_media_images, ...urls],
    });
    toggleCultureMediaModel();
  }

  return (
    <div>
      <UploadModel
        isOpen={modelOpen}
        toggle={toggleModel}
        handleImage={handleImageUpload}
      />
      <MultipleUpload
        isOpen={cultureModalOpen}
        toggle={toggleCultureMediaModel}
        handleImage={handleImage}
      />
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="profile_edit_model_main"
      >
        <ModalHeader
          toggle={props.toggle}
          className="profile_edit_model_header"
        >
          <p className="profile_edit_model_header_text p-2">
            Edit your profile
          </p>
        </ModalHeader>
        <ModalBody>
          <div className="edit_profile_card_page">
            <div>
              <Row className="profile_feild_cont profile_feild_border">
                <Col xs={12} className="editprofile_feild_cont">
                  <Row className="mb-4">
                    <Col
                      xs={3}
                      sm={12}
                      md={4}
                      className="editprofile_upper_feild"
                    >
                      <Avatar
                        color="primary"
                        sx={{ height: 94, width: 94 }}
                        src={`${imageURL}${company?.profile_image}`}
                        size="lg"
                      >
                        {company?.first_name?.charAt(0)}
                      </Avatar>
                    </Col>
                    <Col
                      xs={4}
                      sm={8}
                      md={4}
                      className="pt-4 editprofile_upper_feild"
                    >
                      <Button
                        color="primary"
                        variant="solid"
                        onClick={toggleModel}
                      >
                        Upload new logo{" "}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="mt-2">
                      <Row>
                        <Col xs={6} className="mb-3 ">
                          <TextField
                            label="First name"
                            value={company.first_name}
                            onChange={e => {
                              setCompany({
                                ...company,
                                first_name: e.target.value,
                              });
                            }}
                            className="form-control"
                            placeholder="Enter first name"
                            type="text"
                          />
                        </Col>
                        <Col xs={6} className="mb-3">
                          <TextField
                            label="Last name"
                            onChange={e => {
                              setCompany({
                                ...company,
                                last_name: e.target.value,
                              });
                            }}
                            value={company.last_name}
                            className="form-control"
                            placeholder="Enter last name"
                            type="text"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col xs={12}>
                      <div className="mb-3">
                        <TextField
                          label="email"
                          disabled={true}
                          onChange={e => {
                            setCompany({
                              ...company,
                              email: e.target.value,
                            });
                          }}
                          value={company.email}
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <TextField
                          label="tagline"
                          onChange={e => {
                            setCompany({
                              ...company,
                              tagline: e.target.value,
                            });
                          }}
                          value={company.tagline}
                          className="form-control"
                          placeholder="Enter Tagline"
                          type="email"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <PhoneInput
                          international
                          countryCallingCodeEditable={true}
                          className="input_phone"
                          defaultCountry="IN"
                          initialValueFormat="national"
                          placeholder="Enter phone number"
                          value={company.phone_number}
                          error={
                            phone
                              ? isValidPhoneNumber(phone)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                          onChange={value => {
                            setCompany({ ...company, phone_number: value });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <TextField
                          label="textField"
                          value={company.company_name}
                          onChange={e => {
                            setCompany({
                              ...company,
                              company_name: e.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Company name"
                          type="text"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="mt-3">
                      <PlacesAutocomplete
                        location={company.location}
                        setLocation={e => {
                          setCompany({
                            ...company,
                            location: e,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* <ReactSelect
                      isMulti={false}
                      options={industries}
                      onChange={e => {
                        setCompany({
                          ...company,
                          industry: e,
                        });
                      }}
                      value={company?.industry}
                    /> */}
                    <Col xs={12} className="mt-3 mb-3">
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="selct-user-type">Industry</InputLabel>
                        <Select
                          labelId="selct-user-type"
                          id="demo-multiple-name"
                          input={
                            <OutlinedInput
                              onChange={e => {
                                setCompany({
                                  ...company,
                                  industry: e?.target,
                                });
                              }}
                              value={company?.industry?.value}
                              label="Industry"
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {industries?.map(item => {
                            return (
                              <MenuItem
                                key={item?.value}
                                value={item?.value} // style={getStyles(name, personName, theme)}
                              >
                                {item?.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="mt-1">
                      <TextField
                        label="about"
                        type="textarea"
                        value={company?.about}
                        onChange={e => {
                          setCompany({
                            ...company,
                            about: e.target.value,
                          });
                        }}
                      />{" "}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <TextField
                        label="website"
                        type="text"
                        placeholder="Website url"
                        value={company.website}
                        onChange={e => {
                          setCompany({
                            ...company,
                            website: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <TextField
                        label="linkedin url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            linkedin_url: e.target.value,
                          });
                        }}
                        placeholder="Linkedin url"
                        value={company.linkedin_url}
                      />
                    </Col>
                    <Col xs={6}>
                      <TextField
                        label="facebook url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            facebook_url: e.target.value,
                          });
                        }}
                        placeholder="Facebook url"
                        value={company.facebook_url}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xs={6}>
                      <TextField
                        label="twitter url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            twitter_url: e.target.value,
                          });
                        }}
                        placeholder="Twitter url "
                        value={company.twitter_url}
                      />
                    </Col>
                    <Col xs={6}>
                      <TextField
                        label="galssdoor url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            glassdoors_url: e.target.value,
                          });
                        }}
                        placeholder="Glassdoor url"
                        value={company.glassdoors_url}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <TextField
                        label="ambitionbox url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            ambition_box_url: e.target.value,
                          });
                        }}
                        placeholder="Ambitionbox url"
                        value={company.ambition_box_url}
                      />
                    </Col>
                    <Col xs={6}>
                      <TextField
                        label="comparably url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            comparably_url: e.target.value,
                          });
                        }}
                        placeholder="Comparably url"
                        value={company.comparably_url}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <TextField
                        label="careerbliss url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            careerbliss_url: e.target.value,
                          });
                        }}
                        placeholder="Careerbliss url"
                        value={company.careerbliss_url}
                      />
                    </Col>
                    <Col xs={6}>
                      <TextField
                        label="payscale url"
                        type="text"
                        placeholder="Payscale url"
                        value={company.payscale_url}
                        onChange={e => {
                          setCompany({
                            ...company,
                            payscale_url: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <TextField
                        label="google reviews url"
                        type="text"
                        onChange={e => {
                          setCompany({
                            ...company,
                            google_reviews: e.target.value,
                          });
                        }}
                        placeholder="Google review url"
                        value={company.google_reviews}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3 py-2 child_col_m_2">
                    {perks?.map(({ image, name, selected }, index) => {
                      return (
                        <Col lg={2} md={3} xs={12} sm={4} key={index}>
                          <div
                            style={{
                              transition: "0.4s ease-in-out",
                            }}
                            className={`perks_selection ${
                              selected ? "selected_perk" : " "
                            }`}
                            onClick={() => {
                              handleSelect(name, index);
                            }}
                          >
                            <img src={image} className="small_img" />
                            <p>{name}</p>
                          </div>
                        </Col>
                      );
                    })}
                    <Col xs={12}>
                      <TextField
                        label="culture"
                        style={{ minHeight: "120px" }}
                        type="textarea"
                        onChange={e => {
                          setCompany({
                            ...company,
                            describe_your_culture: e.target.value,
                          });
                        }}
                        value={company.describe_your_culture}
                        placeholder="describe your culture"
                      />
                    </Col>
                    <Col xs={12}></Col>
                    <Col xs={6}>
                      <div className="cluture_image">
                        <div className="row_sm">
                          {company?.culture_media_images?.length === 0 ? (
                            <img
                              src="https://img.icons8.com/color/240/000000/insert-raster-image.png"
                              className="pb-2"
                            />
                          ) : (
                            <Row>
                              {company?.culture_media_images?.map(
                                (item, index) => {
                                  return index < 4 ? (
                                    <Col xs={4}>
                                      <img
                                        src={`${imageURL}${item}`}
                                        className="small_img"
                                      />
                                    </Col>
                                  ) : (
                                    ""
                                  );
                                }
                              )}
                            </Row>
                          )}
                          <Button
                            color="success"
                            variant="solid"
                            onClick={toggleCultureMediaModel}
                          >
                            Choose an Image
                          </Button>
                          <div className="small_gray">
                            Please upload only the following formats .jpg .jpeg
                            .png only
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="cluture_image">
                        <div className="row_sm">
                          <img src="https://app.zepul.com/static/media/meals.02a258b4.svg" />{" "}
                          <div className="flex_center">
                            <TextField
                              label="culture video url"
                              value={company.culture_media_video_url}
                              type="text"
                              onChange={e => {
                                setCompany({
                                  ...company,
                                  culture_media_video_url: e.target.value,
                                });
                              }}
                              placeholder="paste your video URL"
                            />
                            <Button
                              color="success"
                              variant="solid"
                              onClick={handleYoutubeVideo}
                            >
                              ADD
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xs={12}>
                      <YouTube
                        videoId={video}
                        // defaults -> ''
                        // id={string} // defaults -> ''
                        // className={string} // defaults -> ''
                        // iframeClassName={string} // defaults -> ''
                        // style={object} // defaults -> {}
                        // title={string} // defaults -> ''
                        // loading={string} // defaults -> undefined
                        opts={opts} // defaults -> {}
                        onReady={onReadyVideo} // defaults -> noop
                        onPlay={onPlayVideo} // defaults -> noop
                        onPause={onPauseVideo} // defaults -> noop
                        // onEnd={func} // defaults -> noop
                        // onError={func} // defaults -> noop
                        // onStateChange={func} // defaults -> noop
                        // onPlaybackRateChange={func} // defaults -> noop
                        // onPlaybackQualityChange={func} // defaults -> noop
                      />
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="solid"
              sx={{ width: "100px" }}
              onClick={async () => {
                props.toggle();
                const result = await updateCompanyModel(company);
                await updateCompanies(result);
                const data = await findParticularUserByRole();
                props.setUserInfo(data);
              }}
            >
              <span>Submit</span>
            </Button>
            <Button
              color="danger"
              variant="solid"
              sx={{ width: "100px" }}
              outline
              onClick={() => {
                props.toggle();
              }}
            >
              <span>Cancel</span>
            </Button>
          </Stack>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CompanyProfileEditModel;
