import { PrimaryButton, SecondaryFilledButton } from "assets/Mui/globalTheme";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { createEducationalSpecialization } from "helpers/services/jobAttributes/educationalQualifications";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Modal } from "reactstrap";

export default function CreateEducationalQualification({
  isOpen,
  toggle,
  qualification,
  purpose,
  name,
  id,
  index,
  updated,
  educational_qualification_id,
}) {
  const [multi, setMulti] = useState([]);
  const [inpt, setInpt] = useState("");
  const handleSelectMulti = data => {
    setMulti(data);
  };
  const handleInput = e => {
    setInpt(e.target.value);
  };
  const handleSubmit = async () => {
    await createEducationalSpecialization({ name: inpt, id: multi.value });
    updated();
    toggle();
  };
  useEffect(() => {
    if (purpose === "edit") {
      setInpt(name);
      const data = qualification.find(
        item => item.value === educational_qualification_id
      );
      setMulti({ value: educational_qualification_id, label: data?.label });
    }
  }, [purpose]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle("");
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {purpose} Educational Specialization
        </h5>
        <button
          type="button"
          onClick={() => toggle(!isOpen)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <AvForm>
          <AvField
            name="text"
            // label={`${purpose} ${headTag}`}
            className="form-control"
            value={inpt}
            onChange={handleInput}
            placeholder={`enter Specialization name`}
            type="text"
            required
          />{" "}
        </AvForm>
      </div>
      <div className="modal-body">
        <ReactSelect
          style={{ zIndex: "999" }}
          //   className={className}
          value={multi}
          isMulti={false}
          onChange={handleSelectMulti}
          options={qualification}
          classNamePrefix="select2-selection"
        />
      </div>
      <div className="modal-footer">
        <SecondaryFilledButton
          type="button"
          sx={{ width: "80px", marginRight: "10px" }}
          onClick={() => {
            toggle(!isOpen);
          }}
          data-dismiss="modal"
        >
          Close
        </SecondaryFilledButton>
        <PrimaryButton
          sx={{ width: "130px" }}
          type="button"
          onClick={handleSubmit}
        >
          Save changes
        </PrimaryButton>
      </div>
    </Modal>
  );
}
