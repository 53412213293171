import callApi from "helpers/callApi";

export async function getAllActualJobLevel() {
  return await callApi("actual_job_levels");
}

export async function createActualJobLevels(data) {
  const result = { name: data?.name };
  return await callApi("actual_job_levels", result, "POST");
}

export async function updateActualJobLevel(data, id) {
  return await callApi("actual_job_levels/" + id, data, "PUT");
}
