import {
  Divider,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputAdornment, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import { useSelector } from "react-redux";
import { updateCompanies } from "helpers/services/companies/company";
import {
  BlackTypography,
  GrayDivider,
  GrayLiniearDivider,
  GreyTypography,
  PrimaryButton,
  PrimaryDivider,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputs,
  PrimaryTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { updateCommonUser } from "helpers/services/common";
import { toast } from "react-hot-toast";

export default function PasswordChange() {
  const user = useSelector(state => state.Login);
  const [showPassword, setShowPassword] = useState(false);
  const [cShowPasswrod, setCShowPassword] = useState(false);
  const [samePasswordError, setSamePasswordError] = useState(false);
  const [cpassworError, setCpasswordError] = useState(false);
  const [showcurrentPassword, setShowCurrentPassword] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    unregister,
    formState: { errors },
    setValue,
  } = useForm();

  function handleShowCPassword() {
    setCShowPassword(!cShowPasswrod);
  }
  function handleShowPassword() {
    setShowPassword(!showPassword);
  }
  function handleShowCurrentPassword() {
    setShowCurrentPassword(!showcurrentPassword);
  }
  function handleChangePassword(data) {
    if (data.password === data.current_password) {
      setSamePasswordError(true);
    } else {
      setSamePasswordError(false);
      updateCommonUser({
        password: data?.password,
      });
      reset();
      toast.success("Password Changed Successfully");
    }
  }
  return (
    <Paper elevation={"0"} sx={{ padding: "20px" }}>
      <BlackTypography className="mb-2" variant="h2">
        password
      </BlackTypography>
      <GreyTypography variant="h6" className="mb-4">
        Please Enter Your Current Password to Change Your Password
      </GreyTypography>
      {/* <GrayLiniearDivider className="mb-2" /> */}
      <Form onSubmit={handleSubmit(handleChangePassword)}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel>Current Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <PrimaryNormalInputs
              placeholder="password"
              sx={{ width: "100%" }}
              {...register("current_password", { required: true })}
              id="current-password-outlined"
              autoFocus
              label="current Password"
              type={showcurrentPassword ? "text" : "password"}
              error={errors.current_password}
              helperText={
                errors?.current_password?.type === "required" &&
                "Current Password is required"
              }
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCurrentPassword}
                >
                  {showcurrentPassword ? (
                    <FeatherIcon icon="lock" size={"14"} />
                  ) : (
                    <FeatherIcon icon="unlock" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
              variant="outlined"
            />
            <PrimaryNormalFormHelperText>
              Enter your current password
            </PrimaryNormalFormHelperText>
          </Grid>
          {/* <Grid item xs={12}>
            <GrayLiniearDivider className="mb-2" />
          </Grid>{" "} */}
          <Grid item xs={6}>
            <InputLabel>New Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <PrimaryNormalInputs
              placeholder="password"
              sx={{ width: "100%" }}
              {...register("password", { required: true })}
              id="password-outlined"
              label="Password"
              type={showPassword ? "text" : "password"}
              error={errors.password || samePasswordError}
              helperText={
                (errors?.password?.type === "required" &&
                  "Password is required",
                samePasswordError && "Password is same as current password")
              }
              onChange={e => {
                if (e.target.value === getValues("current_password")) {
                  setSamePasswordError(true);
                } else {
                  setSamePasswordError(false);
                }
              }}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <FeatherIcon icon="lock" size={"14"} />
                  ) : (
                    <FeatherIcon icon="unlock" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
              variant="outlined"
            />
            <PrimaryNormalFormHelperText>
              New Password must be greate than 10 characters
            </PrimaryNormalFormHelperText>
          </Grid>{" "}
          {/* <Grid item xs={12}>
            <GrayLiniearDivider className="mb-2" />
          </Grid>{" "} */}
          <Grid item xs={6}>
            <InputLabel>confirm New Password</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <PrimaryNormalInputs
              placeholder="password"
              sx={{ width: "100%" }}
              {...register("cpassword", { required: true })}
              id="cpassword-outlined"
              label="confirm password"
              helperText={
                (errors?.cpassword?.type === "required" &&
                  "confirm new password is required",
                cpassworError && "password is not matching")
              }
              type={cShowPasswrod ? "text" : "password"}
              error={errors.cpassword || cpassworError}
              onChange={e => {
                if (e.target.value !== getValues("password")) {
                  setCpasswordError(true);
                } else {
                  setCpasswordError(false);
                }
              }}
              variant="outlined"
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCPassword}
                >
                  {cShowPasswrod ? (
                    <FeatherIcon icon="lock" size={"14"} />
                  ) : (
                    <FeatherIcon icon="unlock" size={"14"} />
                  )}
                </InputAdornment>
              }
            />
            <PrimaryNormalFormHelperText>
              Confirm new Password
            </PrimaryNormalFormHelperText>
          </Grid>
          <Stack
            sx={{ width: "100%" }}
            alignItems="center"
            direction="row"
            justifyContent={"flex-end"}
            spacing={2}
          >
            <SecondaryFilledButton sx={{ width: "100px" }}>
              Cancel
            </SecondaryFilledButton>
            <PrimaryButton type="submit" sx={{ width: "200px" }}>
              Save Changes
            </PrimaryButton>
          </Stack>
        </Grid>
      </Form>
    </Paper>
  );
}
