import React, { useEffect, useReducer, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Paper, Stack } from "@mui/material";
import {
  getPipeLineStagesBasedOnPostId,
  updateFromPipeline,
} from "helpers/services/kanban/getKanbanDetails";
import DropTargets from "components/kanban/dropTagets";
import SocketConnection from "hooks/sockets";
import ErrorHandlingToaster from "helpers/Toaster/error";
import KanbanHeader from "components/kanban/header";
import GlobalLoader from "components/loader";
import KanbanGrid from "./kanbanGrid";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
// import { ZA } from "common/constants/userModules";

export default function Kanban() {
  const { id } = useParams();
  const { sendMessage, lastMessage, connectionStatus } = SocketConnection();
  const [pipeline, setPipeline] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [showGrid, setShowGrid] = useState("grid");
  const userId = useSelector(state => state.Login);
  const activeUserId = userId?.id

  /* Testing of kanban stages restrictions start here */
  let role = userId?.role;
  const roleRestrictions = {
    CA: [
      "Sourced",
      "Applied",
      "Submitted",
      "Pre Evaluated Profiles",
      "Shortlisted",
      "Technical Evalutation(By Zepul)",
      "Technical Evalutation(Employeer)",
      "Technical Evalutation(Internal)",
      "Technical Evalutation(External)",
      "Evaluated",
      "Offered",
      "BGV",
      "Verified",
      "On boarded",
      "Rejected",
    ],
    CS: [
      "Sourced",
      "Applied",
      "Submitted",
      "Pre Evaluated Profiles",
      "Shortlisted",
      "Technical Evalutation(By Zepul)",
      "Technical Evalutation(Employeer)",
      "Technical Evalutation(Internal)",
      "Technical Evalutation(External)",
      "Evaluated",
      "Offered",
      "BGV",
      "Verified",
      "On boarded",
      "Rejected",
    ],
    ACM: [
      "Sourced",
      "Applied",
      "Screened",
      "Moderated",
      "Submitted",
      "Pre Evaluated Profiles",
      "Shortlisted",
      "Technical Evalutation(By Zepul)",
      "Technical Evalutation(Employeer)",
      "Technical Evalutation(Internal)",
      "Technical Evalutation(External)",
      "Evaluated",
      "Offered",
      "BGV",
      "Verified",
      "On boarded",
      "Rejected",
    ],
    TE: [
      "Pre Evaluated Profiles",
      "Shortlisted",
      "Technical Evalutation(By Zepul)",
      "Technical Evalutation(Employeer)",
      "Technical Evalutation(Internal)",
      "Technical Evalutation(External)",
      "Evaluated",
      "Rejected",
    ],
    SP: ["Applied", "Screened", "Rejected"],
    MODERATOR: ["Sourced","Applied","Screened", "Moderated","Submitted", "Rejected"],
    RC: ["Sourced", "Screened",  "Rejected"],
    RS:["Sourced", "Screened",  "Rejected"],
    BVC:["BGV","Verified","Rejected"],
    BVS:["BGV","Verified","Rejected"],
    ZA:["Sourced",
    "Applied",
    "Submitted",
    "Screened",
    "Moderated",
    "Pre Evaluated Profiles",
    "Shortlisted",
    "Technical Evalutation(By Zepul)",
    "Technical Evalutation(Employeer)",
    "Technical Evalutation(Internal)",
    "Technical Evalutation(External)",
    "Evaluated",
    "Offered",
    "BGV",
    "Verified",
    "On boarded",
    "Rejected",]
  };
  let extract;
  if (Object.keys(roleRestrictions).includes(role)) {
    extract = roleRestrictions[role];
  }

  // const modifyPipeline = pipeline?.map(each => {

  //   if (extract?.includes(each?.stage_name)) {
  //     return {
  //       ...each,
  //       isDisabled: false,
  //     };
  //   } else {
  //     return {
  //       ...each,
  //       isDisabled: true,
  //     };
  //   }
  // });
  /* Testing of kanban stages restrictions End here */

  useEffect(() => {
    initialFetch(id);
  }, [id, updated]);
  useEffect(() => {}, [connectionStatus]);
  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "job_apply_success" || data?.type !== "job_apply_success") {
          initialFetch(id);
          findJobDetails(id)
        }
      }
    }
  }, [lastMessage]);
  useEffect(() => {}, [pipeline]);
  async function initialFetch(id) {
    const { data, status } = await getPipeLineStagesBasedOnPostId(id);
    console.log(data?.pipeline_stage_details, "stages");
    /*Testing start here */

    let extractRoles
    if (Object.keys(roleRestrictions).includes(role)) {
      extractRoles = roleRestrictions[role];
    }
  
    let modifyPipeline = data?.pipeline_stage_details?.map(each => {
   
      if (extractRoles?.includes(each?.stage_name)) {
      
        return {
          ...each,
          isDisabled: false,
        };
      } else {
        return {
          ...each,
          isDisabled: true,
        };
      }
    });


    const filterSourcedProfiles = modifyPipeline?.map((each)=>{
      if(each?.stage_name === "Sourced"){
        const filterByRole = each?.user_job_posts_list?.map((item)=>{
          if(item?.created_by == activeUserId){
        
            return {
              ...item,
              isAccess:true
            }
          }else {
            return {
              ...item,
              isAccess:false
            }
          }
        })
        return filterByRole
      }else {
        return {
          ...each
        }
      }
    })
    const updatedList = [...filterSourcedProfiles?.[0]];
    modifyPipeline[0] = { ...modifyPipeline[0], user_job_posts_list: updatedList };
    setPipeline(modifyPipeline);
 

  
    /* Testing end here */

    // setPipeline(data?.pipeline_stage_details); uncomment this after your testing
    if (status === 200) {
      setLoading(false);
    }
  }
  useEffect(() => {
    findJobDetails(id);
  }, [id]);
  DropTargets;
  async function findJobDetails(id) {
    const { status, data } = await getSingleJobDetails(id);
    ErrorHandlingToaster({ data, status });
    setJobDetails(data);
  }
  async function handleDrop(x, y, datas) {
    const order = datas?.id;
    const cardId = datas?.card_id;
    if (order > x) {
    } else if (order === x) {
    } else {
      let copy = [...pipeline];
      // console.log(pipeline,"v");
      const cards = copy.find(item => item.stage_order === order);
      const cardsData = cards.user_job_posts_list;
      const cardDetails = cardsData.find(item => item.id === cardId);
      const data = copy.find(item => item.stage_order === x);

      const updatedContext = pipeline.findIndex(item => item.stage_order === x);
      const currentPipelineIndex = pipeline.findIndex(
        item => item.stage_order === order
      );
      data.user_job_posts_list = [cardDetails, ...data.user_job_posts_list];
      const currentCardId = cardsData.findIndex(item => item.id === cardId);
      cardsData.splice(currentCardId, 1);
      copy[currentPipelineIndex] = {
        ...copy[currentPipelineIndex],
        user_job_posts_list: cardsData,
      };
      console.log(cardId, cards, "as", cardDetails, "v");
      setPipeline([]);
      setPipeline(copy);
      if (order === 13) {
        sendMessage(
          JSON.stringify({
            type: "myearning",
            data: {
              user_job_post_id: cardId,
            },
            reciever_connectionId: "",
          })
        );
      }
      const result = await updateFromPipeline(
        cardId,
        data?.stage_id,
        cards?.stage_id
      );
      const { status } = result;
      if ([200, 201].includes(status))
        toast.success(`sucessfully moved to ${data?.stage_name}`);
        findJobDetails(id)
      ErrorHandlingToaster(result);
      sendMessage(
        JSON.stringify({
          type: "kanban",
          data: {
            job_post_id: id,
            user_id: cardDetails?.user_id,
          },
          reciever_connectionId: "",
        })
      );
    }
  }
  function checkDropLocation(x, y, data) {
    const order = data?.id;
    const cardId = data?.card_id;
    console.log("order", order, "x", x, "v");
    if (order > x) {
      return false;
    } else if (order === 13) {
      if (x === 13) {
        return true;
      }
      return false;
    } else if (order < x) {
      return true;
    } else if (order === x) {
      return true;
    } else {
      return false;
    }
  }
  function handleGrid(_, val) {
    // console.log(typeof val,val,"v");
    if (val !== null) setShowGrid(val);
  }
  console.log(pipeline,"pipeline")
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <div
          style={{
            overflow: "hidden",
            paddingTop: "0px",
            paddingLeft: "20px",
          }}
        >
          <KanbanHeader
            showGrid={showGrid}
            handleGrid={handleGrid}
            jobDetails={jobDetails}
            setUpdated={setUpdated}
            updated={updated}
          />
          {showGrid === "grid" ? (
            <DndProvider backend={HTML5Backend}>
              <Stack
                direction={"row"}
                spacing={2}
                sx={{
                  overflowX: "scroll",
                }}
              >
                {pipeline?.map((item, index) => (
                  <DropTargets
                  // isAccess = {item?.isAccess?item?.isAccess:false}
                    newItem={item}
                    index={index}
                    checkDropLocation={checkDropLocation}
                    handleDrop={handleDrop}
                  ></DropTargets>
                ))}
              </Stack>
            </DndProvider>
          ) : (
            <KanbanGrid pipeLine={pipeline} />
          )}
        </div>
      )}
    </>
  );
}
