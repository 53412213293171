import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { getAllReferalParterns } from "helpers/services/referalPartner";
import { imageURL } from "common/constants/commonURLS";
import {
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
  SmallSizeChip,
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PrimaryChip } from "assets/Mui/globalTheme";
import SkillViewModal from "helpers/Modals/SkillViewModal";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";

export default function ReferalPartnerTable() {
  const [referalPartners, setReferalPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openSkillModal, setOpenSkillModal] = React.useState(false);
  const [modalName, setModalName] = React.useState("");

  const dispatch = useDispatch();
  React.useEffect(() => {
    initialFetch();
  }, []);
  const history = useHistory();

  async function initialFetch() {
    setLoading(true);
    const { data, status } = await getAllReferalParterns("referral");
    const result = data?.map((item, index) => ({
      id: item?.id,
      phone_number: item?.phone_number,
      image: `${imageURL}${item?.image}`,
      email: item?.email,
      location: `${item?.location ? item.location : "N/A"}`,
      jobs_applied: item?.get_applied_job_post_counts?.count,
      resume: item?.resume,
      name: `${item?.first_name} ${item?.last_name}`,
      experience: `${item?.experience ? item.experience+"Yrs" : "N/A"}`,
      highest_education: "1d",
      skills: `${
        item?.skill_details?.length > 0
          ? item?.skill_details?.map(item => item?.name)?.join(",")
          : "N/A"
      }`,
      // skills: item?.skills?.map(item => item?.name)?.join(","),
      // gender: item?.gender?item.gender:"N/A",
      // candidate_referred: "1 d",
      // candidate_onboarded: "1d",
      // total_earnings: "1d",
      // available_earnings: "1d",
      // bank_account_details: "2d",
    }));
    if (status === 200) {
      setLoading(false);
    }

    setReferalPartners(result);
  }

  const handleCloseModal = () => {
    setOpenSkillModal(!openSkillModal);
  };
  const handleCellClick = params => {
    const field = params.field;

    if (field === "skills") {
      if (params.row.skills !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.skills.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } else if (field === "Categories") {
      if (params.row.Categories !== "N/A") {
        setModalName(params.field);
        setSelectedRowData(params.row.Categories.split(","));
        setOpenSkillModal(!openSkillModal);
      }
    } 
  };

  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      {/* <PrimaryButton
        onClick={() => {
          dispatch({ type: "APPROVE_MODAL" });
        }}
      >
        TOGGLE
      </PrimaryButton> */}
      <PrimaryDataGrid
        // onCellDoubleClick={event => {
        //   history.push(`/common-user/${event?.row?.id}`);
        // }}
        onCellClick={handleCellClick}
        loading={loading}
        checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName: "Image",
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name", headerName: "Name", width: 200 },
          { field: "phone_number",headerName: "Phone Number", width: 300 },
          { field: "email",headerName: "Email", width: 300 },
       
          { field: "location",headerName: "Location", width: 400, filterOperators: LocationOperators },
          { field: "experience",headerName: "Experience", width: 200 },
          {field:"jobs_applied", headerName: "Jobs Applied Count",width:200},
          // { field: "skills", width: 200, filterOperators: KeySkillOperator },
          {
            field: "skills",
            headerName: "Skills",
            width: 400,
            renderCell: params => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {params.value && params.value !== "N/A" ? (
                  <>
                  {params.value.split(",").slice(0,2)?.map((each)=><SmallSizeChip label={each} style={{margin:"1px"}}size="small"/>)}
                  </>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            ),
          },
          { field: "highest_education",headerName: "Highest Qualification", width: 200 },
          // { field: "gender", width: 200, filterOperators: GenderOperators },
          // { field: "candidate_referred", width: 200 },
          // { field: "candidate_onboarded", width: 200 },
          // { field: "total_earnings", width: 200 },
          // { field: "available_earnings", width: 200 },
          // { field: "bank_account_details", width: 200 },
        ]}
        rows={referalPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        // components={{
        //   noRowsOverlay: CustomNoRowsOverlay,
        // }}
      />
         {openSkillModal && (
        <SkillViewModal
          rowdata={selectedRowData}
          closeModalFunc={handleCloseModal}
          openModal={openSkillModal}
          headerName={modalName}
        />
      )}
    </div>
  );
}
