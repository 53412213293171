import { UPDATE_COMPANY } from "./actionTypes";
const initState = {
  company_image: "",
  tagline: "",
  size_of_company: "",
  about: "",
  industry: "",
  location: "",
  website: "",
  linkedin_url: "",
  facebook_url: "",
  twitter_url: "",
  glassdoors_url: "",
  ambition_box_url: "",
  comparably_url: "",
  careerbliss_url: "",
  payscale_url: "",
  google_reviews: "",
  perks: [],
  describe_your_culture: "",
  culture_media_images: [],
  culture_media_video_url: "",
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_COMPANY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
