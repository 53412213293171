import { SCREENING_ID, START_SCREENING } from "./actionTypes";

export const startScreening = e => ({
  type: START_SCREENING,
  payload: e,
});
export const startScreeningUserJob = e => ({
  type: SCREENING_ID,
  payload: e,
});
export const setScreening = e =>({
  type: "SET",
  payload:e
})