import { Grid, Stack } from "@mui/material";
import ChatJobSidebar from "components/Chat/Sidebar/chatJobs";
import ChatData from "./chatData";
import { connect } from "react-redux";

function Chat({collapse}) {
  return (
    <Grid sx={{ padding: "15px", height:"calc(100vh - 60px)" }} container spacing={2}>
      <Grid height={"100%"} item xs={12} md={!collapse ?4 :5}>
        <ChatJobSidebar />
      </Grid>
      <Grid height={"100%"} item xs={12} md={!collapse ? 8 : 7}>
        <ChatData />
      </Grid>
    </Grid>
  );
}
const mapState=(state)=>({
  collapse:state.Layout.collapse
})
export default  connect(mapState,null)(Chat)