import "../../../assets/css/jobsaddition.css";
import React from "react";
import { withRouter } from "react-router-dom";
import { Label } from "reactstrap";
// import ReactSelectComponent from "components/Common/reactSelectComponent";
import { departement, location } from "common/constants/options";
import USERS from "../../../common/constants/common-words";
import { connect } from "react-redux";
import { Input } from "reactstrap";

class Jobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      multi: [],
      width: window.innerWidth,
      click: false,
    };
    this.handleSelectMulti = this.handleSelectMulti.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  handleSelectMulti = data => {
    this.setState({
      multi: data,
    });
  };
  onChange = e => {
    this.setState({ value: e.target.value });
  };
  componentDidMount() {
    if (window.innerWidth < 800) {
      this.setState({ click: true });
    }
    this.setState({ width: window.innerWidth });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props)
      window.addEventListener("resize", e => {
        if (window.innerWidth > 800) {
          this.setState({ click: false });
        } else {
          this.setState({ click: true });
        }
        this.setState({ width: window.innerWidth });
      });
  }
  render() {
    return (
      <div
        id="screened_application_sidebar"
        style={{
          transition: "0.5s ease-in-out",
        }}
        className={`global_scroll ${
          !this.state.click ? "job__addition" : "job_small_addition"
        } `}
      >
        {this.props.login === USERS.ADMIN ? (
          <button
            className="add_new_jobs_btn"
            onClick={() => {
              this.props.history.push("/add-new-jobs");
            }}
          >
            + Add New Jobs
          </button>
        ) : (
          <></>
        )}
        {this.state.click && this.state.width < 800 && (
          <button
            className="down_icon"
            onClick={() => {
              this.setState({ click: false });
            }}
            style={{
              position: "absolute",
              bottom: "-10px",
              left: "45%",
              // transform: "translate(50%,50%)",
            }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
        )}
        {!this.state.click && this.state.width < 800 && (
          <button
            className="down_icon_large"
            onClick={() => {
              this.setState({ click: true });
            }}
            style={{
              position: "absolute",
              bottom: "3px",
              left: "45%",
              transform: "rotate(180deg)",
            }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
        )}
        {!this.state.click && (
          <div className="screened_application_topbar ">
            <div>
              <p>Search</p>
              <Input type="text" placeholder="Job title, Keyword or Company" />
            </div>
            <div className="group__container mb-4">
              {/* <p>Departement</p> */}

              {/* <ReactSelectComponent isMulti={false} options={departement} /> */}
            </div>
            <div className="group__container mb-4">
              {/* <p>Location</p> */}
              {/* <ReactSelectComponent isMulti={false} options={location} /> */}
            </div>
            <div>
              <p className="mb-2">Jobs Status</p>
              <div className="job_filter_names mb-4">
                <div className="job_filter_radio">
                  <input type="radio" id="job_type1" name="job_type" />
                  <Label htmlFor="job_type1">All Jobs</Label>
                </div>
                <div className="job_filter_radio">
                  <input type="radio" id="job_type2" name="job_type" />
                  <Label htmlFor="job_type2">Published</Label>
                </div>
                <div className="job_filter_radio">
                  <input type="radio" id="job_type3" name="job_type" />
                  <Label htmlFor="job_type3">Closed</Label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    login: state.Login,
  };
};

export default withRouter(connect(mapStateToProps, null)(Jobs));
