import { POST, PUT } from "common/constants/methods";
import { convertRoleForApi } from "helpers/roleFinder";

const { default: callApi } = require("helpers/callApi");

async function getID() {
  return await JSON.parse(localStorage.getItem("authUser"))
    ?.screeningPartner_id;
}
async function getUserType() {
  return await JSON.parse(localStorage.getItem("autUser")?.user_type);
}
export const handleScreeningPartner = async key => {
  await callApi(`admin_authorise/delete_company/${key}`, {}, "DELETE");
  return data.data;
};

export const getAllScreeningPartner = async () => {
  const data = await callApi("screening_partner_authorise");
  return data.data;
};
export const getAllScreeningPartnerWithStatus = async () => {
  return await callApi("screening_partner_authorise");
};

export const getPerticulerScreeningPartner = async () => {
  const data = await callApi(`screening_partner_authorise/${await getID()}`);
  return data.data;
};

export const getPerticulerScreeningPartnerByID = async id => {
  const data = await callApi(`screening_partner_authorise/${id}`);
  return data.data;
};

export const createScreenPartner = async body => {
  const reqBody = {
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.password,
    usertype: body.userType,
    // company_name: body.company_name,
  };
  const data = await callApi(
    "/screening_partner_authorise/register",
    reqBody,
    POST
  );
  return data;
};

export const updateScreeningPartner = async data => {
  return await callApi(
    `/screening_partner_authorise/${await getID()}`,
    data,
    PUT
  );
};

export const getAllJobsForScreeningPartner = async () => {
  return await callApi("/screening_partner/get_all_job_posts");
};
export const getAllScreenedApplicantsForUser = async (id,type) => {
  // return await callApi(
  //   `/common/get_individual_stage_applicants?stage=SCREENING_PARTNER&user_id=${await getID()}`
  // );
  return await callApi(
    `admin_dashboard/get_evaluated_list?type=${type}&user_id=${id}`
  );
};
export const startScreening = async (job_post_id, status, evalType) => {
  return await callApi(
    `/get_applicants_in_kanban/screen_user_job/${job_post_id}/${status}/${evalType}`,
    {},
    "PATCH"
  );
};

export async function getBVGParnters(userId){
  const res =   await callApi(`/admin_dashboard/get_bgv_verified_applicants_list?user_id=${userId}`)
  return res
}