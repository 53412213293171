const { Row, Container, Col, Card, CardBody } = require("reactstrap");
import React from "react";
import { IOSSwitch } from "components/Common/iosswitch";
import JobModel from "../../components/Common/models/jobRole";
import {
  createJobLevel,
  deleteJobLevel,
  getAllJobLevlsWithoutFunctionalArea,
  updateJobLevel,
} from "../../helpers/services/jobAttributes/jobLevels";
import { toast } from "react-hot-toast";
import { FormControlLabel, FormGroup } from "@mui/material";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
class JobRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      data: [],
      purpose: "",
      functional_area_id: "",
      isLoading: "true",
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.getAllJobLevlsWithoutFunctionalArea =
      getAllJobLevlsWithoutFunctionalArea.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const data = await this.getAllJobLevlsWithoutFunctionalArea();
    this.setState({ data: data,isLoading: false });
  }

  async componentDidUpDate(_, prevState) {
    if (prevState.data !== this.state.data) {
    }
    if (prevState.data.length !== prevState.data.length) {
      const data = await this.getAllJobLevlsWithoutFunctionalArea();
      this.setState({ data: data });
    }
  }
  toggle(
    purpose = "",
    name = "",
    active = "",
    index = "",
    id = "",
    functional_area_id = ""
  ) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      functional_area_id: functional_area_id,
      active: active,
      id: id,
      isOpen: !this.state.isOpen,
    });
  }
  handleDelete(index, id) {
    deleteJobLevel(id);
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
    toast.error("Disabled")
  }

  async updateFunc(sample, type, index) {
    if (type === "create") {
      await createJobLevel(sample);
      this.setState({ isOpen: false });
      this.setState({
        data: [...this.state?.data, sample],
      });
      toast.success("created")
    } else {
      await updateJobLevel(sample, this.state.id, sample.functional_area_id);
      toast.success(" Updated")
      let result = this.state.data;
      result[index] = sample;
      this.setState({
        data: result,
        isOpen: false,
      });
    }
  }
  handlRestore(item, index, fnId) {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    updateJobLevel(sample, item.id, fnId);
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
    toast.success("Enabled")
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id)
      : this.handlRestore(item, index, item.functional_area_id);
  }
  render() {
    return (
      <Container fluid>
        <JobModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Job Role"}
          purpose={this.state.purpose}
          index={this.state.index}
          fn_id={this.state.functional_area_id}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
        />
        <div className="header_jobs_sticky">
          <PrimaryButton
            sx={{ width: "150px" }}
            onClick={() => this.toggle("create")}
          >
            Create Job Role
          </PrimaryButton>
        </div>
        <Row style={{ marginLeft: "20px" }}>
          <div className="table_cont">
            <div className="table-responsive">
              <Card>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Job Role</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                     

              {this.state.isLoading ? (
                  <BubbleLoader />
                  ) : (
                    this.state.data.map((item, index) => (
                      <TableRow key={item.name + index}>
                        <TableCell>{item.name}</TableCell>

                        <TableCell className="active_td">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <PrimarySwitch
                                  sx={{ m: 1 }}
                                  checked={item.is_active}
                                  onChange={async e => {
                                    this.handleAction(item, index);
                                  }}
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell className="col_btn">
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() =>
                              this.toggle(
                                "edit",
                                item.name,
                                // item.default,
                                item.is_active,
                                index,
                                item.id,
                                item.functional_area_id
                              )
                            }
                          >
                            Edit
                          </PrimaryButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}

                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default JobRole;
