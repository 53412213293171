import callApi from "helpers/callApi";

export const getAllScoreCard = async (u_id, job_id, type) => {
  return await callApi(
    `job_post_evaluation?user_job_post_id=${u_id}&job_post_id=${job_id}&user_type=${type}`
  );
};
export const updateJobPostEvaluation = async (id, data) => {
  return await callApi(`job_post_evaluation/${id}`, data, "PUT");
};
export const findTotalEvaluationsforScoreCard = async user_job_post_id => {
  return await callApi(
    `/get_applicants_in_kanban/get_score_card_details/${user_job_post_id}`
  );
};

export async function getSkillRecords(userJobPostId){
  const res =  await callApi(`/get_applicants_in_kanban/get_score_card_details/${userJobPostId}`);
  return res
}

export async function getScreeningSkills({userJobPostId,evalId,evType}){
  const res = await callApi(`/job_post_evaluation?user_job_post_id=${userJobPostId}&evaluator_id=${evalId}&evaluator_type=${evType}`)
  return res
}
export async function getModeratorSkills({userJobPostId,evalId,evType}){
  const res = await callApi(`/job_post_evaluation?user_job_post_id=${userJobPostId}&evaluator_id=${evalId}&evaluator_type=${evType}`)
  return res
}
export async function getTechnicalSkills({userJobPostId,evalId,evType}){
  const res = await callApi(`/job_post_evaluation?user_job_post_id=${userJobPostId}&evaluator_id=${evalId}&evaluator_type=${evType}`)
  return res
}