import { FormControlLabel, FormGroup } from "@mui/material";
import { imageURL } from "common/constants/commonURLS";
import { IOSSwitch } from "components/Common/iosswitch";
import UploadModel from "components/Common/models/uploadModel";
import { createCategories } from "helpers/services/blogs";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-hot-toast";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Container,
  CardFooter,
  Button,
} from "reactstrap";
class CreateCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      modalOpen: false,
      is_featured: false,
      is_active: false,
      name: "",
    };
    this.toggleModel = this.toggleModel.bind(this);
    this.handleImage = this.handleImage.bind(this);
  }
  toggleModel() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }
  async handleImage(data) {
    const url = await getPreSignedURL(data);
    this.setState({ image: url });
    this.toggleModel();
  }
  render() {
    return (
      <Container fluid>
        <UploadModel
          isOpen={this.state.modalOpen}
          toggle={this.toggleModel}
          handleImage={this.handleImage}
        />
        <Row style={{ marginTop: "70px", marginLeft: "20px" }}>
          <Col xs={12} style={{ padding: "40px" }}>
            <h3>Create Blog</h3>
          </Col>
          <Row>
            <Card>
              <CardBody className="global_gap">
                <Row>
                  <Col>
                    <Label>Name</Label>
                    <Input
                      type="text"
                      value={this.state.name}
                      onChange={e => {
                        this.setState({ name: e.target.value });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Label>Thumbnail</Label>
                  <Col xs={2}>
                    {this.state.image === "" ? (
                      <Button className="w-2" onClick={this.toggleModel}>
                        Upload Image
                      </Button>
                    ) : (
                      <img
                        src={`${imageURL}${this.state.image}`}
                        style={{ width: "100px", height: "100px" }}
                      />
                    )}
                  </Col>{" "}
                  {/* </Col> */}
                </Row>
                <Row>
                  <Label className="yellow">Reccomented Size 100*100</Label>
                </Row>
                <Row>
                  <Col>
                    <Label>Is Featured</Label>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={this.state.is_featured}
                            onChange={async e => {
                              this.setState({
                                is_featured: !this.state.is_featured,
                              });
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Is Active</Label>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={this.state.is_active}
                            onChange={async e => {
                              this.setState({
                                is_active: !this.state.is_active,
                              });
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-after">
                  <Col xs={11}>
                    <button className="btn btn-secondary">Cancel</button>
                  </Col>
                  <Col xs={1} className="ml-auto">
                    <button
                      className="btn btn-primary"
                      onClick={async () => {
                        const { status } = await createCategories({
                          name: this.state.name,
                          image: this.state.image,
                          is_featured: this.state.is_featured,
                        });
                        if (status === 201) {
                          this.setState({
                            name: "",
                            image: "",
                            is_featured: false,
                          });
                          toast.success("Category Created Successfully");
                        }
                      }}
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Row>
      </Container>
    );
  }
}

export default CreateCategories;
