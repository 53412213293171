import {
  Avatar,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import React from "react";
import { grey } from "@mui/material/colors";
import { PrimaryTypography, Styledh2Typography } from "assets/Mui/globalTheme";
export default function LogoutModel({ logout, handleLogout }) {
  const style = {
    position: "absolute",
    top: "50%",
    borderRadius: "8px",
    pading: "20px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    p: 4,
  };
  const history = useHistory();
  return (
    <Modal
      //   hideBackdrop={true}
      open={logout}
      onClose={handleLogout}
      sx={{
        pading: "20px",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <PrimaryTypography variant="h2">Logout?</PrimaryTypography>
        <Typography variant="body1" sx={{ my: 2, color: "gray" }}>
          Are you sure you want to logout? You will be redirected to the login
          page.
          <br />
        </Typography>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Button
            onClick={handleLogout}
            variant="text"
            sx={{
              backgroundColor: grey,
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            purpose="delete"
            onClick={() => {
              history.push("/logout");
            }}
          >
            Logout
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
