import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  datasets: [
    {
      label: "# of Votes",
      data: [Math.random() * 20, Math.random() * 15, Math.random() * 10],
      backgroundColor: ["#5fcfa5", "#ebeff2", "#556ee6"],
      borderColor: ["#5fcfa5", "#ebeff2", "#556ee6"],
      borderWidth: 1,
    },
  ],
};

export default function RoundChart() {
  return <Pie data={data} style={{ maxWidth: "200px", maxHeight: "200px" }} />;
}
