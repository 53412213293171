import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React from "react";
import { Col, Container, Label, Row } from "reactstrap";
import "../../assets/css/talentpool.css";
import DatatableTables from "./table";
class MainPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <Row className="talent_header">
            <Col xs={2}>
              <h5>All Candidates</h5>
            </Col>
            <Col xs={3}>
              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            </Col>
            <Col xs={2}>
              <Label>1-1 of 1 Candidates</Label>
            </Col>
            {/* <Col xs={1}>


                        </Col> */}
            <Col xs={1} className="nav_icon">
              <div className="outlined_btn">
                <LeftOutlined />
              </div>
              <div className="outlined_btn">
                <RightOutlined />
              </div>
            </Col>
            <Col xs={2}>
              <button className="btn btn-outline-primary">Data Fields</button>
            </Col>
            <Col md={2}>
              <button className="btn btn-primary">Candidates</button>
            </Col>
          </Row>
          <Row>
            <DatatableTables />
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default MainPage;
