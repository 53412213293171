import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logoutUser } from "../../store/actions";

class Logout extends Component {
  componentDidMount = () => {
    this.mounted = true;
    if (this.mounted) this.props.logoutUser(this.props.history);
  };
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
    return <React.Fragment></React.Fragment>;
  }
}

Logout.propTypes = {
  history: PropTypes.any,
  logoutUser: PropTypes.func,
};

export default withRouter(connect(null, { logoutUser })(Logout));
