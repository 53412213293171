import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { getAllReferalParterns } from "helpers/services/referalPartner";
import { getAllBackgroundUsersList } from "helpers/services/Background/backgroundVerify";
import { imageURL } from "common/constants/commonURLS";
import {
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { useHistory } from "react-router-dom";
import { PrimaryChip,HoldChip } from "assets/Mui/globalTheme";
import { StyledGridOverlay } from "assets/Mui/globalTheme";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";

export default function BgvTable() {
  const [referalPartners, setReferalPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    initialFetch();
  }, []);
  const history = useHistory();

  async function initialFetch() {
    setLoading(true);
    const { data, status } = await getAllBackgroundUsersList();
    const result = data?.map((item, index) => ({
      id: item?.id,
      phone_number: item?.phone_number,
      image: `${imageURL}${item?.image}`,
      email: item?.email,
      gender: item?.gender,
      location: `${item?.location?item.location:"N/A"}`,
      name: `${item?.first_name} ${item?.last_name}`,
      Status: `${item.user_approval}`,
      // jobs_applied: item?.get_applied_job_post_counts?.count,
      // resume: item?.resume,
      // experience: "1 d",
      // candidate_referred: "1 d",
      // candidate_onboarded: "1d",
      // total_earnings: "1d",
      // available_earnings: "1d",
      // highest_education: "1d",
      // skills: item?.skills?.map(item => item?.name)?.join(","),
      // bank_account_details: "2d",
    }));
    if (status === 200) {
      setLoading(false);
    }

    setReferalPartners(result);
  }
  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      <PrimaryDataGrid
        onCellClick={event => {
          history.push(`/common-user/${event?.row?.id}`);
        }}
        loading={loading}
        // checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName: "Image",
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name",headerName: "Name", width: 200 },
          { field: "phone_number",headerName: "Phone Number", width: 300 },
          { field: "email",headerName: "Email", width: 300 },
          {
            field: "Status",
            headerName: "Status",
            width: 200,
            renderCell: params => {
              if (params.formattedValue !== "Approve") {
                return <HoldChip label={params?.formattedValue} size="small"/>;
              }
              return <PrimaryChip label={params?.formattedValue} size="small"/>;
            },
          },
          { field: "location",headerName: "Location", width: 400, filterOperators: LocationOperators },
          // { field: "employees_count", width: 200 },
          // { field: "candidate_onboarded", width: 200 },
          // { field: "about", width: 200 },
          // { field: "total_bgv_tasks_completed", width: 200 },
          // { field: "available_earnings", width: 200 },
          // { field: "total_earnings", width: 200 },
          // { field: "highest_education", width: 200 },
          // { field: "skills", width: 200, filterOperators: KeySkillOperator },
          // { field: "bank_account_details", width: 200 },
        ]}
        rows={referalPartners}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </div>
  );
}
