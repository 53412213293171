import { PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function handleApproveJob(jobId, verified = true) {
  const result = await callApi(
    `account_manager/verify_job_post/${jobId}`,
    {
      is_job_post_verified: verified,
    },
    PUT
  );
  return result;
}
