import { Grid } from "@mui/material";
import { BlackTypography, GreyTypography } from "assets/Mui/globalTheme";

export default function UserInformationGrid({ type, user }) {
  return (
    <>
      <Grid item md={4}>
        <GreyTypography variant="h6" component="h6">
          {type}
        </GreyTypography>
      </Grid>
      <Grid item md={2}>
        <GreyTypography variant="h6" component="h6">
          :
        </GreyTypography>
      </Grid>
      <Grid item md={6}>
        <BlackTypography variant="h6" component="h6">
          {user}
        </BlackTypography>
      </Grid>
    </>
  );
}
