import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Grid,
  Stack,
} from "@mui/material";
import {
  BlackTypography,
  CustomAvatar,
  StyledTootlTip,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { deepOrange, grey, teal } from "@mui/material/colors";
import { useDrag } from "react-dnd";
import { Link, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { SP,TE,CS,CA,ACM,ZA,RC} from "common/constants/userModules";

export default function UserJobCard({ user_data, order, stage_name }) {

  const user = useSelector(state => state.Login);
  const role = user?.role

// console.log(user_data,"nani")
 
  const { id } = useParams();
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "KANBAN",
    // canDrag: stage_name === 'Sourced' && [RC,CS,CS].includes(role) ? user_data?.isAccess : stage_name !== 'Sourced',
    canDrag: stage_name === 'Sourced' && [RC,CS,CS].includes(role)?user_data?.isAccess:true,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: {
      id: order,
      card_id: user_data?.id,
    },
  }));
  const sp = user_data?.screening_partner_details? Object.keys(user_data?.screening_partner_details)?.length
 ? user_data.screening_partner_details
.first_name?.charAt(0) + user_data.screening_partner_details
.last_name?.charAt(0) : SP : SP
  const te = user_data?.evaluator_details? Object.keys(user_data?.evaluator_details)?.length ? user_data.evaluator_details.first_name?.charAt(0) + user_data.evaluator_details.last_name?.charAt(0) : TE : TE
  const me = user_data?.moderator_details? Object.keys(user_data?.moderator_details)?.length ? user_data.moderator_details.first_name?.charAt(0) + user_data.moderator_details.last_name?.charAt(0) : "ME" : "ME"
function getTimeDiff(){
const comingDate = user_data?.createdAt
const currentDate = new Date()
const targetDate = new Date(comingDate);
const differenceInMilliseconds = currentDate.getTime() - targetDate.getTime();

const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
const differenceInMinutes = Math.floor(differenceInSeconds / 60);
const differenceInHours = Math.floor(differenceInMinutes / 60);
const differenceInDays = Math.floor(differenceInHours / 24);

if(differenceInMinutes >60 && differenceInHours < 24){
  let value = differenceInHours.toString() +" Hours ago"
  return value
}else if (differenceInMinutes <60){
  let value = differenceInMinutes.toString() +" Minutes ago"
  return value
}else {
  let value = differenceInDays.toString()+ " days ago"
  return value
}
}

  return (
    <div
      ref={drag}
      style={{
        border: "0.5px solid rgba(0,0,0,0.3)",
        background: "white",
        marginBottom: "10px",
        padding: "10px",
        borderRadius: "14px",
        boxShadow: "none",
        cursor: stage_name === "Sourced" && [CS,CA,RC].includes(role)?user_data?.isAccess? "auto" : "not-allowed":"",
        opacity: stage_name === "Sourced" && [CS,CA,RC].includes(role)?user_data?.isAccess ?"":"50%":"",
        // "&:hover": {
        //   background: "rgba(255,255,255,0.5)",
        // },
      }}
      className="job_user_card_information"
      sx={{ maxWidth: 345 }}
    >
      <Stack spacing={1}>
        <div className="d-flex align-items-center justify-content-between">
          <Link
          onClick={()=>{
            const evalDetails = {
              moderator:user_data?.moderator_details?.id,
              screening:user_data?.screening_partner_details?.id,
              technical:user_data?.evaluator_details?.id
            }
            localStorage.setItem("evaluation_info",JSON.stringify(evalDetails));
          }}
            to={`/joblist/jobs/user-job-post?job_id=${id}&user_id=${user_data?.user_id}&user_job_post_id=${user_data?.id}&stage_name=${stage_name}`}
          >
            <BlackTypography variant="h4">
              {user_data?.first_name} {user_data?.last_name}
            </BlackTypography>
          </Link>

          {/* <BlackTypography variant="h3">5Y</BlackTypography> */}
        </div>

        <Stack alignItems="center" direction="row" spacing={1}>
          <FeatherIcon icon="map-pin" size="14" />
          <Typography variant="p">{user_data?.location}</Typography>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={1}>
          <FeatherIcon icon="mail" size="14" />
          <Typography variant="p">{user_data?.email}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
        <StyledTootlTip title={sp!==SP ? "screened by " + user_data.screening_partner_details
?.first_name +" " + user_data.screening_partner_details
?.last_name:'screening not yet finalized'}>
          <CustomAvatar bgColor="rgb(3, 23, 83)" bgHeight="24px" bgWidth="24px">
            {sp}
          </CustomAvatar>
        </StyledTootlTip>

        <StyledTootlTip title={te!==TE ? "evaluated by "+ user_data.evaluator_details?.first_name +" " + user_data.evaluator_details?.last_name : 'evaluation not yet finalized'}>
          <CustomAvatar bgColor={teal["500"]} bgHeight="24px" bgWidth="24px">
            {te}
          </CustomAvatar>
        </StyledTootlTip>
        <StyledTootlTip title={me!=="ME" ? 'moderated by '+ user_data.moderator_details?.first_name +" " + user_data.moderator_details?.last_name :'moderation not yet finalized'}>
          <CustomAvatar
            bgColor={deepOrange["500"]}
            bgHeight="24px"
            bgWidth="24px"
          >
            {me}
          </CustomAvatar>
        </StyledTootlTip>
      </Stack>
      <div className="d-flex align-items-end justify-content-end">
        <Typography variant="p" sx={{ color: "gray" }}>
          {/* 7 Minutes ago */}
          {getTimeDiff()}
        </Typography>
      </div>
    </div>
  );
}
