import GenderSelect from "../header/genderSelect";

export const GenderOperators = [
  {
    label: "includes",
    value: "includes",
    getApplyFilterFn: filterItem => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return params => {
        return filterItem?.value?.includes(params?.value);
      };
    },
    InputComponent: GenderSelect,
    getValueAsString: value => `${value}`,
  },
];
