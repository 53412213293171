import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../assets/css/index.css";
import Header from "./Header";
import Sidebar from "./sidebar";
import { handleCollapse } from "store/actions";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      width: 0,
      height: 0,
      collapse: false,
    };
    this.collapseMain = this.collapseMain.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  collapseMain() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <main className="d-flex">
            <Sidebar />

            <div
              className="left_main_content"
              style={
                this.props.collapse
                  ? { marginLeft: "208px", width:"calc(100% - (208px + 26px))", transition: "0.5s ease-in-out" }
                  : {
                      marginLeft: "70px",
                      width:"calc(100% - (70px + 26px))",
                      transition: "0.5s ease-in-out",
                    }
              }
            >
              <Header />
              <div style={{ marginLeft: "10px",width:'100%' }}>{this.props.children}</div>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    ...state.Layout,
    // collapse: state.Layout.collapse,
  };
};
const mapDispatchToProps = dispatch => ({
  collpaseHanlder: () => dispatch(handleCollapse()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
