import { withRouter } from "react-router-dom";
import React from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  getAllFunctionalArea,
  getSingleFunctionalArea,
} from "helpers/services/jobAttributes/functionalArea";
import ReactSelect from "react-select";
const { Modal, Label } = require("reactstrap");
import PropTypes from "prop-types";
import { PrimaryButton, SecondaryFilledButton } from "assets/Mui/globalTheme";
class CommonModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      active: false,
      options: [],
      functional_area: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }
  async componentDidMount() {
    this.mounted = true;
    const items = await getAllFunctionalArea();

    const data = items.map(iterator => {
      return {
        value: iterator.id,
        label: iterator.name,
      };
    });
    if (this.mounted) this.setState({ options: data });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.purpose === "create" &&
      this.props.purpose !== prevProps.purpose
    ) {
      this.setState({ input: "", functional_area: {} });
    }
    if (this.props.fn_id !== prevProps.fn_id && this.props.purpose === "edit") {
      const defaultFnArea = this.props.fn_id;
      if (defaultFnArea !== "") {
        const data = await getSingleFunctionalArea(defaultFnArea);
        this.setState({
          functional_area: {
            value: data.id,
            label: data.name,
          },
        });
      }
    }
    if (this.props.title !== prevProps.title) {
      this.setState({
        input: this.props.title,
      });
    }
  }
  handleInput(e) {
    this.setState({ input: e.target.value });
  }
  handleSubmit() {
    if (this.state.input !== "" || this.state.functional_area.value !== "") {
      this.props.update(
        {
          name: this.state.input,
          is_active: true,
          functional_area_id: this.state.functional_area?.value,
        },
        this.props.purpose,
        this.props.index
      );
    }
  }

  render() {
    const {
      headTag,
      isOpen,
      toggle,
      purpose,
      // default: defaulValue,
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={this.toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {purpose} {headTag}
          </h5>
          <button
            type="button"
            onClick={() => toggle(!isOpen)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm>
            <AvField
              name="text"
              label={`create ${headTag}`}
              className="form-control"
              value={this.state.input}
              onChange={this.handleInput}
              placeholder={`enter ${headTag} name`}
              type="text"
              required
            />{" "}
          </AvForm>
        </div>
        <div className="modal-body">
          <Label>Category</Label>

          <ReactSelect
            style={{ zIndex: "999" }}
            value={this.state.functional_area}
            isMulti={false}
            onChange={e => {
              this.setState({
                functional_area: { value: e.value, label: e.label },
              });
            }}
            options={this.state.options}
            classNamePrefix="select2-selection"
          />
        </div>

        <div className="modal-footer">
          <SecondaryFilledButton
            type="button"
            sx={{ width: "100px", marginRight: "10px" }}
            onClick={() => {
              toggle(!isOpen);
            }}
            data-dismiss="modal"
          >
            Close
          </SecondaryFilledButton>
          <PrimaryButton
            type="button"
            sx={{ width: "150px" }}
            className="btn btn-primary"
            onClick={this.handleSubmit}
          >
            Save changes
          </PrimaryButton>
        </div>
      </Modal>
    );
  }
}
CommonModel.propTypes = {
  headTag: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  purpose: PropTypes.string,
  fn_id: PropTypes.string,
  title: PropTypes.string,
  update: PropTypes.func,
  index: PropTypes.number,
};
export default withRouter(CommonModel);
