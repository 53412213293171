import { updatingTask, checkSingleTask } from "./actions";
import {
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_ADDED_TASKS,
  UPDATE_TASK,
  CHECK_SINGLE,
} from "./actionTypes";

const INIT_STATE = {
  tasks: [],
  error: {},
};

const tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_TASK:
      return updatingTask(state, action);
    case CHECK_SINGLE:
      return checkSingleTask(state, action.payload);

    case GET_ADDED_TASKS:
      return state.tasks;
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      };

    case GET_TASKS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default tasks;
