import { Step, StepLabel, Stepper } from "@mui/material";
import {
  ColorlibStepIconRoot,
  PrimaryStep,
  PrimaryStepConnector,
  PrimaryStepLabel,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

export default function CompanyStepper({ activeStep }) {
  const steps = ["About company ", "Reviews", "perks"];
  const icons = ["briefcase", "star", "feather"];
  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<PrimaryStepConnector />}
      >
        {steps.map((label, index) => (
          <PrimaryStep key={label}>
            <PrimaryStepLabel StepIconComponent={ColorlibStepIcon}>
              {label}
            </PrimaryStepLabel>
          </PrimaryStep>
        ))}
      </Stepper>
    </>
  );
}

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = ["briefcase", "star", "feather"];
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      <FeatherIcon icon={icons[String(props.icon - 1)]} size="14" />{" "}
    </ColorlibStepIconRoot>
  );
}
