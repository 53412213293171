const {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  FormGroup,
} = require("reactstrap");
import React from "react";
import "../../assets/css/jobattr.css";
import "../../assets/css/comment.css";
import "../../assets/css/categories/index.css";

import { allCategories, updateCategories } from "helpers/services/blogs";
import { Link } from "react-router-dom";
import { imageURL } from "common/constants/commonURLS";
import { FormControlLabel } from "@mui/material";
import { IOSSwitch } from "components/Common/iosswitch";
import { toast } from "react-hot-toast";

class AllCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  async componentDidMount() {
    const { data } = await allCategories();
    this.setState({ data: data?.data });
  }
  render() {
    return (
      <Container fluid>
        <Row
          style={{
            marginTop: "120px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Card>
            <CardBody>
              <Row className="align-items-center justify-content-center">
                <Col md={6}>
                  <button className="btn btn-primary"> category</button>
                </Col>
                <Col md={4}>
                  <form className="app-search d-none d-lg-block">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                      />
                      <span className="bx bx-search-alt" />
                    </div>
                  </form>
                </Col>
                <Col md={2}>
                  <Link to="create-categories">
                    <button className="btn btn-success ">
                      <span>+ Create Category</span>
                    </button>
                  </Link>
                </Col>
              </Row>
              <Row style={{ minHeight: "60vh" }}>
                <Col>
                  {this.state?.data?.length === 0 ? (
                    <div className="comment_container">
                      <div className="items_cont">
                        <i className="bx bx-briefcase-alt"></i>
                        <h4>No Category Found</h4>
                        <p>You dont have any Category.</p>
                        <button className="btn btn-primary">New Blog</button>
                      </div>
                    </div>
                  ) : (
                    <Row>
                      {this.state?.data?.map((item, idx) => (
                        <Col xs={3}>
                          <div className="card_categories">
                            <img
                              className="blog_catgory_card_thumbnail"
                              onError={e => {
                                e.target.src =
                                  "https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo=";
                              }}
                              src={`${imageURL}${item?.image}`}
                              alt="image"
                            />
                            <div className="category_card_name">
                              <h5>{item?.name}</h5>
                              <div className="d-flex gap-2 align-items-center">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={item.is_featured}
                                        onChange={async e => {
                                          const { status } =
                                            await updateCategories({
                                              id: item?.id,
                                              is_featured: !item.is_featured,
                                              is_active: !item.is_active,
                                              name: item?.name,
                                              image: item?.image,
                                            });
                                          if (status !== 200) {
                                            toast.error("something went wrong");
                                            return;
                                          }
                                          toast.success("updated successfully");
                                          const particularData =
                                            this.state.data;
                                          particularData[idx].is_featured =
                                            !particularData[idx].is_featured;
                                          this.setState({
                                            data: [...particularData],
                                          });
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                                {/* <button className="del_btn">
                                  <i className="bx bx-trash"></i>
                                </button> */}
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default AllCategories;
