import { CircularProgress, Grid } from "@mui/material";
import CandidateInformation from "./CandidateInformation";
import CandidateExperience from "./WorkExperience";
import AdditionalInformation from "./additonalInformation";
import CandidateSkills from "./skills";
import SearchParamHook from "hooks/searchParams";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import EducationInformation from "./educationalInformation";
import CandidateLanguages from "./languages";
import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
import { useDispatch } from "react-redux";

export default function ProfileSummary() {
  const searchParams = SearchParamHook();
  const dispatch = useDispatch();
  const [candidateDetails, setCandidateDetails] = useState({});
  const [loading, setLoading] = useState(true);
  // const { id: jobPostId } = useParams();
  useEffect(() => {
    const userId = searchParams.get("user_id");
    const jobPostId = searchParams.get("job_id");
    if (userId && jobPostId) {
      fetchUserJobPostDetails(userId, jobPostId);
    }
  }, [searchParams]);
  async function fetchUserJobPostDetails(userId, jobPostId) {
    const { data } = await viewSingleUserDetailsInKanbanBoard(
      userId,
      jobPostId
    );
    dispatch({ type: "ADD_CANDIDATES", payload: data });
    setCandidateDetails(data?.user_job_post_details);
    setLoading(false);
  }
  return (
    <>
      {loading ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6} spacing={2}>
            <CandidateInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
            />
            <CandidateExperience
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <CandidateSkills
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
            />
            {/* <CandidateLanguages
          candidateDetails={candidateDetails}
          setCandidateDetails={setCandidateDetails}
        /> */}
            <AdditionalInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
            />
            <EducationInformation
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
