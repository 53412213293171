import CreatableSelect from "react-select/creatable";
import "../../assets/css/booleanquery.css";
const {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Modal,
  ModalBody,
} = require("reactstrap");
import React from "react";
import toast from "react-hot-toast";
import { PLATFORM_INFORMATIONS } from "common/constants/platform";
import { SEARCH, SEARCH_STRING } from "common/constants/search";
class BooleanQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multi: [],
      xRayLink: false,
      queryType: "google",
      platform: "linkedin",
      andSkills: [],
      orSkill: [],
      location: [],
      jobTitle: [],
      notSkill: [],
    };
    this.handleXRayLink = this.handleXRayLink.bind(this);
  }

  handleXRayLink = () => {
    this.setState({
      xRayLink: true,
    });
  };
  handleJobTitle = (newValue, _) => {
    this.setState({
      jobTitle: newValue,
    });
  };

  handleAndSkills = (newValue, _) => {
    this.setState({
      andSkills: newValue,
    });
  };
  handleOrSkills = (newValue, _) => {
    this.setState({
      orSkill: newValue,
    });
  };
  hanldeNotSkill = (newValue, _) => {
    this.setState({
      notSkill: newValue,
    });
  };
  handleLocationSkill = (newValue, _) => {
    this.setState({
      locationSkill: newValue,
    });
  };

  HandlePlatformQuery(key) {
    switch (key) {
      case PLATFORM_INFORMATIONS.STACKOVERFLOW: {
        return this.handleStackOverflow();
      }
      case PLATFORM_INFORMATIONS.TWITTER: {
        return this.handleTwitter();
      }
      case PLATFORM_INFORMATIONS.DRIBBLE: {
        return this.handlDribble();
      }
      case PLATFORM_INFORMATIONS.GITHUB: {
        return this.handleGithub();
      }
      case PLATFORM_INFORMATIONS.LINKEDIN: {
        return this.handleLinkedin();
      }
      default:
        return "";
    }
  }
  render() {
    const jobTitle = [
      {
        options: [
          { label: "Django Developer", value: "Django Developer" },
          { label: "Flutter Developer", value: "Flutter Developer" },
          { label: "Swift Developer", value: "Swift Developer" },
        ],
      },
    ];

    const andSkills = [
      {
        options: [
          { label: "Django", value: "Django" },
          { label: "Flutter", value: "Flutter" },
          { label: "Swift", value: "Swift" },
        ],
      },
    ];

    const orSkills = [
      {
        options: [
          { label: "Django", value: "Django" },
          { label: "Flutter", value: "Flutter" },
          { label: "Swift", value: "Swift" },
        ],
      },
    ];
    const notSkills = [
      {
        options: [
          { label: "Django", value: "Django" },
          { label: "Flutter", value: "Flutter" },
          { label: "Swift", value: "Swift" },
        ],
      },
    ];
    const location = [
      {
        options: [
          { label: "HYD", value: "HYD" },
          { label: "india", value: "india" },
          { label: "kerala", value: "kerala" },
        ],
      },
    ];
    this.onCloseClick = () => {
      this.setState({
        xRayLink: false,
      });
    };

    this.handleLinkedin = () => {
      return `${SEARCH}${this.state.jobTitle?.map((item, index) => {
        return (
          item.value.replace(" ", "+") +
          (this.state.jobTitle.length - 1 !== index ? "+OR+" : "")
        );
      })}${this.state.andSkills?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.andSkills.length - 1 !== index ? "+" : "")
        );
      })}${this.state.orSkill?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.orSkill.length - 1 !== index ? "+" : "")
        );
      })}${this.state.notSkill?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.notSkill.length - 1 !== index ? "+" : "")
        );
      })} -intitle:"profiles" -inurl:"dir/+"+site:in.${
        this.state.platform
      }.com/in/+OR+site:in.${this.state.platform}.com/pub/`;
    };
    this.handleGithub = () => {
      return `
${SEARCH}=site:${this.state.platform}.com+contributions in the last year+
${this.state.andSkills?.map((item, index) => {
  return (
    item.value.replace(" ", "+") +
    (this.state.andSkills.length - 1 !== index ? "+AND+" : "")
  );
})}${this.state.orSkill?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.orSkill.length - 1 !== index ? "+AND+" : "")
        );
      })}${this.state.notSkill?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.notSkill.length - 1 !== index ? "+AND+" : "")
        );
      })}`;
    };

    this.handleStackOverflow = () => {
      return `
       ${SEARCH}site:${
        this.state.platform
      }.com/users -"Keeping a low profile."${this.state.andSkills?.map(
        (item, index) => {
          return (
            "+" +
            item.value.replace(" ", "+") +
            (this.state.andSkills.length - 1 !== index ? "+" : "")
          );
        }
      )}${this.state.orSkill?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.orSkill.length - 1 !== index ? "+" : "")
        );
      })}

`;
    };
    this.handlDribble = () => {
      return `
        
${SEARCH}site:${this.state.platform}${SEARCH_STRING}${this.state.andSkills?.map(
        (item, index) => {
          return (
            "+" +
            item.value.replace(" ", "+") +
            (this.state.andSkills.length - 1 !== index ? "+" : "")
          );
        }
      )}${this.state.orSkill?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.orSkill.length - 1 !== index ? "+" : "")
        );
      })}`;
    };
    this.handleTwitter = () => {
      return `${SEARCH}site:twitter.com -inurl:(search|favorites|status|statuses|jobs) -intitle:(job|jobs) -recruiter -HR -careers${this.state.andSkills?.map(
        (item, index) => {
          return (
            "+" +
            item.value.replace(" ", "+") +
            (this.state.andSkills.length - 1 !== index ? "+" : "")
          );
        }
      )}${this.state.orSkill?.map((item, index) => {
        return (
          "+" +
          item.value.replace(" ", "+") +
          (this.state.orSkill.length - 1 !== index ? "+" : "")
        );
      })}`;
    };
    this.handleBoolean = () => {
      return ` AND(${this.state.andSkills?.map((item, index) => {
        return (
          '"' +
          item.value.replace(" ", "+") +
          (this.state.andSkills.length - 1 !== index ? " AND " : "") +
          '"'
        );
      })})AND(${this.state.orSkill?.map((item, index) => {
        return (
          '"' +
          item.value.replace(" ", "+") +
          (this.state.orSkill.length - 1 !== index ? " AND" : "") +
          '"'
        );
      })})NOT(${this.state.notSkill?.map((item, index) => {
        return (
          '"' +
          item.value.replace(" ", "+") +
          (this.state.orSkill.length - 1 !== index ? " AND " : "") +
          '"'
        );
      })})
      
      
      `;
    };
    return (
      <React.Fragment>
        {this.state.xRayLink && (
          <Modal
            isOpen={this.state.xRayLink}
            toggle={this.onCloseClick}
            centered={true}
          >
            <ModalBody className="py-3 px-5">
              <Row>
                <div className="close_cont">
                  <div className="close_item" onClick={this.onCloseClick}>
                    <i className="bx bx-x"></i>
                  </div>
                </div>
                <Col md={12}>
                  <h4>
                    Here’s your
                    {this.state.queryType === "google"
                      ? " X-Ray Search URL"
                      : "Boolean Query"}
                  </h4>
                  {this.state.queryType === "google" ? (
                    <React.Fragment>
                      <div className="link_box">
                        <a
                          target="_blank"
                          href={this.HandlePlatformQuery(this.state.platform)}
                          rel="noreferrer"
                        >
                          {this.HandlePlatformQuery(this.state.platform)}
                        </a>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div
                        style={{
                          border: "1px solid #bababa",
                        }}
                      >
                        {this.handleBoolean()}
                      </div>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
              <Row className="justify-content-between   padding_x_20px">
                <Col md={6}>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      if (this.state.queryType === "boolean") {
                        navigator.clipboard
                          .writeText(this.handleBoolean())
                          .then(() => {
                            toast.success("boolean copied successfully");
                          });
                      } else {
                        const platformBasedData = this.HandlePlatformQuery(
                          this.state.platform
                        );
                        navigator.clipboard
                          .writeText(platformBasedData)
                          .then(() =>
                            toast.success("data copied successfully")
                          );
                      }
                    }}
                  >
                    Copy Link
                  </button>
                </Col>
                <Col md={6}>
                  {this.state.queryType === "google" && (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        const platformBasedData = this.HandlePlatformQuery(
                          this.state.platform
                        );
                        window.open(platformBasedData);
                      }}
                    >
                      Open in google
                    </button>
                  )}
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        )}
        <div className="page-content">
          {" "}
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Label>Auto-create query from an open job (optional)</Label>

                    <CreatableSelect
                      isMulti
                      onChange={this.handleJobTitle}
                      options={jobTitle}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>CUSTOMIZE YOUR QUERY</Col>
                </Row>
                <Row>
                  <Row>
                    <Col xs={12}>
                      <h4>Job Titles</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Label>OR (At least include one of them)</Label>
                    <Col xs={12}>
                      <CreatableSelect
                        isMulti
                        onChange={this.handleJobTitle}
                        options={jobTitle}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <h4>Skills</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Label>AND (Include all of them)</Label>
                    <Col xs={12}>
                      <CreatableSelect
                        isMulti
                        onChange={this.handleAndSkills}
                        options={andSkills}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label>OR (At least include one of them)</Label>
                    <Col xs={12}>
                      <CreatableSelect
                        isMulti
                        onChange={this.handleOrSkills}
                        options={orSkills}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label>NOT (Exclude)</Label>
                    <Col xs={12}>
                      <CreatableSelect
                        isMulti
                        onChange={this.hanldeNotSkill}
                        options={notSkills}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <h4>Location</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Label>OR (At least include one of them)</Label>
                    <Col xs={12}>
                      <CreatableSelect
                        isMulti
                        onChange={this.handleLocationSkill}
                        options={location}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>Select a query type from below</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div
                        className="btn-toolbar"
                        // role="toolbar"
                        // aria-label="Toolbar with button groups"
                      >
                        <div
                          className="btn-group me-2"
                          role="group"
                          //   aria-label="Second group"
                        >
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.queryType === "google" &&
                              "rounded_btn_violet_click"
                            }`}
                            onClick={() => {
                              this.setState({
                                queryType: "google",
                              });
                            }}
                          >
                            GOOGLE X-RAY
                          </button>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.queryType === "boolean" &&
                              "rounded_btn_violet_click"
                            }`}
                            onClick={() => {
                              this.setState({
                                queryType: "boolean",
                              });
                            }}
                          >
                            BOOLEAN
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>Customize Query for:</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {this.state.queryType === "google" ? (
                        <React.Fragment>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "linkedin"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "linkedin" });
                            }}
                          >
                            linkedin
                          </button>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "stackoverflow"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "stackoverflow" });
                            }}
                          >
                            Stack Overflow
                          </button>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "github"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "github" });
                            }}
                          >
                            Github
                          </button>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "dribbble"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "dribbble" });
                            }}
                          >
                            Dribbble
                          </button>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "twitter"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "twitter" });
                            }}
                          >
                            Twitter
                          </button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "linkedinBool"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "linkedinBool" });
                            }}
                          >
                            Linkedin
                          </button>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "naukri"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "naukri" });
                            }}
                          >
                            Naukri
                          </button>
                          <button
                            className={`btn btn-outline-primary ${
                              this.state.platform !== "iim jobs"
                                ? "rounded_btn_violet"
                                : "rounded_click"
                            }`}
                            onClick={() => {
                              this.setState({ platform: "iim jobs" });
                            }}
                          >
                            IIM Jobs
                          </button>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                  <Row className="padding_x_20px">
                    <Col xs={2}>
                      <Button
                        color="primary"
                        onClick={
                          //   this.state.queryType === "google" &&
                          this.handleXRayLink
                        }
                      >
                        {this.state.queryType === "google"
                          ? "Get X-ray Link"
                          : "Get Boolean Query"}
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <Button color="primary">Reset</Button>
                    </Col>
                  </Row>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default BooleanQuery;
