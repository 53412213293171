import {
  Avatar,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import React from "react";
import { grey } from "@mui/material/colors";
import { GreyTypography, PrimaryTypography } from "assets/Mui/globalTheme";
export default function RejectConfirmation({
  reject,
  handleClose,
  handleReject,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    borderRadius: "8px",
    pading: "20px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    p: 4,
  };
  const history = useHistory();
  return (
    <Modal
      //   hideBackdrop={true}
      open={reject}
      onClose={handleClose}
      sx={{
        pading: "20px",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <PrimaryTypography variant="h2">Reject?</PrimaryTypography>
        <GreyTypography variant="h6" component="h6">
          Are you sure you want to Reject? This candidate will move to Rejected
          Stage
          <br />
        </GreyTypography>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Button
            onClick={handleClose}
            variant="text"
            sx={{
              backgroundColor: grey,
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button onSubmit={handleReject} variant="solid" purpose="delete" onClick={handleReject}>
            Reject
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
