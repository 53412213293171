import React, { Component, lazy, useEffect, useState } from "react";
import titleCaller from "components/Common/title";
import "../../assets/css/auth/forget_password.css";
import {
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import ResetPasswordImage from "../../assets/images/zepul/reset_password.svg";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { resetPassword } from "helpers/services/auth";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
} from "assets/Mui/globalTheme";
export default function ResetPassword() {
  const [email, setEmail] = useState("");
  useEffect(() => {
    titleCaller("zepul Reset password");
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [cShowPasswrod, setCShowPassword] = useState(false);
  const [cpassworError, setCpasswordError] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    unregister,
    formState: { errors },
    setValue,
  } = useForm();
  const { search } = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get("token");
    setValue("token", token);
  }, [search]);
  async function handleData(data) {
    const { status } = await resetPassword({
      token: data?.token,
      password: data?.password,
    });
    if (status === 200) {
      toast.success("password is updated");
      history.push("/login");
    } else {
      toast.error("something went wrong");
    }
  }
  function handleShowCPassword() {
    setCShowPassword(!cShowPasswrod);
  }
  function handleShowPassword() {
    setShowPassword(!showPassword);
  }
  return (
    <React.Fragment>
      <div className="forget_password_container">
        <Form
          className="forget_password_elem_container"
          onSubmit={handleSubmit(handleData)}
        >
          <img
            src={ResetPasswordImage}
            alt="forget_password"
            className="forget_password_image"
          />
          <Typography variant="h1" component="h1">
            Reset your password?
          </Typography>
          <Typography color="neutral" level="h6">
            Please enter the email address associated with your account and We
            will email you a link to reset your password.
          </Typography>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="login-last-name-outlined"
              error={errors.password}
            >
              Password
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              {...register("password", { required: true })}
              id="password-outlined"
              autoFocus
              label="Password"
              type={showPassword ? "text" : "password"}
              error={errors.password}
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <FeatherIcon icon="lock" size={"14"} />
                  ) : (
                    <FeatherIcon icon="unlock" size={"14"} />
                  )}{" "}
                </InputAdornment>
              }
            />
            <PrimaryNormalFormHelperText error={errors.password}>
              {errors.password ? "password is required field" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputLabel
              shrink
              htmlFor="login-last-name-outlined"
              error={errors.cpassword}
            >
              Confirm Password
            </PrimaryNormalInputLabel>
            <PrimaryNormalInputs
              {...register("cpassword", { required: true })}
              id="cpassword-outlined"
              label="confirm password"
              type={cShowPasswrod ? "text" : "password"}
              error={errors.cpassword || cpassworError}
              onChange={e => {
                if (e.target.value !== getValues("password")) {
                  setCpasswordError(true);
                } else {
                  setCpasswordError(false);
                }
              }}
              variant="outlined"
              endAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    right: "10px",
                  }}
                  position="end"
                  onClick={handleShowCPassword}
                >
                  {cShowPasswrod ? (
                    <FeatherIcon icon="lock" size={"14"} />
                  ) : (
                    <FeatherIcon icon="unlock" size={"14"} />
                  )}
                </InputAdornment>
              }
            />
            <PrimaryNormalFormHelperText error={errors.cpassword}>
              {errors.cpassword ? "confirm your new password" : ""}
            </PrimaryNormalFormHelperText>
          </FormControl>{" "}
          <PrimaryButton type="submit">Change Password</PrimaryButton>
          <Stack direction="row" alignItems="center">
            <FeatherIcon icon="chevron-left" size="14" />
            <Link className="link_navigation" to="/login">
              Return to Sign In
            </Link>
          </Stack>
        </Form>
      </div>
    </React.Fragment>
  );
}
