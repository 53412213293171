import React from "react";
import toast from "react-hot-toast";
import { withRouter } from "react-router-dom";
import "../../assets/css/questionbank.css";
const {
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} = require("reactstrap");
import "../../assets/css/questionbank.css";

class QuestionBank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      questions: [
        { name: "true1" },
        { name: "true2" },
        { name: "true3" },
        { name: "true4" },
        // { name: true },
        // { name: true },
        // { name: true },
      ],
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };
  handleFirstDropdown = mode => {
    this.props.history.push(`/questions/create?question_type=${mode}`);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content" style={{ minHeight: "70vh" }}>
          <Container fluid>
            {/* <Row> */}
            <Card style={{ marginBottom: "0px" }}>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <h4>Questions</h4>
                  </Col>
                  <Col md={6} className="two_btns">
                    <Button color="primary">Import Questions</Button>

                    <Dropdown
                      isOpen={this.state.menu}
                      toggle={this.toggle}
                      className="d-inline-block"
                    >
                      <DropdownToggle
                        style={{
                          // background: "#5d78ff",
                          // color: "white",
                          height: "35px",
                          borderRadius: "4px",
                          minWidth: "180px",
                        }}
                        className="click_btn_option"
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        {/* <span
                          className=" d-xl-inline-block ms-1"
                          style={{
                            minWidth: "100px",
                          }}
                        > */}
                        New Question
                        {/* </span> */}
                        <i className="mdi mdi-chevron-down  d-xl-inline-block" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          onClick={() => this.handleFirstDropdown("MSA")}
                        >
                          {/* <i className="bx bx-user font-size-16 align-middle ms-1" /> */}
                          multiple choice single answer{" "}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleFirstDropdown("MMA")}
                        >
                          Multiple choice multiple answers
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleFirstDropdown("TOF")}
                        >
                          True of false
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleFirstDropdown("SA")}
                        >
                          Short Answer
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleFirstDropdown("MTF")}
                        >
                          Match the following
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleFirstDropdown("OS")}
                        >
                          ordering/sequence
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleFirstDropdown("FIB")}
                        >
                          Fill in the blanks
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card
              style={{
                background: "#f3f3f9",
              }}
            >
              <CardBody
                style={{
                  background: "#f3f3f9",
                  height: "80px",
                  marginBottom: "20px",
                }}
              >
                <Row>
                  <div className="question_list_item">
                    <Row className="align-items-center">
                      <Col xs={2}>CODE</Col>
                      <Col xs={2}>Question</Col>
                      <Col xs={2}>Type</Col>
                      <Col xs={1}>Section</Col>
                      <Col xs={1}>Skill</Col>
                      <Col xs={1}>Topic</Col>
                      <Col xs={1}>Status</Col>
                      <Col xs={2}>Actions</Col>
                    </Row>
                  </div>
                </Row>
              </CardBody>

              <CardBody style={{ minHeight: "70vh", background: "#f3f3f9" }}>
                <Row>
                  {this.state.questions.map((item, index) => (
                    <div key={index} className="question_list_item">
                      <Row className="align-items-center">
                        <Col xs={2}>
                          <span
                            className="badge badge-soft-info"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigator.clipboard
                                .writeText("Q_DKJFSDLKFJKDS")
                                .then(() => {
                                  toast.success(
                                    "successfully copied to clipboard"
                                  );
                                });
                            }}
                          >
                            Q_DKJFSDLKFJKDS
                          </span>
                        </Col>
                        <Col xs={2}>adsfasdfasfd</Col>
                        <Col xs={2}>Technical</Col>
                        <Col xs={1}>Computer science</Col>
                        <Col xs={1}>{item.name}</Col>
                        <Col xs={1}>computers</Col>
                        <Col xs={1}>Pending</Col>
                        <Col xs={2}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <div className="edit_icon icon_box">
                              <i
                                className="bx bx-pencil green
"
                              ></i>
                            </div>

                            <div
                              className="delete_icon icon_box"
                              onClick={() => {
                                const question = [...this.state.questions];
                                question.splice(index, 1);
                                this.setState({
                                  questions: question,
                                });
                                toast.error(
                                  "you have successfully deleted a quiz"
                                );
                              }}
                            >
                              <i
                                className="bx bx-trash red
"
                              ></i>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Row>
              </CardBody>
            </Card>
            {/* </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(QuestionBank);
