import { ZA, SP, CA, ACM } from "common/constants/userModules";
import getCompanyProfileModel from "helpers/models/CompanyProfileModel";
import { getLoggedInCompanyDetails } from "../companies/company";
import { getPerticulerScreeningPartner } from "../screeningPartner/screeningPartner";
import callApi from "helpers/callApi";
import { getSingleAccountManger } from "../zepulAdmin/AccountMangers";

const localData = async () => {
  return await JSON.parse(localStorage.getItem("authUser"));
};
export async function findLoggedInUser() {
  const data = await localData();
  switch (data.role) {
    case SP:
      return await getPerticulerScreeningPartner();
    case CA:
      return await getLoggedInCompanyDetails();
    case "ACM":
      return await getSingleAccountManger();
    default:
      const { data } = await getUserByToken();
      return data?.data;
    // return {
    //   user_image: "",
    //   user_name: "",
    //   usertype: "",
    //   first_name: "",
    //   last_name: "",
    //   phone_number: "",
    //   email: "",
    //   country: "",
    //   job_roles_details: [],
    //   functional_area_details: [],
    // };
  }
}
export default async function findParticularUserByRole() {
  const data = await localData();
  switch (data.role) {
    case SP:
      return await getPerticulerScreeningPartner();
    case CA:
      const data = await getLoggedInCompanyDetails();
      return getCompanyProfileModel(data);

    default:
      return {
        user_image: "",
        user_name: "",
        usertype: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        country: "",
        job_roles_details: [],
        functional_area_details: [],
      };
  }
}

export const getUserByToken = async () => {
  return await callApi("/common/get_user_details_based_on_token");
};
