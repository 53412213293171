import React from "react";
const { Container, Row, Col, CardBody, Card, Label } = require("reactstrap");
import "../../assets/css/createquestion.css";
import AttachementForm from "./attachementForm";
import DetailsPage from "./detailsPage";
import SettingsPage from "./settingsPage";
import SolutionForm from "./solutionForm";
class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optCount: [{ value: "" }, { value: "" }],
      multi: [],
      btnOption: 1,
      questionType: "",
    };
    this.handleSelectMulti = this.handleSelectMulti.bind(this);
    this.handleOptions = this.handleOptions.bind(this);
    this.handleRemoveOptions = this.handleRemoveOptions.bind(this);
    this.handleState = this.handleState.bind(this);
  }
  componentDidMount() {
    this.setState({
      questionType: new URLSearchParams(this.props.location.search).get(
        "question_type"
      ),
    });
  }
  handleOptions = () => {
    const arr = [...this.state.optCount];
    this.setState(prevState => ({
      optCount: [...prevState.optCount, { value: "" }],
    }));
  };
  handleRemoveOptions = index => {
    const arr = [...this.state.optCount];
    arr.splice(index, 1);
    this.setState({
      optCount: arr,
    });
  };
  handleSelectMulti = data => {
    this.setState({ multi: data });
  };
  handleState = data => {
    this.setState({
      btnOption: data,
    });
  };
  render() {
    const optionGroup = [
      {
        label: "Picnic",
        options: [
          { label: "Django", value: "Django" },
          { label: "Flutter", value: "Flutter" },
          { label: "Swift", value: "Swift" },
        ],
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                <Row className="justify-content-between">
                  {" "}
                  <Col xs={4}>
                    <h4>Question Details</h4>
                    <Label>
                      {this.state.questionType === "MSA" &&
                        "Multiple Choice Single Answer Question"}
                      {this.state.questionType === "MMA" &&
                        "Multiple Choice Multiple Answer Question"}
                      {this.state.questionType === "TOF" && "True or False"}
                      {this.state.questionType === "SA" && "Short Answer"}
                      {this.state.questionType === "MTF" &&
                        "Match the following"}
                      {this.state.questionType === "OS" && "Ordering/sequence"}
                      {this.state.questionType === "FIB" &&
                        "Fill in the blanks"}
                    </Label>
                  </Col>
                  <Col xs={8} className="btn_4">
                    <button
                      className="bottom_click"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          btnOption: 1,
                        });
                      }}
                    >
                      Details
                    </button>
                    <button
                      className="bottom_click"
                      onClick={() => {
                        this.setState({
                          btnOption: 2,
                        });
                      }}
                      color="primary"
                    >
                      Settings
                    </button>
                    <button
                      className="bottom_click"
                      onClick={() => {
                        this.setState({
                          btnOption: 3,
                        });
                      }}
                      color="primary"
                    >
                      Solution
                    </button>
                    <button
                      className="bottom_click"
                      onClick={() => {
                        this.setState({
                          btnOption: 4,
                        });
                      }}
                      color="primary"
                    >
                      Attachement
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {this.state.btnOption === 1 && (
              <DetailsPage
                handleOptions={this.handleOptions}
                handleRemoveOptions={this.handleRemoveOptions}
                handleSelectMulti={this.handleSelectMulti}
                state={this.state}
                optionGroup={optionGroup}
                handleState={this.handleState}
              />
            )}
            {this.state.btnOption === 2 && (
              <SettingsPage
                handleOptions={this.handleOptions}
                handleRemoveOptions={this.handleRemoveOptions}
                handleSelectMulti={this.handleSelectMulti}
                state={this.state}
                optionGroup={optionGroup}
                handleState={this.handleState}
              />
            )}
            {this.state.btnOption === 3 && (
              <SolutionForm handleState={this.handleState} />
            )}
            {this.state.btnOption === 4 && (
              <AttachementForm handleState={this.handleState} />
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateForm;
