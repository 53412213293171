import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { imageURL } from "common/constants/commonURLS";
import {
  MenuProps,
  PrimaryButton,
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimaryNormalInputs,
  PrimarySwitch,
  SmallPrimaryAvatar,
  PrimaryChip
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import {
  getAllScreenedApplicantsForUser,
  getAllScreeningPartnerWithStatus,
  getBVGParnters,
} from "helpers/services/screeningPartner/screeningPartner";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import IndustryInput from "components/table/header/industrySelect";
import { IndustryOperators } from "components/table/operators/industryOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { WorkModeOperators } from "components/table/operators/worModeOperators";
import { useDispatch, useSelector } from "react-redux";
import CustomNoRowsOverlay from "common/data/NoRowsFoundDatagrid/NoRowsFound";
import { BVC } from "common/constants/userModules";
// import moment from "moment";
export default function ScreenedApplicantsTable() {
  const [ScreenedApplicants, setScreenedApplicants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const selector = useSelector(state => state?.Login);
  const role = selector?.role
  const userId = selector?.id
  // console.log(selector);
  let type
  if(selector?.usertype == "EVALUATOR"){
    type = "Evaluator"
  }else if (selector?.usertype == "SCREENING_PARTNER"){
    type = "Sp"
  }else {
    type = "Moderator"
  }
  React.useEffect(() => {
    if(role!== BVC)
    initialFetch();
    else{
      fetchBVG()
    }
  }, []);

  async function fetchBVG(){
    // const userId = "f63f5afb-81d7-4b39-9cc6-4975ed033844"
  const {data} =  await getBVGParnters(userId)
  const {rows,count} = data?.message
  const list = count ? rows : []
  const tweakList = list?.map((item)=>({
    id:item.id,
    name:item?.user_job_post?.first_name + item?.user_job_post?.last_name,
    image: `${item?.user_job_post?.job_applicant?.image?item?.user_job_post?.job_applicant?.image:""}`,
    phone_number: `${item?.user_job_post?.phone_number?item?.user_job_post?.phone_number:"N/A"}`,
    email: `${item?.user_job_post?.email}`,
    jobs_screened: item?.get_screened_job_post_counts?.count,
    resume: item?.resume,
    company_image: `${imageURL}${item?.company_details?.company?.company_image}`,
    company_name: item?.company_details?.company?.company_name,
    status: `${item?.user_job_post?.job_post_pipeline_stage?.stage_name}`,
    date_screened: new Date(item?.updatedAt).toLocaleDateString(),
    job_post_id: item?.user_job_post?.job_post_id,
    job_title: item?.user_job_post?.job_post_in_user_job_post?.job_title,
  }))
  setScreenedApplicants(tweakList)
  // console.log(rows);
  }

  async function initialFetch() {
    setLoading(true);
    const {data,status}= await getAllScreenedApplicantsForUser(selector.id,type);
    const result = data?.message?.rows?.map((item, index) => ({
      id: index + 1,
      name: `${item?.user_job_post?.first_name} ${item?.user_job_post?.last_name}`,
      image: `${item?.user_job_post?.job_applicant?.image?item?.user_job_post?.job_applicant?.image:""}`,
      phone_number: `${item?.user_job_post?.phone_number?item?.user_job_post?.phone_number:"N/A"}`,
      email: `${item?.user_job_post?.email}`,
      jobs_screened: item?.get_screened_job_post_counts?.count,
      resume: item?.resume,
      company_image: `${imageURL}${item?.company_details?.company?.company_image}`,
      company_name: item?.company_details?.company?.company_name,
      status: `${item?.user_job_post?.job_post_pipeline_stage?.stage_name}`,
      date_screened: new Date(item?.updatedAt).toLocaleDateString(),
      job_post_id: item?.user_job_post?.job_post_id,
      job_title: item?.user_job_post?.job_post_in_user_job_post?.job_title,
    }));
    if (data?.message?.rows) {
      setLoading(false);
    }
    setScreenedApplicants(result);
  }

  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      <PrimaryDataGrid
        loading={loading}
        checkboxSelection={true}
        columns={[
          {
            field: "image",
            headerName: "Image",
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name",  headerName: "Name", width: 200 },
          { field: "phone_number",  headerName: "Phone Number", width: 300 },
          { field: "email",   headerName: "Email", width: 300 },
          { field: "job_title",  headerName: "Job Title",width:300 },
          {
            field: "date_screened",
            headerName: "Date Screened",
            width: 400,
          },
          // { field: "status", width: 400 },
          {
            field: "Status",
            headerName: "Status",
            width: 200,
            renderCell: params => {
              console.log(params,"params")
              return (
                <PrimaryChip label={params?.row?.status} size="small"/>
              );
            },
          },
        ]}
        rows={ScreenedApplicants}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
      />
    </div>
  );
}
