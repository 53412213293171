const { Container, Row, Col } = require("reactstrap");
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import noData from "../../assets/images/zepul/no_jobs.svg";
import { CloseJobModel } from "pages/Job/JobList/CloseJobModel";
import { deleteSingleJob } from "helpers/services/jobs/jobs";
import { connect } from "react-redux";
import { GreyTypography, StyledCard, StyledCardContent } from "assets/Mui/globalTheme";
import { Stack } from "@mui/material";
import JobPostedCompanyAvatar from "components/jobCard/JobPostedCompanyAvatar";
import JobCardInformation from "components/jobCard/jobCardInformations";
import ViewMoreButton from "components/jobCard/ViewMoreButtons";

function ClosedJobs({ role, jobs, load, setUpdate }) {
  let history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [viewDetails, setViewDetails] = useState(tableData[0]);
  const [modelData, setModelData] = useState({
    modal: false,
    details: {},
  });

  const handleClose = confirm => {};

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchItems = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = tableData.filter(item =>
      item.job_title.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);

  useEffect(() => {
    const activeData = jobs.filter(data => data.is_active === false);
    setTableData(activeData);
  }, [jobs]);

  useEffect(() => {
    setViewDetails(tableData[0]);
  }, [tableData]);
  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }
  return (
    <div className="loader_container">
      {load ? (
        <Container fluid style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <Row>
            {tableData.length > 0 ? (
              tableData.map(item => (
                <Col xs={12} md={6} lg={4} className="mb-4" key={item.id}>
                  <StyledCard>
                    <StyledCardContent>
                      <Stack direction="column" spacing={1}>
                        <JobPostedCompanyAvatar item={item} />

                        <JobCardInformation
                          tableData={tableData}
                          setUpdate={setUpdate}
                          setTableData={setTableData}
                          item={item}
                        />
                        <Stack direction="row" justifyContent={"flex-end"}>
                          <ViewMoreButton id={item?.id} />
                        </Stack>
                      </Stack>
                    </StyledCardContent>
                  </StyledCard>
                </Col>
              ))
            ) : (
              <div className="no_data_show">
                <div>
                  <img src={noData} className="lg_img"></img>
                  <GreyTypography variant="h6">Closed Bucket is Empty</GreyTypography>
                </div>
              </div>
            )}
          </Row>

          {modelData ? (
            <CloseJobModel
              setModelData={doc => setModelData(doc)}
              show={modelData.modal}
              onHide={() => {
                setModelData({ modal: false, details: {} });
              }}
              params={modelData}
              onSubmit={async () => {
                const { status } = await deleteSingleJob(modelData.details.id);
                const index = this.state.tableData.findIndex(
                  ({ id }) => id === modelData.details.id
                );
                const data = [...this.state.tableData];

                data.splice(0, index);
                this.setState({ tableData: data });
                handleClose();
              }}
            />
          ) : null}
        </Container>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
}
const mapStateToProps = state => ({
  role: state.Login.role,
});
export default connect(mapStateToProps, null)(ClosedJobs);
