import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Polygon,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  cell: {
    width: "calc(100%/3)",
    padding: "4px",
    fontSize: "12px",
    fontWeight: 400,
    // fontFamily:"Roboto"
  },
  title: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subTitle: {
    fontSize: "14px",
    marginVertical: "16px",
  },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid black",
    padding: "4px",
    alignItems: "center",
  },
  rowHeader: {
    fontSize: "12px",
    fontWeight: 600,
    // fontFamily:'Roboto',
    width: "calc(100%/3)",
    padding: "4px",
  },
  table: {
    border: "1px solid black",
    borderBottom: "none",
  },
  pageNumbers: {
    position: "absolute",
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "right",
    marginTop: 20,
  },
  starCell: {
    width: "calc(100%/3)",
    padding: "4px",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default function PdfScore({
  jobTitle,
  score,
  screening,
  moderator,
  evaluator,
  jobPostInfo,
  candidateName,
  currentSalary,
  expectedSalary,
  jobRoleName,
}) {
  //   const classes = useStyles();
  function Stars({ count }) {
    const active = count;
    const inActive = 5 - count;
    return (
      <>
        {Array.from({ length: active }).map((_, index) => (
          <Svg
            key={index}
            width={"14"}
            height={"14"}
            viewBox={"0 0 24 24"}
            fill="#faaf00"
            stroke="#faaf00"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <Polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></Polygon>
          </Svg>
        ))}
        {Array.from({ length: inActive }).map((_, index) => (
          <Svg
            key={index}
            width={"14"}
            height={"14"}
            viewBox={"0 0 24 24"}
            fill="#ddd"
            stroke="#ddd"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <Polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></Polygon>
          </Svg>
        ))}
      </>
    );
  }
  const jobSkills = jobPostInfo?.key_skills_details.length
    ? jobPostInfo?.key_skills_details
    : [];

  return (
    <Document>
      <Page style={{ padding: "16px" }}>
        <View style={styles.title}>
          <Text>
            Candidate: {candidateName}
            {"\n"}
            CTC: {currentSalary}
            {"\n"}
            Exp CTC: {expectedSalary}
            {"\n"}
            Job Title: {jobTitle}
            {"\n"}
            Job Role: {jobRoleName}
            {"\n"}
            Score: {score}
          </Text>
        </View>
        {/* <View style={styles.title}>
          <Text>Job Title{jobTitle}</Text>
          <Text>Job Role:{jobRoleName}</Text>
          <Text>Score: {score}</Text>
        </View> */}
        <Text style={styles.subTitle}>Screening</Text>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.rowHeader}>Skills</Text>
            <Text style={styles.rowHeader}>Ratings</Text>
            <Text style={styles.rowHeader}>Comments</Text>
          </View>
          {screening.length
            ? screening?.map((data, id) => (
                <View key={id} style={styles.row}>
                  <Text style={styles.cell}>
                    {data?.skill_details[0]?.name}
                  </Text>
                  <View style={styles.starCell}>
                    <Stars count={data?.sp_rating} />
                  </View>
                  <Text style={styles.cell}>{data?.sp_comment}</Text>
                </View>
              ))
            : jobSkills?.map((data, id) => (
                <View key={id} style={styles.row}>
                  <Text style={styles.cell}>{data?.name}</Text>
                  <View style={styles.starCell}>
                    <Stars count={0} />
                  </View>
                  <Text style={styles.cell}>{""}</Text>
                </View>
              ))}
        </View>
        <Text style={styles.subTitle}>Technical</Text>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.rowHeader}>Skills</Text>
            <Text style={styles.rowHeader}>Ratings</Text>
            <Text style={styles.rowHeader}>Comments</Text>
          </View>
          {evaluator.length
            ? evaluator?.map((data, id) => (
                <View key={id} style={styles.row}>
                  <Text style={styles.cell}>
                    {data?.skill_details[0]?.name}
                  </Text>
                  <View style={styles.starCell}>
                    <Stars count={data?.evaluator_rating} />
                  </View>
                  <Text style={styles.cell}>{data?.evaluator_comment}</Text>
                </View>
              ))
            : jobSkills?.map((data, id) => (
                <View key={id} style={styles.row}>
                  <Text style={styles.cell}>{data?.name}</Text>
                  <View style={styles.starCell}>
                    <Stars count={0} />
                  </View>
                  <Text style={styles.cell}>{""}</Text>
                </View>
              ))}
        </View>
        <Text style={styles.subTitle}>Moderator</Text>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.rowHeader}>Skills</Text>
            <Text style={styles.rowHeader}>Ratings</Text>
            <Text style={styles.rowHeader}>Comments</Text>
          </View>
          {moderator.length
            ? moderator?.map((data, id) => (
                <View key={id} style={styles.row}>
                  <Text style={styles.cell}>
                    {data?.skill_details[0]?.name}
                  </Text>
                  <View style={styles.starCell}>
                    <Stars count={data?.moderator_rating} />
                  </View>
                  <Text style={styles.cell}>{data?.moderator_comment}</Text>
                </View>
              ))
            : jobSkills?.map((data, id) => (
                <View key={id} style={styles.row}>
                  <Text style={styles.cell}>{data?.name}</Text>
                  <View style={styles.starCell}>
                    <Stars count={0} />
                  </View>
                  <Text style={styles.cell}>{""}</Text>
                </View>
              ))}
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
