import UserJobPostNotesHeader from "./header";
import UserJobPostNoteSection from "./notes";
export default function UserJobPostNotes() {
  return (
    <>
      <UserJobPostNotesHeader />
      <UserJobPostNoteSection />
    </>
  );
}
