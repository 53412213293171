import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function createKeySkills(data) {
  const item = { name: data.name };
  return await callApi("job_attributes/key_skills", item, POST);
}
export async function getAllKeySkills() {
  const data = await callApi("job_attributes/key_skills");
  return data.data;
}
export async function getAllKeySkillsWithStatus() {
  return await callApi("job_attributes/key_skills");
}
export async function updateKeySkills(sample, id) {
  const items = { name: sample.name, is_active: sample.is_active };
  const data = await callApi(`job_attributes/key_skills/${id}`, items, PUT);
  if (data.status !== 200) return true;
  return false;
}
export async function getSingleKeySkill(id) {
  const data = await callApi(`job_attributes/key_skills/${id}`);
  return data.data;
}
export async function deleteKeySkill(id) {
  await callApi(`job_attributes/key_skills/${id}`, {}, DELETE);
}
