const INIT_STATE = {
  user: {},
};

const Modals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_COMMON_USER_DATA":
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default Modals;
