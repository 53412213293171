const { Container, Col, Row } = require("reactstrap");
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import noData from "../../assets/images/zepul/no_jobs.svg";
import { CloseJobModel } from "pages/Job/JobList/CloseJobModel";
import { deleteSingleJob, getSingleJob } from "helpers/services/jobs/jobs";
import { connect } from "react-redux";
import { ACM } from "common/constants/userModules";
import JobDetails from "pages/jobs/navFunctions/jobDetails";
import { Grid, Skeleton, Stack } from "@mui/material";
import JobPostedCompanyAvatar from "components/jobCard/JobPostedCompanyAvatar";
import JobCardInformation from "components/jobCard/jobCardInformations";
import PendingCardButtons from "components/jobCard/pendingCardButtons";
import {
  GreyTypography,
  StyledCard,
  StyledCardContent,
} from "assets/Mui/globalTheme";
import GlobalLoader from "components/loader";

function Pending({ jobs, load, setUpdate }) {
  const role = JSON.parse(localStorage.getItem("authUser"))?.role;
  let history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [jobDetails, setJobDetails] = useState(false);
  const [singleJobDetails, setSingleJobDetails] = useState({});
  const [viewDetails, setViewDetails] = useState(tableData[0]);
  const [modelData, setModelData] = useState({
    modal: false,
    details: {},
  });

  const [viewDetailsModal, setViewDetailsModal] = useState({
    modal: false,
    details: {},
  });

  const [viewCandidateDetailsModal, setCandidateDetailsModal] = useState({
    modal: false,
    details: {},
  });

  const handleClose = confirm => {};
  const handleViewMore = async id => {
    const data = await getSingleJob(id);
    setJobDetails(true);
    setSingleJobDetails(data);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchItems = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = tableData?.filter(item =>
      item.job_title.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);

  useEffect(() => {
    const activeData = jobs?.filter(
      data => data.is_job_post_verified !== true && data.is_active === true
    );
    setTableData(activeData);
  }, [jobs]);

  useEffect(() => {
    setViewDetails(tableData[0]);
  }, [tableData]);
  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }
  return (
    <>
      {load ? (
        <>
          <JobDetails
            isOpen={jobDetails}
            toggle={() => {
              setJobDetails(false);
            }}
            data={singleJobDetails}
          />
          <Container
            fluid
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            <div className="row row-gap-4">
              {tableData.length > 0 ? (
                tableData.map(item => (
                  <Col xs={12} md={6} lg={4} className="mb-4" key={item.id}>
                    <StyledCard>
                      <StyledCardContent>
                        <Stack direction="column" spacing={1}>
                          <JobPostedCompanyAvatar item={item} />

                          <JobCardInformation
                            tableData={tableData}
                            setUpdate={setUpdate}
                            setTableData={setTableData}
                            item={item}
                          />
                          {role === ACM ? (
                            <PendingCardButtons
                              tableData={tableData}
                              item={item}
                              setUpdate={setUpdate}
                              setTableData={setTableData}
                              handleViewMore={handleViewMore}
                            />
                          ) : (
                            ""
                          )}
                        </Stack>
                      </StyledCardContent>
                    </StyledCard>
                  </Col>
                ))
              ) : (
                <div className="no_data_show">
                  <div>
                    <img src={noData} className="lg_img"></img>
                    <GreyTypography variant="h6">
                      Pending Bucket is Empty
                    </GreyTypography>
                  </div>
                </div>
              )}
            </div>

            {modelData ? (
              <CloseJobModel
                setModelData={doc => setModelData(doc)}
                show={modelData.modal}
                onHide={() => {
                  setModelData({ modal: false, details: {} });
                }}
                params={modelData}
                onSubmit={async () => {
                  const { status } = await deleteSingleJob(
                    modelData.details.id
                  );
                  if (status === 200) {
                    setUpdate(true);
                    const index = tableData.findIndex(
                      ({ id }) => id === modelData.details.id
                    );
                    const data = [...tableData];
                    data.splice(index, 1);
                    setTableData(data);
                  }
                  handleClose();
                }}
              />
            ) : null}
          </Container>
        </>
      ) : (
        <GlobalLoader />
      )}
    </>
  );
}

export default connect(null, null)(Pending);
