import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../assets/css/talentpool.css";

// Table data
const products = [
  {
    Sn: 1,
    Name: "Airi Satou",
    Job: "Accountant",
    Stage: "Tokyo",
    "Profile Created On": "33",
    Source: "2008/11/28",
    Folder: "$162,700",
  },

  {
    Sn: 2,
    Name: "Angelica Ramos",
    Job: "Chief Executive Officer (CEO)",
    Stage: "London",
    "Profile Created On": "47",
    Source: "2009/10/09",
    Folder: "$1,200,000",
  },

  {
    Sn: 3,
    Name: "Ashton Cox",
    Job: "Junior Technical Author",
    Stage: "San Francisco",
    "Profile Created On": "66",
    Source: "2009/01/12",
    Folder: "$86,000",
  },

  {
    Sn: 4,
    Name: "Bradley Greer",
    Job: "Software Engineer",
    Stage: "London",
    "Profile Created On": "41",
    Source: "2012/10/13",
    Folder: "$132,000",
  },

  {
    Sn: 5,
    Name: "Brenden Wagner",
    Job: "Software Engineer",
    Stage: "San Francisco",
    "Profile Created On": "28",
    Source: "2011/06/07",
    Folder: "$206,850",
  },

  {
    Sn: 6,
    Name: "Brielle Williamson",
    Job: "Integration Specialist",
    Stage: "New York",
    "Profile Created On": "61",
    Source: "2012/12/02",
    Folder: "$372,000",
  },

  {
    Sn: 7,
    Name: "Bruno Nash",
    Job: "Software Engineer",
    Stage: "London",
    "Profile Created On": "38",
    Source: "2011/05/03",
    Folder: "$163,500",
  },

  {
    Sn: 8,
    Name: "Caesar Vance",
    Job: "Pre-Sales Support",
    Stage: "New York",
    "Profile Created On": "21",
    Source: "2011/12/12",
    Folder: "$106,450",
  },

  {
    Sn: 9,
    Name: "Cara Stevens",
    Job: "Sales Assistant",
    Stage: "New York",
    "Profile Created On": "46",
    Source: "2011/12/06",
    Folder: "$145,600",
  },

  {
    Sn: 10,
    Name: "Cedric Kelly",
    Job: "Senior Javascript Developer",
    Stage: "Edinburgh",
    "Profile Created On": "22",
    Source: "2012/03/29",
    Folder: "$433,060",
  },

  {
    Sn: 11,
    Name: "Marshall",
    Job: "Regional Director",
    Stage: "San Francisco",
    "Profile Created On": "36",
    Source: "2008/10/16",
    Folder: "$470,600",
  },

  {
    Sn: 12,
    Name: "Hurst",
    Job: "Javascript Developer",
    Stage: "San Francisco",
    "Profile Created On": "39",
    Source: "2009/09/15",
    Folder: "$205,500",
  },

  {
    Sn: 13,
    Name: "Rios",
    Job: "Personnel Lead",
    Stage: "Edinburgh",
    "Profile Created On": "35",
    Source: "2012/09/26",
    Folder: "$217,500",
  },

  {
    Sn: 14,
    Name: "SnSner",
    Job: "Customer Support",
    Stage: "New York",
    "Profile Created On": "27",
    Source: "2011/01/25",
    Folder: "$112,000",
  },

  {
    Sn: 15,
    Name: "Wilder",
    Job: "Sales Assistant",
    Stage: "SSnney",
    "Profile Created On": "23",
    Source: "2010/09/20",
    Folder: "$85,600",
  },

  {
    Sn: 16,
    Name: "Camacho",
    Job: "Support Engineer",
    Stage: "San Francisco",
    "Profile Created On": "47",
    Source: "2009/07/07",
    Folder: "$87,500",
  },

  {
    Sn: 17,
    Name: "Green",
    Job: "Chief Operating Officer (COO)",
    Stage: "San Francisco",
    "Profile Created On": "48",
    Source: "2010/03/11",
    Folder: "$850,000",
  },

  {
    Sn: 18,
    Name: "Winters",
    Job: "Accountant",
    Stage: "Tokyo",
    "Profile Created On": "63",
    Source: "2011/07/25",
    Folder: "$170,750",
  },

  {
    Sn: 19,
    Name: "Cortez",
    Job: "Team Leader",
    Stage: "San Francisco",
    "Profile Created On": "22",
    Source: "2008/10/26",
    Folder: "$235,500",
  },

  {
    Sn: 20,
    Name: "Joyce",
    Job: "Developer",
    Stage: "Edinburgh",
    "Profile Created On": "42",
    Source: "2010/12/22",
    Folder: "$92,575",
  },

  {
    Sn: 21,
    Name: "Gloria Little",
    Job: "Systems Administrator",
    Stage: "New York",
    "Profile Created On": "59",
    Source: "2009/04/10",
    Folder: "$237,500",
  },

  {
    Sn: 22,
    Name: "Haley Kennedy",
    Job: "Senior Marketing Desi,ner",
    Stage: "London",
    "Profile Created On": "43",
    Source: "2012/12/18",
    Folder: "$313,500",
  },

  {
    Sn: 23,
    Name: "Hermione Butler",
    Job: "Regional Director",
    Stage: "London",
    "Profile Created On": "47",
    Source: "2011/03/21",
    Folder: "$356,250",
  },

  {
    Sn: 24,
    Name: "Herrod Chandler",
    Job: "Sales Assistant",
    Stage: "San Francisco",
    "Profile Created On": "59",
    Source: "2012/08/06",
    Folder: "$137,500",
  },

  {
    Sn: 25,
    Name: "Hope Fuentes",
    Job: "Secretary",
    Stage: "San Francisco",
    "Profile Created On": "41",
    Source: "2010/02/12",
    Folder: "$109,850",
  },

  {
    Sn: 26,
    Name: "Howard Hatfield",
    Job: "Office Manager",
    Stage: "San Francisco",
    "Profile Created On": "51",
    Source: "2008/12/16",
    Folder: "$164,500",
  },

  {
    Sn: 27,
    Name: "Jackson Bradshaw",
    Job: "Director",
    Stage: "New York",
    "Profile Created On": "65",
    Source: "2008/09/26",
    Folder: "$645,750",
  },

  {
    Sn: 28,
    Name: "Jena Gaines",
    Job: "Office Manager",
    Stage: "London",
    "Profile Created On": "30",
    Source: "2008/12/19",
    Folder: "$90,560",
  },

  {
    Sn: 29,
    Name: "Jenette Caldwell",
    Job: "Development Lead",
    Stage: "New York",
    "Profile Created On": "30",
    Source: "2011/09/03",
    Folder: "$345,000",
  },

  {
    Sn: 30,
    Name: "Jennifer Acosta",
    Job: "Junior Javascript Devel,per",
    Stage: "Edinburgh",
    "Profile Created On": "43",
    Source: "2013/02/01",
    Folder: "$75,650",
  },
];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "Sn",
        text: "Sn",
        sort: false,
      },
      {
        dataField: "Name",
        text: "Name",
        sort: false,
      },
      {
        dataField: "Job",
        text: "Job",
        sort: false,
      },
      {
        dataField: "Stage",
        text: "Stage",
        sort: false,
      },
      {
        dataField: "Profile Created On",
        text: "Profile Created On",
        sort: false,
      },
      {
        dataField: "Source",
        text: "Source",
        sort: false,
      },
      {
        dataField: "Folder",
        text: "Folder",
        sort: false,
      },
    ];

    const defaultSorted = [
      {
        dataField: "Sn",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 8,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <Card className="talent_table">
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="Sn"
            columns={columns}
            data={this.state.productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Sn"
                columns={columns}
                data={this.state.productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"Sn"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    );
  }
}

export default DatatableTables;
