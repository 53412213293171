import "../../assets/css/blogs/create.css";
import EditorJs from "react-editor-js";

import { EDITOR_JS_TOOLS } from "./constants";

import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useRef, useState } from "react";
// import callApi from "../../utils/callApi";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Progress } from "reactstrap";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { imageURL } from "common/constants/commonURLS";
import { allCategories, createBlogs } from "helpers/services/blogs";

export default function EditBlog() {
  const instanceRef = React.useRef(null);
  const [titleClick, setTitleClick] = useState(false);
  const [cover, setCover] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadPos, setLoadPos] = useState(0);
  const [title, setTitle] = useState("");
  const history = useHistory();
  const [description, setDescription] = useState("");
  const [ShortDescription, setShortDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [tags, setTags] = useState([]);
  const [right, setRight] = useState(0);
  let img;
  // const user = useSelector(state => state.admin.admin);

  useEffect(() => {
    let mount = true;
    async function dataFetcher() {
      const { data } = await allCategories();
      data.data.map(item => {
        item.clicked = false;
      });

      setCategories(data.data);
    }

    const scrollLeft = () => {
      const data = document.querySelector(".category_section");
      setRight(data.scrollWidth - data.clientWidth);

      setScrollLeft(data.scrollLeft);
    };
    scrollLeft();
    const categorySection = document.querySelector(".category_section");
    categorySection.addEventListener("scroll", scrollLeft);
    mount ? dataFetcher() : null;
    return () => {
      mount = false;
      setCategories([]);
      setRight(0);
      setScrollLeft(0);
      categorySection.removeEventListener("scroll", scrollLeft);
    };
  }, []);

  return (
    <div className="editing_section">
      <div>
        <div className="title">
          {titleClick && (
            <div
              className="add_icon"
              onClick={() => {
                const file = document.createElement("input");
                file.type = "file";
                file.name = "image";
                file.accept = "image/png,image/jpeg";
                // upperForm.append(file);
                file.click();
                file.addEventListener("change", async () => {
                  const imageData = await file.files[0];

                  let formData = new FormData();
                  formData.append("image", file.files[0]);

                  setLoadPos(25);
                  let data = "";
                  data = await getPreSignedURL(file.files);
                  if (data !== "") {
                    setLoadPos(0);
                    setLoading(false);
                    setCover(`${imageURL}${data}`);
                  } else setLoadPos(70);

                  if (!imageData.type.startsWith("image/")) {
                    return;
                  }
                  img = document.querySelector(".image_placer");
                  img.file = imageData;

                  img.onload = function () {
                    setWidth(Number(this.width));
                    setHeight(Number(this.height));
                  };
                  const reader = new FileReader();
                  reader.onload = (function (aImg) {
                    return function (e) {
                      aImg.src = e.target.result;
                      // if (width > 700 && height > 300)
                      // setCover(e.target.result);
                    };
                  })(img);
                  const editingSection = document.querySelector(".image_adder");
                  if (editingSection) {
                    editingSection.append(img);
                  }
                  reader.readAsDataURL(imageData);
                  // }
                });
              }}
            >
              {cover === "" ? <AddIcon /> : <ModeEditOutlineOutlinedIcon />}
            </div>
          )}
          <textarea
            maxLength={"80"}
            className="title_input"
            type={"text"}
            placeholder={"New Post Title Here"}
            onClick={() => {
              setTitleClick(true);
            }}
            onChange={e => {
              e.target.style.height = "auto"; // added this line
              e.target.style.height = e.target.scrollHeight + "px";
              setTitle(e.target.value);
            }}
          />
          <p className="length_text">{title.length}/80</p>
        </div>
        {loadPos > 0 ? (
          <div className="">
            <Progress
              value={loadPos}
              color="primary"
              style={{
                width: "100%",
              }}
              animated
            ></Progress>
          </div>
        ) : (
          ""
        )}
        <div className="image_adder">
          <img
            className={`image_placer ${cover === "" && "no_image"}`}
            alt="cover"
          />
        </div>{" "}
        <div className="short_desc">
          <textarea
            maxLength={"500"}
            type={"text"}
            placeholder={"short Description"}
            onChange={e => {
              e.target.style.height = "auto"; // added this line
              e.target.style.height = e.target.scrollHeight + "px";
              setShortDescription(e.target.value);
            }}
          />
          <p className="length_text">{ShortDescription.length}/500</p>
        </div>
        <br />
        <br />
        <EditorJs
          placeholder="Description"
          instanceRef={instance => (instanceRef.current = instance)}
          tools={EDITOR_JS_TOOLS}
          i18n={{
            messages: {},
          }}
          data={{
            time: 1556098174501,
            blocks: [
              {
                type: "header",
                data: {
                  text: "Why clone websites?",
                  level: 2,
                },
              },
              {
                type: "paragraph",
                data: {
                  text: "Frontend development has everything to do with the client side. Everything the user can see and interact with on their web browser.",
                },
              },
              {
                type: "paragraph",
                data: {
                  text: "Studies show that if a website does not load within 2 seconds, users bounce. That being said, design has just as much importance as all the frontend programming going on in the background. After all, developers are tasked to implement a professional design into a working website all the time. It’s very much an important piece of a frontend developers arsenal. Get good at it!                  ",
                },
              },
              {
                type: "header",
                data: {
                  text: "What is the ultimate goal?                  ",
                  level: 3,
                },
              },
              {
                type: "list",
                data: {
                  style: "ordered",
                  items: [
                    " Large homepage banners",
                    "Big block designs",
                    "Reversed grid columns",
                    "Full or half page menus",
                    "Sticky or absolute positioned navigations",
                    "Galleries",
                    "Dropdown / accordions",
                    "Minor animations like fade, or type effects",
                    "Two grid columns",
                    "Responsive design and more",
                    "It is a block-styled editor",
                    "It returns clean data output in JSON",
                    "Designed to be extendable and pluggable with a simple API",
                  ],
                },
              },
              {
                type: "header",
                data: {
                  text: "1.<a href='#aff'>Netflix</a>",
                  level: 3,
                },
              },
              {
                //632333b0ff5fb3ae1c04bf3b
                type: "image",
                data: {
                  file: {
                    url: "https://i.pcmag.com/imagery/reviews/05cItXL96l4LE9n02WfDR0h-5.fit_scale.size_1028x578.v1582751026.png",
                  },
                  caption: "",
                  withBorder: true,
                  stretched: false,
                  withBackground: false,
                },
              },
              {
                type: "paragraph",
                data: {
                  text: "When logged in Netflix is a pretty simple design. Horizontal rows, galleries, with a big featured banner.                  ",
                },
              },
              {
                type: "header",
                data: {
                  text: "1.<a href='#aff'>Airbnb</a>",
                  level: 3,
                },
              },
              {
                //632333b0ff5fb3ae1c04bf3b
                type: "image",
                data: {
                  file: {
                    url: "https://d1.awsstatic.com/product-marketing/rds/airbnb_horizontal_lockup_web.bd87ed2c8e964eb35463744e07a1a0670177bda2.png",
                  },
                  caption: "",
                  withBorder: true,
                  stretched: false,
                  withBackground: false,
                },
              },
              {
                type: "paragraph",
                data: {
                  text: "Just like Netflix, the logged in experience in Hulu is pretty similar. Has a large featured banner, and basically rows of movies or tv shows with every few rows having a featured section.                  ",
                },
              },

              {
                type: "paragraph",
                data: {
                  text: "Just like Netflix, the logged in experience in Hulu is pretty similar. Has a large featured banner, and basically rows of movies or tv shows with every few rows having a featured section.                  ",
                },
              },
              {
                type: "header",
                data: {
                  text: "What does it mean «block-styled editor»",
                  level: 3,
                },
              },
              {
                type: "paragraph",
                data: {
                  text: 'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class="cdx-marker">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.',
                },
              },
              {
                type: "paragraph",
                data: {
                  text: 'There are dozens of <a href="https://github.com/editor-js">ready-to-use Blocks</a> and the <a href="https://editorjs.io/creating-a-block-tool">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games.',
                },
              },
              {
                type: "header",
                data: {
                  text: "What does it mean clean data output",
                  level: 3,
                },
              },
              {
                type: "paragraph",
                data: {
                  text: "Classic WYSIWYG-editors produce raw HTML-markup with both content data and content appearance. On the contrary, Editor.js outputs JSON object with data of each Block. You can see an example below",
                },
              },
              {
                type: "paragraph",
                data: {
                  text: 'Given data can be used as you want: render with HTML for <code class="inline-code">Web clients</code>, render natively for <code class="inline-code">mobile apps</code>, create markup for <code class="inline-code">Facebook Instant Articles</code> or <code class="inline-code">Google AMP</code>, generate an <code class="inline-code">audio version</code> and so on.',
                },
              },
              {
                type: "paragraph",
                data: {
                  text: "Clean data is useful to sanitize, validate and process on the backend.",
                },
              },
              {
                type: "delimiter",
                data: {},
              },
              {
                type: "paragraph",
                data: {
                  text: "We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make it's core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏",
                },
              },
            ],
            version: "2.12.4",
          }}
        />
        <label>Category</label>
        <div className="category_container">
          {scrollLeft > 0 && (
            <div className="left_arrow">
              <KeyboardArrowLeftIcon
                className=""
                onClick={() => {
                  const data = document.querySelector(".category_section");

                  data.scrollLeft = scrollLeft - 200;
                }}
              />
            </div>
          )}
          <div className="category_section">
            {categories.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    item.clicked ? "clicked_btns" : "unclicked_btns"
                  }`}
                  onClick={() => {
                    const data = [...categories];
                    data.map(item => {
                      item.clicked = false;
                    });
                    data[index].clicked = !data[index].clicked;
                    setCategories(data);
                  }}
                >
                  {item.clicked && (
                    <DoneIcon
                      style={{
                        fontSize: "14",
                        color: "white",
                      }}
                    />
                  )}
                  {item.name}
                </div>
              );
            })}
          </div>
          {right - scrollLeft > 10 && (
            <div
              className="right_arrow"
              onClick={() => {
                const data = document.querySelector(".category_section");

                data.scrollLeft = scrollLeft + 200;
              }}
            >
              <KeyboardArrowRightIcon />
            </div>
          )}{" "}
        </div>
        <div className="short_desc">
          <div
            contentEditable="true"
            placeholder="tags"
            onInput={e => {
              setTags(e.target.innerText.split(" "));
            }}
          ></div>
        </div>
        <button
          className="form_submit"
          onClick={async () => {
            const dataSave = new Promise(async (resolve, reject) => {
              await instanceRef.current
                .save()
                .then(async data => {
                  resolve(data);
                  let categoriesUpload = "";
                  categories.map(item => {
                    if (item.clicked) categoriesUpload = item.id;
                  });
                  await createBlogs({
                    category_id: categoriesUpload,
                    content: data,
                    description: ShortDescription,
                    image: cover,
                    title: title,
                    tags,
                  });
                })
                .catch(() => {
                  reject();
                  return;
                });
            });
          }}
        >
          Publish
        </button>
      </div>
    </div>
  );
}
