import { CardContent, Grid, Stack, Typography } from "@mui/material";
import {
  BlackTypography,
  BorderCard,
  GreyTypography,
  PrimaryButton,
  PrimaryChip,
  PrimaryPopUp,
  StyledListItemButton,
  StyledProfilePopText,
} from "assets/Mui/globalTheme";
import HeaderTextForCompany from "components/companyView.js/headerText";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function CommonUserIndex() {
  const user_data = useSelector(state => state.CommonUser?.user);
  console.log(user_data,"user_dataSP")
  const { id } = useParams();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };
  const [statusDropDown, setStatusDropDown] = useState(null);
  const open = Boolean(statusDropDown);
  function handleStatusDropDownClick(event) {
    setStatusDropDown(event.currentTarget);
  }
  function handleStatusDropDownClose() {
    setStatusDropDown(null);
  }

  return (
    <Stack spacing={3} style={{ marginTop: "10px" }}>
      <Stack spacing={1}>
        <HeaderTextForCompany>about</HeaderTextForCompany>
        <GreyTypography
          additionalProps={{ textTransform: "lowercase !important" }}
          variant="h6"
          component="h6"
        >
          {user_data?.about_me}
        </GreyTypography>
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>Categories</HeaderTextForCompany>
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }} gap={1}>
          {user_data?.functional_area_details?.length > 0
            ? user_data?.functional_area_details?.map((skill, index) => (
                <PrimaryChip key={skill?.id} label={skill?.name}></PrimaryChip>
              ))
            : "-"}{" "}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>Job Role</HeaderTextForCompany>
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }} gap={1}>
          {user_data?.job_roles_details?.length > 0
            ? user_data?.job_roles_details?.map((skill, index) => (
                <PrimaryChip key={skill?.id} label={skill?.name}></PrimaryChip>
              ))
            : "-"}{" "}
        </Stack>{" "}
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>Skills</HeaderTextForCompany>
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }} gap={1}>
          {user_data?.key_skill_details?.length > 0
            ? user_data?.key_skill_details?.map((skill, index) => (
                <PrimaryChip key={skill?.id} label={skill?.name}></PrimaryChip>
              ))
            : "-"}
        </Stack>{" "}
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>Email</HeaderTextForCompany>
        <GreyTypography
          additionalProps={{ textTransform: "lowercase !important" }}
          variant="h6"
          component="h6"
        >
          {user_data?.email}
        </GreyTypography>
      </Stack>
      <Stack spacing={1}>
        <HeaderTextForCompany>Basic Information</HeaderTextForCompany>

        <BorderCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  User Role
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {user_data?.usertype?.replace("_", " ") ?? "-"}
                </BlackTypography>
              </Grid>

              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  User Id
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {id?.split("-")[0] ?? "-"}
                </BlackTypography>
              </Grid>
              <Grid item md={4}>
                <GreyTypography variant="h6" component="h6">
                  location
                </GreyTypography>
                <BlackTypography variant="h5" component="h5">
                  {user_data?.locaton ?? "-"}
                </BlackTypography>
              </Grid>
            </Grid>
          </CardContent>
        </BorderCard>
      </Stack>
    </Stack>
  );
}
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
