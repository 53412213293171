import { Editor } from "react-draft-wysiwyg";
import ReactSelect from "react-select";
const {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Button,
} = require("reactstrap");
import React from "react";
import TextArea from "antd/lib/input/TextArea";

class DetailsPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    this.handleTypeFunction = () => {
      if (this.props.state.questionType === "MSA") {
        return "radio";
      }
      if (this.props.state.questionType === "MMA") {
        return "checkbox";
      }
      if (this.props.state.questionType === "TOF") {
        return "radio";
      }
      if (this.props.state.questionType === "SA") {
        return "radio";
      }
    };
    return (
      <React.Fragment>
        <Container fluid>
          <Row className="padding_x_20px">
            <Card>
              <CardBody>
                <Row className="padding_x_20px">
                  <Col xs={12}>
                    <ReactSelect
                      style={{ zIndex: "999" }}
                      // defaultValue={content?.seniority}
                      value={this.props.state.multi}
                      isMulti={true}
                      onChange={this.props.handleSelectMulti}
                      options={this.props.optionGroup}
                      classNamePrefix="select2-selection"
                      // style={{marginBottom:'10px'}}
                    />{" "}
                  </Col>
                  <Col xs={12}>
                    <Label>Description</Label>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      placeholder="Place Your Job Description Here..."
                    />{" "}
                  </Col>
                </Row>

                {this.props.state.questionType !== "FIB" &&
                  this.props.state.optCount.map((item, index) => (
                    <React.Fragment key={index}>
                      <Row className="padding_x_20px">
                        <Col xs={12}>
                          <h5>
                            {(this.props.state.questionType === "MSA" ||
                              this.props.state.questionType === "MMA" ||
                              this.props.state.questionType === "TOF") &&
                              "Option "}
                            {this.props.state.questionType === "SA" &&
                              "Acceptable Answer "}{" "}
                            {this.props.state.questionType === "OS" &&
                              "Sequence Item "}
                            {this.props.state.questionType === "MTF" && "Pair "}
                            {index}
                          </h5>
                          {this.props.state.questionType === "MTF" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <TextArea />
                              <TextArea />
                            </div>
                          ) : (
                            <TextArea />
                          )}
                        </Col>
                      </Row>
                      <Row className="padding_x_20px ">
                        <Col md={6} className="option_action">
                          {this.props.state.questionType !== "MTF" && (
                            <div>
                              <Input
                                type={this.handleTypeFunction()}
                                name="correct"
                                id={`crct${index}`}
                                style={{
                                  transform: "scale(1.4)",
                                }}
                              />
                              <Label htmlFor={`crct${index}`}>
                                {this.props.state.questionType === "SA"
                                  ? "Exact"
                                  : "Correct"}{" "}
                                Answer
                              </Label>
                            </div>
                          )}
                        </Col>
                        {index >= 2 && (
                          <Col xs={6} className="option_effects">
                            <div>
                              <Button
                                color="danger"
                                onClick={() =>
                                  this.props.handleRemoveOptions(index)
                                }
                              >
                                Remove
                              </Button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </React.Fragment>
                  ))}
                {this.props.state.questionType === "MMA" ||
                  this.props.state.questionType === "MSA" ||
                  (this.props.state.questionType === "MTF" && (
                    <Row>
                      <Col md={2}>
                        <Button
                          color="success"
                          onClick={this.props.handleOptions}
                        >
                          Add{" "}
                          {this.props.state.questionType === "MTF"
                            ? "Pair"
                            : "Options"}
                        </Button>
                      </Col>
                    </Row>
                  ))}
                <Row
                  className="padding_x_20px"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Col md={2}>
                    <button
                      className="theme_btn"
                      color="success"
                      onClick={() => {
                        this.props.handleState(2);
                      }}
                    >
                      Save Details
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default DetailsPage;
