import { Grid, Stack, Typography } from "@mui/material";
import {
  BlackTypography,
  GreyTypography,
  PrimaryButton,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  useEffect(() => {}, []);
  return (
    <div
      style={{
        display: "grid",
        width: "100vw",
        height: "100vh",
        placeItems: "center",
      }}
    >
      <Grid container sx={{ paddingLeft: "80px" }} alignItems={"center"}>
        <Grid item xs={12} md={6}>
          <Stack spacing={4}>
            <BlackTypography
              sx={{ fontSize: "38px !important" }}
              variant="h2"
              component="h2"
            >
              Oh No ! Error 404
            </BlackTypography>
            <GreyTypography variant="h6" sx={{ fontWeight: "400 !important" }}>
              This page doesn’t exist or was removed!
              <br /> We suggest you back to home
            </GreyTypography>
            <PrimaryButton
              component={Link}
              to="/dashboard"
              sx={{ width: "200px" }}
            >
              Back to Home
            </PrimaryButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="not_found.svg"
            alt="not found_img"
            style={{
              height: "600px",
              width: "600px",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
