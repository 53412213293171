import * as CONSTANT from "../../common/constants/common-words";

export default function findWorkMode(data) {
  switch (data) {
    case CONSTANT.REMOTE:
      return "Fully Remote";
    case "On_site":
      return "On Site";
    case "Partially_remote":
      return "Partially Remote";
    default:
      "";
  }
}
