import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import "./assets/css/index.css";
import {
  authProtectedRoutes,
  identifyUser,
  publicRoutes,
  SPROUTES,
} from "./routes/";
import AppRoute from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { Toaster } from "react-hot-toast";

// import "./assets/scss/theme.scss";
import { isAdmin, loginSuccess, loginUser } from "store/actions";
import NotFound from "pages/notFound";
import { getUserByToken } from "helpers/services/CommonUser/findUserbasedOnType";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "assets/Mui/globalTheme";
import SocketConnection from "hooks/sockets";

export default function App() {
  const data = SocketConnection();
  const user = useSelector(state => state?.Login);
  const chat = useSelector(state => state?.Chat);
  const layout = useSelector(state => state?.Layout);
  const Layout = VerticalLayout;
  const [routes, setRoutes] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dataFetcher(user?.role);
  }, [user?.role]);


  async function dataFetcher(role) {
    setRoutes(identifyUser(role));
    if (localStorage.getItem("authUser")) {
      const role = JSON.parse(localStorage.getItem("authUser")).role;
      const local = JSON.parse(localStorage.getItem("authUser"));
      if (local?.token) {
        const { data } = await getUserByToken();
        dispatch(loginSuccess({ ...data?.data, access: local?.token }));
      }
      // this.props.loginSuccess({ ...local, usertype: convertRoleForApi(role) });
      if (typeof role === "string") setRoutes(identifyUser(role));
      if (role === "ZA") dispatch(isAdmin(true));
      else dispatch(isAdmin(false));
    }
  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.role !== prevProps.role) {
  //     this.setState({ role: this.props.role }, () => {
  //       this.setState({ routes: identifyUser(this.state.role) }, () => {});
  //     });
  //   }
  // }
  return (
    <React.Fragment>
      <ThemeProvider theme={globalTheme}>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.Component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {user.isAdmin &&
              authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  isAdmin={user.isAdmin}
                  component={route.Component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}

            {!user.isAdmin &&
              routes?.map(({ path, Component, layout = Layout }, idx) => (
                <AppRoute
                  path={path}
                  layout={Layout}
                  isAdmin={user.isAdmin}
                  component={Component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
            <Route component={NotFound} />
          </Switch>
        </Router>
        <Toaster position="top-right" reverseOrder={false} />
      </ThemeProvider>
    </React.Fragment>
  );
}
