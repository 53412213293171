import { call, put, takeEvery } from "redux-saga/effects";

import { GET_INVOICES, GET_INVOICE_DETAIL } from "./actionTypes";
import {
  getInvoicesSuccess,
  getInvoicesFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
} from "./actions";

function* fetchInvoices() {
  try {
    const response = [
      {
        id: 1,
        // image: "https://johnbabu.vercel.app/me.png",
        color: "red",
        invoiceID: 2333,
        founder: "JOHN",
        invoicePrice: 23333,
        company: "Google",
        date: "Jan 1 22",
      },
    ];
    yield put(getInvoicesSuccess(response));
  } catch (error) {
    yield put(getInvoicesFail(error));
  }
}

function* fetchInvoiceDetail({ invoiceId }) {
  try {
    // const response = yield call(getInvoiceDetail, invoiceId);
    const response = {
      orderId: 1,
      billingAddress: "san Francisco, Alamba,Alaska",
      shippingAddress: "san Francisco, Alamba,Alaska",
      card: "3333333",
      email: "asdfe@gamdi.com",
      orderDate: "Jan 1 22",
      orderSummary: {
        items: [{ id: "1", item: "asdf", price: "asdf" }],
        subTotal: 3333,
        shipping: "alaska",
        total: "33333",
      },
    };
    yield put(getInvoiceDetailSuccess(response));
  } catch (error) {
    yield put(getInvoiceDetailFail(error));
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices);
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail);
}

export default invoiceSaga;
