const { Table, Row, Container, CardBody, Card } = require("reactstrap");

import COMMON_WORD from "../../common/constants/common-words";
import { data } from "../jobs/navFunctions/publishJob";
import React from "react";
import "../../assets/css/company.css";
import SearchFilter from "components/Common/filters/searchFilter";
import { withRouter } from "react-router-dom";
class BvcList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row style={{ padding: "40px" }}>
              <SearchFilter />
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          {/* <th>Logo</th> */}
                          <th>Company Info</th>
                          <th>Employer Info</th>
                          <th>Info</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index + item.imgRef}>
                            <td className="table_item">{item.name}</td>
                            <td className="table_item">{item.candidates}</td>
                            <td
                              className={`table_item ${
                                item.action === COMMON_WORD.PUBLISHED
                                  ? "green"
                                  : "red"
                              }`}
                            >
                              {item.action === COMMON_WORD.PUBLISHED ? (
                                <>
                                  <i className="bx bx-comment-error" />
                                  Active
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <i className="bx bx-x"></i> Active
                                </>
                              )}
                            </td>
                            <td className="table_item">
                              <button
                                onClick={() => {
                                  this.props.history.push(
                                    "../screening-partner/edit/23"
                                  );
                                }}
                                className="edit_btn"
                              >
                                View
                              </button>
                            </td>{" "}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(BvcList);
