import { BlackTypography, GreyTypography } from "assets/Mui/globalTheme";

export default function TimeLineHeader({ timeLineCount }) {
  return (
    <div
      style={{
        background: "rgba(0,0,0,0.1)",
        padding: "16px",
        borderRadius: "8px",
        marginBottom: "10px",
      }}
    >
      <BlackTypography variant="h4" component="h4">
        {timeLineCount} Actions Taken
      </BlackTypography>
    </div>
  );
}
