import { withRouter } from "react-router-dom";
import React from "react";
import { toast } from "react-hot-toast";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { PrimaryButton, SecondaryFilledButton } from "assets/Mui/globalTheme";
const { Modal } = require("reactstrap");

class CommonModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      active: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }
  handleInput(e) {
    this.setState({ input: e.target.value });
  }
  handleSubmit() {
    if (this.state.input !== "") {
      this.props.update(
        {
          name: this.state.input,
          is_active: true,
        },
        this.props.purpose,
        this.props.index
      );
      // toast.success(
      //   `successfully ${this.props.purpose}d ${this.props.headTag}`
      // );
      toast.success(
        `${this.props.purpose}d`
      );
     
      this.props.toggle(!this.props.isOpen);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        input: this.props.title,
        // default: this.props.defaultValue,
        active: this.props.active,
      });
    }
  }
  render() {
    const { headTag, isOpen, toggle, purpose } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={this.toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {purpose} {headTag}
          </h5>
          <button
            type="button"
            onClick={() => toggle(!isOpen)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm>
            <AvField
              name="text"
              label={`${purpose} ${headTag}`}
              className="form-control"
              value={this.state.input}
              onChange={this.handleInput}
              placeholder={`enter ${headTag} name`}
              type="text"
              required
            />{" "}
          </AvForm>
        </div>

        <div className="modal-footer">
          <SecondaryFilledButton
            sx={{ width: "100px", marginRight: "10px" }}
            type="button"
            onClick={() => {
              toggle(!isOpen);
            }}
          >
            Close
          </SecondaryFilledButton>
          <PrimaryButton
            sx={{ width: "150px" }}
            type="button"
            onClick={this.handleSubmit}
          >
            Save changes
          </PrimaryButton>
        </div>
      </Modal>
    );
  }
}
export default withRouter(CommonModel);
