import { Stack } from "@mui/material";
import {
  BorderCard,
  BorderedCardActionArea,
  PrimaryTextButton,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import AddEditNotes from "./addNotes";
import { useEffect, useState } from "react";
import { createNotes } from "helpers/services/notes/create";
import SearchParamHook from "hooks/searchParams";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNotes } from "helpers/services/notes/getNotes";

export default function UserJobPostNotesHeader() {
  const [notesModel, setNotesModel] = useState(false);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const [jobPostId, setJobPostId] = useState(null);
  const searchParams = SearchParamHook();
  const dispatch = useDispatch();
  useEffect(() => {
    const userJobPostId = searchParams.get("user_job_post_id");
    if (userJobPostId) {
      setUserJobPostId(userJobPostId);
    }
    const jobPostId = searchParams.get("job_id");
    if (jobPostId) {
      setJobPostId(jobPostId);
    }
  }, [searchParams]);
  function toggleModel() {
    setNotesModel(!notesModel);
  }
  async function updateNotes(data) {
    createNotes({
      user_job_post_id: userJobPostId,
      job_post_id: jobPostId,
      ...data,
    });
    const { data: notes, status } = await getNotes(userJobPostId);
    if (status === 200) {
      dispatch({
        type: "ADD_NOTES",
        payload: notes?.data,
      });
      toggleModel();
    }
  }
  return (
    <BorderCard sx={{ marginBottom: "10px" }}>
      <Stack>
        <PrimaryTextButton
          sx={{ width: "180px" }}
          onClick={toggleModel}
          endIcon={<FeatherIcon icon="plus-circle" size="14" />}
        >
          Add Notes
        </PrimaryTextButton>
      </Stack>
      <AddEditNotes
        open={notesModel}
        type="create"
        toggleModel={toggleModel}
        updateOrEditNotes={updateNotes}
      />
    </BorderCard>
  );
}
