export const updateAdminStaffModel = data => {
  return {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    is_active: data.is_active,
    // usertype: data.usertype,
    company_id: data.company_id,
  };
};
