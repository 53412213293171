import React, { useEffect, useState } from "react";
import "../../assets/css/login/login.css";
import { CardBody, Card, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import LoginImage from "../../assets/images/zepul/login.svg";
import { loginUser } from "../../store/actions";
import logoDark from "../../assets/images/zepul/zepul_dark.png";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
  Stack,
  TextField,
  Typography,
  circularProgressClasses,
  colors,
} from "@mui/material";
import { useForm } from "react-hook-form";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useHistory } from "react-router-dom";
import {
  SearchPrimaryInput,
  GreyTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  LargeSemiboldTypography,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import { toast } from "react-hot-toast";

export default function PartnerLogin() {
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("authUser"))?.token !== "" &&
      JSON.parse(localStorage.getItem("authUser"))?.token !== null &&
      JSON.parse(localStorage.getItem("authUser"))?.token !== undefined
    ) {
      history.push("/login");
    }
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { search } = useLocation();

  const history = useHistory();
  const loginDispatch = useDispatch();
  const location = useLocation();
  const selector = useSelector(state => state?.Login);
  async function handleData(data) {
    await loginDispatch(loginUser(data, history, location));
    setLoading(true);
  }
  useEffect(() => {
    if (selector?.loading === false) {
      setLoading(false);
    }
  }, [selector?.loading]);

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }
  return (
    <React.Fragment>
      <div className="login_parent">
        <section className="login_img_section">
          <Typography
            variant="h3"
            component="h3"
            sx={{
              color: "white",
              lineSpacing: "20px",
              fontSize: "20px",
              textTransform: "capitalize",
            }}
          >
            The most efficient platform to Easily
            <br />
            <Typography
              variant="h3"
              component="h3"
              sx={{
                color: "#7CD4F9",
                padding: "10px 0px",
                fontSize: "20px",
                textTransform: "capitalize",
              }}
            >
              Apply jobs, Refer Candidates
            </Typography>
            talented people
          </Typography>
          <img src={LoginImage} />
        </section>{" "}
        <Card className="overflow-hidden card_image">
          <div className="bg-primary bg-soft"></div>
          <CardBody className="pt-0 main_content_login">
            <div className="p-2">
              <form onSubmit={handleSubmit(handleData)} sx={{ width: "100%" }}>
                <Stack
                  direction="column"
                  spacing={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      sx={{
                        ...LargeSemiboldTypography,
                        color: "#081753",
                      }}
                      variant="h2"
                      component="h2"
                    >
                      SignIn
                    </Typography>
                    <Typography
                      sx={{
                        ...LargeSemiboldTypography,
                        color: "rgba(0,0,0,0.5)",
                      }}
                      variant="h2"
                      component="h2"
                    >
                      Here
                    </Typography>
                  </Stack>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <PrimaryNormalInputLabel
                      shrink
                      htmlFor="login-email-outlined"
                      error={errors.email}
                    >
                      Email
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Enter your email"
                      {...register("email", { required: true })}
                      id="login-email-outlined"
                      helperText={errors?.email ? "email is required" : ""}
                      autoFocus
                      label="email address"
                      type={"text"}
                      name="email"
                      error={errors.email}
                      variant="outlined"
                    />
                    <PrimaryNormalFormHelperText error={errors.email}>
                      {errors.email ? "Email is required Field" : ""}
                    </PrimaryNormalFormHelperText>
                  </FormControl>

                  <FormControl sx={{ width: "100%" }}>
                    <PrimaryNormalInputLabel
                      shrink
                      sx={{ transform: "scale(0.75)" }}
                      htmlFor="login-password-outlined"
                      error={errors.password}
                    >
                      Password
                    </PrimaryNormalInputLabel>
                    <PrimaryNormalInputs
                      {...register("password", { required: true })}
                      id="login-password-outlined"
                      label="Password"
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      error={errors.password}
                      name="password"
                      helperText={
                        errors?.password ? "password is required" : ""
                      }
                      endAdornment={
                        <InputAdornment
                          sx={{
                            position: "absolute",
                            right: "10px",
                          }}
                          position="end"
                          onClick={handleShowPassword}
                        >
                          {!showPassword ? (
                            <FeatherIcon icon="lock" size={"14"} />
                          ) : (
                            <FeatherIcon icon="unlock" size={"14"} />
                          )}{" "}
                        </InputAdornment>
                      }
                      variant="outlined"
                    />
                    <PrimaryNormalFormHelperText error={errors.password}>
                      {errors.password ? "Password is required Field" : ""}
                    </PrimaryNormalFormHelperText>
                  </FormControl>
                  {loading ? (
                    <PrimaryButton sx={{ position: "relative" }}>
                      <CircularProgress
                        variant="determinate"
                        sx={{
                          color: colors.grey[300],
                        }}
                        size={30}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant="indeterminate"
                        color="primary"
                        disableShrink
                        sx={{
                          animationDuration: "550ms",
                          position: "absolute",
                          // left: 0,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                          },
                        }}
                        size={30}
                        thickness={4}
                      />
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton type="submit">Login</PrimaryButton>
                  )}
                </Stack>
              </form>
              <div className="mt-4 text-center">
                <Link to="/forgot-password" className="text-muted">
                  <i className="mdi mdi-lock me-1" /> Forgot your password?
                </Link>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p>
                Don&apos;t have an account ?
                <Link to={`/signup`} className="fw-medium text-primary">
                  Signup Now
                </Link>
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}
