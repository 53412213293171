const { Input, Button, Row, Col, Label } = require("reactstrap");
import ClipBoardData from "helpers/dashboard/clipboard";
import React from "react";
import { toast } from "react-hot-toast";
class ShareJob extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        {this.props.toggle && (
          <Row className="justify-content-around share__job">
            <div className="close_cont">
              <div className="close_item" onClick={this.props.toggleFunc}>
                <i className="bx bx-x"></i>
              </div>
            </div>
            <Col xs={12}>
              <Label>Job Link</Label>
            </Col>
            <div
              style={{ display: "flex", paddingBottom: "20px", gap: "10px" }}
            >
              <Input
                type="text"
                placeholder=""
                defaultValue={"https://zepul.com/career?company?id=1244"}
              />
              <Button
                color="primary"
                onClick={() => {
                  this.props.toggleFunc();
                  ClipBoardData(
                    "https://zepul.com/career?company?id=1244",
                    "link copied successfully"
                  );
                }}
              >
                Copy
              </Button>
            </div>
            <Col>
              <Label>Share job on social Media</Label>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
export default ShareJob;
