import { useState, useEffect } from "react";
import EvaluationReport from "./Report";
import UserJobPostScoreEvalutions from "./UserJobPostScoreEvalutions";
import { getSkillRecords } from "helpers/services/scorecard";
import SearchParamHook from "hooks/searchParams";
import dataNotFound from "../../../../assets/images/noData.png";
import { PrimaryTypography } from "assets/Mui/globalTheme";
import { CircularProgress,Rating} from "@mui/material";
import ReportTable from "./reportTable";
import { useSelector } from "react-redux";
import { MODERATOR, SP,TE } from "common/constants/userModules";
import { getScreeningSkills,getModeratorSkills,getTechnicalSkills } from "helpers/services/scorecard";
import { getSingleJobDetails } from "helpers/services/jobs/jobs";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  table:{
      width:'100%',
      borderCollapse:'collapse',
      border:"1px solid",
      "& td,& th":{
          border:"1px solid",
          padding:'4px'
      }
  }
})

export default function ScoreCard() {
  const [viewPrint,setViewPrint] = useState(false)
  const [skillRecord, setSkillRecord] = useState({});
  const [jobPostInfo,setJobPostInfo] = useState(null);
  const [loading,setLoading] = useState(false)
  const search = SearchParamHook();
  const userJobPostId = search.get("user_job_post_id");
  const jobPostId = search.get('job_id')
  const evalDetails = JSON.parse(localStorage.getItem("evaluation_info"))
  // console.log(evalDetails,"v");
  const [screeningSkills,setScreeningSkills] = useState([])
  const [moderatorSkills,setModeratorSkills] = useState([])
  const [evaluatorSkills,setEvaluatorSkills] = useState([])
  const classes = useStyles()
  useEffect(() => {
    (async function () {
      setLoading(true)
      const res = await getSkillRecords(userJobPostId);
      const { data } = res.data;
      console.log(data,"v");
      setSkillRecord(data);
      screening(data?.screening_partner_evalutation?.sp_details?.id || null)
      technical(data?.technical_evalutation?.evaluator_details?.id || null)
      moderator(data?.moderator_evalutation?.moderator_details?.id || null)
      setTimeout(()=>setLoading(false),1000)      
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const res = await getSingleJobDetails(jobPostId);
      const { data } = res;
      setJobPostInfo(data);
      // console.log(data,"v skills");
    })();
  }, []);

  async function screening (id) {
    const obj = {
      userJobPostId,
      evalId: id,
      evType: SP,
    };
    if (Boolean(id)) {
      const res = await getScreeningSkills(obj);
      const { data } = res;
      setScreeningSkills(data.rows);
    } else {
      setScreeningSkills([]);
    }
  }
  async function technical (id) {
    const obj = {
      userJobPostId,
      evalId: id,
      evType: TE,
    };
    if (Boolean(id)) {
      const res = await getTechnicalSkills(obj);
      const { data } = res;
      setEvaluatorSkills(data.rows);
    } else {
      setEvaluatorSkills([]);
    }
  }

  async function moderator (id) {
    const obj = {
      userJobPostId,
      evalId: id,
      evType: MODERATOR,
    };
    if (Boolean(id)) {
      const res = await getModeratorSkills(obj);
      console.log(res, "v");
      const { data } = res;
      setModeratorSkills(data.rows);
    } else {
      setModeratorSkills([]);
    }
  }

  function getAllFieldValues(obj) {
    const values = [];

    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively call the function for nested objects
        const nestedValues = getAllFieldValues(obj[key]);
        values.push(...nestedValues);
      } else {
        // Add the value to the array
        values.push(obj[key]);
      }
    }

    return values;
  }

  const fieldValues = [...getAllFieldValues(skillRecord)].reduce((pre,curr)=> pre + curr,0);
  return (
    <div>
      {loading ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </div>
      ) : fieldValues ? (
        <>
          <EvaluationReport
            openPrintView={() => setViewPrint(true)}
            scoreData={skillRecord}
            screeningSkills={screeningSkills}
            moderatorSkills={moderatorSkills}
            evaluatorSkills={evaluatorSkills}
            jobPostInfo={jobPostInfo}
          />
          <>
            <ReportTable
              skills={screeningSkills}
              evType={SP}
              jobPostInfo={jobPostInfo}
              heading={"Screening"}
            />
            <ReportTable
              skills={moderatorSkills}
              evType={MODERATOR}
              jobPostInfo={jobPostInfo}
              heading={"Moderator"}
            />
            <ReportTable
              skills={evaluatorSkills}
              evType={TE}
              jobPostInfo={jobPostInfo}
              heading={"Technical"}
            />
          </>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <PrimaryTypography textAlign={"left"} className="mb-4" variant="h2">
            Evaluation Report
          </PrimaryTypography>
          <img
            style={{
              width: "350px",
              height: "350px",
              marginTop: 0,
            }}
            src={dataNotFound}
            alt="/not-found"
          />
        </div>
      )}
      {/* <UserJobPostScoreEvalutions /> */}
    </div>
  );
}
