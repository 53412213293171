import {
  ZA,
  SP,
  CA,
  ACM,
  ZM,
  CS,
  TE,
  RC,
  BVC,
  BVS,
  RS,
  MODERATOR
} from "common/constants/userModules";

export function roleFinder(data) {
  switch (data) {
    case "ADMIN":
      return ZA;
    case "EVALUATOR":
      return TE;
    case "COMPANY":
      return CA;
    case "ACCOUNT_MANAGER":
      return ACM;
    case "SCREENING_PARTNER":
      return SP;
    case "MODERATOR":
      return MODERATOR;
    case "COMPANY_STAFF":
      return CS;
    case "RECRUITMENT_COMPANY":
      return RC;
    case "RECRUITMENT_COMPANY_STAFF":
      return RS;
    case "BACKGROUND_VERIFICATION":
      return BVC;
    case "BACKGROUND_VERIFICATION_STAFF":
      return BVS;
    default:
      return ACM;
  }
}
export function convertRoleForApi(data) {
  switch (data) {
    case ZA:
      return "ADMIN";
    case CA:
      return "COMPANY";
    case ACM:
      return "ACCOUNT_MANAGER";
    case TE:
      return "EVALUATOR";
    case BVC:
      return "BACKGROUND_VERIFICATION";
    case SP:
      return "SCREENING_PARTNER";
    default:
      return SP;
  }
}
