import React from "react";
import { withRouter } from "react-router-dom";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Label, Row } from "reactstrap";
import "../../assets/css/talentpool.css";

class TalentPool extends React.Component {
  constructor(props) {
    super(props);
    this.handleTalent = this.handleTalent.bind(this);
  }
  handleTalent = () => {
    this.props.history.push("/talent-pool/123");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={4}>
                <div className="talent__box" onClick={this.handleTalent}>
                  <div className="talent_inner_border">
                    <h4>Default Folder</h4>
                    <p>
                      Default folder contains the list of all the candidates
                      from the organization, and cannot be deleted
                    </p>
                  </div>

                  <h5>1 candidates</h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
TalentPool.prototypes = {
  // history: Route.propTypes.history,
};
export default withRouter(TalentPool);
