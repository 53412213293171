import { Button, IconButton, Input } from "@mui/joy";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Form, Modal } from "reactstrap";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import { createFormsApi } from "helpers/services/forms";
export default function CreateEvaluationForms({ isOpen, toggle, update }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fields: [{ title: "" }],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "fields",
    }
  );
  const handleFormSubmit = async data => {
    await createFormsApi({
      form_name: data?.form_name,
      form_fields: data?.fields?.map(item => ({
        field_name: item?.title,
        input_type: ["range", "textarea"],
      })),
    });
    update();
    toggle();
  };
  const handleAppend = () => {
    append();
  };
  const handleRemove = id => {
    if (id === 0) {
      toast.error("Sorry you are not allowed to delete this field");
      return;
    }
    remove(id);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();
        remove();
        append({ title: "" });
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Create Forms
        </h5>
        <IconButton
          onClick={() => {
            toggle();
            remove();
            append({ title: "" });
          }}
        >
          <span aria-hidden="true">&times;</span>
        </IconButton>
      </div>
      <Form
        className="px-2 d-flex flex-column gap-4 my-4 "
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Input
          placeholder="Form Name"
          error={errors?.form_name ? true : false}
          {...register("form_name", { required: true })}
        />
        {fields.map((field, index) => (
          <div className="d-flex align-items-end gap-2 justify-content-between">
            <Input
              placeholder={`Skill Attribute ${index + 1}`}
              className="flex-grow-2"
              key={field.id}
              error={errors.fields?.[index]?.title && true}
              {...register(`fields[${index}].title`, {
                required: true,
              })}
            />
            <div className="d-flex align-items-center gap-2">
              <Button onClick={handleAppend}>
                <FeatherIcon icon="plus" size="14" />
              </Button>
              <Button color="danger" onClick={() => handleRemove(index)}>
                <FeatherIcon icon="minus" size="14" />
              </Button>
            </div>
          </div>
        ))}
        <Button type="submit" style={{ width: "100px" }}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
