import { Button, Radio, Select, Space } from "antd";
import "../../../assets/css/jobsaddition.css";
import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Input, Label } from "reactstrap";
// import ReactSelectComponent from "components/Common/reactSelectComponent";
import { includeRemote, jobType, optionGroup } from "common/constants/options";

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      multi: [],
      width: window.innerWidth,
      click: false,
    };
    this.handleSelectMulti = this.handleSelectMulti.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  handleSelectMulti = data => {
    this.setState({
      multi: data,
    });
  };
  onChange = e => {
    this.setState({ value: e.target.value });
  };

  render() {
    return (
      <div
        style={{
          transition: "0.5s ease-in-out",
        }}
        className={"filter_horizontal"}
      >
        {this.state.click && this.state.width < 800 && (
          <button
            className="down_icon"
            onClick={() => {
              this.setState({ click: false });
            }}
            style={{
              position: "absolute",
              bottom: "-10px",
              left: "45%",
              // transform: "translate(50%,50%)",
            }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
        )}
        <div className="job_filter_text py-4">
          {/* <Label htmlFor="job_type1">Search Jobs</Label> */}
          <Input
            type="text"
            id="job_type1"
            name="job_type"
            placeholder="Company name"
          />
        </div>
        <div className="job_filter_text">
          {/* <Label htmlFor="job_type1">City/Country</Label> */}
          <Input
            type="text"
            id="job_type1"
            name="job_type"
            placeholder="city/country"
          />
        </div>

        <Col xs={2}>
          <div className="group__container">
            {/* <p>work Type</p> */}

            {/* <ReactSelectComponent isMulti={false} options={includeRemote} /> */}
          </div>
        </Col>
        <Col xs={2}>
          <div className="group__container">
            {/* <p>Job Type</p> */}
            {/* <ReactSelectComponent isMulti={true} options={jobType} /> */}
          </div>
        </Col>
      </div>
    );
  }
}

export default withRouter(SearchFilter);
