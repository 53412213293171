const { Container, Col } = require("reactstrap");
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import noData from "../../assets/images/zepul/no_jobs.svg";
import { connect } from "react-redux";
import JobCardInformation from "components/jobCard/jobCardInformations";
import JobPostedCompanyAvatar from "components/jobCard/JobPostedCompanyAvatar";
import { GreyTypography, StyledCard, StyledCardContent } from "assets/Mui/globalTheme";
import { Stack } from "@mui/material";
import ApprovedCardsButton from "components/jobCard/ApproveCardButtons";

function JobList({ role, jobs, load, setUpdate }) {
  let history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [viewDetails, setViewDetails] = useState(tableData[0]);

  const handleClose = confirm => {};

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchItems = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = tableData?.filter(item =>
      item.job_title.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);

  useEffect(() => {
    const activeData = jobs?.filter(
      data => data.is_job_post_verified === true && data.is_active === true
    );
    setTableData(activeData);
  }, [jobs]);

  useEffect(() => {
    setViewDetails(tableData[0]);
  }, [tableData]);
  function randcolor() {
    function c() {
      return Math.floor(Math.random() * 220 + 36).toString(16);
    }
    return "#" + c() + c() + c();
  }
  return (
    <>
      {load ? (
        <div>
          <Container
            fluid
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            <div className="row row-gap-4">
              {tableData.length > 0 ? (
                tableData.map(item => (
                  <Col xs={12} md={6} lg={4} className="mb-4" key={item.id}>
                    <StyledCard>
                      <StyledCardContent>
                        <Stack direction="column" spacing={1}>
                          <JobPostedCompanyAvatar item={item} />

                          <JobCardInformation
                            tableData={tableData}
                            setUpdate={setUpdate}
                            setTableData={setTableData}
                            item={item}
                          />
                          <ApprovedCardsButton
                            tableData={tableData}
                            item={item}
                            setUpdate={setUpdate}
                            setTableData={setTableData}
                            // handleViewMore={handleViewMore}
                          />
                        </Stack>
                      </StyledCardContent>
                    </StyledCard>
                  </Col>
                ))
              ) : (
                <div className="no_data_show">
                  <div>
                    <img src={noData} className="lg_img"></img>
                    <GreyTypography variant="h6">
                      Approved Bucket is Empty
                    </GreyTypography>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
      ) : (
        <div className="loader_container" style={{ height: "80vh" }}>
          <div className="loader"></div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = state => ({
  role: state.Login.role,
});
export default connect(mapStateToProps, null)(JobList);
