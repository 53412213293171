import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
} = require("reactstrap");
import React from "react";
class SolutionForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="padding_x_20px">
                <Col xs={12}>
                  <Label>Solution</Label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    placeholder="Place Your Job Description Here..."
                  />{" "}
                </Col>
              </Row>
              <Row className="padding_x_20px">
                <Col xs={12}>
                  <Label>Enable Video Solution</Label>
                </Col>
              </Row>
              <Row className="padding_x_20px">
                <Col xs={12}>
                  <div
                    className="btn-group me-2"
                    role="group"
                    aria-label="Second group"
                  >
                    <button className="btn btn-outline-secondary">YES</button>
                    <button className="btn btn-outline-secondary">NO</button>
                  </div>
                </Col>
              </Row>
              <Row className="padding_x_20px">
                <Col xs={12}>
                  <Label>
                    Video Type (Supported YouTube, Vimeo & .mp4 files)
                  </Label>
                </Col>
              </Row>

              <Row className="padding_x_20px">
                <Col xs={12}>
                  <div
                    className="btn-toolbar"
                    // role="toolbar"
                    // aria-label="Toolbar with button groups"
                  >
                    <div
                      className="btn-group me-2"
                      role="group"
                      //   aria-label="Second group"
                    >
                      <button className="btn btn-outline-secondary">
                        MP4 VIDEO
                      </button>
                      <button className="btn btn-outline-secondary">
                        YOUTUBE VIDEO
                      </button>
                      <button className="btn btn-outline-secondary">
                        VIMEO VIDEO
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="padding_x_20px align-items-center justify-content-center">
                <Col xs={10}>
                  <Input />
                </Col>
                <Col xs={2}>
                  <button className="theme_btn" color="primary">
                    Preview
                  </button>
                </Col>
              </Row>
              <Row className="padding_x_20px">
                <Col xs={12}>
                  <Label>Hint</Label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    placeholder="Place Your Job Description Here..."
                  />{" "}
                </Col>
              </Row>
              <Row
                className="padding_x_20px"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Col xs={2}>
                  <button
                    className="theme_btn"
                    onClick={() => {
                      this.props.handleState(4);
                    }}
                  >
                    Update
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default SolutionForm;
