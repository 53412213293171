import { Paper, Stack } from "@mui/material";
import { GreyTypography, PrimaryTypography } from "assets/Mui/globalTheme";
import InterviewCover from "../../assets/images/zepul/interview.png";
export default function ScheduleCoverImage() {
  return (
    <Paper
      elevation={0}
      sx={{
        background: "#F1F6F9",
        padding: "20px",
        borderRadius: "16px",
        marginTop: "30px",
        height: "167px",
      }}
    >
      <Stack
        direction="row"
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Stack sx={{ marginTop: "20px" }} spacing={1}>
          <PrimaryTypography
            variant="h2"
            component="h2"
            sx={{ fontWeight: "900 !important", fontSize: "24px !important" }}
          >
            Share a fixed time slot with the candidate
          </PrimaryTypography>
          <GreyTypography variant="h6" component="h6">
            Select an available time slot for the interview and share it with
            the candidate.
          </GreyTypography>
        </Stack>
        <img
          style={{ width: "170px", height: "170px" }}
          src={InterviewCover}
          alt="Interview Cover"
        />
      </Stack>
    </Paper>
  );
}
