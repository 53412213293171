import React from "react";
import "../../assets/css/email.css";
import { Button, Card, Col, Row } from "reactstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Report } from "@mui/icons-material";

import PieChart from "./PieChart";
import RoundChart from "./rounChart";

class CandidatesOverview extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <div className="candidate_header email_brand_div">
              <div>
                <h4>Candidate Overview</h4>
                <p>
                  Overview of all candidates in the hiring pipelines or talent
                  pools.
                </p>
              </div>
              <Button color="primary">Help</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            {/* <Card> */}
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
            {/* </Card> */}
          </Col>

          <Col xs={4}>
            {/* <Card> */}
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
            {/* </Card> */}
          </Col>
          <Col xs={4}>
            {/* <Card> */}
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
            {/* </Card> */}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card>
              <PieChart />
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col md={6}>
            <Card>
              <PieChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <PieChart />
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col md={6}>
            <Card>
              <RoundChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <RoundChart />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CandidatesOverview;
