import * as React from "react";
import {Box,Modal, TextField,Rating,Tooltip} from "@mui/material";
import {
  BlackTypography,
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalInputLabel,
  SecondaryFilledButton,
  SecondaryFilledChip,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  Grid,
  Stack,
  createFilterOptions,
} from "@mui/material";
import {v4 as uuid} from "uuid";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import {
  createKeySkills,
  getAllKeySkillsWithStatus,
} from "helpers/services/jobAttributes/keyskills";
import SearchParamHook from "hooks/searchParams";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    label:{
        "& label":{
          marginBottom:"0px !important"
        },
        "&.css-iljtu2-MuiRating-root":{
            border:(props)=>props.rateError ? '1px solid #ff5630':'none'
        }
  }
  })

  const feedbacks=[
    "Poor",
    "Not Good",
    "Below Average",
    "Average",
    "Good",
    "Excellent"
 ]
 
 const  popperProps = {
   modifiers: [
     {
       name: 'offset',
       options: {
         offset: [1,-10], // Adjust the values to reduce the distance
       },
     },
   ],
 };

export default function AddASkill({
  updateHandler,
  open,
  toggleModel,
}) {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues:{
        key_skills:'',
        comment:''
    }
  });
  const [allKeySkills, setAllKeySkills] = React.useState([]);
  const [userJobPostId, setUserJobPostId] = React.useState(null);
  const [rating, setRating] = React.useState({
    value: 0,
    hover: 0,
  });
//   const [rateError,setRateError] = React.useState(false)
  const searchParams = SearchParamHook();
  const filter = createFilterOptions();
//   const rateError = Boolean(!rating.value)
// const rateError = Boolean(!rating.value)

  const classes = useStyles()
  React.useEffect(() => {
    let queryParamsForUserJobPostId = searchParams.get("user_job_post_id");
    if (queryParamsForUserJobPostId)
      setUserJobPostId(queryParamsForUserJobPostId);
  }, [searchParams]);
  React.useEffect(() => {
    getAllKeySkills();
  }, []);
  async function getAllKeySkills() {
    const { data } = await getAllKeySkillsWithStatus();
    const result = data?.map(item => ({
      value: item.id,
      name: item.name,
      isActive:item.is_active
    }));
    setAllKeySkills(result);
  }

  async function onSubimission(data) {
    console.log(data);
    const { comment, key_skills } = data;
    // await updateUserJobPost(userJobPostId, {
    //   key_skills: [key_skills.value],
    // });
    updateHandler(key_skills.value, comment, rating.value,toggleModel);
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            Add a Skill{" "}
          </BlackTypography>
          <form onSubmit={handleSubmit(onSubimission)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <ReactSelect
                  isMulti={true}
                  options={allKeySkills}
                  onChange={data => {
                    setKeySkills(data);
                  }}
                  value={keySkills}
                /> */}
                <Controller
                  render={props => {
                    return (
                      <PrimaryAutoComplete
                        sx={{ width: "100%" }}
                        {...props}
                        disableCloseOnSelect
                        componentsProps={autocompleteProps}
                        // multiple={true}
                        options={allKeySkills}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <SecondaryFilledChip
                              deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          option?.isActive ?
                          <li key={option?.id} {...props}>
                            <Checkbox
                              icon={<FeatherIcon icon="square" size="14" />}
                              checkedIcon={
                                <FeatherIcon icon="check-square" size="14" />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li> : null
                        )}
                        getOptionLabel={option => option?.name || ''}
                        renderInput={params => (
                          <FormControl
                            variant="standard"
                            sx={{ width: "100%" }}
                          >
                            <PrimaryNormalInputLabel
                              shrink
                              htmlFor="key-skills-outlined"
                              error={errors.key_skills}
                            >
                              Required Skills (select all that applies)
                            </PrimaryNormalInputLabel>
                            <MultipleSelectInputs
                              sx={{
                                ...(errors.key_skills && {
                                  borderColor: "red",
                                }),
                              }}
                              variant="standard"
                              ref={params.InputProps.ref}
                              {...params}
                            />
                          </FormControl>
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // // Suggest the creation of a new value
                          const isExisting = options.some(
                            option => inputValue === option.name
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              isActive:true,
                              id:uuid(),
                              name: `${inputValue}`,
                            });
                          }

                          return filtered;
                        }}
                        value={getValues("key_skills") || ''}
                        onChange={async (_, data) => {
                          // console.log(data,'veda new Data');
                          if (data && data.inputValue) {
                            const { data: skill } = await createKeySkills({
                              name: data.inputValue,
                            });
                            await getKeySkills();
                            props.field.onChange([
                              ...getValues("key_skills"),
                              {
                                name: skill.key_skill_res.name,
                                value: skill.key_skill_res.id,
                              },
                            ]);
                          } else {
                            props.field.onChange(data);
                          }
                        }}
                      />
                    );
                  }}
                  name="key_skills"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="comment"
                  multiline
                  {...register("comment")}
                  placeholder="Enter a comment"
                  error={errors.comment}
                  sx={{marginBottom:'16px'}}
                />
                <Tooltip
                  PopperProps={popperProps}
                  title={feedbacks[rating.hover] || ""}
                >
                  <Rating
                    className={classes.label}
                    // style={{border: errors.rating? '1px solid #ff5630':'none'}}
                    onChange={(_,val)=>setRating((pre)=>({
                        ...pre,
                        value:val
                    }))}
                    onChangeActive={(_, hover) =>
                      setRating(pre => ({
                        ...pre,
                        hover,
                      }))
                    }
                    // {...register('rating',{required:true})}
                    getLabelText={value => {
                      return `${value} Star${value !== 1 ? "s" : ""}, ${
                        feedbacks[value]
                      }`;
                    }}
                    // precision={0.5}
                    value={rating.value}
                    size="medium"
                    //   readOnly={readOnly}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggleModel();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
