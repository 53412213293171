export default function createjobModel(data) {
  const result = {
    job_title: data.job_title,
    job_type: data.job_type,
    location: data.location,
    work_mode: data.work_mode,
    job_desc: data.job_desc,
    functional_areas: data.functional_areas,
    job_roles: data.job_roles,
    no_of_vacancies: Number(data.no_of_vacancies),
    currency: data.currency,
    application_deadline: data.application_deadline,
    // time_zone: data.time_zone,
    time_zone: data.time_zone,
    key_skills: findKeySkillId(data.key_skills),
    min_sal: Number(data.min_sal),
    max_sal: Number(data.max_sal),
    min_exp: data.min_exp,
    max_exp: data.max_exp,
    commission_rate_type: data.commission_rate_type,
    commission_rate: data.commission_rate,
    educational_qualifications: data.educational_qualifications,
    screening_questions: data.screening_questions,
    hours_per_week: data.hours_per_week,
    project_duration: data.project_duration,
  };
  if (result.location === "") {
    delete result.location;
  }
  return result;
}
function findKeySkillId(key_skills) {
  return key_skills.map(item => item.value);
}
