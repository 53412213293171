import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "../../assets/css/loader/index.css";
export default function LoaderIcon() {
  return (
    <div
      // style={{
      //   transform: "rotate(360deg)",
      //   transition: "0.5s ease-in-out",
      // }}
      className="loader_icon"
    >
      <FeatherIcon icon="loader" size="20" />
    </div>
  );
}
