import {
  Box,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  OutlinedInputForFilter,
  GrayDivider,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import React from "react";

const DrawerFilter = React.memo(function FilterComponent({
  open,
  toggleDrawer,
}) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const filters = [
    { name: "Latest Applications", value: "latest" },
    { name: "Old Applications", value: "Old" },
    { name: "Top Applications", value: "BVC" },
  ];
  const anchor = "right";
  return (
    <Drawer anchor={anchor} open={open} onClose={toggleDrawer}>
      <Box
        sx={{ width: "50vw", padding: "30px" }}
        role="presentation"
        // onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <PrimaryTypography variant="h2" component="h2">
          Filter
        </PrimaryTypography>
        <GrayDivider />
        <Typography
          sx={{
            fontWeight: "normal",
          }}
        >
          Show data that match these conditions.
        </Typography>{" "}
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="selct-user-type">Sort By</InputLabel>
          <Select
            defaultValue="latest"
            labelId="selct-user-type"
            id="demo-multiple-name"
            input={
              <OutlinedInputForFilter sx={{ width: "200px" }} label="sort by" />
            }
            MenuProps={MenuProps}
          >
            {filters?.map(item => {
              return (
                <MenuItem
                  key={item?.value}
                  value={item?.value} // style={getStyles(name, personName, theme)}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Drawer>
  );
});
export default DrawerFilter;
