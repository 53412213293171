import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  Grid,
  createFilterOptions,
} from "@mui/material";
import {
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryNormalInputLabel,
  SecondaryFilledChip,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import {
  createJobLevelForEveryone,
  getAllJobLevlsWithoutFunctionalArea,
} from "helpers/services/jobAttributes/jobLevels";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ApproveModal({ open, handleClose }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  function onSubmit(data) {
    console.log(data);
  }
  const [jobLevel, setJobLevel] = React.useState([]);
  async function getAllJobLevels() {
    const data = await getAllJobLevlsWithoutFunctionalArea();
    setJobLevel(data?.map(item => ({ name: item.name, value: item.id })));
  }
  const jobRoleFilter = createFilterOptions();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <PrimaryAutoComplete
                  componentsProps={autocompleteProps}
                  single
                  error={errors.job_role}
                  value={watch("job_role")}
                  onChange={async (e, value) => {
                    if (value.inputValue) {
                      const { data } = await createJobLevelForEveryone({
                        name: value.inputValue,
                        functional_area_id: getValues("functional_area")?.value,
                      });

                      setValue("job_role", {
                        name: data.job_level_res.name,
                        value: data.job_level_res.id,
                      });
                      await getAllJobLevels();
                    } else {
                      setValue("job_role", value);
                    }
                  }}
                  disableCloseOnSelect
                  id="multiple-job-roles"
                  options={jobLevel}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<FeatherIcon icon="square" size="14" />}
                        checkedIcon={
                          <FeatherIcon icon="check-square" size="14" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = jobRoleFilter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={option => option?.name}
                  renderInput={params => {
                    return (
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor="job-role-outlined"
                          error={errors.job_role}
                        >
                          Job Role (select all that applies)
                        </PrimaryNormalInputLabel>
                        <MultipleSelectInputs
                          sx={{
                            ...(errors.job_role && { borderColor: "red" }),
                          }}
                          variant="standard"
                          ref={params.InputProps.ref}
                          {...params}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
