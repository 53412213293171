import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { map } from "lodash";
import Breadcrumbs from "components/Common/Breadcrumb";
import CardInvoice from "./card-invoice";
import { getInvoices } from "store/actions";
class InvoicesList extends Component {
  componentDidMount() {
    const { onGetInvoices } = this.props;
  }

  render() {
    const { invoices } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          {/* add meta title */}
          {/* <MetaTags>
            <title>Invoice List | Skote - React Admin & Dashboard Template</title>
          </MetaTags> */}
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Invoices" breadcrumbItem="Invoice List" />

            <Row>
              {map(invoices, (invoice, key) => (
                <CardInvoice data={invoice} key={"_invoice_" + key} />
              ))}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
};

const mapStateToProps = ({ invoices }) => ({
  invoices: invoices.invoices,
});

const mapDispatchToProps = dispatch => ({
  onGetInvoices: () => dispatch(getInvoices()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoicesList));
