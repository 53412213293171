const {
  Container,
  Row,
  Col,
  Card,
  Nav,
  NavItem,
  NavLink,
  Input,
} = require("reactstrap");
import React from "react";
import "../../assets/css/posts.css";
import "../../assets/css/email.css";
import RecentlyDeleted from "./RecentlyDeleted";
import QualfiedCandidates from "./sample";
import NewCandidates from "./NewCandidates";
import NotContacted from "./NotContactedCandidates";
import FollowedCandidates from "./FollowedCandidates";
import OverDue from "./OverDue";
import ReactSelectComponent from "components/Common/reactSelectComponent";

class Candy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      multi: [],
      buttons: [
        {
          name: "Recently Deleted",
          icon: "fas fa-users me-2",
        },
        {
          name: "Qualified Candidates",
          icon: "mdi mdi-email-check-outline",
        },
        {
          name: "New Candidates",
          icon: "mdi-trash-can-outline",
        },
        {
          name: " Not Contacted",
          icon: "fas fa-suitcase me-2",
        },
        {
          name: "Followed Candidates",
          icon: "fas fa-thumbs-down me-2",
        },
        {
          name: "OverDue",
          icon: "fas fa-globe-asia",
        },
      ],
    };
    this.handleSelectMulti = this.handleSelectMulti.bind(this);
  }
  handleSelectMulti = data => {
    this.setState({ multi: data });
  };
  render() {
    this.toggleTab = data => {
      this.setState({
        activeTab: data,
      });
    };
    return (
      <React.Fragment>
        <div className="page-content global_scroll">
          <Container fluid>
            {/* <Card>
              <CardBody> */}
            <Row className="justify-content-end">
              <Col xs={12}>
                <div className="posts_header">
                  <h5>Posts</h5>
                  {/* <button className="add_image">Add Posts</button> */}
                </div>
              </Col>
            </Row>

            <Row style={{ marginRight: "10px" }}>
              <Col md={3}>
                {/* Render Email SideBar */}
                <Card
                  className="report_sidebar global_scroll"
                  style={{
                    maxHeight: "80vh",
                    overflowY: "scroll",
                  }}
                >
                  <Button
                    type="button"
                    color="dark"
                    onClick={this.togglemodal}
                    block
                  >
                    New Dashboard
                  </Button>
                  <h6 className="mt-4">Essential dashboards</h6>

                  <div className="mail-list margin_bottom_sm mt-2">
                    <Nav
                      tabs
                      className="nav-tabs-custom"
                      vertical
                      role="tablist"
                    >
                      {this.state.buttons.map((item, index) => {
                        return (
                          <NavItem style={{ margin: "5px" }} key={index}>
                            <NavLink
                              style={{ padding: "10px" }}
                              className={`${
                                this.state.activeTab ===
                                  (index + 1).toString() && "active_data"
                              }`}
                              onClick={() => {
                                this.toggleTab((index + 1).toString());
                              }}
                            >
                              <i className={item.icon}></i> {item.name}
                              <span className="ml-1 float-end">
                                {Math.round(Number(index) * Math.random() * 10)}
                              </span>
                            </NavLink>
                          </NavItem>
                        );
                      })}
                    </Nav>
                  </div>

                  <h6 className="mt-2">Custom Dashboard</h6>
                  <div className="mail-list margin_bottom_sm mt-2">
                    <div
                      className="input_flex"
                      style={{
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      <Input type="checkbox" />
                      <span>Qualfied</span>
                    </div>

                    <div
                      className="input_flex"
                      style={{
                        // justifyContent: "space-between",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <Input type="checkbox" />
                      <span>DisQualified</span>
                    </div>

                    <div
                      className="input_flex"
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <Input type="checkbox" />
                      <span>New</span>
                    </div>

                    <div
                      className="input_flex"
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <Input type="checkbox" />
                      <span>OverDue</span>
                    </div>
                  </div>
                  <div className="mail-list margin_bottom_sm mt-4">
                    <div
                      className="input_flex"
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <i className="bx bxs-briefcase" />
                      <span>In Job</span>
                    </div>

                    <ReactSelectComponent />
                  </div>

                  <div className="mail-list margin_bottom_sm mt-4">
                    <div
                      className="input_flex"
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <i className="bx bxs-briefcase" />
                      <span>In Stage</span>
                    </div>

                    <ReactSelectComponent />
                  </div>

                  <div className="mail-list margin_bottom_sm mt-4">
                    <div
                      className="input_flex"
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <i className="bx bxs-briefcase" />
                      <span>Hash Tag</span>
                    </div>

                    <ReactSelectComponent />
                  </div>
                  <div className="mail-list margin_bottom_sm mt-4">
                    <div
                      className="input_flex"
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <i className="bx bxs-briefcase" />
                      <span>Hash Source Tag</span>
                    </div>

                    <ReactSelectComponent />
                  </div>

                  {/* <h6 className="mt-4">Incoming CVs/Resumes</h6> */}

                  <div className="mail-list margin_bottom_sm mt-1"></div>
                </Card>
              </Col>
              <Col md={9} className="eighty_vh_max_child">
                {this.state.activeTab === "1" && <RecentlyDeleted />}

                {this.state.activeTab === "2" && <QualfiedCandidates />}
                {this.state.activeTab === "3" && <NewCandidates />}
                {this.state.activeTab === "4" && <NotContacted />}
                {this.state.activeTab === "5" && <FollowedCandidates />}
                {this.state.activeTab === "6" && <OverDue />}
              </Col>
            </Row>
            {/* </CardBody>
            </Card> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Candy;
