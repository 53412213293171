import React from "react";
import { Col, Container, Row } from "reactstrap";
import "../../../assets/css/partner_jobs.css";
import "../../../assets/css/assigned.css";
import { useHistory } from "react-router-dom";
import SingleJobApplicantTable from "./singleJobApplicantTable";

export default function ApplicantsOfSingleJob({ handleNavBar, navBar }) {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="">
            <Row className="">
              <Col xs={12}>
                <SingleJobApplicantTable />
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
