import callApi from "helpers/callApi";

export const addCommissions = async data => {
  return await callApi("/commissions", data, "POST");
};

export const getCommissions = async data => {
  return await callApi("/commissions");
};
export const updateCommission = async (id, data) => {
  await callApi(`/commissions/${id}`, data, "PUT");
};
