import * as React from "react";
import { Grid, ListItemButton, ListItemText } from "@mui/material";
import {
  ExtraSmallTypography,
  PrimaryPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
  GreyTypography,
} from "assets/Mui/globalTheme";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ClipBoardData from "helpers/dashboard/clipboard";
import { CA, ZA } from "common/constants/userModules";
import UserInformationGrid from "./userInformationGrid";
const ChatUserPopUp = React.memo(function UserChatPoP({
  handleClose,
  anchorEl,
}) {
  const user = useSelector(state => state?.Login);
  const open = Boolean(anchorEl);
  const id = open ? "chat-user-popover" : undefined;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 350,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };

  return (
    <>
      <PrimaryPopUp
        id={id}
        open={open}
        PaperProps={MenuProps}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <StyledProfilePopText sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {UserInformationGrid({
              type: "referrer",
              user: "Saikrishna Vanneldas",
            })}
            {UserInformationGrid({
              type: "Screening Partner",
              user: "Saikrishna Vanneldas",
            })}
            {UserInformationGrid({
              type: "Moderator",
              user: "Saikrishna Vanneldas",
            })}
            {UserInformationGrid({
              type: "Technical Evaluator",
              user: "Saikrishna Vanneldas",
            })}
            {UserInformationGrid({
              type: "Background Verification",
              user: "Saikrishna Vanneldas",
            })}
          </Grid>
        </StyledProfilePopText>
      </PrimaryPopUp>
    </>
  );
});
export default ChatUserPopUp;
