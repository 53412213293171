import {
  Button,
  CssVarsProvider,
  FormLabel,
  Input,
  Option,
  Select,
  Sheet,
} from "@mui/joy";
import CommissionForms from "components/commission/forms";
import { addCommissions, getCommissions } from "helpers/services/commissions";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function Commissions() {
  const [commissions, setCommissions] = useState([]);
  useEffect(() => {
    initialFetch();
  }, []);
  const initialFetch = async () => {
    const { data, status } = await getCommissions();
    console.log(data, "data");
    if (status === 200) setCommissions(data?.data);
   
  };
  const referOptions = [
    { name: "Refer Self", value: "refer_self" },
    { name: "Refer Now", value: "refer_now" },
    { name: "Source", value: "source" },
  ];

  return (
    <div className="page-content">
      <CssVarsProvider>
        {referOptions?.map(item => (
          <CommissionForms
            key={item?.value}
            item={item}
            commissions={commissions}
          />
        ))}
      </CssVarsProvider>
    </div>
  );
}
