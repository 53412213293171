import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
// import AnyButton from "editorjs-button";
let items;
export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  marker: Marker,
  list: List,
  //   AnyButton: AnyButton,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          const myJavs = {
            upload: async function (data) {
              await fetch(
                "https://backend.eazyrooms.com/aws_utils/pre_signed_url/",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY4NTg0MTczLCJpYXQiOjE2NjU5OTIxNzMsImp0aSI6IjMzYWU0YjIyYmQyYjQ1MTNiYWY0YTg1MWQxMGNjYjM2IiwiaWQiOiJmOTgxZTJmOS0xNDUzLTRlMGUtYjhiYi1hNTBmMzhjYTU2MWMifQ.AI3DrufS_r9sqwBBgGvGuYvn9RHzudgMQgyWcFzqC6g",
                  },
                  body: JSON.stringify({
                    content_type: data.type,
                  }),
                }
              )
                .then(req => {
                  return req;
                })
                .then(async req => {
                  const response = await req.json();
                  const raw = await req;
                  await fetch(response.url, {
                    method: "PUT",
                    body: data,
                  })
                    .then(req => {
                      return req;
                    })
                    .then(async res => {
                      items = await response.url.split("?");

                      return {
                        success: 1,
                        file: {
                          url: items[0],
                        },
                      };
                    });
                });
            },
          };
          return myJavs.upload(file).then(data => {
            return {
              success: 1,
              file: {
                url: items[0],
              },
            };
          });
        },
      },
    },
  },
  raw: Raw,
  header: Header,
  quote: Quote,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
};
