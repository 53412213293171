import { WS_URL, get_chat_active_user_list } from "helpers/services/sockets";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";

export default function SocketConnection() {
  const user = useSelector(state => state?.Login);
  const [status, setStatus] = useState(true);
  const [socketURL, setSocketURL] = useState(null);

  // useEffect(()=>{
  //   if (Boolean(user?.id))
  //   (async () => {
  //     const activeUsersList = await get_chat_active_user_list();
  //     const extractMatchId = activeUsersList?.data?.filter(
  //       each => each.userId === user?.id
  //     );
  //     // console.log(activeUsersList,'v from admin');
  //     if (extractMatchId?.length > 0) {
  //       const connectionId = extractMatchId[0]?.connectionId; // Assuming connectionId is present in the first element of extractMatchId
  //       setSocketURL(WS_URL + user?.id + `&connectionId=${connectionId}`);
  //     } else {
  //       setSocketURL(WS_URL + user?.id);
  //     }
  //   })();
  //   // setSocketURL(WS_URL + user?.id);
  // else {
  //   setStatus(false);
  //   setSocketURL(null);
  // }
  // },[socketURL])
  useEffect(() => {
    if (Boolean(user?.id))
      (async () => {
        const activeUsersList = await get_chat_active_user_list();
        const extractMatchId = activeUsersList?.data?.filter(
          each => each.userId === user?.id
        );
       
        if (extractMatchId?.length > 0) {
          const connectionId = extractMatchId[0]?.connectionId; // Assuming connectionId is present in the first element of extractMatchId
          setSocketURL(WS_URL + user?.id + `&connectionId=${connectionId}`);
        } else {
          setSocketURL(WS_URL + user?.id);
        }
      })();

    else {
      setStatus(false);
      setSocketURL(null);
    }
  }, [user?.id]);

  
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketURL,
    {
      share: true,
      shouldReconnect: () => true,
    },
    status
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  // console.log(connectionStatus,'veda status');
  return {
    connectionStatus,
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket,
  };
}
