const { Row, Col, Card, Table, Input, Alert } = require("reactstrap");
import React from "react";
import "../../assets/css/posts.css";
class QualfiedCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalCheck: false,
      tableContents: [
        { name: "Show", checked: false },
        { name: "language", checked: false },
        { name: "Post Type", checked: false },
        { name: "Author", checked: false },
        { name: "Category", checked: false },
        { name: "Sub Category", checked: false },
        { name: "Search", checked: false },
      ],
      filter: [
        { name: "Show" },
        { name: "language" },
        { name: "Post Type" },
        { name: "Author" },
        { name: "Category" },
        { name: "Sub Category" },
        { name: "Search" },
      ],
      filterContent: [
        { items: [{ name: "Italian" }, { name: "Spanish" }] },
        { items: [{ name: "English" }, { name: "Koean" }] },
        { items: [{ name: "Member" }, { name: "Admin" }, { name: "Manager" }] },
        {
          items: [
            { name: "John Doe" },
            { name: "Jane Doe" },
            { name: "Maria Doe" },
          ],
        },
        { items: [{ name: "English" }, { name: "Koean" }] },
        {
          items: [
            { name: "US" },
            { name: "UK" },
            { name: "north korea" },
            { name: "South Korea" },
          ],
        },
        { items: [{ name: "Australia" }, { name: "France" }] },
      ],
      longFilterItem: false,
      filterItem: -1,
    };
  }
  render() {
    return (
      <Row>
        <Col xs={12}>
          <Card
            style={{
              padding: "20px",
            }}
          >
            <div className="flex_btns">
              <div className="alert_small " style={{ width: "300px" }}>
                <Alert color="success" className="alert_small_comp">
                  <p className="bold_success">Qualified candidates</p>
                </Alert>
              </div>{" "}
              <Input
                type="text"
                placeholder="search candidates by anything or use keywords"
              />
            </div>
          </Card>
        </Col>
        <Col
          xs={12}
          className="global_scroll"
          style={{
            maxHeight: "80vh",
            minHeight: "80vh",
            overflowY: "scroll",
            // height: "auto",
          }}
        >
          <Card
            style={{
              paddingTop: "20px",
            }}
          >
            <div className="table-responsive">
              <Table className="table mb-0">
                <caption>List of Posts</caption>
                <thead>
                  <tr>
                    <th>
                      <Input
                        type="checkbox"
                        onClick={() => {
                          const tableContent = [...this.state.tableContents];
                          tableContent.map(item => {
                            item.checked = !item.checked;
                          });
                          this.setState({
                            tableContents: tableContent,
                          });
                        }}
                      />
                    </th>
                    <th>Name</th>
                    <th>Average Score</th>
                    <th>Job</th>
                    <th>Stage</th>
                    <th>Job Fit Score</th>
                    <th>Profile Created</th>
                    <th>Talent Pool</th>
                  </tr>
                </thead>
                {this.state.tableContents.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td className="multi_flex">
                        <Input
                          type="checkbox"
                          checked={item.checked}
                          onClick={() => {
                            const data = [...this.state.tableContents];
                            data[index].checked = !data[index].checked;
                            this.setState({ tableContents: data });
                          }}
                        />{" "}
                        <img
                          className="small_img"
                          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                        />
                        <span>Michael Lang </span>
                      </td>
                      <td scope="row">1</td>
                      <td className="">
                        <span>--</span>
                      </td>
                      <td>Recruiter (Sample)</td>
                      <td>Offer</td>
                      <td>
                        <span className="">_</span>
                      </td>
                      <td>20 days Ago</td>
                      <td>
                        {" "}
                        <span className="">_</span>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default QualfiedCandidates;
