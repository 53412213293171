import freelance from "../../assets/images/freelance.png";
const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" },
    ],
  },
];
const includeRemote = [
  {
    label: "Work Type",
    options: [
      { label: "Include Remote", value: "include-remote" },
      { label: "only Remote", value: "only-remote" },
      { label: "only on-site", value: "only-on-site" },
    ],
  },
];
const jobType = [
  {
    label: "Work Type",
    options: [
      { label: "all", value: "all" },
      { label: "Contract", value: "only-remote" },
      { label: "fixed", value: "fixed" },
      { label: "payroll", value: "payroll" },
    ],
  },
];
export const workMode = [
  {
    label: "Work Mode",
    options: [
      { label: "Full Time", value: "Full_Time" },
      { label: "Part Time", value: "Part_Time" },
      { label: "Contract", value: "Contract" },
      { label: "Freelance", value: "Freelance" },
    ],
  },
];
export const departement = [
  {
    label: "Work Departement",
    options: [
      { label: "IT", value: "IT" },
      { label: "Sales", value: "Sales" },
      { label: "Marketing", value: "Marketing" },
      { label: "Production", value: "Production" },
    ],
  },
];

export const location = [
  {
    label: "Work Location",
    options: [
      { label: "Hyderabad", value: "Hyderabad" },
      { label: "Bengaluru", value: "Bengaluru" },
      { label: "Pune", value: "Pune" },
      { label: "Delhi", value: "Delhi" },
    ],
  },
];
export const source = [
  {
    label: "Source",
    options: [
      { label: "Linkedin", value: "Linkedin" },
      { label: "Naukri", value: "Naukri" },
      { label: "Internshala", value: "Internshala" },
      { label: "Indeed", value: "Indeed" },
    ],
  },
];
export const events = [];
export const education = [
  {
    label: "Education",
    options: [
      { label: "Graduation Not required", value: "Graduation Not required" },
      { label: "Graduate", value: "Graduate" },
      { label: "PostGraduate", value: "PostGraduate" },
      { label: "Doctrate/PhD", value: "Doctrate/PhD" },
    ],
  },
];
export const skills = [
  {
    label: "Skills",
    options: [
      { label: "Python", value: "Python" },
      { label: "Django", value: "Django" },
      { label: "Fast Api", value: "Fast Api" },
      { label: "Node Js", value: "Node Js" },
    ],
  },
];
export const companySize = [
  {
    label: "size",
    options: [
      { label: "1-50", value: "1-50" },
      { label: "50-100", value: "50-100" },
      { label: "100-200", value: "100-200" },
    ],
  },
];
export const year = [
  {
    label: "size",
    options: [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
    ],
  },
];
export const FunctionalArea = [
  {
    label: "Functional Area",
    options: [
      { label: "IT", value: "IT" },
      { label: "Software", value: "Software" },
      { label: "Chemicals", value: "Chemicals" },
      { label: "Development", value: "4Development" },
    ],
  },
];
export { optionGroup, includeRemote, jobType };

export const jobModes = [
  {
    id: 1,
    img: "https://optimhire.com/assets/img/employee.svg",
    data: "Full_Time",
    name: "Full Time",
    selected: false,
    infotext: "Perfect for permanent hires",
    modelhed: "How does “Hire as your Employee” work?",
    modelText:
      "When you want to hire candidates under your payroll, we recommend you to use “Hire an Employee”. Payments and Benefits are offered as your company rules and engagement with the Candidate is fully under your control.",
  },
  {
    id: 2,
    selected: false,
    data: "Part_Time",
    img: "https://optimhire.com/assets/img/team.svg",
    name: "Part Time",
    infotext: "Hire new talent as contractor",
    modelhed: "How does “Hire as Part Time” work?",
    modelText:
      "When you want to hire a Freelancer or an agency on Hourly Contractor. The Contractor submits a Weekly Timesheet and you only pay for the Hours approved by you. Under this engagement, you pay OptimHire and OptimHire releases payment to the Contractor deducting a platform Fee of 8 to 15%. Hiring a Contractor is very beneficial for short-term contracts, as OptimHire deals with all Invoicing headaches.",
  },
  {
    selected: false,
    id: 3,
    img: "https://optimhire.com/assets/img/Group1.svg",
    data: "Contract",
    name: "Contract",
    infotext: "Hire new talent as contractor",
    modelhed: "How does “Fixed-Price Contract” work?",
    modelText:
      "Say you have a tightly defined scope of work, then it is recommended you hire on “Fixed-Price Contract”. After you post the Job with all project details, Contractors will bid on the project. Next, you can Interview and select the best Contractor. Here the payment is released as per Milestones.",
  },
  {
    id: 4,
    data: "Freelance",
    img: freelance,
    selected: false,
    name: "Freelance",
    infotext: "Hire new talent as Freelance",
    modelhed: "How does “Fixed-Price Contract” work?",
    modelText:
      "Say you have a tightly defined scope of work, then it is recommended you hire on “Fixed-Price Contract”. After you post the Job with all project details, Contractors will bid on the project. Next, you can Interview and select the best Contractor. Here the payment is released as per Milestones.",
  },
];
