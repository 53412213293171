import callApi from "helpers/callApi";

export async function viewSingleRefaralPartner(id) {
  return await callApi(`referral_partner_authorise/${id}`);
}
export const viewSingleUserDetailsInKanbanBoard = async (uid, job_post_id) => {
  return await callApi(
    `referral_partner_authorise/get_job_post_details?user_id=${uid}&job_post_id=${job_post_id}`
  );
};
export const getAllReferalParterns = async (path) => {
  // return await callApi("referral_partner_authorise");
  return await callApi(`referral_partner_authorise?type=${path}`);
};


