import {
  GreyTypography,
  StyledPaperForKanban,
  StyledParagraph,
  StageSearchInput,
} from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDrop } from "react-dnd";
import UserJobCard from "./userCard";
import Overlay from "./overlay";
import { deepOrange, grey, red, teal } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import colorCodes from "./colorcodes";
export default function DropTargets({
  newItem,
  index,
  checkDropLocation,
  handleDrop,

}) {
  const [color, setColor] = useState("");
  const [search, setSearch] = useState("");
  const [openSearch, setOpenSearch] = useState(true);
  const [searchedList, setSearchedList] = useState(
    newItem?.user_job_posts_list
  );

  const user = useSelector(state => state.Login);
  useEffect(() => {
    colorCodes(newItem?.stage_order, user.role);
  }, [newItem?.stage_order]);
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "KANBAN",
      drop: item => handleDrop(newItem?.stage_order, newItem?.stage_id, item),
      canDrop: item =>
        checkDropLocation(newItem?.stage_order, newItem?.stage_id, item),
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [newItem?.stage_order]
  );

  // console.log(newItem,"newItem")
  /*Testing Search functionality code start here*/
  const handleSearch = event => {
    const value = event.target.value;
    setSearch(value);
    if (value.trim() !== "") {
      const filteredCards = newItem?.user_job_posts_list?.filter(item => {
        const firstName = item?.first_name?.toLowerCase() || "";
        const lastName = item?.last_name?.toLowerCase() || "";
        const location = item?.location?.toLowerCase() || "";
        const email = item?.email?.toLowerCase() || "";

        return (
          firstName.includes(value.toLowerCase()) ||
          lastName.includes(value.toLowerCase()) ||
          location.includes(value.toLowerCase()) ||
          email.includes(value.toLowerCase())
        );
      });
      setSearchedList(filteredCards);
    } else {
      setSearchedList(newItem?.user_job_posts_list);
    }
  };
  /*Testing Search functionality code End here*/

  /* Search toggle effect */
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
  };
  /* Search toggle effect end */

  return (
    <div
      className=""
      style={{ cursor: newItem?.isDisabled ? "not-allowed" : "auto" }}
    >
      <Stack
        direction="row"
        sx={{ marginBottom: "10px" }}
        spacing={2}
        alignItems={"center"}
      >
        <div
          className="board_access_indicator"
          style={{
            background: "#081753",
          }}
        ></div>{" "}
        <GreyTypography
          variant="h4"
          sx={{
            // marginBottom: "10px",
            marginLeft: "20px",
          }}
        >
          {newItem?.stage_name}
        </GreyTypography>
        <StyledParagraph>
          {newItem?.user_job_posts_list?.length}
        </StyledParagraph>
      </Stack>{" "}
      <StyledPaperForKanban
        sx={{
          ...(isOver && canDrop && { background: "rgba(8,23,83,0.3)" }),
          ...(isOver && !canDrop && { background: red[50] }),
          ...(!isOver && canDrop && { background: teal[50] }),
          "::-webkit-scrollbar": {
            display: "none",
          },
          opacity: newItem?.isDisabled ? 0.4 : 1, // Reduce opacity if disabled
          pointerEvents: newItem?.isDisabled ? "none" : "auto", // Disable pointer events if disabled
        }}
        ref={drop}
        key={index}
        elevation={0}
      >
        {/* <Stack>
          <StageSearchInput
            sx={{ display: newItem?.isDisabled ? "none" : "flex" }}
            onChange={handleSearch}
          />
        </Stack> */}
        <Stack>
          {openSearch ? (
            <SearchIcon
              onClick={handleSearchOpen}
              sx={{
                // display: newItem?.isDisabled ? "none" : "inline",
                alignSelf: "end",
                margin: "2px",
              }}
            />
          ) : (
            <StageSearchInput
              placeholder="search..."
              sx={{ display: newItem?.isDisabled ? "none" : "flex" }}
              onChange={handleSearch}
              endAdornment={
                <IconButton edge="end" onClick={handleSearchOpen}>
                  <ClearIcon />
                </IconButton>
              }
            />
          )}
        </Stack>

        {/* {newItem?.user_job_posts_list?.map(data => ( */}
        {searchedList?.map(data => (
          <UserJobCard
            key={data?.id}
            order={newItem?.stage_order}
            stage_name={newItem?.stage_name}
            user_data={data}
          ></UserJobCard>
        ))}
        {/* {isOver && !canDrop && <Overlay color="red" />}
        {!isOver && canDrop && <Overlay color="yellow" />}
        {isOver && canDrop && <Overlay color="green" />} */}
      </StyledPaperForKanban>
    </div>
  );
}
