import axios from "axios";

export const resumeParser = async (file, token) => {
  const formData = new FormData();

  formData.append("resumecontent", file);
  formData.append("Authorization", token);

  return await axios({
    method: "POST",
    url: "https://zepul-resume-parser.codeace.us/home/resume_parser_details",
    data: formData,
  });
};
