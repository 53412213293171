const INIT_STATE = {
  jobLevel: {},
};

const JobLevels = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADDED_JOB_LEVEL":
      return {
        ...state,
        jobLevel: action.payload,
      };

    default:
      return state;
  }
};

export default JobLevels;
