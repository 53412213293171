import { DELETE, POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

const { getAllFunctionalArea } = require("./functionalArea");

export async function getAllJobLevels() {
  const results = [];
  const items = await getAllFunctionalArea();
  await items?.map(async item => {
    const data = await callApi(
      "job_attributes/job_level/list",
      {
        functional_area_id: item.id,
      },
      POST
    );
    await results.push(...data.data);
  });
  return results;
}
export async function getAllJobLevlsWithoutFunctionalArea() {
  const data = await callApi("job_attributes/job_level");
  return data.data;
}
export async function getAllJobLevelsBasedonFunctionalArea(id) {
  if (id === undefined) return;
  const data = await callApi(
    "job_attributes/job_level/list",
    {
      functional_area_id: id,
    },
    POST
  );
  return data.data;
}

export async function getSingleJobLevel(id) {
  if (id === undefined) return;
  const data = await callApi(`job_attributes/job_level/${id}`);
  return data.data;
}
export async function deleteJobLevel(id) {
  if (id === undefined) return;

  const data = await callApi(`job_attributes/job_level/${id}`, {}, DELETE);
  return data.data;
}
export async function updateJobLevel(incomingData, id, fn_id) {
  if (id === undefined) return;
  const data = await callApi(
    `job_attributes/job_level/${id}`,
    {
      name: incomingData.name,
      functional_area_id: fn_id,
      is_active: incomingData.is_active,
      country: "INDIA",
    },
    PUT
  );
  return data.data;
}
export async function createJobLevel(incomingData) {
  const data = await callApi(
    "job_attributes/job_level",
    {
      name: incomingData.name,
      functional_area_id: incomingData.functional_area_id,
      country: "INDIA",
    },
    POST
  );
  if (data.status !== 201) throw new Error("Error in creating job level");

  return data.status;
}

export async function createJobLevelForEveryone(incomingData) {
  return await callApi(
    "job_attributes/job_level",
    {
      name: incomingData.name,
      functional_area_id: incomingData.functional_area_id,
      country: "INDIA",
    },
    POST
  );
}
