import { Stack, Typography,Tooltip } from "@mui/material";
import {SkillEvaluation,QuestionEvaluation} from "../Results";
import { PrimaryChip,deleteIconContainer } from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { CircularProgress} from "@mui/material";
import AddASkill from "./newSkillModal";
import { useState } from "react";
import AddAQuestion from "./newQuestionModal";
import noDataFound from '../../../../../assets/images/zepul/no_data.svg'
import SearchParamHook from "hooks/searchParams";
import { useSelector } from "react-redux";
import { TE, SP,MODERATOR} from "common/constants/userModules";

function evaluationId(role,candidate) {
  let evalId;
  switch (role) {
    case SP:
      evalId = candidate?.screening_partner_id
      break;
    case TE:
      evalId = candidate?.evaluation_id
      break;
    default:
      evalId = candidate?.moderator_id
  }
  return evalId
}


export default function ScreeningEvaluation({
  loading,
  skillData,
  updateSkillHandler,
  deleteSkillHandler,
  questionData,
  createSkillHandler,
  updateQuestionHandler,
  deleteQuestionHandler,
  createQuestionHandler,
  // readOnly,
  type
}) {
  const searchParams = SearchParamHook()
  const [skillModal,setSkillModal] = useState(false)
  const [questionModal,setQuestionModal] = useState(false)
  const { candidate } = useSelector(state => state.Candidates);
  const stage = searchParams?.get("stage_name");
  const isRead = ['On boarded','Rejected'].includes(stage)
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const evaluatorId = JSON.parse(localStorage.getItem("authUser")).id;
  const evlId = evaluationId(role,candidate?.user_job_post_details)
  const readOnly = role === type
  // console.log(evlId,role,type,candidate?.user_job_post_details,"v");
  // console.log(evaluatorId);
  // console.log(role);
  // console.log(candidate?.user_job_post_details);

  return (
    <Stack  direction={"row"} spacing={1}>
      <Stack style={{overflowY:'scroll',height:'calc(100vh - 370px)'}} flex={1} borderRight={"1px solid #ccc"} spacing={1}>
        <Stack
          paddingRight={1}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography>Skills</Typography>
          {/* {readOnly && <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Skill"
            onClick={()=>setSkillModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />} */}
          {isRead && readOnly ? 
          <Tooltip title={"Sorry it's in Rejection Stage"}>
            <div>
            <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Skill"
            disabled
            onClick={()=>setSkillModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />
            </div>
          </Tooltip>
          : (evlId === null && readOnly) ?
          readOnly && <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Skill"
            onClick={()=>setSkillModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />:(evlId !== evaluatorId && readOnly) ?
          <Tooltip title={"Evaluation in progress"}>
          <div>
            <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Skill"
            disabled
            onClick={()=>setSkillModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />
          </div>
        </Tooltip>:          
        readOnly && <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Skill"
            onClick={()=>setSkillModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />
          }
          {/* {((!isRead && role === type && (evlId !== null || evlId !== evaluatorId)&& (evlId == null || evlId == evaluatorId))) && 
          <Tooltip title={"Evaluation in progress"}>
            <div>
              <PrimaryChip
              color="primary"
              variant="filled"
              label="Add Skill"
              disabled
              onClick={()=>setSkillModal(true)}
              icon={
                <div style={deleteIconContainer}>
                  <FeatherIcon icon="plus" size="14" />
                </div>
              }
            />
            </div>
          </Tooltip>
          } */}
        </Stack>
        {skillModal && <AddASkill open={skillModal} updateHandler={createSkillHandler} toggleModel={()=>setSkillModal(!skillModal)} />}
        {loading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <CircularProgress />
          </div>
        ) : skillData?.length > 0 ? (
          skillData?.map((data, index) => {
            return (
              <SkillEvaluation
                key={data.evaluateId}
                updateSkillHandler={updateSkillHandler}
                deleteSkillHandler={deleteSkillHandler}
                skill={data}
                readOnly={isRead ? isRead: evlId === null && readOnly ? !readOnly: evlId !== evaluatorId && readOnly? readOnly : !readOnly}
              />
            );
          })
        ) : (
            <img style={{alignSelf:'center'}} src={noDataFound} alt="no_data" width={150} height={150} />

        )}
      </Stack>

      <Stack style={{overflowY:'scroll',height:'calc(100vh - 370px)'}} flex={1} spacing={1}>
      <Stack
          paddingRight={1}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography>Questions</Typography>

          {/* {readOnly && <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Question"
            onClick={()=>setQuestionModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />} */}
          {isRead && readOnly ?
          <Tooltip title={"Sorry it's in Rejection Stage"}>
            <div>
              <PrimaryChip
              color="primary"
              variant="filled"
              label="Add Question"
              disabled
              onClick={()=>setQuestionModal(true)}
              icon={
                <div style={deleteIconContainer}>
                  <FeatherIcon icon="plus" size="14" />
                </div>
              }
            />
            </div>
          </Tooltip>
          : (evlId === null && readOnly)?
          readOnly && <PrimaryChip
            color="primary"
            variant="filled"
            label="Add Question"
            onClick={()=>setQuestionModal(true)}
            icon={
              <div style={deleteIconContainer}>
                <FeatherIcon icon="plus" size="14" />
              </div>
            }
          />:(evlId !== evaluatorId && readOnly) ?
          <Tooltip title={"Evaluation in progress"}>
            <div>
              <PrimaryChip
              color="primary"
              variant="filled"
              label="Add Question"
              disabled
              onClick={()=>setQuestionModal(true)}
              icon={
                <div style={deleteIconContainer}>
                  <FeatherIcon icon="plus" size="14" />
                </div>
              }
            />
            </div>
          </Tooltip>:
                    readOnly && <PrimaryChip
                    color="primary"
                    variant="filled"
                    label="Add Question"
                    onClick={()=>setQuestionModal(true)}
                    icon={
                      <div style={deleteIconContainer}>
                        <FeatherIcon icon="plus" size="14" />
                      </div>
                    }
                  />
          }
          {/* {((!isRead && role === type && evaluateId !== evaluatorId && evaluateId !==null)) &&
          <Tooltip title={"Evaluation in progress"}>
            <div>

              <PrimaryChip
              color="primary"
              variant="filled"
              label="Add Question"
              disabled
              onClick={()=>setQuestionModal(true)}
              icon={
                <div style={deleteIconContainer}>
                  <FeatherIcon icon="plus" size="14" />
                </div>
              }
            />
            </div>
          </Tooltip>
          } */}
        </Stack>
        {questionModal && <AddAQuestion open={questionModal} updateHandler={createQuestionHandler} toggleModel={()=>setQuestionModal(!questionModal)} />}
        {loading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <CircularProgress />
          </div>
        ) : questionData?.length > 0 ? questionData?.map((data) => {
          return <QuestionEvaluation 
          readOnly={isRead ? isRead: evlId === null && readOnly ? !readOnly: evlId !== evaluatorId && readOnly? readOnly : !readOnly}
          key={data.evaluateId} evType={type} question={data} updateQuestionHandler={updateQuestionHandler} deleteQuestionHandler={deleteQuestionHandler} />;
        }):
        <img style={{alignSelf:'center'}} src={noDataFound} alt="no_data" width={150} height={150} />
        }
      </Stack>
    </Stack>
  );
}
