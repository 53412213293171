import React from "react";
import { Alert, Card, Col, Input, Row } from "reactstrap";

class RecentlyDeleted extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Col xs={12}>
          <Row>
            <Card
              style={{
                padding: "20px",
              }}
            >
              {/* <h5>Recently Deleted</h5>
              <p>
                Candidates can be restored for 7 days after deletion. After that
                time, they will be permanently deleted. How to restore deleted
                candidates?
              </p> */}
              <div className="flex_btns">
                <div className="alert_small ">
                  <Alert color="danger" className="alert_small_comp">
                    <p className="bold_success">Recently Deleted</p>
                  </Alert>
                </div>{" "}
                <Input
                  type="text"
                  placeholder="search candidates by anything or use keywords"
                />
              </div>
            </Card>
          </Row>
          <Row>
            <div
              className="email_brand_div new_calender_grid"
              style={{ minHeight: "65vh" }}
            >
              <div className="calender_contents">
                <i
                  className="dripicons-align-justify
"
                />
                <h5>No Deleted Candidates</h5>
                <p>Any deleted candidates will appear here.</p>
                {/* <Button color="primary">Sync Calendar</Button> */}
              </div>
            </div>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

export default RecentlyDeleted;
