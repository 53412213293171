import { Grid, Paper, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputAdornment, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import {
  getLoggedInCompanyDetails,
  updateCompanies,
} from "helpers/services/companies/company";
import { useEffect, useState } from "react";
import findParticularUserByRole from "helpers/services/CommonUser/findUserbasedOnType";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalInputs,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import ComingSoonImage from "../../../assets/images/zepul/coming_soon.png";
import ComingSoon from "pages/comingSoon";
export default function PhoneChange() {
  const user = useSelector(state => state.Login);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    unregister,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    initailFetch();
  }, []);
  const [company, setCompany] = useState({});
  async function initailFetch() {
    const data = await findParticularUserByRole();
    setCompany(data);
  }
  function handleGeneral(data) {
    updateCompanies({
      first_name: user?.first_name,
      last_name: data?.last_name,
      email: user?.email,
      company_image: company?.profile_image,
      phone_number: data?.phone_number,
      update_type_to_send_email: "phone_number",
    });
  }
  function handleEmail(data) {
    updateCompanies({
      first_name: user?.first_name,
      last_name: data?.last_name,
      email: user?.email,
      company_image: company?.profile_image,
      email: data?.email,
      update_type_to_send_email: "phone_number",
    });
  }
  return (
    <Paper elevation={"0"} sx={{ padding: "20px" }}>
      {/* <Stack direction="row" spacing={2}>
        <Form onSubmit={handleSubmit(handleGeneral)} style={{ width: "50%" }}>
          <PrimaryNormalInputs
            placeholder="enter your phone Number"
            {...register("phone_number", { required: true })}
            id="phone_number-outlined"
            label="phone_number"
            sx={{ width: "100%" }}
            type={"text"}
            error={errors.phone_number}
            variant="outlined"
          />

          <div className="d-flex justify-content-end mt-2">
            <PrimaryButton type="submit" sx={{ width: "150px" }}>
              Save Phone
            </PrimaryButton>
          </div>
        </Form>
        <Form style={{ width: "50%" }} onSubmit={handleSubmit(handleEmail)}>
          <Stack direction="row" spacing={2}>
            <PrimaryNormalInputs
              {...register("email", { required: true })}
              id="email-outlined"
              label="email"
              type={"email"}
              sx={{ width: "100%" }}
              placeholder={"enter your email"}
              error={errors.email}
              variant="outlined"
            />
          </Stack>

          <div className="d-flex justify-content-end mt-2">
            <PrimaryButton type="submit" sx={{ width: "150px" }}>
              Save Email
            </PrimaryButton>
          </div>
        </Form>
      </Stack> */}
      <ComingSoon />
    </Paper>
  );
}
