import { toast } from "react-hot-toast";

export default function ErrorHandlingToaster(data) {
  switch (data.status) {
    case 422:
      if (typeof data === "string") {
        toast.error(data);
      }
      toast.error(data?.data);
      break;
    case 400:
      if (typeof data?.data === "string") {
        toast.error(data?.data);
        break;
      }
      toast.error(data?.data?.message);
      break;
    case 500:
      toast.error("Internal Server Error");
      break;
    case 401:
      toast.error("Your Session got Expired Please Login Again");
    case 504:
      toast.error("Gateway Timeout: The server is taking too long to respond.");
    default:
      ""
      break;
  }
}
