const { Container, Row, Col } = require("reactstrap");
import React from "react";
import MainPage from "./mainPage";
import TalentFilter from "./talentFilter";
class TalentPoolContents extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col md={3}>
                <TalentFilter />
              </Col>
              <Col md={9}>
                <MainPage />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TalentPoolContents;
