import React from "react";
import {
  Card,
  CardBody,
  Col,
  Label,
  Row,
  Input,
  Container,
  CardFooter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import "../SettingCss/EditProfile.css";
import { AvField, AvForm } from "availity-reactstrap-validation";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import ReactSelect from "react-select";
import FunctionalArea from "pages/JobAttributes/functionalArea";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import UploadModel from "components/Common/models/uploadModel";
import { Editor } from "react-draft-wysiwyg";
const ZepulProfileEditModel = props => {
  const [phone, setPhone] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [modelOpen, setModelOpen] = useState(false);

  const toggleModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setProfileImg(url);
    toggleModel();
  };

  function handleValidSubmit(event, values) {
  }

  return (
    <div>
      <UploadModel
        isOpen={modelOpen}
        toggle={toggleModel}
        handleImage={handleImageUpload}
      />
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="profile_edit_model_main"
      >
        <ModalHeader
          toggle={props.toggle}
          className="profile_edit_model_header"
        >
          <p className="profile_edit_model_header_text p-2">
            Edit your profile
          </p>
        </ModalHeader>
        <ModalBody>
          <div className="edit_profile_card_page">
            <div>
              <Row className="profile_feild_cont profile_feild_border">
                <Col xs={12} className="editprofile_feild_cont">
                  <Row className="mb-4">
                    <Col
                      xs={3}
                      sm={12}
                      md={4}
                      className="editprofile_upper_feild"
                    >
                      <img
                        src={
                          profileImg !== ""
                            ? profileImg
                            : "https://mpng.subpng.com/20180323/pww/kisspng-computer-icons-clip-art-profile-cliparts-free-5ab5a47b02ff75.0880050915218535630123.jpg"
                        }
                        alt=""
                        className="editprofile_image"
                      />
                    </Col>
                    <Col
                      xs={4}
                      sm={8}
                      md={4}
                      className="pt-4 editprofile_upper_feild"
                    >
                      <Button color="primary" onClick={toggleModel}>
                        {" "}
                        Upload new picture{" "}
                      </Button>
                    </Col>
                    <Col
                      xs={3}
                      sm={4}
                      md={3}
                      className="pt-4 editprofile_upper_feild"
                    >
                      <Button color="danger" outline>
                        {" "}
                        Delete{" "}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="mt-2">
                      <Row>
                        <Col xs={6} className="mb-3 ">
                          <Label>First name</Label>
                          <Input
                            value=""
                            className="form-control"
                            placeholder="Enter first name"
                            type="text"
                          />
                        </Col>
                        <Col xs={6} className="mb-3">
                          <Label>Last name</Label>
                          <Input
                            value=""
                            className="form-control"
                            placeholder="Enter last name"
                            type="text"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label>Email</Label>
                        <Input
                          value=""
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label>Phone number</Label>
                        <PhoneInput
                          international
                          countryCallingCodeEditable={true}
                          className="input_phone"
                          defaultCountry="IN"
                          initialValueFormat="national"
                          placeholder="Enter phone number"
                          value={phone}
                          error={
                            phone
                              ? isValidPhoneNumber(phone)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                          onChange={value => {
                            setPhone(value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Label>About</Label>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        placeholder="Write Description..."
                      />{" "}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              props.toggle();
            }}
          >
            <span>Submit</span>
          </Button>
          <Button
            color="success"
            outline
            onClick={() => {
              props.toggle();
            }}
          >
            <span>Cancel</span>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ZepulProfileEditModel;
