const { Row, Container, Col, Card, CardBody } = require("reactstrap");
import {
  createEducationalQualifications,
  getAllEducationalQualifications,
  updateEducationalQualification,
} from "helpers/services/jobAttributes/educationalQualifications";
import React from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import "../../../assets/css/jobattr.css";
import { IOSSwitch } from "components/Common/iosswitch";
import CreateModel from "components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";

class Qualifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      navSelected: "0",
      data: [],
    };
    this.toggle = this.toggle.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }
  async componentDidMount() {
    const data = await getAllEducationalQualifications();
    this.setState({ data });
  }

  toggle(purpose = "", name = "", active = "", id = "", index) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }

  handleAction(item, index) {
    let result = this.state.data;
    updateEducationalQualification(item.id, {
      is_active: !item.is_active,
      name: item.name,
    });
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
  }
  async updateFunc(sample, type, index) {
    if (type === "create") {
      await createEducationalQualifications(sample);
      this.setState({
        data: [...this.state?.data, sample],
      });
    } else {
      await updateEducationalQualification(this.state.id, sample);
      let result = this.state.data;
      result[index] = sample;
      this.setState({
        data: result,
      });
    }
  }

  render() {
    return (
      <Container fluid>
        <CreateModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Educational Qualification"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
        />
        <Row>
          <Row className="align-items-center ">
            <Col md={6} style={{ padding: "40px" }}>
              <PrimaryButton
                sx={{ width: "244px" }}
                onClick={() => this.toggle("create")}
              >
                Create Educational Qualifications
              </PrimaryButton>
            </Col>
          </Row>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw" }}>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qualification</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <TableRow key={item.name + index}>
                          <TableCell>{item.name}</TableCell>

                          <TableCell className="active_td">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <PrimarySwitch
                                    sx={{ m: 1 }}
                                    checked={item.is_active}
                                    onChange={async e => {
                                      this.handleAction(item, index);
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell className="col_btn">
                            <PrimaryButton
                              sx={{ width: "100px" }}
                              onClick={() =>
                                this.toggle(
                                  "edit",
                                  item.name,
                                  item.active,
                                  item.id,
                                  index
                                )
                              }
                            >
                              Edit
                            </PrimaryButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default Qualifications;
