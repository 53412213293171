import { IconButton } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { createJobLevelForEveryone } from "helpers/services/jobAttributes/jobLevels";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  Input,
  FormGroup,
} from "reactstrap";

export const JobFunctionalAreaOtherModel = props => {
  const [text, setText] = useState("");
  console.log(props.data);
  const handleSubmit = async() => {
    const res = await createJobLevelForEveryone({name:text,functional_area_id:props?.data})
    // props.onSubmit && props.onSubmit(text);
    if([201,200].includes(res.status)){
      props.setFilter([res.data?.job_level_res?.id])
      props.updateRole({ job_roles: [res.data?.job_level_res?.id] })
      toast.success("successfully created");
      props.fetchCb()
      return
    }
    toast.error(res.data?.message)
  };

  const toggles = () => {
    props.onHide();
  };

  return (
    <div>
      <Modal
        isOpen={props.show}
        // cl={props.onHide}
        onClosed={props.onHide}
        className="models-main"
      >
        <ModalHeader
          toggle={props.onHide}
          style={{ fontSize: "12px" }}
          className="models-header"
          close={<IconButton onClick={props.onHide}><FeatherIcon  icon="x" size={14} /></IconButton> }
        >
          <h6 style={{ textAlign: "center" }}>
            Please enter your Job Role?
          </h6>
        </ModalHeader>
        <ModalBody>
          <div className="models-body-main">
            <div id="job-close-model-input-cont">
              <FormGroup>
                {/* <Label htmlFor=""></Label> */}
                <Input
                  type="text"
                  onChange={e => {
                    setText(e.target.value);
                  }}
                  placeholder="Enter the role"
                />
              </FormGroup>
            </div>
            <div id="job-close-model-btton-cont">
              <PrimaryButton
                // color="success"
                onClick={() => {
                  toggles();
                  handleSubmit();
                }}
              >
                Yes
              </PrimaryButton>
              <SecondaryButton  onClick={toggles}>
                No
              </SecondaryButton>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
