import { Box, MenuItem, Select } from "@mui/material";
import { MenuProps, PrimaryNormalInputs } from "assets/Mui/globalTheme";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import React, { useEffect } from "react";

export default function GenderSelect(props) {
  const [selectedGenders, setSelectedGenders] = React.useState([]);
  const genders = [
    { name: "male" },
    { name: "female" },
    { name: "Prefer not to say" },
  ];
  const { item, applyValue, focusElementRef } = props;
  useEffect(() => {
    if (item?.value?.length > 0) {
      setSelectedGenders(item?.value);
    }
  }, [item?.value]);
  const handleChange = (event, newValue) => {
    const {
      target: { value },
    } = event;

    setSelectedGenders(typeof value === "string" ? value.split(",") : value);
    applyValue({
      ...item,
      value: typeof value === "string" ? value.split(",") : value,
    });
  };
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        height: 48,
        pl: "20px",
      }}
    >
      <Select
        multiple
        labelId="folloups-industry-outlined"
        id="folloups-industry-outlined"
        value={selectedGenders}
        onChange={handleChange}
        renderValue={selected => {
          if (selected.length === 0) {
            return <span>All</span>;
          }
          return selected?.join(",");
        }}
        input={
          <PrimaryNormalInputs
            multiline
            tabs={4}
            label="folloups-industry-outlined"
          />
        }
        MenuProps={MenuProps}
      >
        {genders?.map(item => {
          return (
            <MenuItem
              key={item?.name}
              value={item?.name} // style={getStyles(name, personName, theme)}
            >
              {item?.name}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}
