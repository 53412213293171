import { Stack } from "@mui/material";
import ScheduleCoverImage from "./cover";
import ScheduleInterviewForm from "./form";

export default function ScheduleInterviewChild() {
  return (
    <Stack spacing={2}>
      <ScheduleCoverImage />
      <ScheduleInterviewForm />
    </Stack>
  );
}
