import ReactSelect from "react-select";
const {
  Col,
  Row,
  Container,
  Label,
  CardBody,
  Card,
  Input,
} = require("reactstrap");
import React from "react";
class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <Card style={{ minHeight: "70vh" }}>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <Label>Skill</Label>
                  <ReactSelect
                    style={{ zIndex: "999" }}
                    // defaultValue={content?.seniority}
                    value={this.props.state.multi}
                    isMulti={true}
                    onChange={this.props.handleSelectMulti}
                    options={this.props.optionGroup}
                    classNamePrefix="select2-selection"
                    // style={{marginBottom:'10px'}}
                  />{" "}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Label>Topic</Label>
                  <ReactSelect
                    style={{ zIndex: "999" }}
                    // defaultValue={content?.seniority}
                    value={this.props.state.multi}
                    isMulti={true}
                    onChange={this.props.handleSelectMulti}
                    options={this.props.optionGroup}
                    classNamePrefix="select2-selection"
                    // style={{marginBottom:'10px'}}
                  />{" "}
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Label>Tags</Label>
                  <ReactSelect
                    style={{ zIndex: "999" }}
                    // defaultValue={content?.seniority}
                    value={this.props.state.multi}
                    isMulti={true}
                    onChange={this.props.handleSelectMulti}
                    options={this.props.optionGroup}
                    classNamePrefix="select2-selection"
                    // style={{marginBottom:'10px'}}
                  />{" "}
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Label>Difficulty Level</Label>
                  <ReactSelect
                    style={{ zIndex: "999" }}
                    // defaultValue={content?.seniority}
                    value={this.props.state.multi}
                    isMulti={true}
                    onChange={this.props.handleSelectMulti}
                    options={this.props.optionGroup}
                    classNamePrefix="select2-selection"
                    // style={{marginBottom:'10px'}}
                  />{" "}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Label>Default Marks/Grade Points</Label>
                  <Input type="text" />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Label>Default Time To Solve (Seconds)</Label>
                  <Input type="number" />
                </Col>
              </Row>
              <Row>
                <Col xs={8}>Active</Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col xs={10}>
                  Active (Shown Everywhere). In-active (Hidden Everywhere).
                </Col>
                <Col xs={2}>
                  <div className="form-check form-switch form-switch-md mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                    />
                    {/* <label
                      className="form-check-label"
                      htmlFor="customSwitchsizemd"
                    >
                      I'm a fresher
                    </label> */}
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Col xs={2}>
                  <button
                    className="theme_btn"
                    // color="primary"
                    onClick={() => {
                      this.props.handleState(3);
                    }}
                  >
                    Update Settings
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default SettingsPage;
