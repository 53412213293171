import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import { FormControl, Grid, Stack } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function AddEditNotes({
  type,
  open,
  currentData,
  toggleModel,
  updateOrEditNotes,
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  React.useEffect(() => {
    if (type?.includes("update") && currentData) {
      setValue("notes", currentData.notes);
      setValue("id", currentData.id);
    }
  }, [type, currentData]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-notes"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            {type === "create" ? "create" : "Edit"} Notes
          </BlackTypography>
          <form onSubmit={handleSubmit(updateOrEditNotes)}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-notes-outlined"
                    error={errors.notes}
                  >
                    notes
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    multiline
                    fullWidth
                    placeholder="Enter your notes"
                    {...register("notes", { required: true })}
                    id="wor-exp-notes-outlined"
                    helperText={errors?.notes ? "email is required" : ""}
                    autoFocus
                    label="Job notes"
                    type={"text"}
                    name="notes"
                    error={errors.notes}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.notes}>
                    {errors.notes ? "notes is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggleModel();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
