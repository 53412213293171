export function findJobType(data) {
  switch (data) {
    case "Part_Time":
      return "Part Time";
    case "Full_Time":
      return "Full Time";
    default:
      return data;
  }
}
