import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1890ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function LeadershipTable(props) {
  return (
    <>
      <div className="laeadership_table_cont card border-0">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <caption className="p-5">
              <Stack>
                <Pagination
                  count={10}
                  shape="rounded"
                  style={{ margin: "auto" }}
                />
              </Stack>
            </caption>
            <TableHead>
              <TableRow>
                <StyledTableCell>Ranking</StyledTableCell>
                <StyledTableCell align="center">Members</StyledTableCell>
                <StyledTableCell align="center">Placements</StyledTableCell>
                <StyledTableCell align="center">Total Earning</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.leaderBoardData.map(row => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    12
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    <div className="leaderboard_mamber_row_cont">
                      <div>
                        <img src={row.user_image} alt="" />
                      </div>
                      <div>{row.first_name}</div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">744</StyledTableCell>
                  <StyledTableCell align="center">$ 574</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
export default LeadershipTable;
