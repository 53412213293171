import { Grid } from "@mui/material";
import { BlackTypography, GreyTypography } from "assets/Mui/globalTheme";

export default function UseJobPostChatUserDetails({ type, result, sx }) {
  return (
    <Grid container>
      <Grid item xs={4}>
        <BlackTypography variant="h6" component="h6">
          {type}
        </BlackTypography>
      </Grid>
      <Grid item xs={8}>
        <GreyTypography sx={sx} variant="h6" component="h6">
          {result}
        </GreyTypography>
      </Grid>
    </Grid>
  );
}
