import {
  Avatar,
  Badge,
  Box,
  CardActionArea,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import {
  BlackTypography,
  BorderCard,
  GrayDivider,
  GreyTypography,
  PrimaryAvatar,
  PrimaryDivider,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryTypography,
  SuccessBadge,
  successNotification,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {useHistory} from "react-router-dom"

export default function ChatJobSidebar({list}) {
  const history = useHistory()
  return (
    <Stack padding={0} height={"100%"} spacing={2}>
      <BorderCard
        sx={{
          // height: "100%",
          // maxHeight: "90vh",
        }}
      >
        {!list ?<div style={{padding:"15px"}}>
          <FormControl sx={{ width: "100%" }} variant="standard">
            <PrimaryNormalInputs
              id="chat-search-outlined"
              label="search"
              type={"text"}
              startAdornment={
                <InputAdornment
                  sx={{
                    position: "absolute",
                    left: "10px",
                  }}
                  position="start"
                >
                  <FeatherIcon icon="x" size={"14"} />
                </InputAdornment>
              }
              placeholder="search by Chats"
            />
          </FormControl>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <PrimaryTypography
              sx={{
                fontWeight: "bold !important",
                width: "75px",
                // borderBottom: "1px solid rgba(3,27,87)",
              }}
              variant="h5"
              component="h5"
            >
              My Chats
            </PrimaryTypography>
            <IconButton>
              <FeatherIcon icon="layers" size="14" />
            </IconButton>
          </Stack>
        </div>: 
        <>
        <div style={{padding:"15px",background:"rgba(8, 23, 83, 0.2)"}}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} spacing={1}>
              <Avatar />
              <div>
                <PrimaryTypography variant="h3">Awesome Dev</PrimaryTypography>
                <PrimaryTypography variant="h6">Spotify</PrimaryTypography>
              </div>
            </Stack>
            <IconButton sx={{alignSelf:'flex-start'}}>
              <FeatherIcon icon="info" size={18} />
            </IconButton>
          </Stack>
        </div>
          <div style={{padding:"15px",borderBottom:'1px solid #ddd'}} >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <PrimaryTypography
              sx={{
                fontWeight: "bold !important",
                width: "75px",
                // borderBottom: "1px solid rgba(3,27,87)",
              }}
              variant="h5"
              component="h5"
            >
              My Chats
            </PrimaryTypography>
          </Stack>
          </div>
        </>
        }
          <Stack sx={{overflowY:'scroll',scrollbarWidth:'none',marginInline:'12px',marginBlockEnd:"12px"}} height={"calc(100vh - 160px)"} spacing={0}>
          {Array.from(Array(10).keys())?.map((item, index) => (
            <Stack onClick={()=>history.push("/chat/12")} alignItems={"center"}>
              <CardActionArea
                key={index}
                sx={{ borderRadius: "8px", padding: "10px 10px 10px 0" }}
              >
                <Grid container>
                  <Grid item xs={2}>
                    <PrimaryAvatar>M</PrimaryAvatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Stack spacing={1}>
                      <BlackTypography variant="h4" component="h4">
                        UI/UX Designer
                      </BlackTypography>
                      {/* <GreyTypography variant="h6" component="h6">
                        Uber
                      </GreyTypography> */}
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Badge color="success" alignItems={"flex-end"} badgeContent={5}/>                     
                  </Grid>
                </Grid>
              </CardActionArea>
              <GrayDivider sx={{ marginTop: "0px !important", width: "70%" }} />
            </Stack>
          ))}
        </Stack>
      </BorderCard>
    </Stack>
  );
}
