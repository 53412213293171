import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col } from "reactstrap";
import "../JobLocation.css";
// import "antd/dist/antd.css";
import "./Skills.css";
import { createKeySkills, getAllKeySkills } from "helpers/services/jobAttributes/keyskills";
import { updateJob } from "store/jobs/actions";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { Checkbox, FormControl, createFilterOptions } from "@mui/material";
import { MultipleSelectInputs, PrimaryAutoComplete, PrimaryNormalInputLabel, SecondaryFilledChip, autocompleteProps } from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {v4 as uuid} from"uuid"
// const { CheckableTag } = Tag;

const JobSkills = ({ updateJob, key_skills }) => {
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setSelected(key_skills.map(each=>({name:each.label,value:each.value,is_active:each.isActive})));
  }, [key_skills]);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    dataCaller();
  }, []);
  async function dataCaller() {
    const items = await getAllKeySkills();
    const data = items.map(item => ({ name: item?.name, value: item?.id,is_active:item?.is_active }))
    setOptions(data);
  }
  const filter = createFilterOptions();
  // console.log(selected,key_skills,"v");
  return (
    <>
      <Col xs={12} className="mt-4">
        <h5>
          Select required Skills{" "}
          <span style={{ fontSize: "10px", color: "gray" }}>
            (Select all that applied)
          </span>
          <span className="red p-2">*</span>
        </h5>
      </Col>
      <Col xs={12} className="mb-2">
        {/* <div className="mt-2">
          <ReactSelect
            style={{ zIndex: "999" }}
            isMulti={true}
            options={options}
            value={selected}
            onChange={e => {
              setSelected(e);
              updateJob({ key_skills: e });
            }}
            classNamePrefix="select2-selection"
          />
        </div> */}
      <PrimaryAutoComplete
        sx={{ width: "100%" }}
        disableCloseOnSelect
        componentsProps={autocompleteProps}
        multiple={true}
        // loading={skillLoader}
        loaderText="Fetching Skills"
        options={options}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <SecondaryFilledChip
              deleteIcon={<FeatherIcon icon={"x"} size="14" />}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option, { selected }) =>
          option?.is_active ? (
            <li {...props} key={props.id}>
              <Checkbox
                icon={<FeatherIcon icon="square" size="14" />}
                checkedIcon={<FeatherIcon icon="check-square" size="14" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          ) : null
        }
        getOptionLabel={option => option?.name || ""}
        renderInput={params => (
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <MultipleSelectInputs
              sx={{
                marginTop:0
              }}
              variant="standard"
              ref={params.InputProps.ref}
              {...params}
            />
          </FormControl>
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => inputValue === option.name);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              is_active: true,
              id: uuid(),
              name: `${inputValue}`,
            });
          }
          return filtered;
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        // isOptionEqualToValue={(option,value)=>console.log(option,value,"v")}
        value={selected}
        onChange={async (_, data) => {
          // console.log(data, "data is here v");
          const lastIndex = data?.length - 1;
          if (data && data[lastIndex]?.inputValue) {
            const { data: skill } = await createKeySkills({
              name: data[lastIndex]?.inputValue,
              is_active: true,
            });
            await dataCaller();
            setSelected(pre => [
              ...pre,
              {
                name: skill.key_skill_res.name,
                value: skill.key_skill_res.id,
                is_active: skill.is_active,
              },
            ]);
          } else {
            setSelected(data);
          }
          const tweak = data.map(item=>({
            isActive:item.is_active,
            label:item.name,
            value:item.value
          }))
          updateJob({key_skills:tweak})
        }}
      />
      </Col>
    </>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  key_skills: Jobs.key_skills,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSkills);
