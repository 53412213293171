import React from "react";
import { Col, Label } from "reactstrap";
import "./JobTitle.css";
import { updateJob } from "store/jobs/actions";
import { connect } from "react-redux";
import { FormLabel } from "@mui/joy";
import { TextField } from "@mui/material";

const JobTitle = ({ title, updateJob, error }) => {
  return (
    <div>
      <Col xs={12}>
        <TextField
          label="Job Title"
          id="jobtitle"
          // invalid={error.job_title === false ? false : true}
          value={title}
          onChange={e => {
            updateJob({ job_title: e.target.value });
          }}
          maxLength={80}
          style={{ height: 60, resize: "none" }}
          placeholder="Enter Job Title"
        />
      </Col>
    </div>
  );
};

const mapStateToProps = ({ Jobs }) => ({
  title: Jobs.job_title,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobTitle);
