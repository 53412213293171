const { Row, Container, Col, Card, CardBody } = require("reactstrap");
import {
  getAllEducationalQualifications,
  getAllEducationalSpecialization,
  updateEducationalSpecialization,
} from "helpers/services/jobAttributes/educationalQualifications";
import React from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import "../../../assets/css/jobattr.css";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";

import CreateEducationalQualification from "./createModel.js/create";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
class Specialization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      navSelected: "0",
      data: [],
      qualification: [],
      purpose: "",
      educational_qualification_id: "",
      id: "",
      name: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.updated = this.updated.bind(this);
  }
  componentDidMount() {
    this.updated();
  }

  async updated() {
    const qualification = await getAllEducationalQualifications();
    const items = qualification.map(item => {
      return { value: item.id, label: item.name };
    });
    const data = await getAllEducationalSpecialization();
    this.setState({ data, qualification: items });
  }
  toggle(
    purpose = "",
    name = "",
    id = "",
    index = "",
    educational_qualification_id = ""
  ) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      isOpen: !this.state.isOpen,
      educational_qualification_id,
    });
  }

  handleAction(item, index) {
    let result = this.state.data;
    updateEducationalSpecialization(result[index].id, {
      educational_qualification_id: result[index].educational_qualification_id,
      name: result[index].name,
      is_active: !result[index].is_active,
    });
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
  }

  render() {
    return (
      <Container fluid>
        <CreateEducationalQualification
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          qualification={this.state.qualification}
          purpose={this.state.purpose}
          name={this.state.name}
          id={this.state.id}
          updated={this.updated}
          index={this.state.index}
          educational_qualification_id={this.state.educational_qualification_id}
        />

        {/* end */}
        <Row>
          <Row className="align-items-center ">
            <Col md={6} style={{ padding: "40px" }}>
              <PrimaryButton
                sx={{
                  width: "245px",
                }}
                onClick={() => this.toggle("Create")}
              >
                Create Educational Specialization
              </PrimaryButton>
            </Col>
          </Row>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw" }}>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qualification</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <TableRow key={item.name + index}>
                          <TableCell>{item.name}</TableCell>

                          <TableCell className="active_td">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <PrimarySwitch
                                    sx={{ m: 1 }}
                                    checked={item.is_active}
                                    onChange={async e => {
                                      this.handleAction(item, index);
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell className="col_btn">
                            <PrimaryButton
                              sx={{ width: "100px" }}
                              onClick={() =>
                                this.toggle(
                                  "edit",
                                  item.name,
                                  item.id,
                                  index,
                                  item.educational_qualification_id
                                )
                              }
                            >
                              Edit
                            </PrimaryButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default Specialization;
