import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";

export default function CandidateInformation({ candidateDetails }) {
  const [candidate, setCandidate] = useState({});
  useEffect(() => {
    setCandidate(candidateDetails);
  }, [candidateDetails]);
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <PrimaryTypography component="h4" variant="h4">
          Candidate Details
        </PrimaryTypography>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={1}>
            <InputButtonField
              text_field={"First Name"}
              input_value={candidate?.first_name}
              type="input"
              update_type={"first_name"}
            />

            <InputButtonField
              text_field={"Last Name"}
              input_value={candidate?.last_name}
              type="input"
              update_type={"last_name"}
            />

            {/* <InputButtonField
              text_field={"Gender"}
              input_value={""}
              type="select"
              update_type={"gender"}
            /> */}
            <InputButtonField
              text_field={"Current Company"}
              input_value={candidate?.current_company}
              type="input"
              update_type={"current_company"}
            />

            <InputButtonField
              text_field={"Current Position"}
              input_value={candidate?.current_postition}
              type="input"
              update_type={"current_postition"}
            />
            <InputButtonField
              text_field={"Current Department"}
              input_value={candidate?.current_department}
              type="input"
              update_type={"current_department"}
            />
            <InputButtonField
              text_field={"Current Industry"}
              input_value={candidate?.current_industry}
              type="input"
              update_type={"current_industry"}
            />
            <InputButtonField
              text_field={"Current Location"}
              input_value={candidate?.location}
              type="location"
              update_type={"location"}
            />
            <InputButtonField
              text_field={"Birth Date"}
              input_value={candidate?.dob}
              type="date"
              update_type={"dob"}
            />
            <InputButtonField
              text_field={"Current Address"}
              input_value={candidate?.current_address}
              type="multiline"
              update_type={"current_address"}
            />
            <InputButtonField
              text_field={"Candidate Email"}
              input_value={candidate?.email}
              type="input"
              update_type={"candidate_email"}
            />
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}
