import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#1890ff",
    backgroundColor:"rgba(8, 23, 83, 0.2)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ApplicantEarning() {
  const data = [
    {
      id: 1,
      name: "Kim",
      company_name: "Syoft",
      reffered_date: "10-10-2022",
      ctc: 5,
      status: "pending",
    },
    {
      id: 2,
      name: "Justin",
      company_name: "Syoft",
      reffered_date: "10-10-2022",
      ctc: 5,
   
      status: "pending",
    },
    {
      id: 3,
      name: "Zayn",
      company_name: "Syoft",
      reffered_date: "10-10-2022",
      ctc: 5,
    
      status: "pending",
    },
    {
      id: 4,
      name: "Zayn malik",
      company_name: "Syoft",
      reffered_date: "10-10-2022",
      ctc: 5,
   
      status: "pending",
    },
  ];
  return (
    <div className="p-3">
      <h5 className="mb-3">Applicants</h5>
      <div className="card border-0 table-bg">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <caption className="p-5">
              <Stack>
                <Pagination
                  count={10}
                  shape="rounded"
                  style={{ margin: "auto" }}
                />
              </Stack>
            </caption>
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Company</StyledTableCell>
                <StyledTableCell align="center">Date Referred</StyledTableCell>
                <StyledTableCell align="center">CTC</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.company_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.reffered_date}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Rs. {row.ctc} LPA
                  </StyledTableCell>
                
                  <StyledTableCell align="center">{row.status}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ApplicantEarning;
