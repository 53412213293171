import { Grid, Stack } from "@mui/material";
import React,{useEffect,useState} from 'react';
import {
  PrimaryButton,
  PrimaryPaperComponent,
  PrimaryTypography,
  SecondaryCard,
} from "assets/Mui/globalTheme";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
import { getAllDashboardDetails } from "helpers/services/Dashboard/Dashboard";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";
import SocketConnection from "hooks/sockets";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const linecartData = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => -595),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => -234),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};



export default function Dashboard() {
  const [loading,setLoading] = useState(true)
  const [ScreenedApplicants, setScreenedApplicants] = useState({});
  SocketConnection()
  useEffect(() => {
    initialFetch();
  }, []);
  
  
  async function initialFetch() {
    // setLoading(true);
    const { data, status } = await getAllDashboardDetails();
    if (data) {
      setLoading(false);
    }
    setScreenedApplicants(data?.message);
  }

  return (
    <>
    {loading? <><div
        className="loader_container"
        style={{ height: "50vh",display:"flex",justifyContent:"center",alignItems:"center"}}
      >
        <div className="loader"></div>
      </div></>:
      <Grid container mt={"20px"} spacing={2}>
        {/* <Grid item xs={12} md={8}>
          <PrimaryPaperComponent elevation={0} square={false}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack spacing={2}>
                <PrimaryTypography variant="h2" sx={{ lineHeight: "30px" }}>
                  Welcome back!
                  <br />
                  Zepul Admin Panel
                </PrimaryTypography>
                <PrimaryTypography variant="h6" sx={{ minWidth: "80%" }}>
                  The most efficient platform to easily source, recruit,refer &
                  hire talented people
                </PrimaryTypography>
                <PrimaryButton>Start Your Journey now</PrimaryButton>
              </Stack>
            </Stack>
          </PrimaryPaperComponent>
        </Grid> */}
        {/* <Grid item xs={12} md={4}></Grid> */}
        <Grid item xs={12} md={4}>
          <SecondaryCard sx={{ height: "100%" }}>
            <Stack
              sx={{ height: "100%" }}
              spacing={1}
              direction={"column"}
              justifyContent={"center"}
            >
              <PrimaryTypography variant="h5">
                Total Active Users
              </PrimaryTypography>
              <PrimaryTypography variant="h1">{ScreenedApplicants?.total_active_users?ScreenedApplicants?.total_active_users:"0"}</PrimaryTypography>
            </Stack>
          </SecondaryCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <SecondaryCard sx={{ height: "100%" }}>
            <Stack
              sx={{ height: "100%" }}
              spacing={1}
              direction={"column"}
              justifyContent={"center"}
            >
              <PrimaryTypography variant="h5">
                Total Screening Partners
              </PrimaryTypography>
              <PrimaryTypography variant="h1">{ScreenedApplicants?.total_screening_partners?ScreenedApplicants?.total_screening_partners:"0"}</PrimaryTypography>
            </Stack>
          </SecondaryCard>
        </Grid>{" "}
        <Grid item xs={12} md={4}>
          <SecondaryCard sx={{ height: "100%" }}>
            <Stack
              sx={{ height: "100%" }}
              spacing={1}
              direction={"column"}
              justifyContent={"center"}
            >
              <PrimaryTypography variant="h5">
                Total Active Companies
              </PrimaryTypography>
              <PrimaryTypography variant="h1">{ScreenedApplicants?.total_active_companies?ScreenedApplicants?.total_active_companies:"0"}</PrimaryTypography>
            </Stack>
          </SecondaryCard>
        </Grid>{" "}

      </Grid>}
    </>
  );
}
