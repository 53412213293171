import { all, fork } from "redux-saga/effects";

import AuthSaga from "./auth/login/saga";
import LayoutSaga from "./layout/saga";
import invoiceSaga from "./invoices/saga";
import tasksSaga from "./tasks/saga";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(invoiceSaga),
    fork(tasksSaga),
  ]);
}
