import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { imageURL } from "common/constants/commonURLS";
import {
  PrimaryButton,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { getAllScreenedApplicantsForModerator } from "helpers/services/moderator/moderator";
import moment from "moment";
import { Link } from "react-router-dom";

export default function ScreeningPartnerTable() {
  const [moderatedApplicants, setModeratedApplicants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    initialFetch();
  }, []);

  async function initialFetch() {
    setLoading(true);
    const { data, status } = await getAllScreenedApplicantsForModerator();
    const result = data?.map((item, index) => ({
      id: index + 1,
      email: `${item?.user_job_post?.email}`,
      image: `${imageURL}${item?.image}`,
      company_log: `${imageURL}${item?.company_details?.company?.company_name}`,
      phone_number: `${item?.user_job_post?.phone_number}`,
      company_name: `${item?.company_details?.company?.company_name}`,
      name: `${item?.user_job_post?.first_name} ${item?.user_job_post?.last_name}`,
      job_title: `${item?.job_post_details?.job_post_in_user_job_post?.job_title}`,
      job_id: `${item?.job_post_details?.job_post_in_user_job_post?.id}`,
      updated_at: `${moment(item?.updatedAt).format("YYYY-MM-DD h:mm:ss a")}`,
      status: item?.new_value,
    }));
    if (status === 200) {
      setLoading(false);
    }
    setModeratedApplicants(result);
  }

  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      <PrimaryDataGrid
        loading={loading}
        disableRowSelectionOnClick={true}
        checkboxSelection={true}
        columns={[
          {
            field: "image",
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name", width: 200 },
          { field: "phone_number", width: 300 },
          { field: "email", width: 300 },
          {
            field: "company_logo",
            width: 300,
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.company_name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },

          { field: "company_name", width: 300 },
          {
            field: "job_title",
            width: 300,
            renderCell: params => {
              return (
                <Link to={`../joblist/jobs/${params?.row?.job_id}`}>
                  {params?.formattedValue}
                </Link>
              );
            },
          },
          { field: "updated_at", width: 300 },
        ]}
        rows={moderatedApplicants}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButton,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
        }}
        slotProps={{
          baseButton: { style: { width: "100px" } },
          panel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          filterPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
          toolbar: { style: {} },
          preferencesPanel: {
            style: {
              right: "0px !important",
              transform: "none !important",
              inset: "none !important",
              top: "138px !important",
            },
          },
        }}
      />
    </div>
  );
}
