import { Stack, Modal, Box } from "@mui/material";
import { TimeTypography,PrimaryBox,SecondaryFilledBox} from "assets/Mui/globalTheme";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imageURL } from "common/constants/commonURLS";
import moment from "moment";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function ChatImages({ message, time, sender }) {
  const [imageModel, setImageModel] = useState(false);
  function handleImageModel() {
    setImageModel(!imageModel);
  }
  const isImage = /\.(jpe?g|png|jpeg|gif)$/i.test(
    message
  );
  return (
    <>
      <Stack
        sx={{
          maxWidth: "200px",
          ...(sender
            ? {
                marginLeft: "auto",
              }
            : {
                marginRight: "auto",
              }),
          marginBottom: "10px",
        }}
      >
        {isImage ? (
          <LazyLoadImage
            effect="blur"
            onClick={handleImageModel}
            style={{
              marginBottom: "10px",
              width: "80px",
              height: "80px",
              borderRadius: "8px",
            }}
            loading="lazy"
            // src={imageURL + message}
            src={`${imageURL + message}?w=200&h=200&fit=crop&auto=format`}
            // srcSet={`${imageURL + message}?w=200&h=200&fit=crop&auto=format&dpr=2 2x`}
          />
        ) : sender ? (
          <PrimaryBox
            sx={{
              minWidth: "auto",
              maxWidth: "300px !important",
              borderTopRightRadius: "0px !important",
              wordBreak: "break-word",
              display: "flex",
              gap: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              const link = document.createElement("a");
              link.download = `${imageURL}${message}`;
              link.href = `${imageURL}${message}`;
              link.target = "_blank";
              link.click();
            }}
          >
            <FeatherIcon icon="download" size={20} />
            <span>{message.replace("img_","").slice(4,message.length-1)}</span>
          </PrimaryBox>
        ) : (
          <SecondaryFilledBox
            sx={{
              minWidth: "auto",
              maxWidth: "300px !important",
              borderTopLeftRadius: "0px !important",
              wordBreak: "break-word",
              display: "flex",
              gap: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              const link = document.createElement("a");
              link.download = `${imageURL}${message}`;
              link.href = `${imageURL}${message}`;
              link.target = "_blank";
              link.click();
            }}
          >
            <FeatherIcon icon="download" size={20} />
            <span>{message.replace("img_","").slice(4,message.length-1)}</span>
          </SecondaryFilledBox>
        )}

        <Stack
          sx={{
            ...(sender
              ? {
                  marginLeft: "auto",
                }
              : {
                  marginRight: "auto",
                }),
          }}
        >
          <TimeTypography>{moment(time).format("LT")}</TimeTypography>
        </Stack>
      </Stack>

      <Modal open={imageModel} onClose={handleImageModel}>
        <Box style={{ ...style }}>
          <img
            onClick={handleImageModel}
            style={{
              marginBottom: "10px",
              width: "400px",
              height: "400px",
              borderRadius: "8px",
              objectFit: "contain",
            }}
            loading="lazy"
            // src={`{${imageURL}${message}}`}
            src={`${imageURL + message}?w=200&h=200&fit=crop&auto=format`}
            srcSet={`${
              imageURL + message
            }?w=200&h=200&fit=crop&auto=format&dpr=2 2x`}
          />
        </Box>
      </Modal>
    </>
  );
}
