import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import "../../../assets/css/csv.css";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Dropzone from "react-dropzone";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const ImportResumes = props => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles({ selectedFiles: files });
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const { isOpen, toggle } = props;
  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      style={{ maxWidth: "700px", width: "100%" }}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Start Upload</ModalHeader>
        <ModalBody>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Resumes" breadcrumbItem="Import Resume" />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Select Source</CardTitle>

                    <Form>
                      <Row>
                        <Col sm="12">
                          <FormGroup className="mb-3">
                            {/* <Label htmlFor="productname">Product Name</Label> */}
                            <Input
                              type="select"
                              className="form-select"
                              id="inlineFormSelectPref"
                            >
                              <option defaultValue>Choose...</option>
                              <option>One</option>
                              <option>Two</option>
                              <option>Three</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-3 h4">Product Images</CardTitle>
                    <Form className="dropzone">
                      <Dropzone
                        onDrop={acceptedFiles =>
                          handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div>
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" onClick={toggle}>
                    Upload Files
                  </Button>
                  <Button onClick={toggle} type="submit" color="secondary">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        {/* <ModalFooter  type="footer"  className="d-flex justify-content-evenly">
            <Button onClick={handleUpload} type="button" color="primary">
                Upload Excel/CSV/TSV and import candidates
            </Button>
       
        </ModalFooter> */}
      </div>
    </Modal>
  );
};

ImportResumes.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ImportResumes;
