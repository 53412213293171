const { Row, Container } = require("reactstrap");
import { getAllEducationalQualifications } from "helpers/services/jobAttributes/educationalQualifications";
import React from "react";
import "../../assets/css/jobattr.css";
import Qualifications from "./educations.js/qualifications";
import Specialization from "./educations.js/specialization";
import { Tabs } from "@mui/material";
import { SolidTab, StyledLoginTabsObject } from "assets/Mui/globalTheme";

class DegreeLevels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      navSelected: "0",
      data: [],
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.handleNavBar = this.handleNavBar.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    const data = await getAllEducationalQualifications();
    this.setState({ data });
  }

  toggle(purpose = "", name = "", active = "", id = "", index) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }
  handleDelete(index, id) {
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id)
      : this.handlRestore(item, index);
  }
  updateFunc(sample, type, index) {
    if (type === "create") {
      this.setState({
        data: [...this.state?.data, sample],
      });
    } else {
      let result = this.state.data;
      result[index] = sample;
      this.setState({
        data: result,
      });
    }
  }

  handleNavBar(e) {
    this.setState({
      navSelected: e,
    });
  }
  NavSelection(data) {
    switch (data) {
      case "0":
        return <Qualifications handleNavBar={this.handleNavBar} />;
      case "1":
        return (
          <Specialization
            handleNavBar={this.handleNavBar}
            navBar={this.state.navSelected}
          />
        );
    }
  }
  render() {
    const navBar = [
      {
        name: "Educational Qualifications",
      },
      {
        name: "Specializations",
      },
    ];

    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <div style={{ width: "500px", maxWidth: "500px" }}>
              <Tabs
                sx={{
                  height: "20px",
                  width: "500px",
                  borderRadius: "8px",
                  border: "0.5px solid rgba(0,0,0,0.5)",
                }}
                value={this.state.navSelected}
                TabIndicatorProps={StyledLoginTabsObject}
                onChange={(_, index) => this.handleNavBar(index.toString())}
                centered
              >
                <SolidTab
                  sx={{ width: "50% !important" }}
                  value="0"
                  label="Educational Qualifications"
                />
                <SolidTab
                  sx={{ width: "50% !important" }}
                  value="1"
                  label="Speicializations"
                />
              </Tabs>
            </div>
            {this.NavSelection(this.state.navSelected)}
          </Row>
        </Container>
      </div>
    );
  }
}

export default DegreeLevels;
