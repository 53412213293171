import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { PrimaryButton, PrimaryChip, PrimaryDataGrid, PrimarySwitch } from 'assets/Mui/globalTheme'
import LoaderIcon from 'utils/icons/loaderIcon'
import TablePopper from 'components/table/header/popper'
import CustomNoRowsOverlay from 'common/data/NoRowsFoundDatagrid/NoRowsFound'
import {useParams,useHistory} from "react-router-dom"
function KanbanGrid({pipeLine}) {
    const {id} = useParams()
    const history = useHistory()
    const listNested = pipeLine?.map((data)=>{
        const {user_job_posts_list,stage_name} = data 
        return user_job_posts_list.map((data)=>({
            id:data?.id,
            first_name:data?.first_name,
            last_name:data?.last_name,
            location:data?.location,
            email:data?.email,
            evaluator_details:data?.evaluator_details,
            moderator_details:data?.moderator_details,
            screening_partner_details:data?.screening_partner_details,
            stage_name,
            user_id:data?.user_id,
        }))
    })
    // console.log(listNested);
    const list = listNested.flat()
    // console.log(list,"v");  
    return (
    <Box sx={{height:"calc(100vh - 160px)"}}>
       <PrimaryDataGrid
        disableRowSelectionOnClick
        onCellClick={({row}) => {
            history.push(`/joblist/jobs/user-job-post?job_id=${id}&user_id=${row?.user_id}&user_job_post_id=${row?.id}&stage_name=${row?.stage_name}`);
          }}
        rows={list}
        columns={[
            {
                field:"ApplicantName",
                headerName:'Applicant Name',
                flex:1,
                valueGetter:({row})=> row.first_name + " " + row.last_name
            },
            {
                field:"email",
                flex:1,
                headerName:"Email"
            },
            {
                field:"location",
                flex:1,
                headerName:"Location",
                valueGetter:({value})=> [null,"N/A"].includes(value) ? "N/A" : value
            },
            {
                field:'evaluator',
                headerName:'Evaluator',
                flex:1,
                valueGetter:({row:{evaluator_details}})=>{
                    if(evaluator_details?.id){
                        return evaluator_details?.first_name + " " + evaluator_details?.last_name
                    }
                    return "N/A"
                } 
            },
            {
                field:'moderator',
                headerName:'Moderator',
                flex:1,
                valueGetter:({row:moderator_details})=>{
                    if(moderator_details?.id){
                        return moderator_details?.first_name + " " + moderator_details?.last_name
                    }
                    return "N/A"
                } 
            },
            {
                field:'screening',
                headerName:'Screener',
                flex:1,
                valueGetter:({row:{screening_partner_details}})=>{
                    if(screening_partner_details?.id){
                        return screening_partner_details?.first_name + " " + screening_partner_details?.last_name
                    }
                    return "N/A"
                } 
            },
            {
                field:'stage_name',
                headerName:'Stage',
                flex:1,
                renderCell:({value})=>(
                    <PrimaryChip size='small' label={value} />
                )
            }
        ]}
        slots={{
            loadIcon: LoaderIcon,
            baseButton: PrimaryButton,
            basePopper: TablePopper,
            baseSwitch: PrimarySwitch,
            toolbar: GridToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            baseButton: { style: { width: "100px" } },
            panel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
            filterPanel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
            toolbar: { style: {} },
            preferencesPanel: {
              style: {
                right: "0px !important",
                transform: "none !important",
                inset: "none !important",
                top: "138px !important",
              },
            },
          }}
       />
    </Box>
  )
}

export default KanbanGrid