import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../../assets/css/email.css";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/css/email.css";
import { Report } from "@mui/icons-material";
import CandidatesOverview from "./CandidatesOverview";
import PipeLineInsights from "./PipelineInsights";
import Disqualifications from "./Disqalification";
import JobOverview from "./JobOverview";
import EventsAndEvaluations from "./EventsAndEvaluations";
import CareerSiteAnalysis from "./CareerSiteAnalysis";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: "1",
      resultArray: [],
      inboxmails: [],
      starredmails: [],
      importantmails: [],
      draftmails: [],
      sentmails: [],
      emailData: [{ name: "fa" }, { name: "fa" }],
      trashmails: [],
      emailOpen: false,
    };
    this.togglemodal.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
  }
  switchTabs(tab) {
    switch (tab) {
      case "1":
        return <CandidatesOverview />;
      case "2":
        return <PipeLineInsights />;
      case "3":
        return <Disqualifications />;
      case "4":
        return <JobOverview />;
      case "5":
        return <EventsAndEvaluations />;
      case "6":
        return <CareerSiteAnalysis />;
    }
  }

  componentDidMount() {
    const {} = this.props;
  }

  toggleTab(tab) {
    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          {/* add meta title */}
          {/* <MetaTags>
            <title>Inbox | Skote - React Admin & Dashboard Template</title>
          </MetaTags> */}
          <Container fluid>
            <Row
              // style={{ maxHeight: "80vh" }}
              className="justify-content-center"
            >
              <Col md={3}>
                {/* Render Email SideBar */}
                <Card className="report_sidebar">
                  <Button
                    type="button"
                    color="primary"
                    onClick={this.togglemodal}
                    block
                  >
                    New Dashboard
                  </Button>
                  <h6 className="mt-4">Essential dashboards</h6>

                  <div className="mail-list mt-4">
                    <Nav
                      tabs
                      className="nav-tabs-custom"
                      vertical
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === "1" && "active_data"
                          }`}
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                        >
                          <i
                            className="fas fa-users me-2
"
                          ></i>{" "}
                          Candidate Overview{" "}
                          <span className="ml-1 float-end">(18)</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === "2" && "active_data"
                          }`}
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                        >
                          <i className="mdi mdi-email-check-outline me-2"></i>
                          Pipeline Insights
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === "3" && "active_data"
                          }`}
                          onClick={() => {
                            this.toggleTab("3");
                          }}
                        >
                          <i className="mdi mdi-trash-can-outline me-2"></i>
                          Disqualifications
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === "4" && "active_data"
                          }`}
                          onClick={() => {
                            this.toggleTab("4");
                          }}
                        >
                          <i
                            className="fas fa-suitcase me-2
"
                          ></i>
                          Job Overview
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === "5" && "active_data"
                          }`}
                          onClick={() => {
                            this.toggleTab("5");
                          }}
                        >
                          <i
                            className="fas fa-thumbs-down me-2
"
                          ></i>
                          Events and Evaluation
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === "6" && "active_data"
                          }`}
                          onClick={() => {
                            this.toggleTab("6");
                          }}
                        >
                          <i
                            className="fas fa-globe-asia
 me-2"
                          ></i>
                          Career site Analytics
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <h6 className="mt-4">Custom Dashboard</h6>

                  {/* <h6 className="mt-4">Incoming CVs/Resumes</h6> */}

                  <div className="mail-list mt-1"></div>
                </Card>
              </Col>
              <Col
                md={9}
                className="global_scroll"
                style={{
                  maxHeight: "80vh",
                  overflowY: "scroll",
                  // height: "auto",
                }}
              >
                {this.switchTabs(this.state.activeTab)}x
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Report.propTypes = {
  // activeTab: PropTypes.string,
  // draftmails: PropTypes.any,
  // sentmails: PropTypes.any,
  // trashmails: PropTypes.any,
  // onGetSentMails: PropTypes.func,
  // onGetDraftMails: PropTypes.func,
  // onGetTrashMails: PropTypes.func,
  // inboxmails: PropTypes.array,
  // starredmails: PropTypes.array,
  // onGetInboxMails: PropTypes.func,
  // onGetStarredMails: PropTypes.func,
  // importantmails: PropTypes.array,
  // onGetImportantMails: PropTypes.func,
  // importantmails: PropTypes.array,
  // onGetImportantMails: PropTypes.func,
  // importantmails: PropTypes.array,
  // onGetImportantMails: PropTypes.func,
  // importantmails: PropTypes.array,
  // onGetImportantMails: PropTypes.func,
};

const mapStateToProps = ({ mails }) => ({
  inboxmails: mails.inboxmails,
  starredmails: mails.starredmails,
  importantmails: mails.importantmails,
  trashmails: mails.trashmails,
  draftmails: mails.draftmails,
  sentmails: mails.sentmails,
});

export default withRouter(Reports);
