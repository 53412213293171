import React from "react";

function BubbleLoader() {
  return (
    <>
      <div
        className="loader_container"
        style={{ height: "50vh",display:"flex",marginLeft:"50%",justifyContent:"center"}}
      >
        <div className="loader"></div>
      </div>
    </>
  );
}

export default BubbleLoader;
