import * as React from "react";
import { Box, Modal } from "@mui/material";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import { makeStyles } from '@mui/styles';
import FileViewer from "react-file-viewer";
import { imageURL } from "common/constants/commonURLS";
import dataNotFound from "../../../../assets/images/noData.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  height: "85vh",
  //   overflowY:'scroll',
  p: 4,
};

export default function ViewResume({ resumeUrl, open, toggleModel, file }) {
  //   const classes = useStyles()
  // console.log(resumeUrl,typeof file,file?.length,"v");
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
        }}
        // aria-labelledby="edit-profile-modal-title"
        // aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          {!Boolean(resumeUrl) ? (
            <img
              style={{
                width: "350px",
                height: "350px",
                marginTop: 0,
                transform: "translateX(45%)",
              }}
              src={dataNotFound}
              alt="/not-found"
            />
          ) : (
            <FileViewer
              fileType={
                !["pdf","docx","xlsx","csv"].includes(resumeUrl.split(".").pop())
                  ? "docx"
                  : resumeUrl.split(".").pop()
              }
              filePath={`${imageURL}${resumeUrl}`}
              // filePath={`${imageURL}${selectedFiles}`}
              errorComponent={<>Error</>}
              onError={e => {
                console.log("error", e);
              }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
