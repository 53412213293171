import axios from "axios";
import { toast } from "react-hot-toast";
// const API_URL = "http://192.168.29.162:3001/api";

const API_URL = "https://backend.zepul.com/api";

const axiosApi = axios.create({
  baseURL: API_URL,
});
function listner() {
  return JSON.parse(localStorage.getItem("authUser"))?.token;
  return store.getState()?.Login?.data?.access;
}
export default async function callApi(
  url,
  data = {},
  method = "GET",
  config = {}
) {
  let newURl = url;
  if (url?.startsWith("/")) {
    newURl = url?.replace("/", "");
  }

  return await axiosApi({
    method: method,
    url: newURl,
    data,
    headers: {
      Authorization: "Bearer " + listner(),
      ...config,
    },
    // ...config,
  })
    .then(res => {
      if (res.status === 401) {
        localStorage.removeItem("authUser");
      }
      if (res.status === 200 || res.status === 201) {
        // if (res?.data?.message?.length > 0) toast.success(res?.data?.message);
      }
      return { data: res.data, status: res.status };
    })
    .catch(error => {
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
      }

      if (error.response) {
        return { status: error?.response.status, data: error.response.data };
      } else if (error.request) {
        toast.error("Internal Server Error");
      } else {
        toast.error(error.response?.data.message);
      }
    });
}
