import * as React from "react";
import {
  PrimaryPopUp,
  GrayDivider,
  StyledListItemButton,
  StyledProfilePopText,
  BlackTypography,
} from "assets/Mui/globalTheme";
// import { useDispatch, useSelector } from "react-redux";
import ClipBoardData from "helpers/dashboard/clipboard";
import SearchParamHook from "hooks/searchParams";
// import { startScreening } from "helpers/services/screeningPartner/screeningPartner";
import ErrorHandlingToaster from "helpers/Toaster/error";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { makeStyles } from "@mui/styles";
import {useParams,useHistory} from 'react-router-dom'

const useStyles = makeStyles({
    label:{
        display:'flex',
        padding:'12px',
        alignItems:'center',
        gap:'6px',
        cursor:'pointer',
        "&:hover":{
            background:'rgba(0, 0, 0, 0.04)'
        },
        "& svg":{
            marginLeft:'12px',
        }
    }
})


export default function KanbanPopup({handleClose, anchorEl,handlePeople }) {
//   const user = useSelector(state => state?.Login);
  const {id:jobId} = useParams()
  const history = useHistory()
  const classes = useStyles()
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };
//   const userJobPostId = searchParams.get("user_job_post_id");
  const open=Boolean(anchorEl)
  const id = open ? 'transition-popper' : undefined;
  return (
    <>
      <PrimaryPopUp
        id={id}
        open={open}
        PaperProps={MenuProps}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // sx={{p:"0px !important"}}
      >
        <div
        className={classes.label}
          onClick={() => {
              handlePeople()
          }}
        >
          <FeatherIcon icon='plus' size={14} />
            <BlackTypography variant="h6" component="h6">
              Add People
            </BlackTypography>
        </div>
        <div
        className={classes.label}
          onClick={() => {
              // startScreeningJob();
            history.push(`/job-edit/${jobId}`)
          }}
        >
          <FeatherIcon icon='edit' size={14} />
            <BlackTypography variant="h6" component="h6">
              Edit
            </BlackTypography>
        </div>
        {/* <StyledListItemButton>
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Edit
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton> */}
        {/* <StyledListItemButton
          onClick={() => {
            handleClose();
            ClipBoardData(window.location.href, "Candidate profile");
          }}
        >
          <StyledProfilePopText>
            <BlackTypography variant="h6" component="h6">
              Share candidate profile
            </BlackTypography>
          </StyledProfilePopText>
        </StyledListItemButton> */}
        {/* <GrayDivider /> */}
      </PrimaryPopUp>
    </>
  );
}
