import React from "react";
import { Col, Label, Row } from "reactstrap";
import "antd/dist/antd.css";
import ProjectCompletionTime from "./ProjectCompletionTime";
import { connect } from "react-redux";
import { updateJob } from "store/jobs/actions";
import "../../../../../assets/css/jobs/jobs.css";

const ContractTimeInWeek = ({ hours_per_week, updateJob, error }) => {
  function selectTimeDetails(data) {
    if (hours_per_week !== "")
      switch (data) {
        default:
          return <ProjectCompletionTime error={error} />;
      }
  }
  const data = [
    {
      id: 1,
      data: "less_than_30",
      name: "Less than 30 hrs/week",
    },
    {
      id: 2,
      data: "more_than_30",
      name: "More than 30 hrs/week",
    },
    {
      id: 3,
      data: "flexible",
      name: "Flexible Hours",
    },
  ];
  const handleSelect = (id, data) => {
    // setTab(id + 1);
    updateJob({ hours_per_week: data });
  };
  return (
    <>
      <Label className=" mt-4">
        How many Hours per week you need?<span className="red">*</span>
      </Label>
      <Col xs={8} className="mt-2">
        <Row>
          {data.map((value, idx) => {
            return (
              <Col xs={4} className="py-2">
                <div
                  style={{
                    height: "100px",
                    width: "90%",
                    transition: "0.4s ease-in-out",
                  }}
                  className={`job_hours ${
                    hours_per_week === value.data ? "selected_hours" : " "
                  }`}
                  onClick={() => {
                    handleSelect(idx, value.data);
                  }}
                >
                  <Label>{value.name}</Label>
                </div>
              </Col>
            );
          })}
        </Row>
      </Col>
      {selectTimeDetails(hours_per_week)}
    </>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  hours_per_week: Jobs.hours_per_week,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractTimeInWeek);
