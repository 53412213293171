import {
  ACCOUNT_MANAGER,
  BVC,
  CA,
  MODERATOR,
  ZA,
} from "common/constants/userModules";
import {
  deleteSingleAdminStaff,
  getAllAdminStaffList,
  updateAdminStaff,
} from "helpers/services/adminStaff/adminStaff";
import {
  getAllCompanyStaffList,
  updateCompanyStaff,
} from "helpers/services/companies/company";
import empty from "../../assets/images/zepul/no_data.svg";
import findUserType from "helpers/functions/findUserType";
import React from "react";
import { Alert, Button, Col, Row } from "reactstrap";
import {
  Card,
  FormControlLabel,
  FormGroup,
  CardContent,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import "../../assets/css/settings.css";
import StaffModel from "./StaffModel/StaffModel";
import { IOSSwitch } from "components/Common/iosswitch";
import {
  createRecrutementCompanyStaff,
  getAllRecrutementCompanyStaffList,
  updateRecrutementCompanyStaff,
} from "helpers/services/RecrutementCompany/recrutement";
import {
  getAllBackgroundVerificationCompanyStaffList,
  updateBackgroundVerificationCompanyStaff,
} from "helpers/services/BackgroundVerifications/backgroundVerifications";
import {
  GreyTypography,
  PrimaryAvatar,
  PrimaryButton,
  PrimaryDivider,
  PrimarySwitch,
  PrimaryTypography,
  StyledCard,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      headTag: "",
      RStaff: [],
      backendData: "",
      purpose: "",
      id: "",
      accountManager: [],
      moderator: [],
      update: false,
      BVCStaff: [],
      role: "SP",
      staff: [],
      loading: true,
      isApprove: ""
    };

    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
  }
  async componentDidMount() {
    const user = await findUserType();
    this.setState({ role: user });
    if (user === ZA) {
      const data = await getAllAdminStaffList();
      data.map(item => {
        if (item.usertype === "MODERATOR") {
          this.setState(prevState => ({
            moderator: [...prevState.moderator, item],
          }));
        }
        if (item.usertype === "ACCOUNT_MANAGER") {
          this.setState(prevState => ({
            accountManager: [...prevState.accountManager, item],
          }));
        }
      });
    } else if (user === CA) {
      const data = await getAllCompanyStaffList();
      data.map(item => {
        this.setState(prevState => ({
          staff: [...prevState.staff, item],
        }));
      });
    } else if (user === BVC) {
      const { data } = await getAllBackgroundVerificationCompanyStaffList();
      data.map(item => {
        this.setState(prevState => ({
          BVCStaff: [...prevState.BVCStaff, item],
        }));
      });
    } else {
      const { data } = await getAllRecrutementCompanyStaffList();
      data?.map(item => {
        this.setState(prevState => ({
          RStaff: [...prevState.RStaff, item],
        }));
      });
    }
    this.setState({ loading: false });
  }
  async componentDidUpdate(_, prevState) {
    if (prevState.update !== this.state.update) {
      if (this.state.role === ZA) {
        const data = await getAllAdminStaffList();
        this.setState({ accountManager: [], moderator: [] });
        data.map(item => {
          if (item.usertype === "MODERATOR") {
            this.setState(prevState => ({
              moderator: [...prevState.moderator, item],
            }));
          }
          if (item.usertype === "ACCOUNT_MANAGER") {
            this.setState(prevState => ({
              accountManager: [...prevState.accountManager, item],
            }));
          }
        });
      } else if (this.state.role === CA) {
        const data = await getAllCompanyStaffList();

        this.setState(() => ({
          staff: data,
        }));
      } else if (this.state.role === BVC) {
        const { data } = await getAllBackgroundVerificationCompanyStaffList();
        this.setState(() => ({
          BVCStaff: data,
        }));
      } else {
        const { data } = await getAllRecrutementCompanyStaffList();
        this.setState(() => ({
          RStaff: data,
        }));
      }
    }
  }

  toggle(headTag = "", backendData = "", purpose = "", id = "",isApprove="Approve") {
    this.setState({
      headTag: headTag,
      backendData: backendData,
      isOpen: !this.state.isOpen,
      purpose,
      id,
      isApprove
    });
  }
  update = () => {
    this.setState({ update: !this.state.update });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <Typography component="div" variant={"h3"}>
              <Skeleton />
            </Typography>
            <Typography component="div" variant={"h3"}>
              <Skeleton />
            </Typography>{" "}
            <Typography component="div" variant={"h3"}>
              <Skeleton />
            </Typography>
          </>
        ) : (
          <React.Fragment>
            <Row>
              <StaffModel
                isOpen={this.state.isOpen}
                toggle={this.toggle}
                headTag={this.state.headTag}
                backendData={this.state.backendData}
                purpose={this.state.purpose}
                id={this.state.id}
                isApprove={this.state.isApprove}
                updated={this.update}
                role={this.state.role}
              />

              <Row>
                {this.state.role === ZA ? (
                  <>
                    <Col xs={12} md={6}>
                      <div
                        className="email_brand_div"
                        style={{
                          marginBottom: "10px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="company_grid">
                          <div className="alert_small">
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems={"center"}
                            >
                              <PrimaryDivider
                                sx={{
                                  borderTopRightRadius: `5px`,
                                  borderBottomRightRadius: `5px`,
                                  height: "30px",
                                  paddingLeft: "4px",
                                }}
                                orientation="vertical"
                                flexItem
                              />
                              <PrimaryTypography component="h4" variant="h4">
                                Moderator
                              </PrimaryTypography>
                            </Stack>
                          </div>
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            color="primary"
                            onClick={() =>
                              this.toggle(
                                "Add Moderator",
                                "MODERATOR",
                                "CREATE"
                              )
                            }
                            startIcon={<FeatherIcon icon="plus" size="14" />}
                          >
                            Add
                          </PrimaryButton>
                        </div>
                      </div>
                      {this.state.moderator.length > 0 ? (
                        this.state.moderator?.map((item, index) => (
                          <Card style={{ marginBottom: "0px" }}>
                            <Row
                              className="email_brands email_brand_div"
                              key={index}
                            >
                              <Col xs={6} className="brand_wrapper">
                                <PrimaryAvatar>
                                  <span>{item.email.charAt(0)}</span>
                                </PrimaryAvatar>
                                <div>
                                  <h5>{item.user_name}</h5>
                                  <h6 style={{ color: "gray" }}>
                                    {item.email}
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={3}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <PrimarySwitch
                                        sx={{ m: 1 }}
                                        defaultChecked={item.is_active}
                                        onChange={async e => {
                                          if (item.is_active)
                                            await deleteSingleAdminStaff(
                                              item.id
                                            );
                                          else {
                                            const data = await updateAdminStaff(
                                              {
                                                ...item,
                                                is_active: true,
                                                usertype: MODERATOR,
                                              },
                                              item.id
                                            );
                                          }
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={3} className="edit_del_btn">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.toggle(
                                      "Edit Moderator",
                                      "MODERATOR",
                                      "EDIT",
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      ) : (
                        <div className="no_data_staff">
                          <img className="lg_img" src={empty} />
                          <GreyTypography variant="h6">
                            No Moderators
                          </GreyTypography>{" "}
                        </div>
                      )}
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        className="email_brand_div"
                        style={{
                          marginBottom: "10px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      >
                        <div className="company_grid">
                          <div className="alert_small">
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems={"center"}
                            >
                              <PrimaryDivider
                                sx={{
                                  borderTopRightRadius: `5px`,
                                  borderBottomRightRadius: `5px`,
                                  height: "30px",
                                  paddingLeft: "4px",
                                }}
                                orientation="vertical"
                                flexItem
                              />
                              <PrimaryTypography component="h4" variant="h4">
                                Account Manger
                              </PrimaryTypography>
                            </Stack>
                          </div>{" "}
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() =>
                              this.toggle(
                                "Add Account Manager",
                                "ACCOUNT_MANAGER",
                                "CREATE"
                              )
                            }
                            startIcon={<FeatherIcon icon="plus" size="14" />}
                          >
                            Add
                          </PrimaryButton>
                        </div>
                      </div>
                      <div>
                        {this.state.accountManager.length > 0 ? (
                          this.state.accountManager?.map((item, index) => (
                            <Card style={{ marginBottom: "0px" }}>
                              <Row
                                className="email_brands email_brand_div"
                                key={index}
                              >
                                <Col xs={6} className="brand_wrapper">
                                  <PrimaryAvatar>
                                    <span>{item.email.charAt(0)}</span>
                                  </PrimaryAvatar>
                                  <div>
                                    <h5>{item.user_name}</h5>
                                    <h6 style={{ color: "gray" }}>
                                      {item.email}
                                    </h6>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <PrimarySwitch
                                          sx={{ m: 1 }}
                                          defaultChecked={item.is_active}
                                          onChange={async e => {
                                            if (item.is_active)
                                              await deleteSingleAdminStaff(
                                                item.id
                                              );
                                            else {
                                              const data =
                                                await updateAdminStaff(
                                                  {
                                                    ...item,
                                                    is_active: true,
                                                    usertype: ACCOUNT_MANAGER,
                                                  },
                                                  item.id
                                                );
                                            }
                                          }}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={3} className="edit_del_btn">
                                  <Button
                                    color="success"
                                    onClick={() => {
                                      this.toggle(
                                        "Edit Account Manger",
                                        "ACCOUNT_MANAGER",
                                        "EDIT",
                                        item.id
                                      );
                                    }}
                                  >
                                    <i className="bx bx-edit"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          ))
                        ) : (
                          <div className="no_data_staff">
                            <img className="lg_img" src={empty} />
                            <GreyTypography variant="h6">
                              No Account Managers
                            </GreyTypography>{" "}
                          </div>
                        )}
                      </div>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {this.state.role === CA ? (
                  <Col xs={12} md={12}>
                    <div
                      className="email_brand_div"
                      style={{
                        marginBottom: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="company_grid">
                        <div className="alert_small">
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <PrimaryDivider
                              sx={{
                                borderTopRightRadius: `5px`,
                                borderBottomRightRadius: `5px`,
                                height: "30px",
                                paddingLeft: "4px",
                              }}
                              orientation="vertical"
                              flexItem
                            />
                            <PrimaryTypography component="h4" variant="h4">
                              Staff
                            </PrimaryTypography>
                          </Stack>
                        </div>{" "}
                        <PrimaryButton
                          sx={{ width: "100px" }}
                          onClick={() =>
                            this.toggle("Add Staff", "COMPANY_STAFF", "CREATE")
                          }
                          startIcon={<FeatherIcon icon="plus" size="14" />}
                        >
                          Add
                        </PrimaryButton>
                      </div>
                    </div>
                    <div>
                      {this.state.staff.length > 0 ? (
                        this.state.staff?.map((item, index) => (
                          <Card style={{ marginBottom: "0px" }}>
                            <Row
                              className="email_brands email_brand_div"
                              key={index}
                            >
                              <Col xs={6} className="brand_wrapper">
                                <PrimaryAvatar>
                                  {item.email.charAt(0)}
                                </PrimaryAvatar>
                                <div>
                                  <h5>
                                    {item.first_name} {item.last_name}
                                  </h5>
                                  <h6 style={{ color: "gray" }}>
                                    {item.email}
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={3}>
                                {" "}
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <PrimarySwitch
                                        sx={{ m: 1 }}
                                        defaultChecked={item.is_active}
                                        onChange={async e => {
                                          if (item?.is_active)
                                            await updateCompanyStaff(
                                              {
                                                email: item.email,
                                                first_name: item.first_name,
                                                last_name: item.last_name,
                                                is_active: !item?.is_active,
                                              },
                                              item.id
                                            );
                                          else {
                                            const data =
                                              await updateCompanyStaff(
                                                {
                                                  email: item.email,
                                                  first_name: item.first_name,
                                                  last_name: item.last_name,
                                                  is_active: !item?.is_active,
                                                },
                                                item.id
                                              );
                                          }
                                          const index =
                                            this.state.staff.findIndex(
                                              ({ id }) => id === item.id
                                            );
                                          data = [...this.state.staff];
                                          data[index].is_active =
                                            !data[index].is_active;
                                          this.setState({ staff: data });
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={3} className="edit_del_btn">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.toggle(
                                      "Edit Staff",
                                      "COMPANY_STAFF",
                                      "EDIT",
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      ) : (
                        <div className="no_data_staff">
                          <img className="lg_img" src={empty} />
                          <GreyTypography variant="h6">
                            No Company Staff
                          </GreyTypography>{" "}
                        </div>
                      )}{" "}
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.role === "RC" ? (
                  <Col xs={12} md={12}>
                    <div
                      className="email_brand_div"
                      style={{
                        marginBottom: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="company_grid">
                        <div className="alert_small">
                          <Alert color="success" className="alert_small_comp">
                            <p className="bold_success">Staff</p>
                          </Alert>
                        </div>{" "}
                        <Button
                          color="primary"
                          onClick={() =>
                            this.toggle(
                              "Add RECRUTEMENT_STAFF",
                              "RECRUTEMENT_STAFF",
                              "CREATE"
                            )
                          }
                        >
                          <span className="mdi mdi-plus"></span> Add
                        </Button>
                      </div>
                    </div>
                    <div>
                      {this.state.RStaff.length > 0 ? (
                        this.state.RStaff?.map((item, index) => (
                          <Card style={{ marginBottom: "0px" }}>
                            <Row
                              className="email_brands email_brand_div"
                              key={index}
                            >
                              <Col xs={6} className="brand_wrapper">
                                <PrimaryAvatar>
                                  {item.email.charAt(0)}
                                </PrimaryAvatar>
                                <div>
                                  <h5>{item.user_name}</h5>
                                  <h6 style={{ color: "gray" }}>
                                    {item.email}
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={3}>
                                {" "}
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <PrimarySwitch
                                        sx={{ m: 1 }}
                                        defaultChecked={item.is_active}
                                        onChange={async e => {
                                          if (item?.is_active)
                                            await updateRecrutementCompanyStaff(
                                              {
                                                email: item.email,
                                                first_name: item.first_name,
                                                last_name: item.last_name,
                                                is_active: !item?.is_active,
                                              },
                                              item.id
                                            );
                                          else {
                                            const data =
                                              await updateRecrutementCompanyStaff(
                                                {
                                                  email: item.email,
                                                  first_name: item.first_name,
                                                  last_name: item.last_name,
                                                  is_active: !item?.is_active,
                                                },
                                                item.id
                                              );
                                          }
                                          const index =
                                            this.state.staff.findIndex(
                                              ({ id }) => id === item.id
                                            );
                                          data = [...this.state.staff];
                                          data[index].is_active =
                                            !data[index].is_active;
                                          this.setState({ staff: data });
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={3} className="edit_del_btn">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.toggle(
                                      "Edit Staff",
                                      "RECRUTEMENT_STAFF",
                                      "EDIT",
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      ) : (
                        <div className="no_data_staff">
                          <img className="lg_img" src={empty} />
                          <GreyTypography variant="h6">
                            No Company Staff
                          </GreyTypography>{" "}
                        </div>
                      )}{" "}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                {this.state.role === "BVC" ? (
                  <Col xs={12} md={12}>
                    <div
                      className="email_brand_div"
                      style={{
                        marginBottom: "10px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="company_grid">
                        <div className="alert_small">
                          <Alert color="success" className="alert_small_comp">
                            <p className="bold_success">Staff</p>
                          </Alert>
                        </div>{" "}
                        <Button
                          color="primary"
                          onClick={() =>
                            this.toggle(
                              "Add BVC_STAFF",
                              "BACKGROUND_VERIFICATION_STAFF",
                              "CREATE"
                            )
                          }
                        >
                          <span className="mdi mdi-plus"></span> Add
                        </Button>
                      </div>
                    </div>
                    <div>
                      {this.state.BVCStaff.length > 0 ? (
                        this.state.BVCStaff?.map((item, index) => (
                          <Card style={{ marginBottom: "0px" }}>
                            <Row
                              className="email_brands email_brand_div"
                              key={index}
                            >
                              <Col xs={6} className="brand_wrapper">
                                <div className="pofile_banner">
                                  <span style={{ textTransform: "uppercase" }}>
                                    {item.email.charAt(0)}
                                  </span>
                                </div>
                                <div>
                                  <h5>{item.user_name}</h5>
                                  <h6 style={{ color: "gray" }}>
                                    {item.email}
                                  </h6>
                                </div>
                              </Col>
                              <Col xs={3}>
                                {" "}
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <PrimarySwitch
                                        sx={{ m: 1 }}
                                        defaultChecked={item.is_active}
                                        onChange={async e => {
                                          if (item?.is_active)
                                            await updateBackgroundVerificationCompanyStaff(
                                              {
                                                email: item.email,
                                                first_name: item.first_name,
                                                last_name: item.last_name,
                                                is_active: !item?.is_active,
                                              },
                                              item.id
                                            );
                                          else {
                                            const data =
                                              await updateBackgroundVerificationCompanyStaff(
                                                {
                                                  email: item.email,
                                                  first_name: item.first_name,
                                                  last_name: item.last_name,
                                                  is_active: !item?.is_active,
                                                },
                                                item.id
                                              );
                                          }
                                          const index =
                                            this.state.staff.findIndex(
                                              ({ id }) => id === item.id
                                            );
                                          data = [...this.state.staff];
                                          data[index].is_active =
                                            !data[index].is_active;
                                          this.setState({ staff: data });
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={3} className="edit_del_btn">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.toggle(
                                      "Edit Staff",
                                      "BACKGROUND_VERIFICATION_STAFF",
                                      "EDIT",
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))
                      ) : (
                        <div className="no_data_staff">
                          <img className="lg_img" src={empty} />
                          <GreyTypography variant="h6">
                            No Company Staff
                          </GreyTypography>{" "}
                        </div>
                      )}{" "}
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Row>
          </React.Fragment>
        )}
      </>
    );
  }
}

export default Staff;
