import callApi from "helpers/callApi";

export const createFormsApi = async data => {
  return await callApi("/forms", data, "POST");
};
export const getAllForms = async user_job_post_id => {
  return await callApi(`/forms?user_job_post_id=${user_job_post_id}`);
};
export const getAllFormsByUserJobPostId = async id => {
  return await callApi(`/forms?user_job_post_id=${id}`);
};
export const editForm = async (id, data) => {
  return await callApi(`/forms/${id}`, data, "PUT");
};
export const changeFormStatus = async data => {
  return await callApi(`/forms`, data, "DELETE");
};
export const getFormsById = async id => {
  return await callApi(`/forms/${id}`);
};
export const formSubmission = async data => {
  return await callApi(`/forms_submission`, data, "POST");
};

export const updateFormSubmission = async (id, data) => {
  return await callApi(`/forms_submission/${id}`, data, "PUT");
};
