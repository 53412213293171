import React from "react";
import { Alert, Col, Row } from "reactstrap";
import "../../assets/css/settings.css";
import "../../assets/css/settings/integrations.css";
import "./SettingCss/Integration.css";
import { PrimarySwitch } from "assets/Mui/globalTheme";
import ComingSoon from "pages/comingSoon";
class Integrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [
        {
          name: "Google Meet",
          url: "https://www.google.com",
          url_name: "google.com",
          status: false,
          desc: "Meet Anyone Seamlessly",
          img: "https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/48/google-meet-1024.png",
        },
        {
          name: "Google Calendar",
          url: "https://www.google.com",
          url_name: "google.com",
          status: false,
          desc: "View Calendar Analytics",
          img: "https://cdn1.iconfinder.com/data/icons/google-new-logos-1/32/google_calendar_new_logo-1024.png",
        },
        {
          name: "Zoom",
          url: "https://www.google.com",
          url_name: "zoom.com",
          status: false,
          desc: "View Zoom Analytics",
          img: "https://blog.logomyway.com/wp-content/uploads/2021/08/zoom-icon.png",
        },
        {
          name: "Outlook",
          url: "https://www.google.com",
          url_name: "Outlook.com",
          status: false,
          desc: "View Outlook Analytics",
          img: "https://logos-world.net/wp-content/uploads/2021/02/Outlook-Logo.png",
        },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        {/* <Row>
          <Col xs={12} className="email_wrapper">
            <React.Fragment>
              <div className="email_brand_div integration_main_div">
                <div className="setting_integrations_cards_cont">
                  {this.state.brands.map(item => (
                    <div key={item.name}>
                      <div className="integrations_card">
                        <div className="integrations_card_headers">
                          <div>
                            <div>
                              <h4>{item.name}</h4>
                            </div>
                            <div></div>
                          </div>

                          <div className="small_integration_img_cont">
                            <img
                              style={{
                                objectFit: "contain",
                              }}
                              className="small_integration_img"
                              src={item.img}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="integrations_card_header">
                            <div>
                              <p>{item.desc}</p>
                            </div>
                          </div>

                          <div className="integration_card_bottom">
                            <div>
                              <button className="integration_button">
                                View Integration
                              </button>
                            </div>
                            <div>
                              {" "}
                              <PrimarySwitch defaultChecked />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          </Col>
        </Row> */}
        <ComingSoon />
      </React.Fragment>
    );
  }
}
export default Integrations;
