import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Input, Label, Row, Container } from "reactstrap";
class CreateCandidate extends React.Component {
  render() {
    return (
      <Container fluid>
        <Row style={{ marginTop: "70px", marginLeft: "20px" }}>
          <Col xs={12} style={{ padding: "10px 0px" }}>
            <h3>Create Candidate</h3>
          </Col>
          <Row>
            <Card>
              <CardBody>
                <Row className="align-items-end">
                  <Col md={6}>
                    <Label>Employer</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={6}>
                    <Label>Title</Label>
                    <Input type="file" />
                  </Col>
                </Row>
                {/* <Row md={12}>
                  <p className="yellow">Select a employer for candidate</p>
                </Row> */}

                {/* <Row>
                  <Col md={3}>
                    <Label>Active</Label>
                    <div className="d-flex gap-2">
                      <Input type="checkbox" id="act" />
                      <Label htmlFor="act">Allow Active job</Label>
                    </div>
                  </Col>
                  <Col md={9}>
                    <Label>Is Featured</Label>
                    <div className="d-flex gap-2">
                      <Input type="checkbox" id="feat" />
                      <Label htmlFor="feat">Is Featured</Label>
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col md={6}>
                    <Label>candidate Name</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={6}>
                    <Label>candidate Email</Label>
                    <Input type="mail" />
                  </Col>
                </Row>
                {/* <Row>
                  <Col md={4}>
                    <Label>candidate CEO</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Industry</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>OwnerShip</Label>
                    <Input type="text" />
                  </Col>
                </Row> */}
                <Row>
                  <Col md={4}>
                    <Label>No of Employees</Label>
                    <Input type="selct">
                      <option>1-10</option>
                      <option>10-100</option>
                      <option>101-1000</option>
                      <option>1001-10000</option>
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label>No of Office</Label>
                    <Input type="selct">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </Input>{" "}
                  </Col>
                  <Col md={4}>
                    <Label>Established</Label>
                    <Input type="selct">
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                      <option>2019</option>
                    </Input>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Label>Description</Label>
                  </Col>
                  <Col>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      placeholder="Place Your Content Here..."
                    />{" "}
                  </Col>
                </Row>

                <Row>
                  <Col md={8}>
                    <Label>Address</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>City</Label>
                    <Input type="selct">
                      <option>Alabama</option>
                      <option>Alaska</option>
                      <option>Arisona</option>
                      <option>Washington</option>
                    </Input>{" "}
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Label>Website URL</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Phone</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Fax</Label>
                    <Input type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Label>Facebook Link</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Twitter Link</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Linkedin Link</Label>
                    <Input type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Label>Youtube Link</Label>
                    <Input type="text" />
                  </Col>{" "}
                  <Col md={4}>
                    <Label>Pinterest Link</Label>
                    <Input type="text" />
                  </Col>
                </Row>
                {/* <CardFooter className="my-auto"> */}
                <Row
                  className="justify-content-end"
                  style={{ marginBottom: "20px", marginTop: "10px" }}
                >
                  <Col xs={12} className="mx-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.props.history.push("/");
                      }}
                    >
                      Save
                    </button>
                  </Col>
                </Row>
                {/* </CardFooter> */}
              </CardBody>
            </Card>
          </Row>
        </Row>
      </Container>
    );
  }
}

export default withRouter(CreateCandidate);
