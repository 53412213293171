import { Box, MenuItem, Select } from "@mui/material";
import { MenuProps, PrimaryNormalInputs } from "assets/Mui/globalTheme";
import { getAllIndustries } from "helpers/services/jobAttributes/industries";
import React, { useEffect } from "react";

export default function IndustryInput(props) {
  React.useEffect(() => {
    getIndustries();
  }, []);
  const [selectedIndustries, setSelectedIndustries] = React.useState([]);

  const [industries, setIndustries] = React.useState([]);

  async function getIndustries() {
    const data = await getAllIndustries();
    setIndustries(data);
  }
  const { item, applyValue, focusElementRef } = props;
  useEffect(() => {
    if (item?.value?.length > 0) {
      setSelectedIndustries(item?.value);
    }
  }, [item?.value]);
  const handleChange = (event, newValue) => {
    const {
      target: { value },
    } = event;

    setSelectedIndustries(typeof value === "string" ? value.split(",") : value);
    applyValue({
      ...item,
      value: typeof value === "string" ? value.split(",") : value,
    });
  };
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        height: 48,
        pl: "20px",
      }}
    >
      <Select
        sx={{ minWidth: "100px" }}
        multiple
        labelId="folloups-industry-outlined"
        id="folloups-industry-outlined"
        value={selectedIndustries}
        onChange={handleChange}
        renderValue={selected => {
          if (selected.length === 0) {
            return <span>All</span>;
          }
          return selected?.join(",");
        }}
        input={
          <PrimaryNormalInputs
            multiline
            sx={{ maxWidth: "100px" }}
            tabs={4}
            label="folloups-industry-outlined"
          />
        }
        MenuProps={MenuProps}
      >
        {industries?.map(item => {
          return (
            <MenuItem
              key={item?.name}
              value={item?.name} // style={getStyles(name, personName, theme)}
            >
              {item?.name}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}
