import { Stack } from "@mui/material";
import { PrimaryDivider, PrimaryTypography } from "assets/Mui/globalTheme";

export default function TextDivider({ type }) {
  return (
    <Stack direction="row" spacing={1} alignItems={"center"}>
      <PrimaryDivider
        sx={{
          borderTopRightRadius: `5px`,
          borderBottomRightRadius: `5px`,
          height: "30px",
          paddingLeft: "4px",
        }}
        orientation="vertical"
        flexItem
      />
      <PrimaryTypography
        sx={{ textTransform: "capitalize" }}
        component="h4"
        variant="h4"
      >
        {type}
      </PrimaryTypography>
    </Stack>
  );
}
