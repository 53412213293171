import React from "react";
import "../../assets/css/email.css";

import { Button, Card, Col, Row } from "reactstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import RoundChart from "./rounChart";

class Disqualifications extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <div className="candidate_header email_brand_div">
              <div>
                <h4>Disqualifications</h4>
                <p>
                  Overview of disqualifications in the hiring pipelines or
                  talent pools.
                </p>
              </div>
              <Button color="primary">Help</Button>
            </div>
          </Col>
        </Row>

        <Row>
          {new Array(10).fill("hi").map((item, index) => {
            return (
              <Col md={6} key={index}>
                <Card className="d-flex align-items-center">
                  <Row>
                    <Col md={12} className="pt-2">
                      <div className="justify-content-center row">
                        <div className="col-sm-4">
                          <div className="text-center">
                            <h5 className="mb-0">
                              {Math.round(Math.random() * 80)}
                            </h5>
                            <p className="text-muted text-truncate">
                              Activated
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="text-center">
                            <h5 className="mb-0">
                              {Math.round(Math.random() * 70)}
                            </h5>
                            <p className="text-muted text-truncate">Pending</p>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="text-center">
                            <h5 className="mb-0">
                              {Math.round(Math.random() * 60)}
                            </h5>
                            <p className="text-muted text-truncate">
                              Deactivated
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="d-flex justify-content-center align-items-center">
                        <RoundChart />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row></Row>
      </React.Fragment>
    );
  }
}

export default Disqualifications;
