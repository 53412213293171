import "../../../assets/css/dashboard.css";
import React, { useEffect, useReducer, useState } from "react";

import { CardBody, Container, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import JobList from "../../../components/Jobs/list";
import ClosedJobs from "../../../components/Jobs/closedJobs";
import Pending from "../../../components/Jobs/pending";
import { ACM, ZA } from "common/constants/userModules";
import JobsBasedOnCompanies from "./jobsBasedOnCompanies";
import { getAllJobs, getSingleJobDetails } from "helpers/services/jobs/jobs";
import ErrorHandlingToaster from "helpers/Toaster/error";
import SearchParamHook from "hooks/searchParams";
import DetailsDrawer from "components/JobDetails/DetailDrawer";
import { Stack } from "@mui/material";
import {
  PrimaryButton,
  PrimaryTextButton,
  SecondaryButton,
  SolidTab,
  StyledLoginTabsObject,
  StageSearchInput,
} from "assets/Mui/globalTheme";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Tabs } from "@mui/material";
import searchFilter from "components/Common/filters/searchFilter";

function WholeJobs({ history, match }) {
  const searchParams = SearchParamHook();
  const [openSearch, setOpenSearch] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    application_deadline: new Date(),
    createdAt: new Date(),
  });
  useEffect(() => {
    const data = searchParams.get("id");
    if (data) {
      setShowDetails(true);
      findJobDetails(data);
    }
  }, [searchParams]);
  async function findJobDetails(id) {
    const { status, data } = await getSingleJobDetails(id);
    ErrorHandlingToaster({ data, status });
    setJobDetails(data);
  }
  const login = {
    role: JSON.parse(localStorage.getItem("authUser"))?.role,
  };
  // const [path, setPath] = useState("/joblist/pending");
  const [path, setPath] = useState("/joblist/approved");
  const [jobs, setJobs] = useState([]);
  // const [filteredJobs,setFilteredJobs] = useState([])
  const [load, setLoad] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [update, setUpdate] = useState(false);
  // useEffect(() => {
  //   setPath(location.pathname);
  // }, [location]);
  useEffect(() => {
    dataCaller();
    history.push("/joblist/approved");
  }, []);
  const toggleDrawer = React.useCallback(() => {
    setShowDetails(!showDetails);
    searchParams.delete("id");
    history.replace({
      search: searchParams.toString(),
    });
  }, [showDetails]);
  // function toggleDrawer() {
  //   setShowDetails(!showDetails);
  //   searchParams.delete("id");
  //   history.replace({
  //     search: searchParams.toString(),
  //   });
  // }

  async function dataCaller() {
    let flag = true;
    const { data, status } = await getAllJobs();
    ErrorHandlingToaster({ data, status });
    if (flag && status === 200) {
      setJobs(data);
      // setFilteredJobs(data)
      status ? setLoad(true) : "";
    }
    return () => {
      flag = false;
    };
  }
  useEffect(() => {
    if (update) dataCaller();
    else return;
    setUpdate(false);
  }, [update]);

  /*Testing Search functionality code start here*/
  const handleSearch = event => {
    const value = event.target.value;
    setFilterSearch(value.trim());
    // const jobCards = jobs
    // if (value.trim() !== "") {
    //   const filteredJobCards = jobCards?.filter(item => {
    //     const role = item?.job_title?.toLowerCase() || "";

    //     return (
    //       role.includes(value.toLowerCase()) || ""
    //     );
    //   });
    //   setFilteredJobs(filteredJobCards);
    // } else {
    //   setFilteredJobs(jobs);
    // }
  };
  /*Testing Search functionality code End here*/

  const filteredJobs = jobs?.filter(item =>
    item?.job_title?.toLowerCase().includes(filterSearch?.toLowerCase())
  );
  function NavSelection(data) {
    switch (data) {
      case "/joblist/approved":
        return (
          <JobList setUpdate={setUpdate} jobs={filteredJobs} load={load} />
        );
      case "/joblist/pending":
        return (
          <Pending setUpdate={setUpdate} jobs={filteredJobs} load={load} />
        );
      case "/joblist/closed":
        return (
          <ClosedJobs setUpdate={setUpdate} jobs={filteredJobs} load={load} />
        );
      case "/joblist/companies":
        return <JobsBasedOnCompanies />;
    }
  }
  let navBar = [
    {
      name: "Active",
      to: "/joblist/approved",
    },
    {
      name: "Pending",
      to: "/joblist/pending",
    },
    {
      name: "Closed",
      to: "/joblist/closed",
    },
  ];
  if (login.role === ACM) {
    navBar = [
      {
        name: "Active",
        to: "/joblist/approved",
      },
      {
        name: "Pending",
        to: "/joblist/pending",
      },

      {
        name: "Closed",
        to: "/joblist/closed",
      },
    ];
  }
  if (login.role === ZA) {
    navBar = [
      {
        name: "Active",
        to: "/joblist/approved",
      },
      {
        name: "Pending",
        to: "/joblist/pending",
      },

      {
        name: "Closed",
        to: "/joblist/closed",
      },
      // {
      //   name: "Company",
      //   to: "/joblist/companies",
      // },
    ];
  }

  /* Search toggle effect */
  const handleSearchOpen = () => {
    setOpenSearch(!openSearch);
  };
  /* Search toggle effect end */

  return (
    // <div className="dashboard_wrapper">
    <React.Fragment>
      <div className="page-content position-sticky top-0">
        <Container fluid>
          {login.role !== "ZM" && login.role !== "SP" ? (
            <Row className="header_jobs_sticky">
              <div className="tab_bar">
                <Tabs
                  sx={{
                    height: "20px",
                    borderRadius: "8px",
                    // ...(login.role === ZA && {
                    //   width: "25% !important",
                    // }),
                    // border: "0.5px solid rgba(0,0,0,0.5)",
                  }}
                  value={path}
                  TabIndicatorProps={StyledLoginTabsObject}
                  onChange={(_, to) => {
                    setPath(to);
                    history.push(to);
                  }}
                  centered
                >
                  {navBar.map(({ name, to }, index) => {
                    return (
                      <SolidTab
                        value={to}
                        label={name}
                        key={index.toString()}
                      />
                    );
                  })}
                </Tabs>
                {/* {navBar.map(({ name, to }, index) => {
                    return (
                      <React.Fragment key={index.toString()}>
                        {path === to ? (
                          <PrimaryButton
                            sx={{ width: "100px" }}
                            onClick={() => {
                              history.push(to);
                            }}
                            key={index.toString()}
                          >
                            {name}
                          </PrimaryButton>
                        ) : (
                          <PrimaryTextButton
                            sx={{ width: "100px" }}
                            onClick={() => {
                              history.push(to);
                            }}
                            key={index.toString()}
                          >
                            {name}
                          </PrimaryTextButton>
                        )}
                      </React.Fragment>
                    );
                  })} */}
                <Stack>
                  {openSearch ? (
                    <SearchIcon
                      onClick={handleSearchOpen}
                      sx={{
                        alignSelf: "end",
                        marginTop: "8px",
                        marginRight: "8px",
                      }}
                    />
                  ) : (
                    <StageSearchInput
                      placeholder="search..."
                      onChange={handleSearch}
                      endAdornment={
                        <IconButton edge="end" onClick={handleSearchOpen}>
                          <ClearIcon />
                        </IconButton>
                      }
                    />
                  )}
                </Stack>
              </div>
            </Row>
          ) : (
            ""
          )}
          <Row
            style={{
              marginTop: "20px",
            }}
          >
            {NavSelection(path)}
          </Row>
        </Container>
      </div>
      <DetailsDrawer
        jobDetails={jobDetails}
        toggleDrawer={toggleDrawer}
        open={showDetails}
      />
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    login: state.Login,
  };
};
export default withRouter(connect(mapStateToProps, null)(WholeJobs));
