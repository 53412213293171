import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryTypography,
  GrayDivider,
  GreyTypography,
  BlackTypography,
  PrimaryOpacitedButton,
  PrimaryButton,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import AddEditWorkExperience from "./AddEditWorkExperience";
import { updateJobPost } from "helpers/services/jobs/jobs";
import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
import SearchParamHook from "hooks/searchParams";

export default function CandidateExperience({ candidateDetails }) {
  const [workExpModel, setWorkExpModel] = useState(false);
  const [updateType, setUpdateType] = useState("create");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [workExp, setWorkExp] = useState([]);
  useEffect(() => {
    if (candidateDetails?.work_exp) {
      setWorkExp(candidateDetails?.work_exp);
    }
  }, [candidateDetails]);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const searchParams = SearchParamHook();
  useEffect(() => {
    let queryParamsForUserJobPostId = searchParams.get("user_job_post_id");
    if (queryParamsForUserJobPostId)
      setUserJobPostId(queryParamsForUserJobPostId);
  }, [searchParams]);
  function toggleModel() {
    setWorkExpModel(!workExpModel);
  }
  function updateOrEditWorExperience(work_exp_data) {
    toggleModel();
    if (updateType?.includes("update")) {
      const data = [...workExp];
      data[updateIndex] = {
        ...work_exp_data,
      };
      updateUserJobPost(userJobPostId, {
        work_exp: data,
      });
      setWorkExp(data);
    } else {
      setWorkExp([...workExp, work_exp_data]);
      updateUserJobPost(userJobPostId, {
        work_exp: [...workExp, work_exp_data],
      });
    }
  }
  return (
    <Stack spacing={2} className="mt-3">
      <AddEditWorkExperience
        toggleModel={toggleModel}
        open={workExpModel}
        type={updateType}
        currentData={workExp[updateIndex]}
        updateOrEditWorExperience={updateOrEditWorExperience}
      />
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PrimaryDivider
          sx={{
            borderTopRightRadius: `5px`,
            borderBottomRightRadius: `5px`,
            height: "30px",
            paddingLeft: "4px",
          }}
          orientation="vertical"
          flexItem
        />
        <Stack
          spacing={1}
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <PrimaryTypography component="h4" variant="h4">
            Experience Section
          </PrimaryTypography>
          <PrimaryOpacitedButton
            onClick={() => {
              toggleModel();
              setUpdateType("create");
            }}
            startIcon={<FeatherIcon icon="plus" size="14" />}
          >
            Add
          </PrimaryOpacitedButton>
        </Stack>
      </Stack>
      <BorderCard>
        <CardContent>
          <Stack spacing={3}>
            {workExp?.map(
              ({ title, employer, start_date, end_date }, workExpIndex) => {
                return (
                  <>
                    <Stack spacing={1}>
                      <BlackTypography variant="h5" component="h5">
                        {title}
                      </BlackTypography>
                      <BlackTypography variant="h6" component="h6">
                        {employer}
                      </BlackTypography>
                      <GreyTypography variant="h6" component="h6">
                        {start_date} - {end_date}
                      </GreyTypography>
                      <PrimaryButton
                        sx={{ width: "100px" }}
                        onClick={() => {
                          toggleModel();
                          setUpdateType(`update${workExpIndex}`);
                          setUpdateIndex(workExpIndex);
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    </Stack>
                  </>
                );
              }
            )}
          </Stack>
        </CardContent>
      </BorderCard>
    </Stack>
  );
}
