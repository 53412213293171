import { Button, CssVarsProvider, Sheet } from "@mui/joy";
import { Container } from "reactstrap";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import Switch from "@mui/joy/Switch";
import { useCallback, useEffect, useState } from "react";
import CreateEvaluationForms from "./creationForms";
import { changeFormStatus, getAllForms } from "helpers/services/forms";
import EditEvaluationForms from "./editForms";

export default function EvaluationForms() {
  const [checked, setChecked] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState("");
  const [forms, setForms] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const handleEditForm = () => {
    setEditForm(!editForm);
  };
  const handleCreateForm = () => {
    setCreateForm(!createForm);
  };
  useEffect(() => {
    dataFetcher();
  }, []);
  async function dataFetcher() {
    const { status, data } = await getAllForms(null);
    // data?.sort((a, b) => a.is_active - b.is_active);
    if (status === 200) setForms(data?.data);
  }

  return (
    <div className="page-content">
      <Container fluid>
        <CssVarsProvider>
          <Sheet
            variant="outlined"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              boxShadow: "sm",
              borderRadius: "sm",
              padding: "10px",
            }}
          >
            <Button
              className="ml-auto"
              sx={{ alignSelf: "flex-end" }}
              onClick={handleCreateForm}
            >
              Create Forms
            </Button>
            {forms?.map((form, index) => (
              <List size={"lg"}>
                <ListItem className="d-flex gap-4">
                  <ListItemButton>{form?.form_name}</ListItemButton>
                  <Switch
                    checked={form?.is_active}
                    onChange={async event => {
                      const { status } = await changeFormStatus({
                        id: form?.id,
                        is_active: !form?.is_active,
                      });
                      dataFetcher()
                    }}
                    color={form?.is_active ? "primary" : "neutral"}
                    variant="outlined"
                    slotProps={{
                      endDecorator: {
                        sx: {
                          minWidth: 24,
                        },
                      },
                    }}
                  />
                  <Button
                    color="success"
                    onClick={() => {
                      setEditForm(true);
                      setSelectedForm({ title: form?.form_name, id: form?.id });
                    }}
                  >
                    Edit
                  </Button>
                </ListItem>
              </List>
            ))}
          </Sheet>
          <CreateEvaluationForms
            isOpen={createForm}
            toggle={handleCreateForm}
            update={dataFetcher}
          />
          {editForm && <EditEvaluationForms
            id={selectedForm?.id}
            title={selectedForm?.title}
            isOpen={editForm}
            toggle={handleEditForm}
            update={dataFetcher}
          />}
        </CssVarsProvider>
      </Container>
    </div>
  );
}
