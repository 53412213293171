import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modal.css";

export const ViewCandidateDetailsModal = props => {
  const data = [
    {
      id: 1,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1666075400/man_jbngz2.png",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
    {
      id: 2,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1661766093/pexels-photo-927451_rpjznl.webp",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
    {
      id: 3,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1660220048/pic2_mkait2.jpg",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
    {
      id: 4,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1657728982/profile_uumsbr.png",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
    {
      id: 5,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1650879044/avatar-4_goanjj.jpg",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
    {
      id: 6,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1650879012/avatar-2_qzfbfh.jpg",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
    {
      id: 7,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1650878972/avatar-1_se7ioc.jpg",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
    {
      id: 8,
      profile:
        "https://res.cloudinary.com/dt6u679fs/image/upload/v1648295639/download_qoy840.jpg",
      name: "Dhoni",
      email: "dhoni@gmail.com",
      number: 914248272,
      date: "12 / 08 / 2022",
    },
  ];

  return (
    <div>
      <Modal
        isOpen={props.params.modal}
        toggle={props.onHide}
        className="models-main"
        size="lg"
        dialogClassName="modal-90w"
      >
        <ModalHeader
          toggle={props.onHide}
          style={{ fontSize: "12px" }}
          className="models-header"
        ></ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile Number</th>
                    <th scope="col">Date Applied</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(({ profile, name, email, number, date }, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img src={profile} className="user-profile me-2" />
                          {name}
                        </td>
                        <td>{email}</td>
                        <td>{number}</td>
                        <td>{date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
