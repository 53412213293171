import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import {
  BorderCard,
  GreyTypography,
  PrimaryAvatar,
  PrimaryButton,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getNotes } from "helpers/services/notes/getNotes";
import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AddEditNotes from "./addNotes";
import { createNotes } from "helpers/services/notes/create";
import { updateNote } from "helpers/services/notes/update";
import dataNotFound from "../../../../assets/images/noData.png";

export default function UserJobPostNoteSection() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);
  const [userJobPostId, setUserJobPostId] = useState(null);
  const notes = useSelector(state => state.Notes.notes);
  const [jobPostId, setJobPostId] = useState(null);
  const [notesModel, setNotesModel] = useState(false);
  const searchParams = SearchParamHook();
  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    dispatch({ type: "START_LOADING" });
    const userJobPostId = searchParams.get("user_job_post_id");
    if (userJobPostId) {
      setUserJobPostId(userJobPostId);
      fetchNotes(userJobPostId);
    }
    const jobPostId = searchParams.get("job_id");
    if (jobPostId) {
      setJobPostId(jobPostId);
    }
  }, [searchParams]);
  async function fetchNotes(userJobPostId) {
    const { data: notes, status } = await getNotes(userJobPostId);
    if (status === 200) {
      dispatch({
        type: "ADD_NOTES",
        payload: notes?.data,
      });
      dispatch({ type: "STOP_LOADING" });
    }
  }

  function toggleModel() {
    setNotesModel(!notesModel);
  }
  async function updateNotes(note) {
    toggleModel();
    updateNote(note?.id, {
      user_job_post_id: userJobPostId,
      job_post_id: jobPostId,
      notes: note?.notes,
    });
    dispatch({ type: "START_LOADING" });
    const { data, status } = await getNotes(userJobPostId);
    dispatch({ type: "STOP_LOADING" });
    if (status === 200) {
      dispatch({
        type: "ADD_NOTES",
        payload: data?.data,
      });
    }
  }
  if (loading) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Stack spacing={2}>
      {notes?.length ?notes?.map((item, noteIndex) => (
        <Grid container alignItems={"center"} key={item.id}>
          <Grid item xs={12} md={1}>
            <PrimaryAvatar src={item?.notes_updtby?.image}>
              {item?.notes_updtby?.user_name?.charAt("0")}
            </PrimaryAvatar>
            <PrimaryTypography variant="h5">
              {item?.notes_updtby?.user_name}
            </PrimaryTypography>
          </Grid>

          <Grid item xs={12} md={11}>
            <Stack>
              <BorderCard
                sx={{
                  padding: "10px",
                  borderBottomLeftRadius: "0px !important",
                  borderBottomRightRadius: "0px !important",
                }}
              >
                <Card elevation={0}>
                  <CardContent>
                    <PrimaryTypography
                      sx={{ maxWidth: "100% !important" }}
                      variant="h5"
                      component="h5"
                    >
                      {item?.notes}{" "}
                    </PrimaryTypography>
                  </CardContent>
                </Card>
              </BorderCard>
              <BorderCard
                sx={{
                  borderTopLeftRadius: "0px !important",
                  borderTopRightRadius: "0px !important",
                  padding: "2px",
                }}
              >
                <Stack direction="row" justifyContent={"space-between"}>
                  <GreyTypography variant="h6" component="h6">
                    {moment(item?.updatedAt)?.format(
                      "dddd, MMMM Do YYYY, h:mm:ss a"
                    )}{" "}
                  </GreyTypography>
                  {/* <IconButton>
                    <FeatherIcon icon="more-vertical" size={14} />
                  </IconButton> */}
                  <PrimaryButton
                    sx={{ maxWidth: "100px" }}
                    onClick={() => {
                      toggleModel();
                      setActiveIndex(noteIndex);
                    }}
                  >
                    Edit
                  </PrimaryButton>
                </Stack>
              </BorderCard>
            </Stack>
          </Grid>
        </Grid>
      )):
      <div style={{textAlign:"center"}}>
        <img
          style={{
            width: "350px",
            height: "350px",
            marginTop: 0,
          }}
          src={dataNotFound}
          alt="/not-found"
        />
      </div>
      }
      <AddEditNotes
        open={notesModel}
        type="update"
        currentData={notes[activeIndex]}
        toggleModel={toggleModel}
        updateOrEditNotes={updateNotes}
      />
    </Stack>
  );
}
