import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Form, Row } from "reactstrap";
import LoginImage from "../../assets/images/zepul/login.svg";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import "../../assets/css/auth/signup.css";
import OtpModel from "components/Common/models/otpModel";
import { convertRoleForApi } from "helpers/roleFinder";
import { createCompanies } from "helpers/services/companies/company";
import { createScreenPartner } from "helpers/services/screeningPartner/screeningPartner";
import { verifyOTP } from "helpers/services/otpVerifiy/verify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { CA, SP } from "common/constants/userModules";
import { createTechicalEvaluator } from "helpers/services/TechincalEvator/technicalEvaluator";
import { creaetBackgroundVerifications } from "helpers/services/BackgroundVerifications/backgroundVerifications";
import { creteRecrutementCompany } from "helpers/services/RecrutementCompany/recrutement";
import { useForm } from "react-hook-form";
import {
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  PrimaryTypography,
  GreyTypography,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  PrimaryNormalFormHelperText,
  PrimaryButton,
  LargeSemiboldTypography,
  StyledLoginTabsObject,
  SolidTab,
  PrimaryTooltip,
} from "assets/Mui/globalTheme";
import ErrorHandlingToaster from "helpers/Toaster/error";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};
export default function PartnerSignup() {
  const [showPassword, setShowPassword] = useState(false);
  const [cShowPasswrod, setCShowPassword] = useState(false);
  const [userType, setUserType] = useState("CA");
  const [type, setType] = useState("CA");
  const { search, pathname } = useLocation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  useEffect(() => {
    const user_type = searchParams.get("user_type");
    const otp_form = searchParams.get("otp_form");
    if (otp_form) {
      // setOtpModel(true);
    } else {
      setOtpModel(false);
    }
    setValue("register_type", user_type);
    if (user_type !== null) setUserType(user_type);
    else {
      setUserType("CA");
      // searchParams.set("user_type", "CA");
      // history.push({ search: searchParams.toString() });
    }
  }, [searchParams]);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpModel, setOtpModel] = useState("");

  async function handleData(data) {
    let items;
    localStorage.setItem("email", data.email);
    localStorage.setItem("first_name", data.first_name);
    localStorage.setItem("company_name", data?.company_name);
    localStorage.setItem("last_name", data.last_name);
    setEmail(data?.email);
    setPhoneNumber(data?.phone_number);
    setType(data?.user_type);
    async function findRegisterApis() {
      switch (data?.user_type) {
        case "CA":
          return await createCompanies({
            ...data,
            userType: convertRoleForApi(data?.user_type),
          });
        case "SP":
          return await createScreenPartner({
            ...data,
            userType: convertRoleForApi(data.user_type),
          });
        case "RC":
          return await creteRecrutementCompany({
            ...data,
            userType: "RECRUITMENT_COMPANY",
          });
        case "TE":
          return await createTechicalEvaluator({
            ...data,
            userType: convertRoleForApi(data.user_type),
          });
        case "BVC":
          return await creaetBackgroundVerifications({
            ...data,
            userType: "BACKGROUND_VERIFICATION",
          });
        default:
          return await createCompanies({
            ...data,
            userType: convertRoleForApi("CA"),
          });
      }
    }
    items = await findRegisterApis();
    if (items?.status !== 201) {
      ErrorHandlingToaster(items);
      return;
    }
    // searchParams.set("otp_form", true);
    // history.push({ search: searchParams.toString() });
    setOtpModel(true);
  }
  function handleShowCPassword() {
    setCShowPassword(!cShowPasswrod);
  }
  function handleShowPassword() {
    setShowPassword(!showPassword);
  }
  function handlePartnerTabChange(_, data) {
    if (data?.toLowerCase() === "candidate") {
      window.open("//www.zepul.com/login", "_self");
      return;
    }
    setUserType(data);
    searchParams.set("user_type", data);
    setValue("register_type", data);
    history.replace({ search: searchParams.toString() });
  }
  function storeAuthUser(data) {
    localStorage.setItem("authUser", JSON.stringify(data));
  }
  async function handleLogin(otp) {
    const { data, status } = await verifyOTP(getValues("email"), otp);
    if (status !== 201) return status;
    const type = watch('user_type')
    switch (type) {
      case CA:
        storeAuthUser({
          token: data.access,
          role: CA,
          company_id: data.id,
        });
        history.push("/company-create", {
          from: pathname,
        });
        break;
      case SP:
        storeAuthUser({
          token: data.access,
          role: SP,
          screeningPartner_id: data.id,
        });
        history.push("/screening-partner-create", {
          from: pathname,
        });
        break;
      case "RC":
        storeAuthUser({
          token: data.access,
          role: "RC",
          recrutement_company_id: data.company_id,
        });
        history.push("/login", {
          from: pathname,
        });
        break;
      case "TE":
        storeAuthUser({
          token: data.access,
          role: "TE",
          technical_eval_id: data.id,
        });
        history.push("/login", {
          from: pathname,
        });
        break;
      case "BVC":
        storeAuthUser({
          token: data.access,
          role: "BVC",
          background_verification_id: data.id,
        });
        history.push("/login", {});
        break;
      default:
        storeAuthUser({
          token: data.access,
          role: CA,
          company_id: data.id,
        });
        history.push("/company-create", {
          from: pathname,
        });
        break;
    }
  }

  const interviewer = [
    { name: "Screening Partner", value: "SP" },
    // { name: "Account Manager", value: "ACM" },
    // { name: "Moderator", value: "ZM" },
    { name: "Technical Evaluator", value: "TE" },
    { name: "Background Verification Company", value: "BVC" },
    { name: "Recruitment Company", value: "RC" },
  ];
  function handleOtpModel() {
    setOtpModel(!otpModel);
  }
  function getEmail() {
    return getValues("email");
  }
  return (
    <React.Fragment>
      <OtpModel
        phone_number={phoneNumber}
        isOpen={otpModel}
        toggle={handleOtpModel}
        handleLogin={handleLogin}
        email={getEmail()}
      />
      <div className="login_parent">
        <section className="login_img_section">
          <Typography
            variant="h3"
            component="h3"
            sx={{
              color: "white",
              lineSpacing: "20px",
              fontSize: "20px",
              textTransform: "capitalize",
            }}
          >
            The most efficient platform to Easily
            <br />
            <Typography
              variant="h3"
              component="h3"
              sx={{
                color: "#7CD4F9",
                padding: "10px 0px",
                fontSize: "20px",
                textTransform: "capitalize",
              }}
            >
              Apply jobs, Refer Candidates
            </Typography>
            talented people
          </Typography>
          <img src={LoginImage} />
        </section>
        <Card
          className="overflow-hidden card_image"
          style={{ padding: "0px 20px" }}
        >
          <CardBody className="pt-0 ">
            <Form onSubmit={handleSubmit(handleData)}>
              <Stack
                direction="column"
                spacing={4}
                sx={{ maxWidth: "600px" }}
                justifyContent="center"
                alignItems="center"
              >
                <Stack direction="row" spacing={1}>
                  <Typography
                    sx={{
                      ...LargeSemiboldTypography,
                      color: "#081753",
                    }}
                    variant="h2"
                    component="h2"
                  >
                    SignUp
                  </Typography>
                  <Typography
                    sx={{
                      ...LargeSemiboldTypography,
                      color: "rgba(0,0,0,0.5)",
                    }}
                    variant="h2"
                    component="h2"
                  >
                    Here
                  </Typography>
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Tabs
                      sx={{
                        height: "20px",
                        borderRadius: "8px",
                        border: "0.5px solid rgba(0,0,0,0.5)",
                      }}
                      value={userType}
                      TabIndicatorProps={StyledLoginTabsObject}
                      onChange={handlePartnerTabChange}
                      centered
                    >
                      <PrimaryTooltip
                        title={
                          <React.Fragment>
                            <PrimaryTypography variant="h6">
                              Job Seeker
                            </PrimaryTypography>
                            <PrimaryTypography variant="h6">
                              Referal Candidate
                            </PrimaryTypography>
                          </React.Fragment>
                        }
                        placement="top"
                      >
                        <SolidTab value="Candidate" label="Candidates" />
                      </PrimaryTooltip>

                      <SolidTab value="CA" label="company" />
                      {/* <PrimaryTooltip
                        title={
                          <React.Fragment>
                            <PrimaryTypography variant="h6">
                              Screening Partner
                            </PrimaryTypography>
                            <PrimaryTypography variant="h6">
                              Background Verification companies
                            </PrimaryTypography>
                            <PrimaryTypography variant="h6">
                              Technical Evaluators
                            </PrimaryTypography>{" "}
                            <PrimaryTypography variant="h6">
                              Recrutement company
                            </PrimaryTypography>
                          </React.Fragment>
                        }
                        placement="top"
                        // TransitionComponent={({ children }) => children}
                      >
                        <SolidTab
                          id={`simple-tab-PL`}
                          aria-controls={`simple-tabpanel-PL`}
                          value="PL"
                          label="Partner"
                        />
                      </PrimaryTooltip> */}
                      <SolidTab
                        id={`simple-tab-PL`}
                        aria-controls={`simple-tabpanel-PL`}
                        value="PL"
                        label="Partner"
                      />
                    </Tabs>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="login-first-name-outlined"
                        error={errors.first_name}
                      >
                        First Name
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        placeholder="Enter your First name"
                        {...register("first_name", { required: true })}
                        id="login-first-name-outlined"
                        helperText={
                          errors?.first_name ? "Frist Name is a required" : ""
                        }
                        autoFocus
                        label="First Name"
                        type={"text"}
                        error={errors.first_name}
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.first_name}>
                        {errors.first_name
                          ? "First Name is a required Field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="login-last-name-outlined"
                        error={errors.last_name}
                      >
                        Last name
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        placeholder="Enter your Last name"
                        {...register("last_name", { required: true })}
                        id="login-last-name-outlined"
                        helperText={
                          errors?.last_name ? "Last Name is required" : ""
                        }
                        label="email address"
                        type={"text"}
                        error={errors.last_name}
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.last_name}>
                        {errors.last_name
                          ? "Last Name is a required Field"
                          : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>{" "}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="login-email-outlined"
                        error={errors.email}
                      >
                        Email
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        fullWidth
                        placeholder="Enter your email"
                        {...register("email", { required: true })}
                        id="login-email-outlined"
                        helperText={errors?.email ? "email is required" : ""}
                        label="email address"
                        type={"text"}
                        error={errors.email}
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.email}>
                        {errors.email ? "Email is required Field" : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>
                  {userType === "CA" ? (
                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor="login-company-name-2-outlined"
                          error={errors.company_name}
                        >
                          Company Name
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Enter your company name"
                          {...register("company_name", { required: true })}
                          id="login-company-name-2-outlined"
                          helperText={
                            errors?.company_name
                              ? "Company Name is required"
                              : ""
                          }
                          label="email address"
                          type={"text"}
                          error={errors.company_name}
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.company_name}
                        >
                          {errors.company_name
                            ? "Company Name is a required Field"
                            : ""}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <PrimaryNormalInputLabel
                          error={errors?.user_type}
                          shrink
                          sx={{
                            left: "-12px",
                            top: "10px",
                          }}
                          id="signup-user-type"
                        >
                          Describe Yourself
                        </PrimaryNormalInputLabel>
                        <Select
                          labelId="signup-user-type"
                          // labelId="selct-user-type"
                          id="demo-multiple-name"
                          {...register("user_type", { required: true })}
                          input={
                            <PrimaryNormalInputs
                              label="signup-user-type"
                              error={errors?.user_type}
                              {...register("user_type", { required: true })}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {interviewer?.map(item => {
                            return (
                              <MenuItem
                                key={item?.value}
                                value={item?.value} // style={getStyles(name, personName, theme)}
                              >
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <PrimaryNormalFormHelperText error={errors.user_type}>
                          {errors.user_type
                            ? "User Type is a required Field"
                            : ""}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    md={
                      watch("user_type") === "BVC" ||
                      watch("user_type") === "RC"
                        ? 12
                        : 6
                    }
                  >
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="signup-phone-number"
                        error={errors.phone_number}
                      >
                        Phone Number
                      </PrimaryNormalInputLabel>
                    </FormControl>
                    <PhoneInput
                      id="signup-phone-number"
                      labelId="signup-phone-number"
                      international
                      {...register("phone_number", {
                        required: true,
                        minLength: 10,
                      })}
                      className={`input_phone ${
                        errors?.phone_number ? "error_phone" : ""
                      } `}
                      name="phone Number"
                      error={errors.phone_number}
                      onChange={phone => {
                        setValue("phone_number", phone);
                      }}
                      label="signup-phone-number"
                      value={getValues("phone_number")}
                      defaultCountry="IN"
                      initialValueFormat="national"
                      placeholder="Enter phone number"
                    />
                    <PrimaryNormalFormHelperText error={errors.phone_number}>
                      {errors.phone_number
                        ? "Phone Number is a required Field"
                        : ""}
                    </PrimaryNormalFormHelperText>
                  </Grid>
                  {watch("user_type") === "RC" ||
                  watch("user_type") === "BVC" ? (
                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor="login-company-name-outlined"
                          error={errors.company_name}
                        >
                          Company Name
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Enter your email"
                          {...register("company_name", { required: true })}
                          id="login-company-name-outlined"
                          helperText={
                            errors?.company_name ? "email is required" : ""
                          }
                          label="email address"
                          type={"text"}
                          error={errors.company_name}
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText error={errors.email}>
                          {errors.company_name
                            ? "Company Name is a required Field"
                            : ""}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        sx={{ transform: "scale(0.75)" }}
                        htmlFor="login-password-outlined"
                        error={errors.password}
                      >
                        Password
                      </PrimaryNormalInputLabel>
                      <PrimaryNormalInputs
                        {...register("password", { required: true })}
                        id="login-password-outlined"
                        label="Password"
                        placeholder="Enter your password"
                        type={showPassword ? "text" : "password"}
                        error={errors.password}
                        helperText={
                          errors?.password ? "password is required" : ""
                        }
                        endAdornment={
                          <InputAdornment
                            sx={{
                              position: "absolute",
                              right: "10px",
                            }}
                            position="end"
                            onClick={handleShowPassword}
                          >
                            {showPassword ? (
                              <FeatherIcon icon="lock" size={"14"} />
                            ) : (
                              <FeatherIcon icon="unlock" size={"14"} />
                            )}{" "}
                          </InputAdornment>
                        }
                        variant="outlined"
                      />
                      <PrimaryNormalFormHelperText error={errors.password}>
                        {errors.password ? "Password is required Field" : ""}
                      </PrimaryNormalFormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <PrimaryButton type="submit">Sign Up</PrimaryButton>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
            <div className="mt-4 text-center">
              <Link to="/forgot-password" className="text-muted">
                <i className="mdi mdi-lock me-1" /> Forgot your password?
              </Link>
            </div>
            <div className="mt-5 text-center">
              <p>
                Don&apos;t have an account ?
                <Link to={`/login`} className="fw-medium text-primary">
                  Login Now
                </Link>
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}
