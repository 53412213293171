import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GreyTypography,
  PrimaryBox,
  TimeTypography,
} from "assets/Mui/globalTheme";
import moment from "moment";

export default function SendMessages({ message, time }) {
  return (
    <Stack  sx={{ marginLeft: "auto", marginBottom: "10px" }}>
      <PrimaryBox sx={{minWidth:'auto', maxWidth: "300px !important", borderTopRightRadius: "0px !important",wordBreak:'break-word' }}>
        {message}
      </PrimaryBox>
      <Stack sx={{ marginLeft: "auto" }}>
        <TimeTypography variant="h6" component="h6">
          {moment(time).format('LT')}
        </TimeTypography>
      </Stack>
    </Stack>
  );
}
