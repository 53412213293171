import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { useForm } from "react-hook-form";
import { FormControl, Grid, Stack } from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function AddEditEducation({
  type,
  open,
  currentData,
  toggleModel,
  updateOrEditWorExperience,
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  React.useEffect(() => {
    if (type?.includes("update")) {
      setValue("certificate", currentData.certificate);
      setValue("institute", currentData.institute);
      setValue("start_date", currentData.start_date);
      setValue("end_date", currentData.end_date);
    }
  }, [type]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-certificate"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            {type === "create" ? "create" : "Edit"} Education
          </BlackTypography>
          <form onSubmit={handleSubmit(updateOrEditWorExperience)}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-certificate-outlined"
                    error={errors.titile}
                  >
                    certificate
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your certificate"
                    {...register("certificate", { required: true })}
                    id="wor-exp-certificate-outlined"
                    helperText={errors?.certificate ? "email is required" : ""}
                    autoFocus
                    label="Job certificate"
                    type={"text"}
                    name="certificate"
                    error={errors.certificate}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.certificate}>
                    {errors.certificate ? "certificate is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-institute-outlined"
                    error={errors.institute}
                  >
                    Institute
                  </PrimaryNormalInputLabel>
                  <PrimaryNormalInputs
                    fullWidth
                    placeholder="Enter your institute"
                    {...register("institute", { required: true })}
                    id="wor-exp-institute-outlined"
                    helperText={errors?.institute ? "email is required" : ""}
                    autoFocus
                    label="Job institute"
                    type={"text"}
                    name="institute"
                    error={errors.institute}
                    variant="outlined"
                  />
                  <PrimaryNormalFormHelperText error={errors.institute}>
                    {errors.institute ? "institute is required Field" : ""}
                  </PrimaryNormalFormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-start_date-outlined"
                    error={errors.titile}
                  >
                    Start Date
                  </PrimaryNormalInputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      disableFuture={true}
                      label="start Date"
                      value={watch("start_date")}
                      onChange={newValue => {
                        setValue("start_date", newValue);
                      }}
                      renderInput={params => (
                        <PrimaryNormalInputs
                          {...params}
                          error={errors?.start_date}
                          {...register("start_date", { required: true })}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="work-exp-end_date-outlined"
                    error={errors.titile}
                  >
                    End Date
                  </PrimaryNormalInputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="start Date"
                      value={watch("end_date")}
                      onChange={newValue => {
                        setValue("end_date", newValue);
                      }}
                      renderInput={params => (
                        <PrimaryNormalInputs
                          {...params}
                          error={errors?.end_date}
                          {...register("end_date", { required: true })}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggleModel();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
