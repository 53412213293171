import React from "react";
import { Card, Col, Row } from "reactstrap";
import { Table, Tag } from "antd";
import "../../../assets/css/partnerjob.css";
const { withRouter } = require("react-router-dom");

class SignleJobApplicantsList extends React.Component {
  selectStatus(data) {
    switch (data) {
      case "Applied":
        return "geekblue";
      case "moderated":
        return "magenta";
      case "screened":
        return "cyan";
      case "company submission":
        return "gold";
      case "technical round":
        return "lime";
      case "offered":
        return "green";
      case "on boarded":
        return "purple";
      case "rejected":
        return "red";
    }
  }
  render() {
    const columns = [
      {
        title: "Candidate Name",
        dataIndex: "Title",
        key: "Title",
        render: (_, { Title, type, second }) => (
          <>
            <a>{Title}</a>
          </>
        ),
      },

      {
        title: "Screened Date",
        dataIndex: "ScreenedDate",
        key: "ScreenedDate",
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        render: data => {
          return (
            <>
              <Tag
                className="joblist_tags"
                color={`${this.selectStatus(data)}`}
              >
                {data}
              </Tag>
            </>
          );
        },
      },
    ];
    const data = [
      {
        key: "1",
        Title: "John",

        ScreenedDate: "July 4 2022",
        Interview: "2.4.4",
        Status: "rejected",
      },
      {
        key: "2",
        Title: "Anil",

        ScreenedDate: "July 4 2022",
        Status: "screened",
        Interview: "2.4.4",
      },
      {
        key: "3",
        Title: "Ankith",

        ScreenedDate: "July 4 2022",
        Status: "technical round",
        Interview: "2.4.4",
      },
      {
        key: "4",
        Title: "Bezoz",
        ScreenedDate: "July 4 2022",
        Status: "company submission",
        Interview: "2.4.4",
      },
      {
        key: "5",
        Title: "Elon",
        ScreenedDate: "July 4 2022",
        Status: "moderated",
        Interview: "2.4.4",
      },
      {
        key: "6",
        Title: "Musk",
        ScreenedDate: "July 4 2022",
        Status: "rejected",
        Interview: "2.4.4",
      },
      {
        key: "7",
        Title: "Maye",
        ScreenedDate: "July 4 2022",
        Status: "on boarded",
        Interview: "2.4.4",
      },
      {
        key: "8",
        Title: "Maye",
        ScreenedDate: "July 4 2022",
        Status: "offered",
        Interview: "2.4.4",
      },
      {
        key: "9",
        Title: "Maye",
        ScreenedDate: "July 4 2022",
        Status: "moderated",
        Interview: "2.4.4",
      },
      {
        key: "10",
        Title: "Maye",
        ScreenedDate: "July 4 2022",
        Status: "moderated",
        Interview: "2.4.4",
      },
    ];

    return (
      <React.Fragment>
        <Card className="screened-applicant-candidate-list">
          <Row>
            <Col xs={12}>
              {" "}
              <Table columns={columns} dataSource={data} className="p-2" />
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}
export default withRouter(SignleJobApplicantsList);
