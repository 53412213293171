import FileViewer from "react-file-viewer";

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  Stack,
} from "@mui/material";
import {
  BorderCard,
  BorderedCardActionArea,
  PrimaryButton,
  PrimaryIconButton,
  GreyTypography,
  PrimaryAvatar,
  BlackTypography,
  GrayDivider,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import { imageURL } from "common/constants/commonURLS";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
import SearchParamHook from "hooks/searchParams";
import React, { useEffect } from "react";
import { useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { toast } from "react-hot-toast";
import ViewResume from "./viewResume";

export default function UserJobPostResume() {
  const [selectedFiles, setSelectedFiles] = React.useState("");
  const [userJobPostId, setUserJobPostId] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);
  const [viewResume,setViewResume] = useState(false)
  const [resumeUrl,setResumeUrl] = useState(null)
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function onDrop(files) {
    if (files.length === 1) {
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        crop: false,
      })
    );
    // setSelectedFiles(files);
    dispatch({ type: "START_LOADING" });
    files?.map(async file => {
      const url = new Promise((resolve, reject) => {
        const uploadedData = getPreSignedURL([file]);
        if (uploadedData) resolve(uploadedData);
        else reject("error");
      });
      url.then(urlData => {
        updateUserJobPost(userJobPostId, { resumes: [urlData] });
        setSelectedFiles(urlData);
        setResumeUrl(urlData)
        dispatch({ type: "STOP_LOADING" });
      }).catch((e)=>{
        dispatch({ type: "STOP_LOADING" });
        console.error(e);
        toast.error("Sorry you can't upload");
      })
    })
    dispatch({ type: "STOP_LOADING" });
  }
  function typeValidator(file){
    const valid = /\.(pdf|doc)$/i.test(file.name)
    if(!valid && file.type.split("/").pop().includes("vnd.openxmlformats-officedocument.wordprocessingml.document")){
      toast.error('Sorry invalid type')
      return null
    }
  }
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [],
        "application/msword": [],
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        //   [],
      },
      validator:typeValidator
    });

  const searchParams = SearchParamHook();
  useEffect(() => {
    const userJobPostId = searchParams.get("user_job_post_id");
    const userId = searchParams.get("user_id");
    const jobPostId = searchParams.get("job_id");
    if (userId && jobPostId) {
      fetchUserJobPostDetails(userId, jobPostId);
    }
    if (userJobPostId) setUserJobPostId(userJobPostId);
  }, [searchParams]);
  async function fetchUserJobPostDetails(userId, jobPostId) {
    const { data } = await viewSingleUserDetailsInKanbanBoard(
      userId,
      jobPostId
    );
    if(data?.resume_url){
      setResumeUrl(data?.resume_url)
    }
    if (data?.user_job_post_details?.resumes?.length > 0)
      setSelectedFiles(`${data?.user_job_post_details?.resumes[0]}`);
      setResumeUrl(data?.user_job_post_details?.resumes[0])
  }
  if (loading) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  // const isResumeAvailable = !Boolean(selectedFiles) || Boolean(resumeUrl)
  // console.log("sf",selectedFiles,"res",resumeUrl,"v");
  return (
    <>
      <div style={{marginBottom:'10px' }}>
        <PrimaryButton
          startIcon={<FeatherIcon icon="external-link" size="16" />}
          // variant=""
          sx={{ marginTop: "10px", width: "150px" }}
          onClick={()=>{setViewResume(true)}}
        >
          View Resume
        </PrimaryButton>
        <PrimaryButton
          disabled={!Boolean(resumeUrl)}
          sx={{ width: "50px", marginTop: "10px", marginLeft:"10px", padding: "10px" }}
          onClick={() => {
            if(Boolean(selectedFiles)){
              saveAs(`${imageURL}${selectedFiles}`, selectedFiles);
            }else{
              saveAs(`${imageURL}${resumeUrl}`,resumeUrl)
            }
          }}
          startIcon={<FeatherIcon icon="download" size="16" />}
        ></PrimaryButton>
      </div>
      <BorderCard>
        <CardContent>
          <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            direction="row"
          >
            <PrimaryTypography className="mb-3" component="h3" variant="h3">
              Upload Files
            </PrimaryTypography>
            <PrimaryIconButton>
              <FeatherIcon icon="plus" size="14" />
            </PrimaryIconButton>
          </Stack>
          <>
            <input
              {...getInputProps()}
              accept="application/*"
              sx={{ width: "100%", height: "100%" }}
            />
            <BorderedCardActionArea {...getRootProps()} sx={{ width: "100%" }}>
              <Stack spacing={2} alignItems={"center"}>
                <FeatherIcon icon="upload-cloud" size="40" />
                <BlackTypography variant="h6" component="h6">
                  Drop files here or
                </BlackTypography>
                <PrimaryButton>Select files to upload</PrimaryButton>
                <GreyTypography variant="h6" component="h6">
                  Supported File types (20) MB
                </GreyTypography>
              </Stack>
            </BorderedCardActionArea>
          </>
        </CardContent>
      </BorderCard>
      {viewResume && <ViewResume open={viewResume} file={selectedFiles} resumeUrl={resumeUrl} toggleModel={()=>setViewResume(false)} />}
    </>
  );
}
