import React from "react";

import "../../assets/css/email.css";
import { Button, Card, Col, Row } from "reactstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RoundChart from "./rounChart";

class EventsAndEvaluations extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <div className="candidate_header email_brand_div">
              <div>
                <h4>Disqualifications</h4>
                <p>
                  Overview of disqualifications in the hiring pipelines or
                  talent pools.
                </p>
              </div>
              <Button color="primary">Help</Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <RoundChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <RoundChart />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <RoundChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <RoundChart />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default EventsAndEvaluations;
