const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import { updateSingleAdminStaffDetails } from "helpers/services/adminStaff/adminStaff";
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  createIndustries,
  deleteIndustries,
  getAllIndustries,
  updateIndustries,
} from "helpers/services/jobAttributes/industries";
import React from "react";
import { toast } from "react-hot-toast";
import CommonModel from "../../components/Common/models/createModel";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import {
  createActualJobLevels,
  getAllActualJobLevel,
  updateActualJobLevel,
} from "helpers/services/jobAttributes/actualJobLevel";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
class JobLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      data: [],
      isLoading: "true",
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.createActualJobLevels = createActualJobLevels.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const { data } = await getAllActualJobLevel();
    this.setState({ data, isLoading: false });
  }

  toggle(purpose = "", name = "", active = "", id = "", index) {
    this.setState({
      purpose: purpose,
      name: name,
      index,
      id,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }
  handleDelete(index, id, name) {
    updateActualJobLevel({ is_active: false, name: name }, id);
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
    toast.error("Disabled");
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      is_active: !item.is_active,
    };
    updateActualJobLevel(sample, item.id);
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
    toast.success("Enabled");
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id, item.name)
      : this.handlRestore(item, index);
  }
  async updateFunc(sample, type, index) {
    if (type === "create") {
      await this.createActualJobLevels(sample);
      this.setState({
        data: [...this.state?.data, sample],
      });
      toast.success("Created")
      const { data } = await getAllActualJobLevel();
      this.setState({ data });
    } else {
      await updateActualJobLevel(sample, this.state.id);
      let result = this.state.data;
      result[index] = sample;
      this.setState({
        data: result,
      });
      const { data } = await getAllActualJobLevel();
      this.setState({ data });
    }
  }
  render() {
    return (
      <>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Job Levels"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          active={this.state.active}
          update={this.updateFunc}
        />
        <div md={6} style={{ padding: "40px" }} className="header_jobs_sticky">
          <PrimaryButton
            sx={{ width: "200px" }}
            onClick={() => this.toggle("create")}
          >
            Create Job Levels
          </PrimaryButton>
        </div>
        <Row style={{ marginLeft: "20px" }}>
          <div className="table_cont">
            <div className="table-responsive">
              <Card style={{ maxWidth: "80vw" }}>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Job Levels</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.isLoading ? (
                        <BubbleLoader />
                      ) : (
                        this.state.data.map((item, index) => (
                          <TableRow key={item.name + index}>
                            <TableCell>{item.name}</TableCell>

                            <TableCell className="active_td">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <PrimarySwitch
                                      sx={{ m: 1 }}
                                      checked={item.is_active}
                                      onChange={async e => {
                                        this.handleAction(item, index);
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell className="col_btn">
                              <PrimaryButton
                                sx={{ width: "100px" }}
                                onClick={() =>
                                  this.toggle(
                                    "edit",
                                    item.name,
                                    item.active,
                                    item.id,
                                    index
                                  )
                                }
                              >
                                Edit
                              </PrimaryButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

export default JobLevel;
