import {PUT,DELETE,POST} from "common/constants/methods"
import callApi from "helpers/callApi"

 
const endPoint={
    skillEval:'job_post_evaluation',
    questionEval:'user_answer_evaluation'
}

function configurableSkillObj(data){
    return({
        skill:[data.skillId],
        comment:data.comment,
        rating:data.rating,
        user_job_post_id:data.userJobPostId
      })
}

export async function getScreenEvaluationSkills(postId,evalId,evalType){
    const {data}= await callApi(`${endPoint.skillEval}?user_job_post_id=${postId}&evaluator_id=${evalId}&evaluator_type=${evalType}`)
    return data
}

export async function postScreenEvaluationSkills(data){
    const reqObj = configurableSkillObj(data)
   const res =  await callApi(`${endPoint.skillEval}`,reqObj,POST)
   return res
}

export async function updateScreenEvaluationSkills(evaluateId,data){
    const reqObj = configurableSkillObj(data)
   const res =  await callApi(`${endPoint.skillEval}/${evaluateId}`,reqObj,PUT)
   return res
}

export async function deleteScreenEvaluationSkill(evaluateId){
    const res = await callApi(`${endPoint.skillEval}/${evaluateId}`,null,DELETE)
    return res
}

export async function getScreenEvaluationQuestions(candJobId,jobId,evalId,evalType){
    const {data} = await callApi(`${endPoint.questionEval}?user_job_post_id=${candJobId}&job_post_id=${jobId}&evaluator_id=${evalId}&evaluator_type=${evalType}`)
    // console.log(data);
    return data
}

function configurableQuestionObj(data,method){
    const common = {
        comment:data.comment,
        rating:data.rating,
        user_job_post_id:data.userJobPostId,
        job_post_question:data.questionName,
    }
    const result = method === PUT ? ({
        ...common,
        answer: data.answer,
      }):({
        ...common,
        question:data.questionName,
      })
      return result
}

export async function updateScreenEvaluationQuestions(evaluateId,data){
    const reqObj = configurableQuestionObj(data,PUT)
    const res = await callApi(`${endPoint.questionEval}/${evaluateId}`,reqObj,PUT)
    return res
}

export async function postScreenEvaluationQuestions(data){
    const reqObj = configurableQuestionObj(data,'')
    const res = await callApi(`${endPoint.questionEval}`,reqObj,POST)
    return res
}


export async function deleteScreenEvaluationQuestions(evaluateId){
    const res = await callApi(`${endPoint.questionEval}/${evaluateId}`,null,DELETE)
    return res
}