import { optionGroup } from "common/constants/options";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";

export default function ReactSelectComponent({
  isMulti = true,
  options = optionGroup,
  className = "",

  handleChange = e => {},

  value = {},
}) {
  const [multi, setMulti] = useState([]);
  useEffect(() => {
    if (value?.id) {
      setMulti([...value]);
    }
    return () => {
      setMulti([]);
    };
  }, [value]);
  const handleSelectMulti = data => {
    setMulti(data);
    handleChange(data);
  };
  return (
    <ReactSelect
      style={{ zIndex: "999" }}
      className={className}
      value={multi}
      isMulti={isMulti}
      onChange={handleSelectMulti}
      options={options}
      classNamePrefix="select2-selection"
    />
  );
}
