import {
  Checkbox,
  FormControl,
  Grid,
  createFilterOptions,
} from "@mui/material";
import {
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalInputLabel,
  SecondaryFilledChip,
  autocompleteProps,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  getCommonUser,
  updateCommonUser,
  updateCommonUserWithId,
} from "helpers/services/common";
import { getAllFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import {
  createJobLevelForEveryone,
  getAllJobLevlsWithoutFunctionalArea,
} from "helpers/services/jobAttributes/jobLevels";
import {
  createKeySkills,
  getAllKeySkills,
  getAllKeySkillsWithStatus,
} from "helpers/services/jobAttributes/keyskills";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function SetPermissionForCommonUser() {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      job_role: [],
      functional_area: [],
      key_skills: [],
    },
  });
  const jobLevel = useSelector(state => state.JobAttributes.jobLevels);
  const dispatch = useDispatch();
  const [functionalAreas, setFunctionalAreas] = useState([]);
  const [keySkills, setKeySkills] = useState([]);
  const keySkillFilter = createFilterOptions();
  const categoryFilter = createFilterOptions();
  const jobRoleFilter = createFilterOptions();

  useEffect(() => {
    getFunctionalAreas();
    getKeySKills();
    getAllJobLevels();
  }, []);
  const { id } = useParams();
  const [skillLoader, setSkillLoader] = useState(true);
  async function getKeySKills() {
    const { data, status } = await getAllKeySkillsWithStatus();
    if (status === 200) {
      setSkillLoader(false);
    }
    setKeySkills(data?.map(item => ({ name: item?.name, value: item?.id, isActive:item?.is_active })));
  }

  async function getFunctionalAreas() {
    const data = await getAllFunctionalArea();
    setFunctionalAreas(
      data?.map(item => ({ name: item.name, value: item.id, isActive:item?.is_active }))
    );
  }
  async function getAllJobLevels() {
    const data = await getAllJobLevlsWithoutFunctionalArea();
    dispatch({
      type: "ADD_JOB_LEVEL",
      payload: data?.map(item => ({ name: item.name, value: item.id, isActive:item?.is_active })),
    });
    // setJobLevel(data?.map(item => ({ name: item.name, value: item.id })));
  }

  async function onSumbit(data) {
    const results = {
      job_role: data?.job_role?.map(item => item.value),
      functional_area: data?.functional_area?.map(item => item.value),
      skills: data?.key_skills?.map(item => item.value),
      user_approval:"Approve"
    };
    const {status} = await updateCommonUserWithId(id, results);
if(status === 200){
  toast.success("User was updated successfully")
}else {
  toast.error("Something went wrong")
}
    initialFetch(id);
  }
  async function initialFetch(id) {
    const { data } = await getCommonUser(id);
    dispatch({ type: "ADD_COMMON_USER_DATA", payload: data });
  }
  const user_data = useSelector(state => state.CommonUser.user);
  useEffect(() => {
    if (user_data?.job_roles_details?.length > 0) {
      setValue(
        "job_role",
        user_data?.job_roles_details?.map(item => ({
          name: item.name,
          value: item.id,
          isActive:item?.is_active
        }))
      );
    }
    if (user_data?.functional_area_details?.length > 0) {
      setValue(
        "functional_area",
        user_data?.functional_area_details?.map(item => ({
          name: item.name,
          value: item.id,
          isActive:item.is_active
        }))
      );
    }
    if (user_data?.key_skill_details?.length > 0) {
      // functional_area_details;
      setValue(
        "key_skills",
        user_data?.key_skill_details?.map(item => ({
          name: item.name,
          value: item.id,
          isActive:item.is_active
        }))
      );
    }
  }, [user_data]);

  const addedJobLevel = useSelector(state => state.Customs.jobLevel);
  useEffect(() => {
    if (addedJobLevel?.value) {
      setValue("job_role", [...watch("job_role"), addedJobLevel]);
    }
  }, [addedJobLevel]);
  return (
    <form onSubmit={handleSubmit(onSumbit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  // loading={skillLoader}
                  loadingText={"Loading..."}
                  sx={{ width: "100%" }}
                  {...props}
                  disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  options={functionalAreas}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    option.isActive ?
                    <li {...props}>
                      <Checkbox
                        icon={<FeatherIcon icon="square" size="14" />}
                        checkedIcon={
                          <FeatherIcon icon="check-square" size="14" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li> : null
                  )}
                  getOptionLabel={option => option?.name}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="functional-outlined"
                        error={errors.functional_area}
                      >
                        Categories
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.functional_area && {
                            borderColor: "red",
                          }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                    </FormControl>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = categoryFilter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.name
                    );
                    // if (inputValue !== "" && !isExisting) {
                    //   filtered.push({
                    //     inputValue,
                    //     name: `${inputValue}`,
                    //   });
                    // }

                    return filtered;
                  }}
                  value={getValues("functional_area")}
                  onChange={async (_, data) => {
                    const lastIndex = data?.length - 1;
                    // if (data && data[lastIndex].inputValue) {
                    //   const { data: skill } = await createCategories({
                    //     name: data[lastIndex].inputValue,
                    //   });
                    //   console.log(skill);
                    //   await getAllFunctionalArea();
                    //   props.field.onChange([
                    //     ...getValues("functional_area"),
                    //     {
                    //       name: skill.key_skill_res.name,
                    //       value: skill.key_skill_res.id,
                    //     },
                    //   ]);
                    // } else {
                    props.field.onChange(data);
                    // }
                  }}
                />
              );
            }}
            name="functional_area"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            render={props => {
              return (
                <PrimaryAutoComplete
                  loading={skillLoader}
                  sx={{ width: "100%" }}
                  {...props}
                  disableCloseOnSelect
                  componentsProps={autocompleteProps}
                  multiple={true}
                  options={keySkills}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SecondaryFilledChip
                        deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    option.isActive ? 
                    <li {...props}>
                      <Checkbox
                        icon={<FeatherIcon icon="square" size="14" />}
                        checkedIcon={
                          <FeatherIcon icon="check-square" size="14" />
                        }
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li> : null
                  )}
                  getOptionLabel={option => option?.name}
                  renderInput={params => (
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <PrimaryNormalInputLabel
                        shrink
                        htmlFor="key-skills-outlined"
                        error={errors.key_skills}
                      >
                        Required Skills (select all that applies)
                      </PrimaryNormalInputLabel>
                      <MultipleSelectInputs
                        sx={{
                          ...(errors.key_skills && { borderColor: "red" }),
                        }}
                        variant="standard"
                        ref={params.InputProps.ref}
                        {...params}
                      />
                    </FormControl>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = keySkillFilter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      option => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        isActive:true,
                        name: `${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  value={getValues("key_skills")}
                  onChange={async (_, data) => {
                    const lastIndex = data?.length - 1;
                    if (data && data[lastIndex].inputValue) {
                      const { data: skill } = await createKeySkills({
                        name: data[lastIndex].inputValue,
                      });
                      await getKeySKills();
                      props.field.onChange([
                        ...getValues("key_skills"),
                        {
                          name: skill.key_skill_res.name,
                          value: skill.key_skill_res.id,
                        },
                      ]);
                    } else {
                      props.field.onChange(data);
                    }
                  }}
                />
              );
            }}
            name="key_skills"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PrimaryAutoComplete
            componentsProps={autocompleteProps}
            multiple={true}
            disabled={watch("functional_area")?.length === 0}
            error={errors.job_role}
            value={watch("job_role")}
            onChange={async (e, newValue) => {
              const lastIndex = newValue?.length - 1;
              if (typeof newValue[lastIndex] === "string") {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  dispatch({
                    type: "ADD_JOB_ROLE_MODAL",
                    payload: {
                      values: {
                        job_role: newValue,
                        functional_area: watch("functional_area"),
                      },
                    },
                  });
                  // toggleOpen(true);
                });
              } else if (
                newValue[lastIndex] &&
                newValue[lastIndex].inputValue
              ) {
                dispatch({
                  type: "ADD_JOB_ROLE_MODAL",
                  payload: {
                    values: {
                      job_role: newValue[lastIndex].inputValue,
                      functional_area: watch("functional_area"),
                    },
                  },
                });
              } else {
                setValue("job_role", newValue);
              }
            }}
            disableCloseOnSelect
            id="multiple-job-roles"
            options={jobLevel}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <SecondaryFilledChip
                  deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, option, { selected }) => (
              option.isActive ? 
              <li {...props}>
                <Checkbox
                  icon={<FeatherIcon icon="square" size="14" />}
                  checkedIcon={<FeatherIcon icon="check-square" size="14" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li> : null
            )}
            filterOptions={(options, params) => {
              const filtered = jobRoleFilter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                option => inputValue === option.name
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `${inputValue}`,
                });
              }

              return filtered;
            }}
            getOptionLabel={option => option?.name}
            renderInput={params => {
              return (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <PrimaryNormalInputLabel
                    shrink
                    htmlFor="job-role-outlined"
                    error={errors.job_role}
                  >
                    Job Role (select all that applies)
                  </PrimaryNormalInputLabel>
                  <MultipleSelectInputs
                    sx={{
                      ...(errors.job_role && { borderColor: "red" }),
                    }}
                    variant="standard"
                    ref={params.InputProps.ref}
                    {...params}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={10} md={10}></Grid>
        <Grid item xs={2} md={2}>
          <PrimaryButton sx={{ width: "100%" }} type="submit">
            submit
          </PrimaryButton>
        </Grid>
      </Grid>
    </form>
  );
}
