import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  BlackTypography,
  QontoStepIconRoot,
  SuccessStepConnector,
  // SuccessStepLabel,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Grid, Stack } from "@mui/material";
import { Check } from "@mui/icons-material";

const steps = ["Basic Details", "Job Description", "Hire in Day"];

export default function JobPostingStepper({ activeStep }) {
  return (
    <Box sx={{ width: "100%", margin: "20px 0px" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<SuccessStepConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon mt-2" />
      ) : (
        <div className="QontoStepIcon-circle mt-2" />
      )}
    </QontoStepIconRoot>
  );
}
