export const updateCompanyModel = data => {
  return {
    first_name: data.first_name,
    last_name: data.last_name,
    company_image: data.profile_image,
    tagline: data.tagline,
    size_of_company: "1-50",
    about: data?.about,
    phone_number: data.phone_number,
    email: data.email,
    industry: [data?.industry?.value],
    location: data?.location,
    website: data?.website,
    linkedin_url: data?.linkedin_url,
    twitter_url: data?.twitter_url,
    facebook_url: data?.facebook_url,
    glassdoors_url: data?.glassdoors_url,
    ambition_box_url: data?.ambition_box_url,
    comparably_url: data?.comparably_url,
    careerbliss_url: data?.careerbliss_url,
    culture_media_images: data?.culture_media_images,
    payscale_url: data?.payscale_url,
    google_reviews: data?.google_reviews,
    company_name: data?.company_name,
    describe_your_culture: data?.describe_your_culture,
    perks: data?.perks,
    culture_media_video_url: data?.culture_media_video_url,
  };
};
