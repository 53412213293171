import React, { useEffect, useState } from "react";
import { Row, Col, Input, Container } from "reactstrap";
import dayjs from "dayjs";
import { Editor } from "react-draft-wysiwyg";
import FunctionAreas from "../FunctionalArea/FunctionArea";
import JobTypes from "../JobType";
import EducationQualification from "../Education/EducationQualification";

import "./JobAdditionDetails.css";
import {
  ContentState,
  convertFromHTML,
  EditorState,
  convertToRaw,
} from "draft-js";
import { connect } from "react-redux";
import { updateJob, updateJobDescription } from "store/jobs/actions";
import { Button } from "reactstrap";
import UploadModel from "components/Common/models/uploadModel";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { imageURL } from "common/constants/commonURLS";
import draftToHtml from "draftjs-to-html";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { PrimaryNormalInputs } from "assets/Mui/globalTheme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const JobAdditionDetails = ({
  job_desc,
  application_deadline,
  updateJob,
  no_of_vacancies,
  updateJobDescription,
  forwardItem,
  forward,
  history,
  edit = false,
}) => {
  const defaultJobDesc = job_desc;
  const [editorData, setEditorData] = useState(() => EditorState.createEmpty());

  const handleEditorData = e => {
    setEditorData(e);
    updateJobDescription(
      draftToHtml(convertToRaw(editorData.getCurrentContent()))
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [deafaultValue, setDefaultValue] = useState();

  const [image, setImage] = useState("");
  const [otherfiles, setOtherFiles] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  function toggleModel(data) {
    setModalOpen(data);
  }
  async function handleImage(data) {
    const url = await getPreSignedURL(data);
    updateJob({ attachment: url });
    if (
      url.endsWith(".jpg") ||
      url.endsWith(".png") ||
      url.endsWith(".jpeg") ||
      url.endsWith(".gif") ||
      url.endsWith(".svg")
    ) {
      setImage(url);
    } else {
      setOtherFiles(data[0].preview);
    }
    toggleModel();
  }
  // const [sampleEditorContent,setSampleEditorContent] = useState(() => EditorState.createEmpty());
  return (
    <div id="job-desc-main">
      <Container fluid>
        <Row className="child_col_m_2">
          {history?.location?.pathname.includes("job-edit") ? (
            ""
          ) : (
            <Col xs={12}>
              <Editor
                // defaultEditorState={deafaultValue}
                editorState={editorData}
                onEditorStateChange={handleEditorData}
              />
            </Col>
          )}
          <FunctionAreas />
          <JobTypes />
          <EducationQualification />
          <Col xs={12} md={4} className="mt-2 px-0">
            <div>
              <h5>
                Number of Vacanies <span className="red p-2">*</span>
              </h5>

              <Input
                type="number"
                value={no_of_vacancies}
                onChange={e => {
                  updateJob({ no_of_vacancies: e.target.value });
                }}
              />
            </div>
          </Col>
          <Col xs={12} md={6} className="my-2">
            <h5>
              Application Deadline <span className="red p-2">*</span>
            </h5>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                disablePast={true}
                label="application_deadline"
                value={dayjs(application_deadline)}
                onChange={newValue => {
                  updateJob({ application_deadline: dayjs(newValue) });
                }}
                renderInput={params => (
                  <PrimaryNormalInputs
                    {...params}
                    // value={application_deadline}
                    // onChange={e => {
                    //   updateJob({ application_deadline: e.target.value });
                    // }}
                  />
                )}
              />
            </LocalizationProvider>
          </Col>
          <Col className="mt-4 d-none">
            <div className="mt-2 d-flex gap-2 align-items-center">
              <Button onClick={toggleModel}>Add Attachement</Button>
              {otherfiles.length > 0 ? (
                <>
                  <Button
                    onClick={() => {
                      fetch(otherfiles, {
                        method: "GET",
                        mode: "no-cors",
                        referrerPolicy: "no-referrer",
                      })
                        .then(req => {
                          return req.blob();
                        })

                        .then(blob => {
                          const fileURL = window.URL.createObjectURL(blob);
                          let alink = document.createElement("a");
                          alink.href = fileURL;
                          alink.download = otherfiles;
                          alink.click();
                        });
                    }}
                  >
                    Download
                  </Button>

                  <a href={otherfiles} target="_blank">
                    Live Preview
                  </a>
                </>
              ) : (
                <img
                  src={`${imageURL}${image}`}
                  className={`small_img mx-2 ${image === "" ? "dis_n" : " "}`}
                  //   style={{ width: "50px", height: "50px" }}
                ></img>
              )}
            </div>
          </Col>
        </Row>
        {!edit ? (
          <div className="d-flex justify-content-between mt-5 w-full mb-2 ">
            <Button
              type="primary"
              className="danger"
              style={{
                background: "red !important",
                outline: "none",
                border: "none",
              }}
              onClick={() => {
                if (forwardItem < 1) return;
                forward((Number(forwardItem) - 1).toString());
              }}
            >
              cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                forward((Number(forwardItem) + 1).toString(), true);
              }}
            >
              Submit
            </Button>
          </div>
        ) : (
          ""
        )}
      </Container>
      <UploadModel
        isOpen={modalOpen}
        toggle={toggleModel}
        handleImage={handleImage}
      />
    </div>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  job_desc: Jobs.job_desc,
  application_deadline: Jobs.application_deadline,
  no_of_vacancies: Jobs.no_of_vacancies,
});

const mapDispatchToProps = dispatch => ({
  updateJobDescription: e => dispatch(updateJobDescription(e)),
  updateJob: e => dispatch(updateJob(e)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobAdditionDetails)
);
