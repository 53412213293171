import React from "react";
import { Col, Container, Row } from "reactstrap";
import "../../../assets/css/assigned.css";
import JobLists from "./joblist";
import Jobs from "./jobs";
export default class Assigned extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid className="page-content">
          <Row className="">
            {/* <Col x md={12} className="job_cont">
              <Jobs />
            </Col> */}
            <Col x md={12}>
              <Row>
                <JobLists />
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
