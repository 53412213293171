const { Row, Container, Col, CardBody, Card } = require("reactstrap");
import {
  FormControlLabel,
  FormGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-hot-toast";
import React from "react";
import CommonModel from "../../components/Common/models/createModel";
import {
  createAndEditFunctionalArea,
  deleteFunctionalArea,
  getAllFunctionalArea,
} from "../../helpers/services/jobAttributes/functionalArea";
import { PrimaryButton, PrimarySwitch } from "assets/Mui/globalTheme";
import BubbleLoader from "common/data/NoRowsFoundDatagrid/BubbleLoader";
class FunctionalArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      active: false,
      index: -1,
      default: false,
      name: "",
      data: [],
      isLoading: "true",
    };
    this.toggle = this.toggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.updateFunc = this.updateFunc.bind(this);
    this.createAndEditFunctionalArea = createAndEditFunctionalArea.bind(this);
    this.getAllFunctionalArea = getAllFunctionalArea.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handlRestore = this.handlRestore.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const items = await this.getAllFunctionalArea();
    this.setState({ data: items, isLoading: false });
  }

  toggle(
    purpose = "",
    id = "",
    name = "",
    defaultValue = "",
    active = "",
    index
  ) {
    this.setState({
      purpose: purpose,
      id,
      name: name,
      index,
      default: defaultValue,
      active: active,
      isOpen: !this.state.isOpen,
    });
  }
  handleDelete(index, id) {
    deleteFunctionalArea(id);
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
    toast.error("Disabled");
  }
  handlRestore(item, index) {
    const sample = {
      name: item.name,
      active: !item.is_active,
    };
    createAndEditFunctionalArea(sample, "Edit", index, item.id);
    let result = this.state.data;
    result[index].is_active = !result[index].is_active;
    this.setState({
      data: result,
    });
    toast.success("Enabled");
  }
  handleAction(item, index) {
    item.is_active
      ? this.handleDelete(index, item.id)
      : this.handlRestore(item, index);
  }
  updateFunc(sample, type, index) {
    this.createAndEditFunctionalArea(sample, type, index, this.state.id);
  }
  render() {
    return (
      <Container fluid>
        <CommonModel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          headTag={"Category"}
          purpose={this.state.purpose}
          index={this.state.index}
          title={this.state.name}
          defaultValue={this.state.default}
          active={this.state.active}
          update={this.updateFunc}
          // id={this.state.id}
        />
        <div>
          <PrimaryButton
            className="header_jobs_sticky"
            sx={{ width: "200px" }}
            onClick={() => this.toggle("create")}
          >
            Create Category
          </PrimaryButton>
        </div>
        <Row style={{ marginTop: "70px", marginLeft: "20px" }}>
          <Row className="align-items-center "></Row>
          <div className="table_cont">
            <div className="table-responsive">
              <Card>
                <CardBody>
                  <Table className="table mb-0">
                    <TableHead>
                      <TableRow>
                        <TableCell>Categories</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {this.state.isLoading ? (
                        <BubbleLoader />
                      ) : (
                        this.state.data.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>

                            <TableCell className="active_td">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <PrimarySwitch
                                      sx={{ m: 1 }}
                                      checked={item.is_active}
                                      onChange={async e => {
                                        this.handleAction(item, index);
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                            <TableCell className="col_btn">
                              <PrimaryButton
                                style={{ width: "100px" }}
                                onClick={() =>
                                  this.toggle(
                                    "edit",
                                    item.id,
                                    item.name,
                                    item.default,
                                    item.is_active,
                                    index
                                  )
                                }
                              >
                                Edit
                              </PrimaryButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default FunctionalArea;
