import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modal.css";

export const ViewDetailsModal = props => {
  return (
    <div>
      <Modal
        isOpen={props.params.modal}
        toggle={props.onHide}
        className="models-main"
        size="lg"
        dialogClassName="modal-90w"
      >
        <ModalHeader
          toggle={props.onHide}
          style={{ fontSize: "12px" }}
          className="models-header"
        ></ModalHeader>
        <ModalBody>
          <div className="row mb-3">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="card p-3">
                <img
                  src="https://res.cloudinary.com/dt6u679fs/image/upload/v1667821110/job-detail-bg_uwn7ay.jpg"
                  alt="job-image"
                  className="rounded mb-4"
                />
                <h4>Job Description : </h4>
                <p className="mb-3">
                  Ut enim ad minima veniam, quis nostrum exercitationem ullam
                  corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                  consequatur.
                </p>
                <h4>Requirement : </h4>
                <div className="d-flex">
                  <i className="bx bx-check text-primary ms-1 fa-2x" />
                  <p>
                    Must be able to communicate with others to convey
                    information effectively.
                  </p>
                </div>

                <div className="d-flex">
                  <i className="bx bx-check text-primary ms-1 fa-2x" />
                  <p>
                    Must be able to communicate with others to convey
                    information effectively.
                  </p>
                </div>

                <div className="d-flex">
                  <i className="bx bx-check text-primary ms-1 fa-2x" />
                  <p>
                    Must be able to communicate with others to convey
                    information effectively.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
              <div className="modal-custom-card">
                <h4 className="section-head-small mb-4">Job Information</h4>
                <ul className="twm-job-hilites ps-0">
                  <li>
                    <i className="fas fa-calendar-alt me-2 text-primary"></i>
                    <span className="twm-title">Date Posted</span>
                  </li>
                  <li>
                    <i className="fas fa-eye me-2 text-primary"></i>
                    <span className="twm-title">8160 Views</span>
                  </li>
                  <li>
                    <i className="fas fa-file-signature me-2 text-primary"></i>
                    <span className="twm-title">6 Applicants</span>
                  </li>
                </ul>

                <div className="mb-3">
                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <i className="fas fa-calendar-alt  text-primary"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <span className="twm-title ">Date Posted</span>
                      <div className="twm-s-info-discription">
                        April 22, 2022
                      </div>{" "}
                    </div>
                  </div>

                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <i className="fas fa-map-marker-alt  text-primary"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <span className="twm-title ">location</span>
                      <div className="twm-s-info-discription">
                        Hyderabad
                      </div>{" "}
                    </div>
                  </div>

                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <i className="fas fa-user-tie  text-primary"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <span className="twm-title ">Job title</span>
                      <div className="twm-s-info-discription">
                        Software Engineer
                      </div>{" "}
                    </div>
                  </div>

                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <i className="fas fa-user-tie  text-primary"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <span className="twm-title">Experience</span>
                      <div className="twm-s-info-discription">
                        3+ years
                      </div>{" "}
                    </div>
                  </div>

                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <i className="fas fa-clock  text-primary"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <span className="twm-title">Qualification</span>
                      <div className="twm-s-info-discription">Btech</div>{" "}
                    </div>
                  </div>
                </div>

                <h4 className="section-head-small mb-3">Job Skills</h4>
                <div className="d-flex">
                  <div className="skills me-2">Python</div>
                  <div className="skills me-2">javascript</div>
                  <div className="skills me-2">Nodejs</div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-danger"
              onClick={() => props.onHide()}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
