export default function getCompanyProfileModel(data) {
  return {
    first_name: data.first_name,
    last_name: data.last_name,
    profile_image: data.company.company_image,
    phone_number: data.phone_number,
    email: data.email,
    job_role_details: data.job_role,
    about: data?.company?.about,
    tagline: data?.company?.tagline,
    culture_media_images: data?.company?.culture_media_images,
    industry: data?.industry,
    size_of_company: data?.company?.size_of_company,
    fn_area_details: data.functional_area,
    linkedin_url: data?.company?.linkedin_url,
    twitter_url: data?.company?.twitter_url,
    facebook_url: data?.company?.facebook_url,
    glassdoors_url: data?.company?.glassdoors_url,
    ambition_box_url: data?.company?.ambition_box_url,
    comparably_url: data?.company?.comparably_url,
    careerbliss_url: data?.company?.careerbliss_url,
    payscale_url: data?.company?.payscale_url,
    google_reviews: data?.company?.google_reviews,
    company_name: data?.company?.company_name,
    describe_your_culture: data?.company?.describe_your_culture,
    perks: data?.company?.perks,
    culture_media_video_url: data?.company?.culture_media_video_url,
    //TODO change ?.location[0]
    location: data?.company?.location[0],
    website: data?.company?.website,
  };
}
