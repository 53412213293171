import { EyeOutlined, ShareAltOutlined } from "@ant-design/icons";
import JobDetails from "../../jobs/navFunctions/jobDetails";
import "../../../assets/css/index.css";
import { Tooltip } from "antd";
import { formatDistance } from "date-fns";

import React from "react";
import { withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import ShareJob from "../../../components/Common/shareJob";
import InviteCandidates from "../../jobs/navFunctions/inviteCandidates";
import PublishJob from "../../jobs/navFunctions/publishJob";
import { connect } from "react-redux";
import "./assingedjos.css";
import { getSingleJob } from "helpers/services/jobs/jobs";
import { imageURL } from "common/constants/commonURLS";
import findWorkMode from "helpers/functions/findWorkMode";
import getSymbolFromCurrency from "currency-symbol-map";
import { getAllJobsForScreeningPartner } from "helpers/services/screeningPartner/screeningPartner";
class JobLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDetails: false,
      eventBtn: false,
      selectedIndex: -1,
      publishJob: false,
      inviteCandidates: false,
      jobs: [],
      data: {},
    };
    this.handleJobDetails = this.handleJobDetails.bind(this);
    this.toggleFunc = this.toggleFunc.bind(this);
    this.publishHandler = this.publishHandler.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
  }
  async componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      const { data } = await getAllJobsForScreeningPartner();
      const result = data.map(item => {
        return {
          name: item.job_title,
          company_name: item.company_details.company_name,
          location: item.job_location,
          company_image: item.company_details.company_image,
          time_zone: item.time_zone,
          work_mode: item.work_mode,
          min_sal: item.min_sal,
          max_sal: item.max_sal,
          id: item.id,
          currency: item.currency,
          job_type: item.job_type,
          totalCandidates: 1233,
          activeCandidates: 13,
          deadline: item.application_deadline,
          commission_rate_type: item.commission_rate_type,
          commission_rate: item.commission_rate,
        };
      });
      this.setState({ jobs: result });
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  toggleFunc = () => {
    this.setState({
      eventBtn: !this.state.eventBtn,
    });
  };
  handleJobDetails = () => {
    this.setState({
      jobDetails: false,
    });
  };
  handleInvite = () => {
    this.setState({
      inviteCandidates: !this.state.inviteCandidates,
    });
  };
  publishHandler = () => {
    this.setState({
      publishJob: !this.state.publishJob,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.jobDetails && (
          <JobDetails
            isOpen={this.state.jobDetails}
            toggle={this.handleJobDetails}
            data={this.state.data}
          />
        )}
        {this.state.inviteCandidates && (
          <InviteCandidates
            isOpen={this.state.inviteCandidates}
            toggle={this.handleInvite}
          />
        )}
        {this.state.publishJob && (
          <PublishJob
            isOpen={this.state.publishJob}
            toggle={this.publishHandler}
          />
        )}

        {this.state.jobs.map(
          (
            {
              name,
              location,
              work_mode,
              commission_rate,
              time_zone,
              job_type,
              min_sal,
              max_sal,
              currency,
              id,
              commission_rate_type,
              deadline,
              company_image,
              company_name,
            },
            index
          ) => (
            <Col lg={4} md={6} key={index} className="mb-2">
              <div className="cards-border p-3 hover-up card-grid-2">
                <div className="d-flex mb-1">
                  <div className="flex-shrink-0">
                    <img
                      src={`${imageURL}${company_image}`}
                      alt="job-profile"
                      width="40px"
                      onError={e => {
                        e.target.src =
                          "https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo=";
                      }}
                      className="ms-1 mt-1 cover_img_md"
                      style={{ borderRadius: "12px" }}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="d-flex justify-content-between">
                      <h4
                        className="cursor-pointer"
                        onClick={() => {
                          this.props.history.push(`joblist/jobs/${id}`);
                        }}
                      >
                        <span className="basic-4 job-name mb-2">{name}</span>
                        <div
                          style={{ fontSize: "12px" }}
                          className="text-muted"
                        >
                          {company_name}
                        </div>
                      </h4>
                      <div className="d-flex">
                        <div className="job_head_right">
                          <Tooltip
                            placement="top"
                            title="View Job Details"
                            color="white"
                          >
                            <EyeOutlined
                              className="cursor"
                              onClick={async () => {
                                this.setState({
                                  data: await getSingleJob(id),
                                  jobDetails: true,
                                });
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            placement="top right"
                            title="Share Job"
                            color="white"
                          >
                            <ShareAltOutlined
                              className="cursor"
                              onClick={() => {
                                this.setState({
                                  eventBtn: !this.state.eventBtn,
                                  selectedIndex: index,
                                });
                              }}
                            />
                            {this.state.selectedIndex === index && (
                              <ShareJob
                                toggle={this.state.eventBtn}
                                toggleFunc={this.toggleFunc}
                              />
                            )}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-2 justify-content-between align-items-center">
                  <span>
                    <i className="bx bx-map me-1"></i>
                    <span className="text-muted">
                      {" "}
                      {location || time_zone[0]}
                    </span>
                  </span>{" "}
                  <div>
                    <i className="bx bx-briefcase"></i>{" "}
                    <span>{findWorkMode(work_mode)}</span>{" "}
                  </div>
                  <span>
                    <i className="bx bx-user me-1"></i>
                    <span className="text-muted">120</span>
                  </span>{" "}
                </div>

                <div className="d-flex mb-2 justify-content-between mb-3 align-items-center">
                  <span>
                    <i className="mdi mdi-account-tie me-1"></i>
                    <span className="text-muted">2+</span>
                  </span>{" "}
                  <span>
                    <span className="text-muted">
                      {" "}
                      <span className="currency-text">
                        {getSymbolFromCurrency(currency)} {min_sal} - {max_sal}{" "}
                        /
                      </span>
                      <span className="currency-time">
                        {job_type === "Full_Time" ? "Yr" : "Hr"}
                      </span>
                    </span>
                  </span>{" "}
                  <span>
                    <i className="mdi mdi-book-account me-1"></i>
                    <span className="text-muted">123</span>
                  </span>{" "}
                </div>

                <div className="reward_tab">
                  <div className="me-1">
                    {/* <span className="small">Commisssion</span> */}
                    <Tooltip
                      placement="top"
                      overlayClassName="tooltip_card tool_green"
                      overlayInnerStyle={{
                        fontSize: "10px",
                        color: "#389e0d",
                        margin: "0px",
                        borderRadius: "10px",
                      }}
                      title={`commisssion 35% `}
                      color="#f6ffed"
                    >
                      <div className="btn btn-grey-small small" color={"red"}>
                        <i className="bx bx-timer"></i>
                        <span>
                          {" "}
                          {commission_rate_type === "Percentage" ? (
                            <>
                              {(Number(max_sal) * Number(commission_rate)) /
                                100}
                            </>
                          ) : (
                            ""
                          )}
                          {commission_rate_type === "Flat" ? (
                            <>
                              {getSymbolFromCurrency(currency)}
                              {commission_rate}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip
                      placement="top"
                      overlayClassName="tooltip_card tool_red"
                      overlayInnerStyle={{
                        fontSize: "10px",
                        color: "#cf1322",
                        margin: "0px",
                        borderRadius: "10px",
                      }}
                      title="Last Date to apply 12.04.2022"
                      color="#fff1f0"
                    >
                      {" "}
                      <div className="btn btn-grey-small small" color={"red"}>
                        <i className="bx bx-timer"></i>
                        <span>
                          {formatDistance(new Date(deadline), new Date(), {
                            addSuffix: true,
                          })}
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Col>
          )
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    login: state.Login,
  };
};

export default withRouter(connect(mapStateToProps, null)(JobLists));
