import callApi from "helpers/callApi";
const getId = async () => {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
};
export const updateCommonUser = async data => {
  return await callApi(`/common_user_update/${await getId()}`, data, "PUT");
};

export const updateCommonUserWithId = async (id, data) => {
  return await callApi(`/common_user_update/` + id, data, "PUT");
};
export const getCommonUser = async id => {
  return await callApi(`/common_user_update/user/${id}`, {});
};
