import { Grid, Tabs } from "@mui/material";
import {
  Solid2Tab,
  SolidTab,
  StyledLoginTabsObject,
} from "assets/Mui/globalTheme";
import SearchParamHook from "hooks/searchParams";
import { useEffect, useState } from "react";
import { TE,SP,MODERATOR,RC } from "common/constants/userModules";
import { useHistory } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { startScreening } from "helpers/services/screeningPartner/screeningPartner";
import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";


function evaluId(role,candidate) {
  let evalId;
  switch (role) {
    case SP:
      evalId = candidate?.screening_partner_id
      break;
    case TE:
      evalId = candidate?.evaluation_id
      break;
    default:
      evalId = candidate?.moderator_id
  }
  return evalId
}


export default function UserJobPostEvaluationTabs({fetchSkills,setEvaluId,setEvaluType,fetchQuestions,setEvaluation,evaluation}) {
  const searchParams = SearchParamHook();
  const history = useHistory();
  const dispatch = useDispatch()
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  // const [type, setType] = useState("screening");
  const evaluatorId = JSON.parse(localStorage.getItem("authUser")).id;
  const {candidate} = useSelector(state => state.Candidates);
  const userJobPostId = searchParams.get("user_job_post_id");
  const userId = searchParams.get("user_id");
  const jobPostId = searchParams.get("job_id");
 
  const moderatorEvalId =
    role !== MODERATOR
      ? candidate?.user_job_post_details?.moderator_evalution_details
      : evaluatorId;
  const screeningEvalId =
    role !== SP
      ? candidate?.user_job_post_details?.screening_evalution_details
      : evaluatorId;
  const technicalEvalId =
    role !== TE
      ? candidate?.user_job_post_details?.technical_evalution_details
      : evaluatorId;
  // useEffect(() => {
  //   const result = searchParams.get("evaluation_type");
  //   if (result) setType(result);
  // }, [searchParams]);
  useEffect(()=>{
    let evalId;
    let evalType;
      switch(evaluation){
        case "screening":
          if(role === RC){
            evalType = RC
          }else {
            evalType = SP;
          }
          // evalType = SP
          evalId = (screeningEvalId?screeningEvalId:evaluatorId)
          break
        case "technical":
          evalType = (TE)
          evalId = (technicalEvalId)
          break
        default:
          evalType = (MODERATOR)
          evalId = (moderatorEvalId)
      
    }
    (async function(){
      const { data } = await viewSingleUserDetailsInKanbanBoard(
        userId,
        jobPostId
      );
      dispatch({ type: "ADD_CANDIDATES", payload: data });
      const evaluatorId = evaluId(role,data?.user_job_post_details)
      console.log(evaluatorId)
      if(role === evalType && evaluatorId){
        console.log('u cant hit api');
      }
      if(role === evalType && !evaluatorId)
      {        
        await startScreening(
        userJobPostId,
        'start',
        role === TE ? 'evaluator' : role.toLowerCase()
      );
    }
      return ;
    })()

    setEvaluId(evalId)
    setEvaluType(evalType)
    if(evalId !== ''){
    fetchQuestions(evalId,evalType)
    fetchSkills(evalId,evalType)
  }//type
  },[evaluation])
  function handleTypeChange(_, data) {
    // searchParams?.set("evaluation_type", data);
    // setType(data);
    setEvaluation(data);
    // history.replace({ search: searchParams.toString() });
  }
  return (
        <Tabs
          value={evaluation}
          TabIndicatorProps={StyledLoginTabsObject}
          onChange={handleTypeChange}
          style={{position:'static',top:0}}
        >
          <Solid2Tab
            sx={{ width: "300px" }}
            value="screening"
            label="Screening Evaluation"
          />
            <Solid2Tab
            sx={{ width: "300px" }}
            value="moderate"
            label="Moderate Evaluation"
          />
          <Solid2Tab
            sx={{ width: "300px" }}
            value="technical"
            label="Technical Evaluation"
          />
        
        </Tabs>
  );
}
