import { ADMIN } from "common/constants/common-words";
import { ACM, CS, MODERATOR } from "common/constants/userModules";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import TryOutlinedIcon from '@mui/icons-material/TryOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import InterpreterModeOutlinedIcon from '@mui/icons-material/InterpreterModeOutlined';

export function contentFinder(role) {
  switch (role) {
    case "SP":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Screened Applicants",
          link: "/screened-applicants",
          icon: "bx bx-select-multiple",
        },
       
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },

        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "RC":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Job Openings",
          link: "/joblist/pending",
          icon: "bx bxs-briefcase",
        },
        // {
        //   name: "Screened Applicants",
        //   link: "/screened-applicants",
        //   icon: "bx bx-select-multiple",
        // },
      
        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "mdi mdi-lightbulb-on",
        //   icon:"bx bx-bar-chart"
        // },
        // { name: "email", link: "/emails", icon: "bx bx-envelope" },
        { name: "settings", link: "/profile-settings", icon: "bx bx-cog" },
      ];
    case "TE":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Job List",
          link: "/joblist/pending",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Evaluated",
          link: "/screened-applicants",
          icon: "bx bx-select-multiple",
        },
       
        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        // { name: "email", link: "/emails", icon: "bx bx-envelope" },
        { name: "settings", link: "/profile-settings", icon: "bx bx-cog" },
      ];
    case ACM:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        { name: "company", link: "/company-list", icon: "bx bx-building" },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case MODERATOR:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Jobs",
          link: "/joblist/approved",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Moderated Applicants",
          // link: "/moderated-applicants",
          link: "/screened-applicants",
          icon: "bx bx-select-multiple",
        },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "BVC":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Jobs",
          link: "/joblist/pending",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Verified Applicants",
          link: "/background-verified-applicants",
          icon: "bx bx-select-multiple",
        },
        {
          name: "Staff",
          link: "/profile-settings",
          icon: "bx bx-select-multiple",
        },
      
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },

        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
       
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "BVS":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Jobs",
          link: "/joblist/pending",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Verified Applicants",
          link: "/background-verified-applicants",
          icon: "bx bx-select-multiple",
        },
      
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },

        // { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // {
        //   name: "Leader Board",
        //   link: "/leaderboard",
        //   icon: "bx bx-bar-chart",
        // },
        { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case "TE":
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Jobs",
          link: "/job-openings",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Evaluated-applicants",
          link: "/evaluated-applicants",
          icon: "bx bx-select-multiple",
        },
     
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
      
        { name: "Earnings", link: "/earnings", icon: "bx bx-dollar" },
        { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        {
          name: "Leader Board",
          link: "/leaderboard",
          icon: "bx bx-bar-chart",
        },
        { name: "profile", link: "/profile-settings", icon: "bx bx-cog" },
        { name: "logout", link: "/logout", icon: "bx bx-log-out" },
      ];
    case CS:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Jobs",
          link: "/joblist/pending",
          icon: "bx bx-briefcase",
        },
        { name: "Post a Job", link: "/post-job", icon: "bx bx-cloud-upload" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // { name: "company", link: "/company", icon: "bx bx-building" },
        // { name: "profile", link: "/profile-settings", icon: "bx bx-user" },
        {
          name: "logout",
          link: "/logout",
          icon: "bx bx-log-out",
        },
      ];
    case "CA":
      return [
        { name: "Dashboard", link: "/dashboard", icon: "bx bxs-briefcase" },

        {
          name: "Jobs",
          link: "/joblist/pending",
          icon: "bx bx-briefcase",
        },
        { name: "Post a Job", link: "/post-job", icon: "bx bx-cloud-upload" },
        { name: "Staff", link: "/profile-settings?tab=1", icon: "bx bx-user" },
        // { name: "calendar", link: "/calendar", icon: "bx bx-calendar-alt" },
        // { name: "chat", link: "/chat", icon: "bx bx-chat" },
        // {
        //   name: "Integrations",
        //   link: "/profile-settings?tab=1",
        //   icon: "bx bxl-zoom",
        // },
        // {
        //   name: "payments",
        //   link: "/payments",
        //   icon: "bx bx-dollar-circle",
        // },
        {
          name: "logout",
          link: "/logout",
          icon: "bx bx-log-out",
        },
      ];
    case ADMIN:
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          name: "Company List",
          link: "/company-list",
          icon: "bx bx-buildings",
        },

        {
          name: "Partners",
          logo:<HandshakeOutlinedIcon style={{ color: 'white',fontSize:"20px"}}/>,
          isMulti: true,
          clicked: false,
          items: [
            {
              name: "Referal Partner",
              link: "/referal-partner-list",
              icon: "bx bx-phone",
            },
            {
              name: "Screening Partners",
              link: "/screening-partner-list",
              // icon: "bx bx-help-circle",
              logo:<ImageSearchOutlinedIcon style={{ color: 'white',fontSize:"20px"}}/>,
            },
            {
              name: "Techincal Evaluators",
              link: "/technical-evaluator-list",
              // icon: "bx bx-help-circle",
              logo:<InterpreterModeOutlinedIcon style={{ color: 'white',fontSize:"20px"}}/>,
            },
            {
              name: "Recruiting Company",
              link: "/recruiting-company-list",
              icon: "bx bx-buildings",
            },
            {
              name: "BGV Partners",
              link: "/background-evaluators-list",
              icon: "bx bx-git-compare",
            },
          ],
        },

        // {
        //   name: "Referal Partner",
        //   link: "/referal-partner-list",
        //   icon: "bx bx-phone",
        // },
        // {
        //   name: "Screening Partners",
        //   link: "/screening-partner-list",
        //   icon: "bx bx-help-circle",
        // },

        // {
        //   name: "Account Managers",
        //   link: "/account-manager-list",
        //   icon: "bx bx-help-circle",
        // },
        // {
        //   name: "Techincal Evaluators",
        //   link: "/technical-evaluator-list",
        //   icon: "bx bx-help-circle",
        // },
        // {
        //   name: "BGV Partners",
        //   link: "/background-evaluators-list",
        //   icon: "bx bx-git-compare",
        // },

        // {
        //   name: "Evaluation Forms",
        //   link: "/evaluation-forms",
        //   icon: "bx bx-git-compare",
        // },
        // {
        //   name: "Commissions",
        //   link: "/commissions",
        //   icon: "bx bx-git-compare",
        // },

        {
          name: "Jobs",
          link: "/joblist/pending",
          icon: "bx bxs-briefcase",
        },
        {
          name: "Job Attributes",
          logo:<TryOutlinedIcon style={{ color: 'white',fontSize:"20px"}}/>,
          isMulti: true,
          clicked: false,
          items: [
            {
              name: "Industries",
              img: "buisness_svg size_color",
              link: "/job-attributes/industries",
            },
            {
              name: "Categories",
              icon: "fn_svg size_color",
              link: "/job-attributes/categories",
            },
            {
              name: "Job Role",
              img: "grad_svg size_color",
              link: "/job-attributes/job-role",
            },
            {
              name: "Job Level",
              img: "grad_svg size_color",
              link: "/job-attributes/job-level",
            },
            {
              name: "Key Skills",
              img: "skill_svg size_color",
              link: "/job-attributes/skills",
            },

            {
              name: "Educational Qualifications",
              icon: "bx bx-user",
              link: "/job-attributes/degree-levels",
            },
          ],
        },
        // {
        //   name: "Blogs",
        //   logo:<AssignmentOutlinedIcon style={{ color: 'white',fontSize:"20px"}}/>,
        //   isMulti: true,
        //   clicked: false,
        //   items: [
        //     {
        //       name: "All Blogs",
        //       icon: "bx bx-list-ul",
        //       link: "/blog/all-blogs",
        //     },
        //     {
        //       name: "Blog Grid",
        //       icon: "bx bx-grid-alt",
        //       link: "/blog/grid-blogs",
        //     },
        //     {
        //       name: "Create Blogs",
        //       icon: "bx bxs-message-square-add",
        //       link: "/blog/create-blogs",
        //     },
        //     {
        //       name: "All Categories",
        //       icon: "bx bx-category-alt",
        //       link: "/blog/all-categories",
        //     },
        //     {
        //       name: "Create Categoies",
        //       icon: "bx bxs-category",
        //       link: "/blog/create-categories",
        //     },
        //   ],
        // },

        // {
        //   name: "Talent Pool",
        //   link: "/talent-pool",
        //   icon: "bx bx-user-check",
        // },
        // {
        //   name: "Question Bank",
        //   link: "/questions",
        //   icon: "bx bx-question-mark",
        // },
        // {
        //   name: "Boolean Query Builder",
        //   link: "/boolean-query",
        //   icon: "bx bx-filter",
        // },
        // {
        //   name: "Reports",
        //   link: "/reports",
        //   icon: "bx bx-bar-chart",
        // },
        // {
        //   name: "Calendar",
        //   link: "/calendar",
        //   icon: "bx bx-calendar-alt",
        // },
        // {
        //   name: "Payments",
        //   link: "/payments",
        //   icon: "bx bx-dollar-circle",
        // },
        {
          name: "Setings",
          link: "/profile-settings",
          icon: "bx bx-cog",
        },
      ];
  }
}
