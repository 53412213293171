import { Box, Grid, Rating, Stack,TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  BlackTypography,
  GreyTypography,
  PrimaryTypography,
} from "assets/Mui/globalTheme";
import { MODERATOR, SP,TE } from "common/constants/userModules";
const useStyles = makeStyles({
  table:{
    display:'flex',
    flexDirection:'column',
    border:'0.5px solid rgba(0,0,0,0.5)',
    borderRadius: "8px",
    padding:'8px',
    "& tr":{
      display:'flex',
      width:'100%',
      alignItems:"center"
    },
    "& thead":{
      width:'100%',    
      position: 'sticky',
      top: 0,
      zIndex: 2,
      paddingBlock:'4px',
      borderBottom:'0.5px solid rgb(221 221 221)',
      "& th":{
        width:'calc(100% / 3)',
        textAlign:'left'
      }
    },
    "& tbody":{
      height:"250px",
      overflowY:'scroll',
      "&::-webkit-scrollbar":{
        display:'none'
      },
      "& tr":{
        paddingBlock:'12px',
        "&:not(:last-child)":{
          borderBottom:'0.5px solid rgba(221,221,221,0.5)',
        }
      },
      "& td":{
        width:'calc(100% / 3)',
        textAlign:'left',
      },
      "& .css-15z3oi4-MuiInputBase-root-MuiOutlinedInput-root":{
        padding:'8px'
      }
    }
  }
})

export default function ReportTable({ heading,skills,evType,jobPostInfo}) {  
  let comment;
  let rating;
  if(evType === SP){
    comment="sp_comment"
    rating = "sp_rating"
  }else if(evType === MODERATOR){
    comment = "moderator_comment"
    rating = "moderator_rating"
  }else{
    comment = "evaluator_comment"
    rating = "evaluator_rating"
  }
  const classes = useStyles()
  const jobSkills = jobPostInfo?.key_skills_details.length ?jobPostInfo?.key_skills_details : []

  return (
    <Stack spacing={2} className="mb-4">
      <PrimaryTypography variant="h3">{heading}</PrimaryTypography>
      <Box
      >
        <table className={classes.table}>
          <thead>
            <tr>  
              <BlackTypography variant="h6" component="th">
                {/* <td style={{width:"100%"}}></td> */}
                Skills
              </BlackTypography>
              <BlackTypography variant="h6"  component="th">
                {/* <td style={{width:"100%"}}></td> */}
                Ratings
              </BlackTypography>
              <BlackTypography variant="h6" component="th">
                {/* <td style={{width:"100%"}}></td> */}
                Comments
              </BlackTypography>
            </tr>
          </thead>
          <tbody>
            {skills.length ? skills?.map((data,id)=>(
              <tr key={id}>
                  <td>
                    {/* <td style={{width:"100%"}}></td> */}
                    <GreyTypography variant="h6">{data?.skill_details[0]?.name}</GreyTypography>
                  </td>
                  <td>
                    {/* <td style={{width:"100%"}}></td> */}
                    <Rating style={{display:'flex'}} value={data?.[rating]} readOnly size="medium" />

                  </td>
                  <td>
                    {/* <td style={{width:"100%"}}></td> */}
                    <TextField
                      label="comment"
                      multiline
                      defaultValue={data?.[comment]}
                      // sx={{marginBottom:'16px'}}
                      rows={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </td>
              </tr>
            )): jobSkills.map((data,id)=>(
              <tr key={id}>
              <td>
                <GreyTypography variant="h6">{data?.name}</GreyTypography>
              </td>
              <td>
                <Rating style={{display:'flex'}} value={0} readOnly size="medium" />
              </td>
              <td>
                <TextField
                  label="comment"
                  multiline
                  defaultValue={""}
                  // sx={{marginBottom:'16px'}}
                  rows={2}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </td>
          </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Stack>
  );
}
