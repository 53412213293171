import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { viewSingleCompanyDetails } from "helpers/services/companies/company";
import { imageURL } from "common/constants/commonURLS";
import { Avatar, CircularProgress, Stack } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import {
  PrimaryButton,
  PrimaryPopUp,
  PrimaryTypography,
  SecondaryFilledButton,
  StyledListItemButton,
  StyledProfilePopText,
} from "assets/Mui/globalTheme";
import SearchParamHook from "hooks/searchParams";
import { useDispatch, useSelector } from "react-redux";
import CommonUserTabs from "components/commonUser/tabs";
import CommonUserIndex from "components/commonUser";
import SetPermissionForCommonUser from "components/commonUser/updateCommonUser";
import { Typography } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getCommonUser } from "helpers/services/common";
export default function CompanyView() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);
  const [statusDropDown, setStatusDropDown] = useState(null);
  const open = Boolean(statusDropDown);
  function handleStatusDropDownClick(event) {
    setStatusDropDown(event.currentTarget);
  }
  function handleStatusDropDownClose() {
    setStatusDropDown(null);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
      background: "white",
      marginTop: "10px",
      fontSize: "14px",
      padding: "10px 0px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  };
  const user = useSelector(state => state.Login);
  const user_data = useSelector(state => state.CommonUser.user);

  const history = useHistory();
  const searchParams = SearchParamHook();
  useEffect(() => {
    if (searchParams?.get("tabs")) {
      setSelectedNav(searchParams?.get("tabs"));
    }
  }, []);

  const { id } = useParams();
  const [selectedNav, setSelectedNav] = useState("0");
  useEffect(() => {
    searchParams.set("tabs", selectedNav);
    history.replace({ search: searchParams?.toString() });
  }, [selectedNav]);
  useEffect(() => {
    initialFetch(id);
  }, [id]);
  async function initialFetch(id) {
    const { data } = await getCommonUser(id);
    dispatch({ type: "ADD_COMMON_USER_DATA", payload: data });
  }

  function findPage() {
    switch (selectedNav) {
      case "0":
        return <CommonUserIndex />;
      case "1":
        return <SetPermissionForCommonUser/>;
      default:
        return <>oops Nothing found</>;
    }
  }
  if (loading) {
    return (
      <div
        style={{
          display: "grid",
          height: "100vh",
          width: "80vw",
          placeItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Stack spacing={2} sx={{ padding: "0px 20px", position: "relative" }}>
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Stack spacing={2} direction="row" alignItems={"center"}>
          <Avatar
            variant="circular"
            sx={{
              bgcolor: grey["A200"],
              height: 94,
              color: "black",
              width: 94,
            }}
            alt="Company Logo"
            src={`${imageURL}${user_data?.image}`}
          >
            {user_data?.first_name?.charAt("0")}
          </Avatar>
          <Stack spacing={2}>
            <PrimaryTypography variant="h4">
              {user_data?.first_name} {user_data?.last_name}
            </PrimaryTypography>
          </Stack>
        </Stack>
        {/* <Stack direction="row" spacing={1}>
          <PrimaryButton
            endIcon={<FeatherIcon icon="chevron-down" size="14" />}
            sx={{ width: "180px" }}
            onClick={handleStatusDropDownClick}
          >
            Status
          </PrimaryButton>
          <PrimaryPopUp
            id={open ? "move-candidate-popper" : undefined}
            open={open}
            PaperProps={MenuProps}
            anchorEl={statusDropDown}
            onClose={handleStatusDropDownClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <StyledListItemButton>
              <StyledProfilePopText>
                <Stack alignItems={"center"} direction="row" spacing={2}>
                  <Typography
                    sx={{ color: "green" }}
                    variant="h6"
                    component="h6"
                  >
                    <FeatherIcon icon="check" size="14" />
                  </Typography>
                  <Typography
                    sx={{ color: "green", fontSize: "15px" }}
                    variant="h6"
                    component="h6"
                  >
                    Approved
                  </Typography>
                </Stack>
              </StyledProfilePopText>
            </StyledListItemButton>
          </PrimaryPopUp>
          // <SecondaryFilledButton sx={{ width: "180px" }}>
          //  Reject
          //</SecondaryFilledButton> 
        </Stack> */}
      </Stack>

      <CommonUserTabs
        selectedNav={selectedNav}
        setSelectedNav={setSelectedNav}
      />
      {findPage()}
    </Stack>
  );
}
