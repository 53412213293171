import React from "react";
import { connect } from "react-redux";
import { Label } from "reactstrap";
import { updateJob } from "store/jobs/actions";

const filterContent = [
  { name: "Dubai (UTC +4)" },
  { name: "New Delhi (UTC +5)" },
  { name: "China (UTC +6)" },
  { name: "Singapore (UTC +7)" },
];
const aus = [
  { name: "Australian EDT [UTC+1]" },
  { name: "Australian CDT [UTC+10:30]" },
];
const eur = [
  { name: "Easter European [UTC +2]" },
  { name: "Easter EST [UTC+3]" },
  { name: "Greenwich Mean [UTC +0]" },
  { name: "Further EET [UTC+3]" },
];
const us = [{ name: "Eastern Daylight [UTC+2-4]" }];
class TimeZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asiaBtns: filterContent,
      eurpoeBtns: eur,
      autstrailaBtns: aus,
      unitedStates: us,
      filter: [],
    };
  }
  componentDidMount() {
    let array = this.props.time_zone.map(item => {
      return { data: item };
    });
    this.setState({ filter: array });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.time_zone !== this.props.time_zone) {
      let array = this.props.time_zone.map(item => {
        return { data: item };
      });
      this.setState({ filter: array });
    }
  }

  render() {

    this.handleFilterClick = async (e, item) => {
      if (!this.state.filter.find(({ data }) => data === item)) {
        this.setState(prevState => ({
          filter: [...prevState.filter, { name: e, data: item }],
        }));
        this.props.updateJob({ time_zone: [...this.props.time_zone, item] });
      } else {
        const filters = [...this.state.filter];
        const index = filters.indexOf(
          filters.find(({ data }) => data === item)
        );
        if (index !== -1) {
          filters.splice(index, 1);
          this.setState({ filter: filters });
          const toApiForm = await filters.map(item => item.data);
          this.props.updateJob({ time_zone: toApiForm });
        }
      }
    };
    return (
      <div className="filter__container">
        <Label className="mb-2">
          Select Your Time Zones <span className="red px-2 py-2">*</span>
        </Label>
        <div className="my-2">
          <Label>Asia</Label>
        </div>
        <div className="filter_cont">
          {this.state.asiaBtns.map((item, index) => (
            <div key={index} className="filter_max_width">
              <div
                className={`${
                  this.state.filter.find(({ data }) => data === item.name)
                    ? "selected_time_zones"
                    : "time-zones"
                } normal_filter_class`}
                onClick={e => this.handleFilterClick(index, item.name)}
              >
                <span className="icon_filters">
                  <span>{item.name}</span>
                  {this.state.filter.find(({ data }) => data === item.name) ? (
                    <i
                      className="bx bx-x
                        "
                    ></i>
                  ) : (
                    <i className="bx bx-plus"></i>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="my-2">
          <Label>Australia</Label>
        </div>
        <div className="filter_cont">
          {this.state.autstrailaBtns.map((item, index) => (
            <div key={index} className="filter_max_width">
              <div
                className={`${
                  this.state.filter.find(({ data }) => data === item.name)
                    ? "selected_time_zones"
                    : "time-zones"
                } normal_filter_class`}
                onClick={e => this.handleFilterClick(index, item.name)}
              >
                <span className="icon_filters">
                  <span>{item.name}</span>
                  {this.state.filter.find(({ data }) => data === item.name) ? (
                    <i
                      className="bx bx-x
                        "
                    ></i>
                  ) : (
                    <i className="bx bx-plus"></i>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="my-2">
          <Label>United States</Label>
        </div>
        <div className="filter_cont">
          {this.state.unitedStates.map((item, index) => (
            <div key={index} className="filter_max_width">
              <div
                className={`${
                  this.state.filter.find(({ data }) => data === item.name)
                    ? "selected_time_zones"
                    : "time-zones"
                } normal_filter_class`}
                onClick={e => this.handleFilterClick(index, item.name)}
              >
                <span className="icon_filters">
                  <span>{item.name}</span>
                  {this.state.filter.find(({ data }) => data === item.name) ? (
                    <i
                      className="bx bx-x
                        "
                    ></i>
                  ) : (
                    <i className="bx bx-plus"></i>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="my-2">
          <Label>Europe</Label>
        </div>
        <div className="filter_cont">
          {this.state.eurpoeBtns.map((item, index) => (
            <div key={index} className="filter_max_width">
              <div
                className={`${
                  this.state.filter.find(({ data }) => data === item.name)
                    ? "selected_time_zones"
                    : "time-zones"
                } normal_filter_class`}
                onClick={e => this.handleFilterClick(index, item.name)}
              >
                <span className="icon_filters">
                  <span>{item.name}</span>
                  {this.state.filter.find(({ data }) => data === item.name) ? (
                    <i
                      className="bx bx-x
                        "
                    ></i>
                  ) : (
                    <i className="bx bx-plus"></i>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Jobs }) => ({
  time_zone: Jobs.time_zone,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeZone);
