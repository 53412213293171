import ReactSelectComponent from "components/Common/reactSelectComponent";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-hot-toast";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Input, Label, Row, Container } from "reactstrap";
class CreateJob extends React.Component {
  render() {
    return (
      <Container fluid>
        <Row style={{ marginTop: "70px", marginLeft: "20px" }}>
          <Col xs={12} style={{ padding: "10px 0px" }}>
            <h3>Create Jobs</h3>
          </Col>
          <Row>
            <Card>
              <CardBody className="">
                <Row>
                  <Col md={6}>
                    <Label>Position</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={6}>
                    <Label>Select Client</Label>
                    <ReactSelectComponent />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Label>Add Location</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={6} className="d-flex align-items-center gap-2">
                    <Input type="checkbox" />
                    <Label className="margin_0">Mark as Remote</Label>
                    {/* <br /> */}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>HeadCount</Label>
                    <Input type="number" />
                  </Col>
                  <Col md={6}>
                    <Label>Contact Details</Label>
                    <ReactSelectComponent />
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Label>Minimum Salary</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={3}>
                    <Label>Maximum Salray</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={3}>
                    <Label>currency</Label>
                    <ReactSelectComponent />
                  </Col>
                  <Col md={3}>
                    <Label>Salary Frequency</Label>
                    <ReactSelectComponent />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Label>Skills</Label>
                    <ReactSelectComponent />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Label>Description</Label>
                  </Col>
                  <Col>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      placeholder="Place Your Content Here..."
                    />{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Label>Career level</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Functional Area</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Gender</Label>
                    <Input type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Label>Job type</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Job shift</Label>
                    <Input type="text" />
                  </Col>
                  <Col md={4}>
                    <Label>Expiry date</Label>
                    <Input type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Label>Require degree level</Label>
                    <Input type="text" />
                  </Col>{" "}
                  <Col md={4}>
                    <Label>Require Job Experience</Label>
                    <Input type="text" />
                  </Col>
                </Row>
                {/* <CardFooter className="my-2"> */}
                <Row
                  className="justify-content-center"
                  style={{ margin: "10px 0px" }}
                >
                  <Col xs={6}>
                    <button className="btn btn-secondary">Cancel</button>
                  </Col>
                  <Col
                    xs={6}
                    className="ml-auto d-flex justify-content-end gap-2"
                  >
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        toast.success("job created successfully");
                        this.props.history.push("/");
                      }}
                    >
                      Saver
                    </button>{" "}
                  </Col>
                </Row>
                {/* </CardFooter> */}
              </CardBody>
            </Card>
          </Row>
        </Row>
      </Container>
    );
  }
}

export default withRouter(CreateJob);
