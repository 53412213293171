import React, { useState } from "react";
import { Button, Card, Container } from "reactstrap";

const ZepulAdminProfile = () => {
  const [profileModal, setProfileModal] = useState({
    modal: false,
    type: "",
    action: "",
    modalType: "",
    details: {},
  });

  return (
    <Container fluid>
      <Card className="p-4">
        <p className="text-bold">Zepul Admin</p>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <p>d@syoft.com</p>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default ZepulAdminProfile;
