import React from "react";
import { Alert, Button, Card, Col, Container, Row } from "reactstrap";
import "./MyEarning.css";
import ApplicantEarning from "./ApplicantEarning";
import CompanyEarning from "./CompanyEarning";
import MyEarningsTable from "./MyEarningTable";
function MyEarning() {
  return (
    <>
    <MyEarningsTable />
      {/* <div className="myearning_main_cont">
        <Row>
          <Col sx={12}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <h4 className="leadership-text text-center pt-4">Insights</h4>
                <p className="leadership-desc text-center">
                  Overall activities on your Accounts
                </p>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="leadership-bg p-4 mb-4">
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <h4 className="leadership-text text-center">0</h4>
                      <p className="leadership-desc text-center">
                        Successful Hires
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 border-left">
                      <div className="text-center">
                        <h4 className="leadership-text text-center text-primary">
                          $ 0.000 USD
                        </h4>
                        <p className="leadership-desc text-center">
                          Total commission earned
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 border-left">
                      <div className="text-center">
                        <h4 className="leadership-text text-center text-primary">
                          $ 0.000 USD
                        </h4>
                        <p className="leadership-desc text-center">
                          Commissions in Pipeline
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ApplicantEarning />
              </div>
              <div>
                <CompanyEarning />
              </div>
            </div>
          </Col>
        </Row>
      </div> */}
    </>
  );
}

export default MyEarning;
