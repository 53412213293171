import React from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

export const JobTypeInfo = props => {
  const { modelhed, modelText } = props.params.details;

  return (
    <div>
      <Modal
        isOpen={props.params.modal}
        toggle={props.onHide}
        className="models-main"
      >
        <ModalHeader
          toggle={props.onHide}
          style={{ fontSize: "12px" }}
          className="models-header"
        >
          {modelhed}
        </ModalHeader>
        <ModalBody>
          <div className="models-body-main">{modelText}</div>
        </ModalBody>
      </Modal>
    </div>
  );
};
