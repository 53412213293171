import {
  DELETE_JOB,
  UPDATE_FUNCTIONAL_AREA,
  UPDATE_JOB,
  UPDATE_JOB_DESCRIPTION,
  UPDATE_JOB_LOCATION,
  UPDATE_JOB_ROLE,
  UPDATE_JOB_TITLE,
  UPDATE_JOB_TYPE,
  UPDATE_WORK_MODE,
} from "./actionTypes";

export const updateJobTitle = data => ({
  type: UPDATE_JOB_TITLE,
  payload: data,
});
export const updateJobType = data => ({
  type: UPDATE_JOB_TYPE,
  payload: data,
});
export const updateJobLocation = data => ({
  type: UPDATE_JOB_LOCATION,
  payload: data,
});

export const updateWorkMode = data => ({
  type: UPDATE_WORK_MODE,
  payload: data,
});

export const updateJobDescription = data => ({
  type: UPDATE_JOB_DESCRIPTION,
  payload: data,
});
export const updateFunctionalArea = data => ({
  type: UPDATE_FUNCTIONAL_AREA,
  payload: data,
});
export const updateJobRoles = data => ({
  type: UPDATE_JOB_ROLE,
  payload: data,
});

export const updateJob = data => ({
  type: UPDATE_JOB,
  payload: data,
});
export const deleteJob = () => ({
  type: DELETE_JOB,
  payload: {},
});
