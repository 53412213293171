import { Link, withRouter } from "react-router-dom";
import React from "react";
import Dropzone from "react-dropzone";
const { Modal, Row, Col, Card, Form } = require("reactstrap");
import PropTypes from "prop-types";
class MultipleUploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
      selectedFiles: [],
    };
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    // this.state = { };
  }
  handleInput(e) {
    this.setState({ input: e.target.value });
  }
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: [...this.state.selectedFiles, ...files] });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  render() {
    const { isOpen, toggle, handleImage } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={this.toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Image Upload
          </h5>
          <button
            type="button"
            onClick={() => toggle(!isOpen)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Dropzone
              onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} accept="image/*" />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {this.state.selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Form>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggle(!isOpen);
            }}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleImage(this.state.selectedFiles);
            }}
          >
            Save changes
          </button>
        </div>
      </Modal>
    );
  }
}
MultipleUploadModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  handleImage: PropTypes.func,
};
export default withRouter(MultipleUploadModal);
