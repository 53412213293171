import { LeftSquareOutlined } from "@ant-design/icons";
// import ReactSelectComponent from "components/Common/reactSelectComponent";
// import SliderComponenet from "components/Common/Slider";
import React from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import "../../assets/css/talentpool.css";
import PropTypes from "prop-types";
class TalentFilter extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  valuetext(value) {
    return `${value}°C`;
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid className="talent_filter global_scroll">
          <Card>
            <div className="talent_filter_header">
              <div className="talent_filter_info">
                <LeftSquareOutlined
                  onClick={() => {
                    this.props.history.push("/talent-pool");
                  }}
                />
                <span>Filter</span>
              </div>
              <div>
                <button className="btn btn-outline-primary">clear all</button>
              </div>
            </div>
            <CardBody className="talent_info global_scroll">
              <Row>
                <Col xs={12}>
                  <h5>Fresher/Experienced</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="inpt__filter">
                  <input type="radio" name="exp_type" id="exp1" />
                  <Label htmlFor="exp1">Fresher</Label>
                </Col>
                <Col xs={6} className="inpt__filter">
                  <input type="radio" name="exp_type" id="exp2" />
                  <Label htmlFor="exp2">Experienced</Label>
                </Col>
              </Row>
              <Row>
                <Label>Experience</Label>
              </Row>
              <Row>
                <Col className="inpt__filter">
                  <input type="radio" name="experience" id="exp3" />
                  <Label htmlFor="exp3">0-5 year experience</Label>
                </Col>
              </Row>
              <Row>
                <Col className="inpt__filter">
                  <input type="radio" name="experience" id="exp4" />
                  <Label htmlFor="exp4">0-5 year experience</Label>
                </Col>
              </Row>
              <Row>
                <Col className="inpt__filter">
                  <input type="radio" name="experience" id="exp5" />
                  <Label htmlFor="exp5">0-5 year experience</Label>
                </Col>
              </Row>
              <Row>
                <Col className="inpt__filter">
                  <input type="radio" name="experience" id="exp6" />
                  <Label htmlFor="exp6">0-5 year experience</Label>
                </Col>
              </Row>
              <Row>
                <Col className="inpt__filter">
                  <input type="radio" name="experience" id="exp7" />
                  <Label htmlFor="exp7">0-5 year experience</Label>
                </Col>
              </Row>
              <Row>
                <Label>City</Label>
                {/* <ReactSelectComponent /> */}
              </Row>
              <Row>
                <Label>Source</Label>
                {/* <ReactSelectComponent /> */}
              </Row>

              <Label>Expected CTC</Label>
              {/* <SliderComponenet valuetext={this.valuetext} /> */}

              <Label>Current CTC</Label>
              {/* <SliderComponenet valuetext={this.valuetext} /> */}

              <Label>Notice Period</Label>
              {/* <SliderComponenet valuetext={this.valuetext} /> */}

              <Row>
                <Label>Skills</Label>
                {/* <ReactSelectComponent /> */}
              </Row>
              <Row>
                <Label>Education</Label>

                {/* <ReactSelectComponent /> */}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}
TalentFilter.propTypes = {
  history: PropTypes.object,
};
export default withRouter(TalentFilter);
