import { POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
// !getting company related things
async function Id() {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
}
export const createTechicalEvaluator = async body => {
  const reqBody = {
    email: body.email,
    first_name: body.first_name,
    last_name: body.last_name,
    phone_number: body.phone_number,
    user_password: body.password,
    usertype: body.userType,
  };
  return await callApi(
    "/technical_evaluator_authorise/register",
    reqBody,
    POST
  );
};

export const updateTechnicalEvaluator = async body => {
  return await callApi(
    `/technical_evaluator_authorise/${await Id()}`,
    body,
    PUT
  );
};

export const getAllTechnicalPartners = async () => {
  return await callApi(`referral_partner_authorise/evaluators_list`);
};
