import { UPDATE_SCREENING_PARTNER } from "./actionTypes";

const initState = {
  image: "",
  about: "",
  industry: [],
  functional_area: [],
  job_role: [],
  location: "",
  gender: "",
  work_mode: "",
};
export default function reducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_SCREENING_PARTNER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
