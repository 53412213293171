import React from "react";
import "../../assets/css/email.css";

import { Button, Card, Col, Row } from "reactstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import VerticalChart from "./verticalChart";

class CareerSiteAnalysis extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <div className="candidate_header email_brand_div">
              <div>
                <h4>Career site Analysis</h4>
                <p>
                  Real-time insights into candidate engagement to improve your
                  talent acquisition strategy.
                </p>
              </div>
              <Button color="primary">Help</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div className="candidate_card">
              <h5>Total Visits</h5>
              <h6>Visit date: Last 30 days</h6>
              <h1>0</h1>
            </div>
          </Col>

          <Col xs={4}>
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
          </Col>
          <Col xs={4}>
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
          </Col>

          <Col xs={4}>
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
          </Col>
          <Col xs={4}>
            <div className="candidate_card">
              <h5>Total Candidates</h5>
              <h6>Profile Created</h6>
              <h1>12</h1>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <VerticalChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <VerticalChart />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <VerticalChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <VerticalChart />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <VerticalChart />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <VerticalChart />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CareerSiteAnalysis;
