import * as React from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { getAllReferalParterns } from "helpers/services/referalPartner";
import { imageURL } from "common/constants/commonURLS";
import {
  PrimaryButtonForTable,
  PrimaryDataGrid,
  PrimarySwitch,
  SmallPrimaryAvatar,
} from "assets/Mui/globalTheme";
import TablePopper from "components/table/header/popper";
import LoaderIcon from "utils/icons/loaderIcon";
import { GenderOperators } from "components/table/operators/genderOperators";
import { LocationOperators } from "components/table/operators/locationOperators";
import { KeySkillOperator } from "components/table/operators/keySkillOperator";
import { getAllAdminStaffListWithStatus } from "helpers/services/adminStaff/adminStaff";
import { useHistory } from "react-router-dom";

export default function ReferalPartnerTable() {
  const [accountManagers, setAccountManagers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    initialFetch();
  }, []);
  const history = useHistory();
  async function initialFetch() {
    setLoading(true);
    const { data, status } = await getAllAdminStaffListWithStatus();
    const result = data?.map((item, index) => ({
      id: index + 1,
      email: item?.email,
      image: `${imageURL}${item?.image}`,
      phone_number: item?.phone_number,
      location: item?.location,
      experience: item?.experience,
      active_jobs_assigned: item?.active_jobs_assigned,
      total_jobs_assigned: item?.total_jobs_assigned,
      highest_education: item?.highest_education,
      skills: item?.skills,
      resume: item?.resume,
      name: `${item?.first_name} ${item?.last_name}`,
      companies_mapped: index + 1,
    }));
    if (status === 200) {
      setLoading(false);
    }

    setAccountManagers(result);
  }
  return (
    <div style={{ padding: "10px", height: "91vh" }}>
      <PrimaryDataGrid
        onCellClick={event => {
          history.push(`/common-user/${event?.row?.id}`);
        }}
        loading={loading}
        // checkboxSelection={true}
        columns={[
          {
            field: "image",
            filterable: false,
            renderCell: params => {
              return (
                <SmallPrimaryAvatar
                  imgProps={{ loading: "lazy" }}
                  src={params?.formattedValue}
                >
                  {params?.row?.name?.charAt(0)?.toUpperCase()}
                </SmallPrimaryAvatar>
              );
            },
          },
          { field: "name", width: 200 },
          { field: "phone_number", width: 300 },
          { field: "email", width: 300 },
          { field: "location", width: 400, filterOperators: LocationOperators },
          { field: "companies_mapped", width: 200 },
          { field: "active_jobs_assigned", width: 200 },
          { field: "candidate_onboarded", width: 200 },
          { field: "total_jobs_assigned", width: 200 },
          { field: "available_earnings", width: 200 },
          { field: "highest_education", width: 200 },
          { field: "skills", width: 200, filterOperators: KeySkillOperator },
        ]}
        rows={accountManagers}
        slots={{
          loadIcon: LoaderIcon,
          baseButton: PrimaryButtonForTable,
          basePopper: TablePopper,
          baseSwitch: PrimarySwitch,
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
