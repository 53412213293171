import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import "antd/dist/antd.css";
import "./FunctionalArea.css";
import { getAllFunctionalArea } from "helpers/services/jobAttributes/functionalArea";
import { updateJob } from "store/jobs/actions";
import { connect } from "react-redux";
import { JobFunctionalAreaOtherModel } from "./JobFunctionalAreaOtherModel";

const FunctionAreas = ({ updateJob, functional_areas }) => {
  const [tagsData, setTagsData] = useState([]);

  useEffect(() => {
    let mounted = true;
    async function dataCaller() {
      const items = await getAllFunctionalArea();
      const data = items.filter(vals=>vals.is_active).map(iterator => {
        return { name: iterator.name, id: iterator.id };
      });
      setTagsData(data);
    }
    mounted ? dataCaller() : null;
    return () => {
      mounted = false;
      setTagsData([]);
    };
  }, []);
  const [selectedTags, setSelectedTags] = useState("");
  const handleChange = id => {
    updateJob({ functional_areas: [id], job_roles: [] });
  };
  const [modelData, setModelData] = useState({
    modal: false,
    details: {},
  });
  useEffect(() => {
    if (selectedTags[0] == "Other") {
      setModelData({
        modal: true,
        details: {},
      });
    }
  }, [selectedTags]);

  const handleClose = confirm => {};

  return (
    <div className="px-0">
      <Container className="px-0" fluid>
        <Row className="">
          <Col xs={12} className="mt-2">
            <h5 style={{}}>
              Please choose Job Functional Area?{" "}
              <span className="text_sm_gray">(Select all that applied)</span>
              <span className="red p-2">*</span>
            </h5>
          </Col>
          <Col xs={12}>
            <Row>
              <div className="mt-3">
                <div id="location-timezone-select">
                  <div className="flex_wrapper">
                    {tagsData.map((item, index) => (
                      <div key={index} className="filter_max_width">
                        <div
                          className={`${
                            functional_areas[0] === item.id
                              ? "selected_time_zones"
                              : "time-zones"
                          } normal_filter_class`}
                          onClick={e => handleChange(item.id)}
                        >
                          <span className="icon_filters">
                            <span>{item.name}</span>
                          </span>
                        </div>
                      </div>
                    ))}
                    {/* <div
                      className="selected_time_zones"
                      onClick={() => {
                        setSelectedTags(["Other"]);
                      }}
                    >
                      Others
                    </div> */}
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <div>
        {selectedTags[0] == "Other" ? (
          <JobFunctionalAreaOtherModel
            setModelData={doc => setModelData(doc)}
            show={modelData.modal}
            onHide={() => {
              setModelData({ modal: false, details: {} });
            }}
            params={modelData}
            onSubmit={handleClose}
          />
        ) : null}
      </div> */}
    </div>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  functional_areas: Jobs.functional_areas,
});

const mapDispatchToProps = dispatch => ({
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunctionAreas);
