import React, { useCallback, useEffect, useState } from "react";
import { imageURL } from "common/constants/commonURLS";
import {
  Avatar,
  CardContent,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import {
  PrimaryTypography,
  BorderCard,
  GreyTypography,
  BlackTypography,
  SecondaryFilledButton,
} from "assets/Mui/globalTheme";
import { perks } from "common/constants/perks";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import CompanyProfileEditModel from "../../../pages/Settings/EditProfile/CampanyEditProfile";
import TextDivider from "utils/textDividerPrimary";
import EditInputText from "components/profile/company/edit/inputtext";
import PerksModel from "./edit/perksModel";
import UploadModel from "components/Common/models/uploadModel";
import { getPreSignedURL } from "helpers/services/pre_signed_urls/upload";
import { updateCommonUser } from "helpers/services/common";
import UploadMultipleModel from "components/Common/models/uploadMultipleImages";
import { useDispatch, useSelector } from "react-redux";
export default function CompanyProfile({ userInfo, setUserInfo }) {
  const [company, setCompany] = useState({});
  const [selectedPerks, setSelectedPerks] = useState([]);
  const [perkEdit, setPerkEdit] = useState(false);
  const [model, setModel] = useState(false);
  const [modelOpen, setModelOpen] = React.useState(false);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.Loading.loading);

  function handleUpload() {
    setModelOpen(!modelOpen);
  }
  useEffect(() => {
    dispatch({ type: "START_LOADING" });
    setCompany(userInfo?.company);
    const data = perks?.filter(item => {
      return userInfo?.company?.perks?.includes(item?.name);
    });
    setSelectedPerks(data);
    dispatch({ type: "STOP_LOADING" });
  }, [userInfo]);
  function toggleModel() {
    setModel(!model);
  }
  function updatePerks(value) {
    const perkNames = value.map(item => item.name);
    updateCommonUser({ perks: perkNames });
    // setUserInfo({
    //   ...userInfo,
    //   company: {
    //     perks: perkNames,
    //   },
    // });
    setSelectedPerks(value);
  }
  function updateValue(key, value) {
    updateCommonUser({ [key]: value });
    // setCompany({ ...company, [key]: value });
    setUserInfo({
      ...userInfo,
      company: {
        ...userInfo?.company,
        [key]: value,
      },
    });
  }

  const handlePerkEdit = useCallback(() => {
    setPerkEdit(!perkEdit);
  }, [perkEdit]);
  // function handleUploadCultureMediaImages(images) {
  //   setCompany({ ...company, culture_media_images: images });
  // }
  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    // setCompany({ ...company, company_image: url });
    setUserInfo({
      ...userInfo,
      company: {
        ...userInfo?.company,
        company_image: url,
      },
    });
    updateCommonUser({ company_image: url });
    handleUpload();
  };
  const [cultureMediaPopUp, setCultureMediaPopUp] = useState(false);
  function handleCultureMediaPopUp() {
    setCultureMediaPopUp(!cultureMediaPopUp);
  }
  async function handleCultureMediaImage(data) {
    let urls = [];
    const totalUploadPromise = new Promise((resolve, reject) => {
      data?.forEach(async file => {
        const uploadPromise = new Promise(async (resolve, reject) => {
          const url = await getPreSignedURL([file]);
          resolve(url?.replace(imageURL, ""));
        });
        uploadPromise.then(imageUrls => {
          urls.push(imageUrls);
          if (data?.length === urls?.length) resolve(urls);
        });
      });
    });

    totalUploadPromise.then(urls => {
      if (company.culture_media_images?.length > 0) {
        setUserInfo({
          ...userInfo,
          company: {
            ...userInfo?.company,
            culture_media_images: [...company.culture_media_images, ...urls],
          },
        });
        updateCommonUser({
          culture_media_images: [...company.culture_media_images, ...urls],
        });
      } else {
        setUserInfo({
          ...userInfo,
          company: {
            ...userInfo?.company,
            culture_media_images: urls,
          },
        });
        updateCommonUser({
          culture_media_images: urls,
        });
      }
      handleCultureMediaPopUp();
    });
  }
  if (loading) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Stack spacing={2} sx={{ padding: "10px 0px" }}>
      <UploadModel
        isOpen={modelOpen}
        toggle={handleUpload}
        handleImage={handleImageUpload}
      />
      <UploadMultipleModel
        isOpen={cultureMediaPopUp}
        toggle={handleCultureMediaPopUp}
        handleImage={handleCultureMediaImage}
      />
      <CompanyProfileEditModel
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        isOpen={model}
        toggle={toggleModel}
      />
      <Stack
        direction="row"
        spacing={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack spacing={1} alignItems={"start"}>
          <Avatar
            variant="circular"
            sx={{
              bgcolor: grey["A200"],
              color: "black",
              height: 94,
              width: 94,
            }}
            alt="Company Logo"
            onClick={handleUpload}
            src={`${imageURL}${company?.company_image}`}
          >
            {company?.company_name?.charAt(0)?.toUpperCase()}
          </Avatar>
          <EditInputText
            multiline={false}
            text_field={"company_name"}
            input_value={company?.company_name}
            type={"input"}
            setUpdate={updateValue}
            update_type={"company_name"}
          >
            <PrimaryTypography variant="h2">
              {company?.company_name}
            </PrimaryTypography>
          </EditInputText>
        </Stack>
      </Stack>

      <TextDivider type="about company" />
      <BorderCard>
        <CardContent>
          <EditInputText
            multiline={true}
            text_field={"about"}
            input_value={company?.about}
            type={"input"}
            setUpdate={updateValue}
            update_type={"about"}
          >
            <GreyTypography
              additionalProps={{ textTransform: "lowercase !important" }}
              variant="h6"
              component="h6"
            >
              {company?.about}
            </GreyTypography>
          </EditInputText>
        </CardContent>
      </BorderCard>

      <TextDivider type="Location" />
      <BorderCard>
        <CardContent>
          <EditInputText
            multiline={false}
            sx={{ marginLeft: "300px", minWidth: "700px" }}
            text_field={"location"}
            input_value={company?.location}
            type={"location"}
            setUpdate={updateValue}
            update_type={"location"}
          >
            <GreyTypography variant="h6" component="h6">
              {company?.location}
            </GreyTypography>
          </EditInputText>
        </CardContent>
      </BorderCard>

      <TextDivider type="External Links" />
      <BorderCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                Linkedin
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"linkedin_url"}
                input_value={company?.linkedin_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"linkedin_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.linkedin_url}
                </BlackTypography>
              </EditInputText>
            </Grid>

            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                facebook
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"facebook_url"}
                input_value={company?.facebook_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"facebook_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.facebook_url}
                </BlackTypography>
              </EditInputText>
            </Grid>
            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                Twitter
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"twitter_url"}
                input_value={company?.twitter_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"twitter_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.twitter_url}
                </BlackTypography>
              </EditInputText>
            </Grid>

            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                Glassdoor
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"glassdoors_url"}
                input_value={company?.glassdoors_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"glassdoors_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.glassdoors_url}
                </BlackTypography>
              </EditInputText>
            </Grid>
            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                AmbitionBox
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"ambition_box_url"}
                input_value={company?.ambition_box_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"ambition_box_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.ambition_box_url}
                </BlackTypography>
              </EditInputText>
            </Grid>
            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                Comparably
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"comparably_url"}
                input_value={company?.comparably_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"comparably_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.comparably_url}
                </BlackTypography>
              </EditInputText>
            </Grid>

            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                Careerbliss
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"careerbliss_url"}
                input_value={company?.careerbliss_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"careerbliss_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.careerbliss_url}
                </BlackTypography>
              </EditInputText>
            </Grid>

            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                payscale
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"payscale_url"}
                input_value={company?.payscale_url}
                type={"input"}
                setUpdate={updateValue}
                update_type={"payscale_url"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.payscale_url}
                </BlackTypography>
              </EditInputText>
            </Grid>

            <Grid item md={4}>
              <GreyTypography variant="h6" component="h6">
                Google Reviews
              </GreyTypography>
              <EditInputText
                multiline={false}
                text_field={"google_reviews"}
                input_value={company?.google_reviews}
                type={"input"}
                setUpdate={updateValue}
                update_type={"google_reviews"}
              >
                <BlackTypography variant="h5" component="h5">
                  {company?.google_reviews}
                </BlackTypography>
              </EditInputText>
            </Grid>
          </Grid>
        </CardContent>
      </BorderCard>

      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12} md={2}>
          <TextDivider type="Perks" />
        </Grid>
        <Grid item xs={12} md={2} className="mx-4">
          <SecondaryFilledButton
            startIcon={<FeatherIcon icon="edit" size="14" />}
            onClick={handlePerkEdit}
          >
            Edit
          </SecondaryFilledButton>
        </Grid>
      </Grid>
      <BorderCard>
        <CardContent>
          <Grid alignItems={"center"} container spacing={2}>
            {selectedPerks.map(({ image, name, selected }, index) => {
              return (
                <Grid item xs={12} md={2} key={image}>
                  <div
                    style={{
                      transition: "0.4s ease-in-out",
                    }}
                    className={`perks_selection selected_perk
                  }`}
                  >
                    <img src={image} className="small_img" />
                    <p>{name}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </BorderCard>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12} md={2}>
          <TextDivider type="Cultural Media Images" />
        </Grid>
        <Grid item xs={12} md={2} className="mx-4">
          <SecondaryFilledButton
            onClick={handleCultureMediaPopUp}
            startIcon={<FeatherIcon icon="plus" size="14" />}
          >
            Add More Images
          </SecondaryFilledButton>
        </Grid>
      </Grid>
      <BorderCard>
        <CardContent>
          <ImageList variant="quilted" cols={8} rowHeight={121}>
            {company?.culture_media_images?.map((item, index) => (
              <ImageListItem
                key={item}
                cols={
                  index % 5 === 0 || index % 3 === 0 || index % 4 === 0 ? 2 : 1
                }
                rows={index % 5 === 0 ? 2 : 1}
              >
                <img
                  {...srcset(`${imageURL}${item}`, 121, 2, 2)}
                  alt={company?.company_name}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </CardContent>
      </BorderCard>
      <PerksModel
        userPerks={selectedPerks}
        handleClose={handlePerkEdit}
        open={perkEdit}
        updateValue={updatePerks}
      />
    </Stack>
  );
}
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
