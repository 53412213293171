import { Chip, Divider, Stack } from "@mui/material";
import { findJobType } from "helpers/functions/findJobType";
import { formatDistance } from "date-fns";
import getSymbolFromCurrency from "currency-symbol-map";
import { ACM, ZA } from "common/constants/userModules";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import CloseJobModel from "./closeJobModel";
import { Modal } from "@mui/material";
import React from "react";
import { deleteSingleJob } from "helpers/services/jobs/jobs";
import {
  GrayDivider,
  PrimaryChip,
  SecondaryFilledChip,
  StyledSecondaryTypography,
} from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

export default function JobCardInformation({
  item,
  tableData,
  setTableData,
  setUpdate,
}) {
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1}>
        <div className="d-flex align-items-center gap-1">
          <FeatherIcon icon="briefcase" size="14" />
          <StyledSecondaryTypography variant="h6" component="h6">
            {item.min_exp}-{item.max_exp} years
          </StyledSecondaryTypography>
        </div>
        <GrayDivider orientation="vertical" flexItem />
        <div className="d-flex align-items-center gap-1">
          <StyledSecondaryTypography variant="h6" component="h6">
            {getSymbolFromCurrency(item.currency)} {item.min_sal} -{" "}
            {item.max_sal}
          </StyledSecondaryTypography>
        </div>
        <GrayDivider orientation="vertical" flexItem />
        <div className="d-flex align-items-center gap-1">
          <FeatherIcon icon="map-pin" size="14" />
          <StyledSecondaryTypography variant="h6" component="h6">
            {item.work_mode !== "Fully_remote"
              ? item.location
              : "Remote"}
          </StyledSecondaryTypography>
        </div>
      </Stack>
      <Stack direction="row" spacing={1}>
        <div className="d-flex align-items-center gap-1 position-relative">
          {item.key_skill_details.slice(0, 4).map(data => (
            <StyledSecondaryTypography
              key={data?.id}
              variant="h6"
              component="h6"
              purpose="skills"
            >
              {data?.name}
            </StyledSecondaryTypography>
          ))}
        </div>
      </Stack>
      <GrayDivider />
      <Stack direction="row" spacing={1} justifyContent={"space-between"}>
        <StyledSecondaryTypography variant="h6" component="h6">
          {formatDistance(new Date(item.createdAt), new Date(), {
            addSuffix: true,
          })}{" "}
          posted
        </StyledSecondaryTypography>

        <StyledSecondaryTypography variant="h6" component="h6">
          Applicants ({item?.get_applicants_count})
        </StyledSecondaryTypography>

        <StyledSecondaryTypography variant="h6" component="h6">
          {formatDistance(new Date(item.application_deadline), new Date(), {
            addSuffix: true,
          })}{" "}
          to apply
        </StyledSecondaryTypography>
      </Stack>
    </Stack>
  );
}
