import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

import user1 from "../../../assets/images/profile-pic.png";

import { connect } from "react-redux";
import { imageURL } from "common/constants/commonURLS";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: "Admin",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            {this.props?.user?.image !== null &&
            this.props?.user?.image !== "" ? (
              <img
                className="rounded-circle header-profile-user"
                onError={e => {
                  e.target.src =
                    "https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo=";
                }}
                src={
                  this.props?.user?.image?.includes("img")
                    ? `${imageURL}${this.props?.user?.image}`
                    : "https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo="
                }
                alt="Header Avatar"
              />
            ) : (
              <div
                className="header_profile_alternative rounded-circle header-profile-user"
                style={{
                  background: "rgb(39, 64, 177)",
                  color: "white",
                }}
              >
                {this.props?.user.user_name.charAt(0)}
              </div>
            )}{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem className="link_cont">
              <Link to="/profile-settings" className="link_full">
                <i className="bx bx-user font-size-16 align-middle ms-1" />
                <span>Profile</span>
              </Link>
            </DropdownItem>

            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{"Logout"}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  success: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    user: state.Login,
  };
};

export default withRouter(connect(mapStateToProps, {})(ProfileMenu));
