import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "../../assets/css/email.css";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Nav,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { map } from "lodash";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import EmailToolbar from "./email-toolbar";

class EmailInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: "1",
      resultArray: [],
      inboxmails: [],
      starredmails: [],
      importantmails: [],
      draftmails: [],
      sentmails: [],
      emailData: [{ name: "fa" }, { name: "fa" }],
      trashmails: [],
      emailOpen: false,
    };
    this.togglemodal.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const {
      inboxmails,
      starredmails,
      importantmails,
      draftmails,
      sentmails,
      trashmails,
    } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Modal
              className="modal_overide"
              style={{
                minWidth: "80vw",
                minHeight: "80vh",
                // background: "f8f8fb",
              }}
              isOpen={this.state.emailOpen}
              toggle={() => {
                this.setState({
                  emailOpen: false,
                });
              }}
              centered={true}
            >
              <ModalBody
                style={{
                  minWidth: "80vw",
                  minHeight: "80vh",
                  // background: "f8f8fb",
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="email_header_container">
                      <div className="email_header">
                        <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" />
                        <div>
                          <div className="thumb_holder">
                            <span>Maria K uduppu</span>
                            <i className="dripicons-thumbs-up" />
                            <span>100%</span>
                          </div>
                          <div>
                            <span>Senior Marketer(sample)/Evaluation</span>
                          </div>
                        </div>
                      </div>
                      <Button color="primary">New Email</Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="email_container">
                      <div className="email_container_header">
                        <p className="email_header_content">
                          Senior Marketer (Sample) - Invitation to Interview
                        </p>
                        <div>
                          <div className="violet icon_box">
                            <i className="bx bx-share"></i>
                          </div>
                          <div className="violet icon_box">
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </div>
                        </div>
                      </div>

                      {this.state.emailData.map((item, index) => (
                        <div className="email_data" key={index}>
                          <div className="email_sender_details">
                            <div className="first_email_header">
                              <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" />
                              <div>
                                <div className="email_header_details">
                                  <span className="sender_name">
                                    John the Assistant{" "}
                                  </span>

                                  <span>
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      To:{" "}
                                    </span>{" "}
                                    Serena K. Uppin (Sample)
                                    (serenakuppin@recruiteemail.com)
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <span>12 Days Ago</span>
                              <i className="bx bx-dots-vertical-rounded"></i>
                            </div>
                          </div>
                          <div className="email_content">
                            Hi Serena, Thank you for applying to the Senior
                            Marketer (Sample) position at Workathon.
                            <br />
                            Your application stood out to us and we would like
                            to invite you for an
                            <br />
                            interview at our office to get to know you a bit
                            better.
                            <br />
                            You will meet with the Marketing department manager.
                            The interview
                            <br />
                            will last about 30-60 minutes and you’ll have the
                            chance to discuss <br />
                            the Senior Marketer (Sample) position and learn more
                            about our company. Would you be available on Monday
                            8am or Tuesday 4pm ?<br />
                            Looking forward to hearing from you,
                            <br />
                            Kind regards
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            {/* )} */}
            <Row>
              <Col xs="12">
                <Card className="email-leftbar">
                  <Button
                    type="button"
                    color="danger"
                    onClick={this.togglemodal}
                    block
                  >
                    New Email
                  </Button>
                  <h6 className="mt-4">My Email</h6>

                  <div className="mail-list mt-4">
                    <Nav
                      tabs
                      className="nav-tabs-custom"
                      vertical
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                        >
                          <i className="mdi mdi-email-outline me-2"></i> Inbox{" "}
                          <span className="ml-1 float-end">(18)</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggleTab("5");
                          }}
                        >
                          <i className="mdi mdi-email-check-outline me-2"></i>
                          Sent Mail
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "6",
                          })}
                          onClick={() => {
                            this.toggleTab("6");
                          }}
                        >
                          <i className="mdi mdi-trash-can-outline me-2"></i>
                          Send Later
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <h6 className="mt-4">Team Email</h6>

                  <div className="mail-list mt-1">
                    <Link to="#">
                      <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>
                      All Candidates
                    </Link>
                    <Link to="#">
                      <span className="mdi mdi-arrow-right-drop-circle text-warning float-end"></span>
                      Followed Candidates
                    </Link>
                    <Link to="#">
                      <span className="mdi mdi-arrow-right-drop-circle text-primary float-end"></span>
                      Followed Jobs
                    </Link>
                    <Link to="#">
                      <span className="mdi mdi-arrow-right-drop-circle text-danger float-end"></span>
                      Non-Candidates
                    </Link>
                    <Link to="#">
                      <span className="mdi mdi-arrow-right-drop-circle text-success float-end"></span>
                      Archived
                    </Link>
                  </div>

                  <h6 className="mt-4">Incoming CVs/Resumes</h6>

                  <div className="mail-list mt-1">
                    <Link to="#">
                      <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>
                      Company Inbox
                    </Link>
                    <Link to="#">
                      <span className="mdi mdi-arrow-right-drop-circle text-warning float-end"></span>
                      Job Inbox
                    </Link>
                  </div>
                </Card>

                <Modal
                  isOpen={this.state.modal}
                  role="dialog"
                  autoFocus={true}
                  centered={true}
                  className="exampleModal"
                  tabIndex="-1"
                  toggle={this.togglemodal}
                >
                  <div className="modal-content">
                    <ModalHeader toggle={this.togglemodal}>
                      New Message
                    </ModalHeader>
                    <ModalBody>
                      <form>
                        <div className="mb-3">
                          <Input
                            type="email"
                            className="form-control"
                            placeholder="To"
                          />
                        </div>

                        <div className="mb-3">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                          />
                        </div>
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                        />
                      </form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={this.togglemodal}
                      >
                        Close
                      </Button>
                      <Button type="button" color="primary">
                        Send <i className="fab fa-telegram-plane ms-1"></i>
                      </Button>
                    </ModalFooter>
                  </div>
                </Modal>
                <div className="email-rightbar mb-3">
                  <Card>
                    <EmailToolbar />
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <ul className="message-list">
                          {map(inboxmails, (inbox, key) => (
                            <li
                              key={key}
                              className={inbox.read ? "" : "unread"}
                              onClick={() => {
                                this.setState({
                                  emailOpen: true,
                                });
                              }}
                            >
                              <div className="col-mail col-mail-1">
                                <div className="checkbox-wrapper-mail">
                                  <Input type="checkbox" id={inbox.id} />
                                  <Label
                                    htmlFor={inbox.id}
                                    className="toggle"
                                  />
                                </div>
                                <Link to="#" className="title">
                                  {inbox.name}
                                </Link>
                                <span className="star-toggle far fa-star" />
                              </div>
                              <div className="col-mail col-mail-2">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: inbox.description,
                                  }}
                                ></div>
                                <div className="date">{inbox.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPane>
                      <TabPane tabId="2">
                        <ul className="message-list">
                          {map(starredmails, (starred, key) => (
                            <li
                              key={"starred-" + key}
                              className={starred.read ? "" : "unread"}
                            >
                              <div className="col-mail col-mail-1">
                                <div className="checkbox-wrapper-mail">
                                  <Input
                                    type="checkbox"
                                    id={starred.id + "starred"}
                                  />
                                  <Label
                                    htmlFor={starred.id + "starred"}
                                    className="toggle"
                                  />
                                </div>
                                <Link to="#" className="title">
                                  {starred.name}
                                </Link>
                                <span className="star-toggle fas fa-star" />
                              </div>
                              <div className="col-mail col-mail-2">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: starred.description,
                                  }}
                                ></div>
                                <div className="date">{starred.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPane>
                      <TabPane tabId="3">
                        <ul className="message-list">
                          {map(importantmails, (important, key) => (
                            <li
                              key={"important-" + key}
                              className={important.read ? "" : "unread"}
                            >
                              <div className="col-mail col-mail-1">
                                <div className="checkbox-wrapper-mail">
                                  <Input
                                    type="checkbox"
                                    id={important.id + "important"}
                                  />
                                  <Label
                                    htmlFor={important.id + "important"}
                                    className="toggle"
                                  />
                                </div>
                                <Link to="#" className="title">
                                  {important.name}
                                </Link>
                                <span className="star-toggle far fa-star" />
                              </div>
                              <div className="col-mail col-mail-2">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: important.description,
                                  }}
                                ></div>
                                <div className="date">{important.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPane>
                      <TabPane tabId="4">
                        <ul className="message-list">
                          {map(draftmails, (draft, key) => (
                            <li
                              key={"draft-" + key}
                              className={draft.read ? "" : "unread"}
                            >
                              <div className="col-mail col-mail-1">
                                <div className="checkbox-wrapper-mail">
                                  <Input
                                    type="checkbox"
                                    id={draft.id + "draft"}
                                  />
                                  <Label
                                    htmlFor={draft.id + "draft"}
                                    className="toggle"
                                  />
                                </div>
                                <Link to="#" className="title">
                                  {draft.name}
                                </Link>
                                <span className="star-toggle far fa-star" />
                              </div>
                              <div className="col-mail col-mail-2">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: draft.description,
                                  }}
                                ></div>
                                <div className="date">{draft.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPane>
                      <TabPane tabId="5">
                        <ul className="message-list">
                          {map(sentmails, (sent, key) => (
                            <li
                              key={"sent-" + key}
                              className={sent.read ? "" : "unread"}
                            >
                              <div className="col-mail col-mail-1">
                                <div className="checkbox-wrapper-mail">
                                  <Input
                                    type="checkbox"
                                    id={sent.id + "sent"}
                                  />
                                  <Label
                                    htmlFor={sent.id + "sent"}
                                    className="toggle"
                                  />
                                </div>
                                <Link to="#" className="title">
                                  {sent.name}
                                </Link>
                                <span className="star-toggle far fa-star" />
                              </div>
                              <div className="col-mail col-mail-2">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: sent.description,
                                  }}
                                ></div>
                                <div className="date">{sent.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPane>
                      <TabPane tabId="6">
                        <ul className="message-list">
                          {map(trashmails, (trash, key) => (
                            <li
                              key={"trash-" + key}
                              className={trash.read ? "" : "unread"}
                            >
                              <div className="col-mail col-mail-1">
                                <div className="checkbox-wrapper-mail">
                                  <Input
                                    type="checkbox"
                                    id={trash.id + "trash"}
                                  />
                                  <Label
                                    htmlFor={trash.id + "trash"}
                                    className="toggle"
                                  />
                                </div>
                                <Link to="#" className="title">
                                  {trash.name}
                                </Link>
                                <span className="star-toggle far fa-star" />
                              </div>
                              <div className="col-mail col-mail-2">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: trash.description,
                                  }}
                                ></div>
                                <div className="date">{trash.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPane>
                    </TabContent>
                  </Card>
                  <Row>
                    <Col xs="7">Showing 1 - 20 of 1,524</Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button type="button" color="success" size="sm">
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button type="button" color="success" size="sm">
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EmailInbox.propTypes = {
  activeTab: PropTypes.string,
  draftmails: PropTypes.any,
  sentmails: PropTypes.any,
  trashmails: PropTypes.any,
  onGetSentMails: PropTypes.func,
  onGetDraftMails: PropTypes.func,
  onGetTrashMails: PropTypes.func,
  inboxmails: PropTypes.array,
  starredmails: PropTypes.array,
  onGetInboxMails: PropTypes.func,
  onGetStarredMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
};

const mapStateToProps = mails => ({
  inboxmails: mails.inboxmails,
  starredmails: mails.starredmails,
  importantmails: mails.importantmails,
  trashmails: mails.trashmails,
  draftmails: mails.draftmails,
  sentmails: mails.sentmails,
});

const mapDispatchToProps = dispatch => ({
  onGetInboxMails: () => dispatch(getInboxMails()),
  onGetStarredMails: () => dispatch(getStarredMails()),
  onGetImportantMails: () => dispatch(getImportantMails()),
  onGetDraftMails: () => dispatch(getDraftMails()),
  onGetSentMails: () => dispatch(getSentMails()),
  onGetTrashMails: () => dispatch(getTrashMails()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailInbox));
