const { Table, Row, Container, Col } = require("reactstrap");
import React from "react";
import "../../assets/css/jobattr.css";

class Countries extends React.Component {
  render() {
    const data = [
      {
        name: "America",
        default: true,
        active: true,
      },
      {
        name: "China",
        default: true,
        active: true,
      },
      {
        name: "India",
        default: true,
        active: true,
      },
      {
        name: "Russia",
        default: true,
        active: true,
      },
    ];
    return (
      <Container fluid>
        <Row style={{ marginTop: "70px", marginLeft: "20px" }}>
          <Row className="align-items-center justify-content-center">
            <Col md={6} style={{ padding: "40px" }}>
              <button className="btn btn-primary">New City</button>
            </Col>
            <Col md={6}>
              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            </Col>
          </Row>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th>Functional Area</th>
                  <th>default</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {item.default ? (
                        <i className="bx bx-comment-error green"></i>
                      ) : (
                        <i className="bx bx-x"></i>
                      )}
                    </td>
                    <td>
                      {item.active ? (
                        <i className="bx bx-comment-error green"></i>
                      ) : (
                        <i className="bx bx-x"></i>
                      )}
                    </td>
                    <td className="col_btn">
                      <button className="btn btn-primary">Edit</button>
                      <button className="btn btn-danger">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Row>
      </Container>
    );
  }
}

export default Countries;
