import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import "../../../assets/css/csv.css";
import { withRouter } from "react-router-dom";
import "../../../assets/css/jobsaddition.css";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ReactSelect from "react-select";
import { toast } from "react-hot-toast";

class InviteCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multi: [],
      candidateName: "candidate_name",
      recruterName: "recuter_name",
      companyName: "company_name",
      jobTitle: "job_title",
      jobLink: "job_link",
      jobApplyLink: "apply_link",
      recruterSignature: "recruter_signature",
    };
  }
  render() {
    this.handleCandidateName = () => {
      if (this.state.candidateName === "candidate_name")
        this.setState({
          candidateName: "",
        });
    };
    this.handleSendInviation = () => {
      toast.success("Candidate Invited Successfully");
      this.props.toggle();
    };
    this.handleRecruterName = () => {
      if (this.state.recruterName === "recuter_name")
        this.setState({
          recruterName: "",
        });
    };
    this.handleCompanyName = () => {
      if (this.state.companyName === "company_name")
        this.setState({
          companyName: "",
        });
    };
    this.handleJobTitle = () => {
      if (this.state.jobTitle === "job_title")
        this.setState({
          jobTitle: "",
        });
    };
    this.handleJobLink = () => {
      if (this.state.jobLink === "job_link")
        this.setState({
          jobLink: "",
        });
    };
    this.handleJobApplyLink = () => {
      if (this.state.jobApplyLink === "apply_link")
        this.setState({
          jobApplyLink: "",
        });
    };
    this.handlRecruterSignature = () => {
      if (this.state.recruterSignature === "recruter_signature")
        this.setState({
          recruterSignature: "",
        });
    };
    this.handleSelectMulti = data => {
      let inputdata = [];
      data.map(({ value }) => {
        inputdata.push({ label: value, value });
      });
      const index = data.length;

      this.setState({ multi: inputdata });
    };
    const optionGroup = [
      {
        label: "Candidates",
        options: [
          {
            label: (
              <div className="invite_profile_card">
                <div className="invite_profile_round">
                  <span>CH</span>
                </div>
                <div className="invite_profile_details">
                  <h6>Chatpatni Ashwini</h6>
                  <h6>chatpatniaswini@gmail.com</h6>
                  <Label>Backend Engineer</Label>
                </div>
              </div>
            ),
            value: "chintalpani",
          },
          {
            label: (
              <div className="invite_profile_card">
                <div className="invite_profile_round">
                  <span>CH</span>
                </div>
                <div className="invite_profile_details">
                  <h6>Chatpatni Ashwini</h6>
                  <h6>chatpatniaswini@gmail.com</h6>
                  <Label>Backend Engineer</Label>
                </div>
              </div>
            ),
            value: "Mustadddrd",
          },
        ],
      },
    ];
    const { isOpen, toggle } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Invite Candidates</ModalHeader>
          <ModalBody>
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs title="Candidates" breadcrumbItem="Add Candidates" />

              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">To</CardTitle>

                      <Form>
                        <Row>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <ReactSelect
                                style={{ zIndex: "999" }}
                                value={this.state.multi}
                                isMulti={true}
                                onChange={data => {
                                  this.handleSelectMulti(data);
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                      <label className="border_bottom">
                        Hit enter to add Email lists
                      </label>
                      <Input
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                        defaultValue="Jasdob vacancy for [[job_tiasfdtle]] position at [[company_name]]"
                      />
                      <label className="border_bottom"></label>
                      <div
                        className="editable_div candidate_email"
                        data-gramm="false"
                        contentEditable="true"
                        autoCorrect="false"
                      >
                        Dear
                        <span
                          className="data_edit  label btn btn-outline-primary badge badge-soft-primary"
                          onClick={this.handleCandidateName}
                        >
                          {this.state.candidateName}
                        </span>
                        , hope this mail finds you well!
                        <br />
                        <br />
                        I'm{" "}
                        <span
                          className="data_edit  label btn btn-outline-primary badge badge-soft-primary"
                          onClick={this.handleRecruterName}
                        >
                          {this.state.recruterName}
                        </span>
                        , and I'm writing on behalf of
                        <span
                          className="data_edit  label btn btn-outline-primary badge badge-soft-primary"
                          onClick={this.handleCompanyName}
                        >
                          {this.state.companyName}
                        </span>
                        . I came across your profile on one of the job portals
                        and, based on your experience, you seem like a great fit
                        to fill our
                        <span
                          className="data_edit  label btn btn-outline-primary badge badge-soft-primary"
                          onClick={this.handleJobTitle}
                        >
                          {this.state.jobTitle}
                        </span>
                        position
                        <br />
                        <br />
                        You can go through the Job Description for the role
                        here:
                        <span
                          className="data_edit  label btn btn-outline-primary badge badge-soft-primary"
                          onClick={this.handleJobLink}
                        >
                          {this.state.jobLink}
                        </span>
                        . If the JD looks good to you, and if it matches your
                        career goals, please use the below link to complete your
                        application.
                        <br />
                        <br />
                        <span
                          className="data_edit  label btn btn-outline-primary badge badge-soft-primary"
                          onClick={this.handleJobApplyLink}
                        >
                          {this.state.jobApplyLink}
                        </span>
                        <br />
                        <br />
                        Looking forward to connecting with you.
                        <br />
                        <br />
                        Best Regards,
                        <span
                          className="data_edit  label btn btn-outline-primary badge badge-soft-primary"
                          onClick={this.handlRecruterSignature}
                        >
                          {this.state.recruterSignature}
                        </span>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      onClick={this.handleSendInviation}
                      style={{
                        textTrasform: "uppercase",
                      }}
                      type="submit"
                      color="success"
                    >
                      Send an application form link
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          {/* <ModalFooter  type="footer"  className="d-flex justify-content-evenly">
            <Button onClick={handleUpload} type="button" color="primary">
                Upload Excel/CSV/TSV and import candidates
            </Button>
       
        </ModalFooter> */}
        </div>
      </Modal>
    );
  }
}

InviteCandidates.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default withRouter(InviteCandidates);
