import {
  GET_TASKS,
  GET_TASKS_FAIL,
  GET_TASKS_SUCCESS,
  GET_ADDED_TASKS,
  UPDATE_TASK,
  CHECK_SINGLE,
} from "./actionTypes";

export const getTasks = () => ({
  type: GET_TASKS,
  payload: "hello world",
});
export const getTasksAdded = () => ({
  type: GET_ADDED_TASKS,
});
export const getTasksSuccess = tasks => ({
  type: GET_TASKS_SUCCESS,
  payload: tasks,
});

export const getTasksFail = error => ({
  type: GET_TASKS_FAIL,
  payload: error,
});
export const updatingTaskEvent = payload => ({
  type: UPDATE_TASK,
  payload: payload,
});
export const checkSingleTaskEvent = payload => ({
  type: CHECK_SINGLE,
  payload,
});
export const updatingTask = (state, { payload }) => {
  const item = state.tasks.find(({ id }) => id === payload.id);
  if (typeof item === "undefined") return state;
  item.clicked = payload.clicked;
  item.tasks.map(item => {
    item.clicked = payload.clicked;
  });
  // const index = state.tasks.indexOf(item);
  // state.tasks[index] = item;
  return state;
};
export const checkSingleTask = (state, payload) => {
  const item = state.tasks.find(({ id }) => id === payload.parentId);
  const child = item.tasks.find(({ id }) => id === payload.id);
  child.clicked = !child.clicked;
  return state;
};
