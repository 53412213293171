import IndustryInput from "../header/industrySelect";

export const IndustryOperators = [
  {
    label: "includes",
    value: "includes",
    getApplyFilterFn: filterItem => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return params => {
        return filterItem.value?.includes(params?.value);
      };
    },
    InputComponent: IndustryInput,
    getValueAsString: value => `${value}`,
  },
  {
    label: "excludes",
    value: "excludes",
    getApplyFilterFn: filterItem => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return params => {
        return filterItem.value?.includes(params?.value) === false;
      };
    },
    InputComponent: IndustryInput,
    getValueAsString: value => `${value}`,
  },
];
