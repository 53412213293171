import { Paper } from "@mui/material";
import { PrimaryTypography } from "assets/Mui/globalTheme";
import ScheduleInterviewChild from "components/interview";

export default function ScheduleInterview() {
  return (
    <div style={{ padding: "20px" }}>
      <PrimaryTypography variant="h2" component="h2">
        Schedule Interview
      </PrimaryTypography>
      <ScheduleInterviewChild />
    </div>
  );
}
