import { POST } from "common/constants/methods";
import callApi from "helpers/callApi";

export async function verifyOTP(email, otp) {
  const otpResult = await callApi(
    "/company_authorise/verify_otp",
    { email, otp },
    POST
  );
  return otpResult;
}

export async function handleResendOTP(email) {
  return await callApi(
    "/company_authorise/resend_otp",
    {
      email,
    },
    POST
  );
}
