import { Grid, Stack } from "@mui/material";
import { BlackTypography, PrimaryButton } from "assets/Mui/globalTheme";
import ComingSoonImage from "../assets/images/zepul/coming_soon.png";
import { useHistory } from "react-router-dom";
export default function ComingSoon() {
  const history = useHistory();
  return (
    <Grid container sx={{ paddingLeft: "80px" }} alignItems={"center"}>
      <Grid item xs={12} md={4}>
        <Stack spacing={4}>
          <BlackTypography
            sx={{ fontSize: "38px !important" }}
            variant="h2"
            component="h2"
          >
            Coming Soon
          </BlackTypography>

          <PrimaryButton
            onClick={() => history.goBack()}
            sx={{ width: "200px" }}
          >
            Go Back
          </PrimaryButton>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8}>
        <img
          src={ComingSoonImage}
          alt="coming soon_img"
          style={{
            height: "400px",
            width: "500px",
          }}
        />
      </Grid>
    </Grid>
  );
}
