import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
const BackgroundEvaluatorProfile = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row style={{ padding: "40px" }}>
            <Card className="p-1">
              <CardBody className="cover_img_cont">
                <img
                  src="https://images.unsplash.com/photo-1528465424850-54d22f092f9d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  className="cover_img"
                />
                <Row className="align-items-center cover_left">
                  {" "}
                  <Col xs={12} className="company_details">
                    <div>
                      <img
                        src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                        className="md_img profile_img"
                      />
                    </div>
                    <div>
                      <h4>Zepul Hyderabad</h4>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xs={8} className="gray">
                    <Row>
                      <Col xs={8} className="personal_details">
                        <div>
                          <p>
                            <GroupIcon /> <span>www.zepul.com</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <LocationOnIcon />{" "}
                            <span>United State, America</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <GroupIcon /> <span>4440 Employees</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <GroupIcon /> <span>+914643473664</span>
                          </p>
                        </div>
                      </Col>
                      <Col xs={3} className="company_job_details">
                        <p>Job Openings</p>
                        <h5 className="company-font-weight company-text">30</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xs={12}>
                    <h5 className="company-font-weight">About Us: </h5>
                    <p>
                      A unique and powerful software suite to transform the way
                      you work built by a company that values your privacy. A
                      unique and powerful software suite to transform the way
                      you work built by a company that values your privacy. A
                      unique and powerful software suite to transform the way
                      you work built by a company that values your privacy.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={12}>
                    <h5 className="company-font-weight">Perks & Conditions</h5>
                  </Col>
                  <Col xs={12}>
                    <ul>
                      {new Array(4).fill(0).map(item => (
                        <>
                          <li>Better Devlopement Enviornment</li>
                          <li>
                            unique and powerful software suite to transform the
                            way you work
                          </li>
                        </>
                      ))}
                    </ul>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xs={12}>
                    <h5 className="company-font-weight">Socials</h5>
                  </Col>
                  <Col xs={12} className="mt-2">
                    <p>Linkedin</p>
                  </Col>
                  <Col xs={12}>
                    <div className="small_flex">
                      {new Array(3).fill(0).map(item => (
                        <img
                          src={
                            "https://images.unsplash.com/photo-1661956600684-97d3a4320e45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
                          }
                          className="large_img"
                        />
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} className="mt-4">
                    <p>Instagram</p>
                  </Col>
                  <Col xs={12}>
                    <div className="small_flex">
                      {new Array(3).fill(0).map(item => (
                        <img
                          src={
                            "https://images.unsplash.com/photo-1580927752452-89d86da3fa0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                          }
                          className="large_img"
                        />
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4 child_col_mt">
                  <Col xs={12}>
                    <h5 className="company-font-weight">Analytics of Zepul</h5>
                  </Col>
                  <Row className="mt-2">
                    <Col xs={9} className="analytics_cont">
                      <Row>
                        <Col xs={6}>
                          <div>
                            <h6>8 Senior Management Hires</h6>
                            <p>Including Dinesh B and 8 others</p>
                          </div>

                          <div className="min_flex">
                            {new Array(3).fill(0).map(item => (
                              <div className="rounded_full">
                                <img
                                  src={
                                    "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                  }
                                  className="rounded_small_img"
                                />
                              </div>
                            ))}
                            <div className="rounded_small_button">
                              <p>+5</p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={3} className="pt-2">
                          <p className="company-font-weight company-fonts company-text">
                            14
                          </p>
                          <h6 className="company-fonts">Total Jobs</h6>
                        </Col>
                        <Col xs={3} className="pt-2">
                          <p className="company-font-weight company-fonts company-text">
                            <LibraryBooksIcon /> 10
                          </p>
                          <h6 className="company-fonts">Hired</h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BackgroundEvaluatorProfile;
