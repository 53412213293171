import { put, takeEvery } from "redux-saga/effects";
import { GET_TASKS } from "./actionTypes";
import { getTasksSuccess, getTasksFail } from "./actions";

function* fetchTasks() {
  let i = 0;
  let parent = 0;
  try {
    const response = {
      pipeline_id: "084ba94f-5b0d-42b9-8063-1914a7d26d1e",
      pipeline_stage_details: [
        {
          stage_name: "Applied",
          stage_id: "e4534550-ee8f-4156-91c3-57c64009d723",
          user_job_posts_count: 1,
          user_job_posts_list: [
            {
              id: "b22a8fdb-1f5d-45e0-8ee3-f48ca9d6a4c0",
              createdAt: "2022-11-29T11:20:22.518Z",
              updatedAt: "2022-11-29T11:20:22.518Z",
              skills: ["aa2ff46a-a7a2-471f-b345-80c213465c21"],
              about_this_job: "nice job",
              is_active: true,
              pipeline_id: "084ba94f-5b0d-42b9-8063-1914a7d26d1e",
              stage_id: "e4534550-ee8f-4156-91c3-57c64009d723",
              user_id: "4af0941d-4df9-4034-aff9-ab3133de14ad",
              job_post_id: "4359fb5c-93c9-4063-baf9-d91b7534669c",
            },
          ],
        },
        {
          stage_name: "Screened applications",
          stage_id: "72b584a2-fc0f-4b93-918c-bc9ab1b79d2e",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
        {
          stage_name: "Moderated",
          stage_id: "bc7538a0-1170-4f40-ac8c-37008f0fe4fd",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
        {
          stage_name: "Submitted",
          stage_id: "2f1590aa-d8d4-4ee0-8c5c-6d7d1be0c8e8",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
        {
          stage_name: "Shortlisted",
          stage_id: "daedb0e0-fcba-400e-bc0c-1788d98b5a3b",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
        {
          stage_name: "Interviewed",
          stage_id: "50fbe28f-0719-4f6f-9b0b-ddba3aec712b",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
        {
          stage_name: "Offered",
          stage_id: "dec15054-372c-41d6-b921-6fa45f1a8d3b",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
        {
          stage_name: "On board",
          stage_id: "eff12d80-d927-4313-8e88-949046ed161f",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
        {
          stage_name: "Rejected",
          stage_id: "a76dd92c-525b-41a6-b70b-8c40a922279e",
          user_job_posts_count: 0,
          user_job_posts_list: [],
        },
      ],
    };
    yield put(getTasksSuccess(response));
  } catch (error) {
    yield put(getTasksFail(error));
  }
}

function* tasksSaga() {
  yield takeEvery(GET_TASKS, fetchTasks);
}

export default tasksSaga;
