import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { perks } from "common/constants/perks";
import { Box, Grid, Modal } from "@mui/material";
import "../../../../assets/css/company/perks_selection.css";
import { PrimaryButton } from "assets/Mui/globalTheme";
const PerkModel = React.memo(function ModelComponent({
  userPerks,
  open,
  handleClose,
  updateValue,
}) {
  const [perkState, setPerkState] = useState([]);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  function handleClick(idx) {
    const duplicate = perkState;
    const updating = perkState?.findIndex((_, index) => index === idx);
    duplicate[updating].selected = !duplicate[updating]?.selected;
    setPerkState(duplicate);
    const resultant = duplicate
      .filter(({ name, selected }) => selected === true)
      ?.map(item => item?.name);
    setValue("perks", resultant);
  }
  useEffect(() => {
    setPerkState(perks);
  }, [perks]);
  useEffect(() => {
    const allPerks = userPerks?.map(item => item?.name);
    const data = perks?.filter(item => {
      return allPerks?.includes(item?.name);
    });
    const resultant = data?.map(item => item?.name);
    setValue("perks", resultant);
    const final = perks?.filter(item => {
      return allPerks?.includes(item?.name) === false;
    });
    const selectedPerks = userPerks?.map(item => {
      return {
        ...item,
        selected: true,
      };
    });
    setPerkState([...final, ...selectedPerks]);
  }, [userPerks]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const onSubmit = data => {
    const result = perks?.filter(item => {
      return data?.perks?.includes(item?.name);
    });
    updateValue(result);
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {perkState.map(({ image, name, selected }, index) => {
              return (
                <Grid item xs={2} key={name}>
                  <div
                    style={{
                      transition: "0.4s ease-in-out",
                    }}
                    className={`perks_selection ${
                      watch("perks")?.includes(name) ? "selected_perk" : " "
                    }`}
                    onClick={() => {
                      handleClick(index);
                    }}
                  >
                    <img src={image} className="small_img" />
                    <p>{name}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <PrimaryButton
            type="submit"
            sx={{ width: "100px", marginTop: "10px", marginLeft: "auto" }}
          >
            Submit
          </PrimaryButton>
        </form>
      </Box>
    </Modal>
  );
});
export default PerkModel;
