import avatar1 from "./zepul/zepul.png";
import avatar2 from "./zepul/zepul.png";
import avatar3 from "./zepul/zepul.png";
import avatar4 from "./zepul/zepul.png";
import avatar5 from "./zepul/zepul.png";
import avatar6 from "./zepul/zepul.png";
import avatar7 from "./zepul/zepul.png";
import avatar8 from "./zepul/zepul.png";
import img1 from "./zepul/zepul_dark.png";
import img2 from "./zepul/zepul_dark.png";
import img3 from "./zepul/zepul_dark.png";
import img4 from "./zepul/zepul_dark.png";
import img5 from "./zepul/zepul_dark.png";
import img6 from "./zepul/zepul_dark.png";
import img7 from "./zepul/zepul_dark.png";
import product7 from "./zepul/zepul_dark.png";
import product8 from "./zepul/zepul_dark.png";

export default {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  product7,
  product8,
};
