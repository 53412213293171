export const ZA = "ZA";
export const SP = "SP";
export const CA = "CA";
export const ADMIN = "ADMIN";
export const ACM = "ACM";
export const TE = "TE";
export const ACCOUNT_MANAGER = "ACCOUNT_MANAGER";
export const MODERATOR = "MODERATOR";
export const ZM = "ZM";
export const CS = "CS";
export const BVC = "BVC";
export const RC = "RC";
export const BVS = "BVS";
export const RS = "RS";
