const INIT_STATE = {
  open: "CLOSE_MODAL",
  values: {},
};

const Modals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "APPROVE_MODAL":
      return {
        ...state,
        open: action.type,
        values: action.payload?.values,
      };
    case "ADD_JOB_ROLE_MODAL":
      return {
        ...state,
        open: action.type,
        values: action.payload?.values,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        open: "CLOSE_MODAL",
        values: {},
      };
    default:
      return state;
  }
};

export default Modals;
