const INIT_STATE = {
  notes: [],
};

const Notes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_NOTES":
      return {
        ...state,
        notes: action.payload,
      };

    default:
      return state;
  }
};

export default Notes;
