import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Col, Container, Row } from "reactstrap";
import getSymbolFromCurrency from "currency-symbol-map";
import findWorkMode from "helpers/functions/findWorkMode";
import { imageURL } from "common/constants/commonURLS";
import { findJobType } from "helpers/functions/findJobType";
import { Avatar, Button, Chip, Typography } from "@mui/material";

const JobDetails = props => {
  const { isOpen, toggle } = props;

  return (
    <Modal
      isOpen={isOpen}
      className="font_family_strict"
      size="lg"
      style={{ maxWidth: "800px", width: "100%", minWidth: "800px" }}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalBody>
        <Row>
          <div className="d-flex align-items-center gap-2">
            <Avatar
              color="primary"
              sx={{ height: 64, width: 64 }}
              src={`${imageURL}${props.data?.company_details?.company_image}`}
              size="lg"
            >
              {props.data.company_details?.company_name?.charAt(0)}
            </Avatar>
            <div>
              <Typography
                level="h6"
                color="primary"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {props.data?.job_title}
              </Typography>
              <Typography
                level="p"
                color="neutral"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {props.data.company_details?.company_name}
              </Typography>
            </div>
          </div>

          <Col xs={8}>
            <h5 className="my-2">Job description</h5>
            <div
              style={{
                borderBottom: "1px solid gray",
                paddingBottom: "10px",
                overflow: "hidden",
                maxHeight: "400px",
                overflowY: "scroll",
              }}
              dangerouslySetInnerHTML={{
                __html: props.data?.job_desc,
              }}
            >
              {/* {props.data?.job_desc} */}
            </div>
          </Col>
          <Col className="job_detals_model_right_cont">
            <h4 className="details_of_right_side">Budget</h4>
            <p>
              {getSymbolFromCurrency(props.data?.currency)}{" "}
              {props.data?.min_sal} - {props.data?.max_sal}/{" "}
              {props.data?.job_type === "Full_Time" ? "Yr" : "Hr"}{" "}
            </p>
            <p>Job type</p>
            <h4 className="details_of_right_side">
              {findJobType(props.data?.job_type)}
            </h4>
            <p>Experience</p>
            <h4 className="details_of_right_side">
              {props.data?.min_exp}- {props?.data?.max_exp} years
            </h4>
            <p>Job Location</p>
            <h4 className="details_of_right_side">
              {props.data?.work_mode !== "Fully_remote"
                ? props.data?.location
                : `${props.data?.time_zone[0]} + ${
                    props.data?.time_zone.length - 1
                  } more`}
            </h4>
            <p>Work Mode</p>
            <h4 className="details_of_right_side">
              {findWorkMode(props.data?.work_mode)}
            </h4>
            <p>Job Description</p>
            <Button
              color="primary"
              variant="solid"
              onClick={() => {
                window.open(imageURL + props.data?.attachment);
              }}
            >
              View
            </Button>
          </Col>
          <Col className="ml-auto" xs={12}>
            <Typography
              level="p"
              color="neutral"
              style={{
                textTransform: "capitalize",
              }}
            >
              Functional Areas
            </Typography>{" "}
            <div className="my-2 d-flex align-items-center gap-2 flex-wrap job_contents">
              {props.data?.functional_area_details?.map((item, idx) => {
                return (
                  <Chip
                    key={idx}
                    color="success"
                    size="small"
                    onClick={function () {}}
                    variant="outlined"
                    label={item?.name}
                  ></Chip>
                );
              })}
            </div>
          </Col>
          <Col className="ml-auto" xs={12}>
            <Typography
              level="p"
              color="neutral"
              style={{
                textTransform: "capitalize",
              }}
            >
              Skills
            </Typography>{" "}
            <div className="my-2 d-flex align-items-center gap-2 flex-wrap">
              {props.data?.key_skill_details?.map((item, idx) => {
                return (
                  <Chip
                    key={idx}
                    color="danger"
                    size="sm"
                    onClick={function () {}}
                    variant="outlined"
                    label={item?.name}
                  ></Chip>
                );
              })}
            </div>
          </Col>
          <Col className="ml-auto" xs={12}>
            <Typography
              level="p"
              color="neutral"
              style={{ textTransform: "capitalize" }}
            >
              Job Roles
            </Typography>
            <div className="d-flex align-items-center gap-2 flex-wrap  my-2">
              {props.data?.job_roles_details?.map((item, idx) => {
                return (
                  <Chip
                    key={idx}
                    color="primary"
                    size="sm"
                    onClick={function () {}}
                    variant="outlined"
                    label={item?.name}
                  ></Chip>
                );
              })}
            </div>
          </Col>
          <Col className="ml-auto" xs={12}>
            <h6>Educational Qualifications</h6>
            <div className="d-flex align-items-center gap-2">
              {props.data?.educational_qualification_details?.map(
                (item, idx) => {
                  return (
                    <Chip
                      key={idx}
                      color="success"
                      size="sm"
                      onClick={function () {}}
                      variant="outlined"
                      label={`${item.education_qualification_id.name} 
                      ${item.education_qualification_spec_id.name}`}
                    ></Chip>
                  );
                }
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

JobDetails.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default JobDetails;
