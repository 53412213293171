import React from "react";
import { Link } from "react-router-dom";
import { Alert, Card, Col, Input, Row } from "reactstrap";

class OverDue extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Col xs={12}>
          <Row>
            <Card
              style={{
                padding: "20px",
              }}
            >
              <div className="flex_btns">
                <div className="alert_small " style={{ width: "300px" }}>
                  <Alert color="warning" className="alert_small_comp">
                    <p className="bold_success">Overdue</p>
                  </Alert>
                </div>{" "}
                <Input
                  type="text"
                  placeholder="search candidates by anything or use keywords"
                />
              </div>
            </Card>
          </Row>
          <Row>
            <div
              className="email_brand_div new_calender_grid"
              style={{ minHeight: "70vh" }}
            >
              <div className="calender_contents">
                <i
                  className="dripicons-align-justify
"
                />
                <h5>No OverDue Candidates</h5>
                <p>
                  Candidates whose recruitment stages are past their time limits
                  will appear here.
                </p>

                <Link to="/">How to set overdue candidate reminders?</Link>
              </div>
            </div>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

export default OverDue;
