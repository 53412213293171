import { PUT } from "common/constants/methods";
import callApi from "helpers/callApi";
async function companyId() {
  return await JSON.parse(localStorage.getItem("authUser"))?.company_id;
}
async function Id() {
  return await JSON.parse(localStorage.getItem("authUser"))?.id;
}
export async function assignAccountMangerToCompany(companyId, accountMangerId) {
  return await callApi(
    `/admin_authorise/assign_account_manager_to_company/${companyId}`,
    {
      is_job_post_verifier_assigned: true,
      job_post_verifier_id: accountMangerId,
    },
    PUT
  );
}

export const getSingleAccountManger = async () => {
  const { data } = await callApi(
    `admin_staff/${await Id()}?company_id=${await companyId()}`
  );
  return data;
};
