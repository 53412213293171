import { POST, PUT } from "common/constants/methods";
import callApi from "helpers/callApi";

export const createCategories = async data => {
  return await callApi("/blogs/category", data, POST);
};
export const allCategories = async () => {
  return await callApi("/blogs/get_categories");
};

export const updateCategories = async data => {
  return await callApi("/blogs/category", data, PUT);
};
export const deleteCategories = async data => {
  return await callApi("/blogs/category", data, DELETE);
};

export const createBlogs = async data => {
  return await callApi("/blogs/blog", data, POST);
};
